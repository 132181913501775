import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import ru from 'vuetify/es5/locale/ru';
import en from 'vuetify/es5/locale/en';

import i18n from '@/i18n';

Vue.use(Vuetify);

export default new Vuetify({
    breakpoint: {
        mobileBreakpoint: 'xs',
    },
    lang: {
        locales: { ru, en },
        current: i18n.locale,
    },
    theme: {
        dark: Number(localStorage.dark ?? 0) > 0,
    }
});
