<template>
    <v-menu
        v-model="menu"

        right
        offset-x
        max-width="300px"

        :activator="activator"
        :close-on-content-click="false"

        @input="close"
    >
        <v-time-picker
            v-model="timeString"
            scrollable
            format="24hr"
            class="w-full"
        >
            <v-btn
                text
                color="error"
                @click="close"
            >
                Закрыть
            </v-btn>

            <v-btn
                text
                color="primary"
                @click="submit"
            >
                Применить
            </v-btn>
        </v-time-picker>
    </v-menu>
</template>

<script>
export default {
    name: 'TimePicker',

    data: () => ({
        menu: false,

        activator: null,
        date: new Date(),

        timeString: new Date()
            .toLocaleTimeString([]),

        res: null,
    }),

    methods: {
        open({ activator, date }) {
            this.activator = activator;
            this.date = date;

            this.timeString = date
                .toLocaleTimeString([]);

            this.menu = true;

            return new Promise((r) => (this.res = r));
        },

        close() {
            this.activator = null;
            this.menu = false;
            this.res(null);
        },

        submit() {
            const timezoneOffset = new Date().getTimezoneOffset() * 60000;
            const dateString = new Date(this.date - timezoneOffset)
                .toISOString()
                .slice(0, 10);
            const localeString = `${dateString} ${this.timeString}`.toLocaleString();
            this.res(new Date(localeString));
            this.close();
        },
    },
};
</script>
