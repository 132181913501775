<template>
    <div
        v-if="$isMobile()"
        class="py-2 d-flex justify-space-between align-center"
    >
        <div>
            <span
                v-if="!hideId"
                v-text="`#${item.id} `"
            />

            <span v-text="item.name" />
        </div>

        <div v-if="type !== 'processingDate'">
            <v-btn
                icon
                x-small
                @click="removeItem"
            >
                <v-icon
                    v-text="'mdi-close'"
                    small
                />
            </v-btn>
        </div>
    </div>

    <v-list-item v-else>
        <v-list-item-action v-if="!hideId">
            <v-list-item-action-text
                v-text="`#${item.id}`"
                class="text--primary font-weight-regular"
            />
        </v-list-item-action>

        <v-list-item-content>
            <v-list-item-subtitle
                v-text="item.name"
                class="text--primary font-weight-regular"
            />
        </v-list-item-content>

        <v-list-item-action v-if="type !== 'processingDate'">
            <v-btn
                icon
                small
                @click="removeItem"
            >
                <v-icon
                    v-text="'mdi-close'"
                    dense
                />
            </v-btn>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
export default {
    name: "FieldListItem",

    inject: ['$isMobile'],

    props: {
        type: {
            type: String,
            required: true,
        },

        item: {
            type: Object,
            required: true,
        },

        hideId: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    methods: {
        removeItem() {
            const payload = {
                type: this.type,
                item: this.item,
            };

            this.$emit("remove-item", payload);
        },
    },
};
</script>
