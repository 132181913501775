<template>
    <v-select
        :items="items"
        :value="value != null ? Number(value) : null"
        @input="$emit('input', $event)"
        dense
    />
</template>

<script>
import ProductOwnerFilterBase from "./Base";
export default {
    name: "ProductOwnerFilterBool",
    extends: ProductOwnerFilterBase,
    computed: {
        items: () => [
            { text: "", value: null },
            { text: "N", value: 0 },
            { text: "Y", value: 1 },
        ],
    },
};
</script>