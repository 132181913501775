<template>
    <router-link
        v-if="type == 'category'"
        :to="{
            name: 'Catalog',
            params: {
                category: value ? value.id : null,
            },
        }"
    >
        {{ value ? value.name : null }}
    </router-link>
    <div v-else-if="['enum', 'storage', 'rack', 'room'].includes(type)">{{ value ? value.name : "" }}</div>
    <div v-else-if="type == 'bool'">{{ value ? "Y" : "N" }}</div>
    <div v-else-if="['date', 'datetime'].includes(type)">
        {{ value | apiDate }}
    </div>
    <a
        v-else-if="value && type == 'string' && perks.includes('aslink')"
        target="_blank"
        :href="value"
    >
        {{ value }}
    </a>
    <div v-else>{{ value }}</div>
</template>

<script>
export default {
    name: "OwnerRow",
    props: {
        type: {
            type: String,
            required: true,
        },
        perks: {
            type: Array,
            required: true,
        },
        value: {
            required: true,
        },
    },
};
</script>