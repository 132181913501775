<template>
    <div>
        <date-time-picker
            ref="picker"
            :show-date="['date', 'datetime'].includes(head.type)"
            :show-time="['time', 'datetime'].includes(head.type)"
            hide-clear
        />
        <v-text-field
            v-if="head.operation != '∩'"
            readonly
            :value="value | apiDate"
            dense
            @click="openPicker"
        />
        <v-row v-else>
            <v-col>
                <v-text-field
                    readonly
                    dense
                    :value="interval[0] | apiDate"
                    @click="openPickerInterval(0)"
                />
            </v-col>
            <v-col>
                <v-text-field
                    readonly
                    dense
                    :value="interval[1] | apiDate"
                    :disabled="!interval[0]"
                    @click="openPickerInterval(1)"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ProductOwnerFilterBaseInterval from "./BaseInterval";
import DateTimePicker from "@c/DateTimePicker";
export default {
    name: "ProductOwnerFilterDateTime",
    extends: ProductOwnerFilterBaseInterval,
    components: {
        DateTimePicker,
    },
    methods: {
        async openPicker() {
            const value = await this.$refs.picker.open(this.value);
            this.$emit("input", value);
        },
        async openPickerInterval(index) {
            const value = await this.$refs.picker.open(this.interval[index]);
            this.interval.splice(index, 1, value);
        },
    },
};
</script>