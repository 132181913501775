<template>
    <component
        :is="$isMobile() ? 'v-dialog' : 'v-menu'"
        v-model="menu"
        offset-y
        top
        eager
        fullscreen
        :close-on-content-click="false"
        :max-height="$isMobile() ? '' : '90vh'"
    >
        <template #activator="{ on, attrs }">
            <v-text-field
                v-on="on"
                v-bind="attrs"

                readonly
                type="text"
                label="Новое значение"
                :value="displayedValue"
                :append-icon="appendIcon"

                @click:append="$emit('reset')"
            />
        </template>

        <v-card class="d-flex flex-column">
            <v-card-text
                class="flex-grow-1"
                :class="{
                    'px-0': $isMobile(),
                }"
            >
                <v-tabs
                    v-model="tab"
                    fixed-tabs
                    icons-and-text
                    class="pb-4"
                    :class="{
                        'pt-4': $isMobile(),
                    }"
                >
                    <v-tab>
                        Дата
                        <v-icon>mdi-calendar-today-outline</v-icon>
                    </v-tab>

                    <v-tab>
                        Время
                        <v-icon>mdi-clock-outline</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-date-picker
                            v-model="date"
                            scrollable
                            :first-day-of-week="1"
                            style="width: 100%;"
                        />
                    </v-tab-item>

                    <v-tab-item>
                        <v-time-picker
                            v-model="time"
                            scrollable
                            use-seconds
                            format="24hr"
                            style="width: 100%;"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>

            <v-divider />

            <v-container
                v-if="!$isMobile()"
                fluid
            >
                <v-row>
                    <v-col cols="6">
                        <v-btn
                            block
                            color="secondary"
                            @click="menu = false"
                        >
                            Закрыть
                        </v-btn>
                    </v-col>

                    <v-col cols="6">
                        <v-btn
                            block
                            color="primary"
                            @click="submit"
                        >
                            Применить
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>

            <v-container
                v-else
                fluid
            >
                <v-row>
                    <v-col>
                        <v-btn
                            block
                            color="primary"
                            @click="submit"
                        >
                            Применить
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-btn
                            block
                            color="secondary"
                            @click="menu = false"
                        >
                            Закрыть
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </component>
</template>

<script>
import { VMenu, VDialog } from 'vuetify/lib';

export default {
    name: 'DatePicker',

    inject: ['$isMobile'],

    components: {
        VMenu,
        VDialog,
    },

    props: {
        draftTime: {
            type: String,
            required: true,
        },

        appendIcon: {
            type: String,
            default: '',
        },
    },

    data: () => ({
        menu: false,
        tab: null,
        date: new Date().toISOString().substr(0, 10),
        time: new Date().toLocaleTimeString([]),
    }),

    watch: {
        menu() {
            const timezoneOffset = new Date().getTimezoneOffset() * 60000;
            this.date = new Date(new Date(this.draftTime) - timezoneOffset).toISOString().substr(0, 10);
            this.time = new Date(this.draftTime).toLocaleTimeString([]);
        },
    },

    computed: {
        displayedValue() {
            const date = new Date(this.draftTime).toLocaleDateString();
            const time = new Date(this.draftTime).toLocaleTimeString([]);
            return `${date} ${time}`;
        }
    },

    methods: {
        submit() {
            this.$emit('submit', `${this.date} ${this.time}`);
            this.menu = false;
        }
    },
};
</script>
