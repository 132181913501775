<template>
    <div>
        <v-simple-table style="width: 100%">
            <tbody>
                <adaptive-item icon="mdi-pound">
                    <template #title>ID</template>
                    <template #content>{{ transaction.id }}</template>
                </adaptive-item>

                <adaptive-item icon="mdi-list-status">
                    <template #title>Статус</template>
                    <template #content>
                        <span
                            v-text="status.text"
                            :class="status.textClass"
                        />
                    </template>
                </adaptive-item>

                <adaptive-item icon="mdi-pound">
                    <template #title>Дата создания</template>
                    <template #content>{{ createdDate }}</template>
                </adaptive-item>

                <adaptive-item
                    icon="mdi-calendar-clock"
                    v-if="transaction.status === 'completed'"
                >
                    <template #title>Дата обработки</template>
                    <template #content>{{ processingDate }}</template>
                </adaptive-item>

                <adaptive-item icon="mdi-currency-rub">
                    <template #title>Операции</template>
                    <template #content>
                        <div
                            v-for="{ id, path, text, textClass } in operations"
                            :key="id"
                        >
                            {{ path }}
                            <span
                                v-text="text"
                                :class="textClass"
                                class="text-no-wrap"
                            />
                        </div>
                    </template>
                </adaptive-item>

                <adaptive-item icon="mdi-account-multiple">
                    <template #title>Участники</template>
                    <template #content>{{ transactionUsers }}</template>
                </adaptive-item>

                <adaptive-item
                    v-if="transaction.links && transaction.links.length"
                    icon="mdi-link"
                >
                    <template #title>Связи</template>
                    <template #content>
                        <div
                            v-for="link in transaction.links"
                            :key="link.id"
                        >
                            <a
                                v-if="link.link"
                                :href="link.link"
                                target="_blank"
                                v-text="link.name"
                            />
                            <span
                                v-else
                                v-text="getLinkName(link)"
                            />
                        </div>
                    </template>
                </adaptive-item>

                <adaptive-item
                    v-if="transaction.comments && transaction.comments.length"
                    icon="mdi-comment-multiple"
                >
                    <template #title>Комментарии</template>
                    <template #content>
                        <div
                            v-for="comment in transaction.comments"
                            :key="comment.id"
                        >
                            <span class="text--secondary pr-1">
                                {{ new Date(comment.created).toLocaleDateString() }}
                                {{ new Date(comment.created).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }}
                            </span>

                            <span class="font-weight-medium pr-1">
                                {{ users[comment.user].login }}:
                            </span>

                            <span>
                                {{ comment.comment }}
                            </span>
                        </div>
                    </template>
                </adaptive-item>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
import { formatNumber } from '@u/formatting';
import { PathToCashbox, getPathToCostByName } from '@u/path';
import AdaptiveItem from "@c/norton-commander/dialogs/transaction/process/AdaptiveItem";

export default {
    name: "TransactionInfo",

    components: {
        AdaptiveItem,
    },

    props: {
        transaction: {
            type: Object,
            required: true,
        },
        cashboxCategories: {
            type: Array,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        costCategories: {
            type: Array,
            required: true,
        },
        costTemplates: {
            type: Array,
            required: true,
        },
        costElements: {
            type: Array,
            required: true,
        },
        statuses: {
            type: Object,
            required: true,
        },
        users: {
            type: Object,
            required: true,
        },
    },

    computed: {
        status() {
            const status = this.statuses[this.transaction.status];

            if (!status) {
                return {
                    text: '',
                    textClass: '',
                };
            }

            return {
                text: status.name,
                textClass: status.textClass,
            };
        },

        processingDate() {
            const formatDate = date => new Date(String(date).replace(/-/g, "/")).toLocaleString();
            return formatDate(this.transaction.processing_finish);
        },

        createdDate() {
            return new Date(this.transaction.draft_time).toLocaleString();
        },

        operations() {
            if (!this.transaction.operations) {
                return [];
            }

            return this.transaction.operations
                .map(({ id, cashbox, amount, currency }) => {
                    let path = `#${cashbox}`;
                    const item = this.cashboxes.find((c) => c.id === cashbox);

                    if (item) {
                        const itemPath = new PathToCashbox(item, this.cashboxCategories);
                        const title = `#${item.id} ${item.title}`;

                        const pathChunks = itemPath.array.map(({ title }) => title);
                        pathChunks.push(title);
                        path = pathChunks.join(' / ');
                    }

                    const sign = amount > 0 ? '+' : '';
                    const text = `${sign}${formatNumber(amount)} ${currency}`;
                    const textClass = amount > 0 ? 'success--text' : 'error--text';

                    return {
                        id,
                        path,
                        text,
                        textClass,
                    };
                });
        },

        transactionUsers() {
            const users = [];

            const created = this.users[this.transaction.user]?.login;
            const processed = this.users[this.transaction.processedUser]?.login;

            if (created) {
                users.push(created);
            }
            if (processed) {
                users.push(processed);
            }

            return users.join(' / ');
        },
    },

    methods: {
        getLinkName({ name, type }) {
            const result = String(name);

            if (type !== 'costs') {
                return result;
            }

            return getPathToCostByName(
                name,
                this.costTemplates,
                this.costElements,
                this.costCategories,
                result,
            );
        },
    },
};
</script>
