var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('adaptive-dialog',{attrs:{"data-test-id":"process-transaction-dialog","data-test-state":_vm.dialog,"dialog":_vm.dialog,"busy":_vm.busy,"dense":"","max-width":"1000"},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.type === 'process')?_c('span',[_vm._v("Подтверждение транзакции")]):_vm._e(),(_vm.type === 'cancel')?_c('span',[_vm._v("Отмена транзакции")]):_vm._e(),(_vm.type === 'info')?_c('span',[_vm._v("Информация о транзакции")]):_vm._e()]},proxy:true},{key:"body",fn:function(){return [(_vm.type !== 'info')?_c('div',{class:{
                'px-4 pt-5': _vm.$isMobile()
            }},[_c('span',{staticClass:"text--primary",class:{
                    'subtitle-1': !_vm.$isMobile(),
                    'body-2': _vm.$isMobile(),
                }},[_c('span',[_vm._v("Подтверждаете ")]),_c('span',{staticClass:"text-decoration-underline"},[(_vm.type === 'process')?_c('span',[_vm._v("обработку")]):_vm._e(),(_vm.type === 'cancel')?_c('span',[_vm._v("отмену")]):_vm._e()]),_c('span',[_vm._v(" транзакции ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("№"+_vm._s(_vm.transaction.id))]),_vm._v("? ")])]):_vm._e(),_c('div',{staticClass:"d-flex flex-column justify-center pb-1",class:{
                'pt-3': _vm.type !== 'info',
            }},[_c('v-sheet',{attrs:{"elevation":_vm.$isMobile() ? 0 : 2}},[_c('transaction-info',{attrs:{"transaction":_vm.transaction,"cashbox-categories":_vm.cashboxCategories,"cashboxes":_vm.cashboxes,"cost-categories":_vm.costCategories,"cost-templates":_vm.costTemplates,"cost-elements":_vm.costElements,"statuses":_vm.statuses,"users":_vm.users}})],1)],1),(_vm.type === 'info')?_c('v-container',{class:{
                'px-0': !_vm.$isMobile(),
            },attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-btn',{attrs:{"block":"","small":!_vm.$isMobile(),"ripple":false,"disabled":_vm.cancelMode},on:{"click":_vm.toggleCommentInput}},[_c('v-icon',{staticClass:"plus-icon mr-1",class:{ 'plus-icon--active': _vm.showCommentInput },attrs:{"dense":"","color":"success"}},[_vm._v(" mdi-plus ")]),(_vm.$isMobile())?_c('span',[_vm._v("Комментарий")]):_c('span',[(!_vm.showCommentInput)?_c('span',[_vm._v("Добавить комментарий")]):_c('span',[_vm._v("Отменить добавление комментария")])])],1)],1),_c('v-col',[_c('v-tooltip',{attrs:{"top":"","open-delay":"150","disabled":_vm.enableCancelButton},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"data-test-id":"process-transaction-dialog-button-cancel","block":"","small":!_vm.$isMobile(),"ripple":false,"disabled":!_vm.enableCancelButton},on:{"click":function($event){_vm.cancelMode = !_vm.cancelMode}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"dense":"","color":"error"}},[_vm._v(" mdi-close ")]),(_vm.$isMobile())?_c('span',[_vm._v("Отменить")]):_c('span',[(!_vm.cancelMode)?_c('span',[_vm._v("Отменить транзакцию")]):_c('span',[_vm._v("Отменить отмену транзакции")])])],1)],1)]}}],null,false,2361429367)},[_c('span',[_vm._v("Эту транзакцию отменить нельзя")])])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-tooltip',{attrs:{"top":"","open-delay":"150","disabled":_vm.disableEditButton},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"block":"","small":!_vm.$isMobile(),"disabled":_vm.disableEditButton},on:{"click":function($event){_vm.$emit('transaction:dialog:edit', _vm.transaction)
                                        _vm.close()}}},[_c('v-icon',{staticClass:"mr-1 text--secondary",attrs:{"dense":""}},[_vm._v(" mdi-receipt-text-edit-outline ")]),(_vm.$isMobile())?_c('span',[_vm._v("Редактировать")]):_c('span',[_vm._v("Редактировать транзакцию")])],1)],1)]}}],null,false,2108148984)},[_c('span',[_vm._v("Редактировать можно только черновики транзакций")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"top":"","open-delay":"150","disabled":_vm.enableRepeatButton},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"data-test-id":"process-transaction-dialog-button-repeat","block":"","small":!_vm.$isMobile(),"disabled":!_vm.enableRepeatButton},on:{"click":_vm.openCreateTransactionDialog}},[_c('v-icon',{staticClass:"mr-1",attrs:{"dense":"","color":"warning"}},[_vm._v(" mdi-rotate-left ")]),(_vm.$isMobile())?_c('span',[_vm._v("Повторить")]):_c('span',[_vm._v("Повторить транзакцию")])],1)],1)]}}],null,false,2295613985)},[_c('span',[_vm._v("Эту транзакцию повторить нельзя")])])],1)],1)],1):_vm._e(),((_vm.type !== 'info') || _vm.showCommentInput || _vm.cancelMode)?_c('div',{class:{
                'px-4': _vm.$isMobile(),
            }},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.formValidation),callback:function ($$v) {_vm.formValidation=$$v},expression:"formValidation"}},[_c('v-textarea',{ref:"textarea",staticClass:"pt-3",attrs:{"rules":_vm.rules,"dense":"","counter":"","outlined":"","auto-grow":"","no-resize":"","hide-details":"auto","append-icon":_vm.comment ? 'mdi-close' : '',"placeholder":("Комментарий" + ((_vm.type !== 'info') || _vm.cancelMode ? ' (опционально)' : ''))},on:{"click:append":function($event){_vm.comment = ''},"focus":function($event){return _vm.$refs.form.resetValidation()}},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1):_vm._e()]},proxy:true},{key:"submit",fn:function(){return [(_vm.type === 'process')?_c('v-btn',{attrs:{"data-test-id":"button-submit","block":"","color":"primary","disabled":!_vm.formValidation},on:{"click":_vm.processTransaction}},[_vm._v(" Подтверждаю обработку ")]):(_vm.type === 'cancel')?_c('v-btn',{attrs:{"data-test-id":"button-submit","block":"","color":"error","disabled":!_vm.formValidation},on:{"click":_vm.cancelTransaction}},[_vm._v(" Подтверждаю отмену ")]):(!_vm.cancelMode && _vm.showCommentInput)?_c('v-btn',{attrs:{"data-test-id":"button-submit","block":"","color":"success","disabled":!_vm.formValidation},on:{"click":_vm.addComment}},[_vm._v(" Добавить комментарий ")]):(_vm.cancelMode)?_c('v-btn',{attrs:{"data-test-id":"button-submit","block":"","color":"error","disabled":!_vm.formValidation},on:{"click":_vm.cancelTransaction}},[_vm._v(" Подтверждаю отмену ")]):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }