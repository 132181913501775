<template>
    <v-data-table
        :items="items"
        :headers="headers"
        :loading="loading"
        disable-pagination
        disable-sort
        hide-default-footer
    >
        <template #header.flags="{ header }">
            <span>{{ header.text }}</span>
            <flag-legend
                v-if="!$vuetify.breakpoint.mobile"
                :perks="perks"
            />
        </template>
        <template #top>
            <v-toolbar flat>
                <flag-legend
                    v-if="$vuetify.breakpoint.mobile"
                    :perks="perks"
                />
                <v-spacer />
                <v-btn color="primary" text @click="$emit('add')">
                    <v-icon left>mdi-plus</v-icon>
                    {{ $t("fields.list.add") }}
                </v-btn>
            </v-toolbar>
        </template>
        <template #[`item.name`]="{ item: { name } }">
            {{ name }}
        </template>
        <template #[`item.type`]="{ item: { type } }">
            <v-icon left>mdi-{{ typeById(type).icon }}</v-icon>
            <span class="one-line">{{ typeById(type).name }}</span>
        </template>
        <template #[`item.flags`]="{ item: { perks } }">
            <v-icon v-for="p in perks" :key="p" :title="perkById(p).name">
                mdi-{{ perkById(p).icon }}
            </v-icon>
        </template>
        <template #[`item.actions`]="{ item: { id, perks, name } }">
            <v-btn icon color="primary" @click="$emit('edit', id)">
                <v-icon v-if="checkCanEdit(perks)">mdi-pencil</v-icon>
                <v-icon v-else>mdi-information</v-icon>
            </v-btn>
            <v-btn
                icon
                color="error"
                @click="$emit('remove', { id, name })"
                :disabled="!checkCanDelete(perks)"
            >
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>

<style lang="scss" scoped>
.one-line {
    vertical-align: bottom;
}
</style>

<script>
import { checkCanEdit, checkCanDelete } from "@u/fields";
import FlagLegend from "./Legend";
export default {
    name: "FieldList",
    components: {
        FlagLegend,
    },
    props: {
        types: {
            type: Array,
            required: true,
        },
        perks: {
            type: Array,
            required: true,
        },
        owner: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        fields: [],
        loading: false,
    }),
    computed: {
        items() {
            return this.fields.filter((f) => !f.isHidden);
        },
        headers() {
            return [
                {
                    text: "#",
                    value: "id",
                },
                {
                    text: this.$t("fields.list.name"),
                    value: "name",
                },
                {
                    text: this.$t("fields.list.type"),
                    value: "type",
                },
                {
                    text: this.$t("fields.list.flags"),
                    value: "flags",
                },
                {
                    value: "actions",
                },
            ];
        },
    },
    methods: {
        checkCanEdit: (perks) => checkCanEdit(perks),
        checkCanDelete: (perks) => checkCanDelete(perks),
        typeById(id) {
            for (let t of this.types) {
                if (t.id == id) {
                    return t;
                }
            }
            return {
                icon: "alert",
                name: id,
            };
        },
        perkById(id) {
            id = id.split("|")[0];
            for (let t of this.perks) {
                if (t.id == id) {
                    return t;
                }
            }
            return {
                icon: "alert",
                name: id,
            };
        },
        async getData() {
            this.loading = true;
            try {
                const fields = await this.$api(`/${this.owner}/fields`);
                this.fields = fields;
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
    },
};
</script>