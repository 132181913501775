<template>
    <v-text-field
        readonly
        :value="meta.category.name"
        :label="field.name"
        dense
    />
</template>

<script>
import FieldBase from "./FieldBase";
export default {
    name: "FieldRoot",
    extends: FieldBase,
    props: {
        value: {
            type: Number,
        },
    },
    methods: {
        getDefaultValue() {
            return this.meta.category.id;
        },
        setValue(value) {
            this.$emit("input", value.id);
        },
    },
};
</script>