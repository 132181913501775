import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.yaml$/i);
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

const locales = Object.keys(JSON.parse(process.env.VUE_APP_I18N_LOCALES));
let targetLocale = process.env.VUE_APP_I18N_LOCALE;
if (localStorage.locale) {
    if (!locales.includes(localStorage.locale)) {
        delete localStorage.locale;
    } else {
        targetLocale = localStorage.locale;
    }
} else {
    let browserLocale = navigator.language ?? navigator.userLanguage;
    browserLocale = browserLocale.split('-')[0];
    if (locales.includes(browserLocale)) {
        targetLocale = browserLocale;
    }
}

export default new VueI18n({
    locale: targetLocale,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
    messages: loadLocaleMessages(),
    pluralizationRules: {
        ru: (choice, choicesLength) => {
            if (choice === 0) {
                return 0;
            }

            const teen = choice > 10 && choice < 20;
            const endsWithOne = choice % 10 === 1;

            if (choicesLength < 4) {
                return (!teen && endsWithOne) ? 1 : 2;
            }
            if (!teen && endsWithOne) {
                return 1;
            }
            if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
                return 2;
            }

            return (choicesLength < 4) ? 2 : 3;
        }
    }
});
