<template>
    <v-list-item v-if="!$vuetify.breakpoint.smAndDown">
        <v-list-item-content>
            <slot name="info" />
        </v-list-item-content>

        <v-list-item-action>
            <div style="max-width: 125px">
                <slot name="balance" />
            </div>
        </v-list-item-action>

        <v-list-item-action>
            <div
                class="mx-4"
                style="width: 175px"
            >
                <slot name="amount" />
            </div>
        </v-list-item-action>

        <v-list-item-action>
            <div style="max-width: 125px">
                <slot name="result" />
            </div>
        </v-list-item-action>

        <v-list-item-action />
    </v-list-item>

    <div
        v-else

        class="py-2"
    >
        <div>
            <slot name="info" />
        </div>

        <div class="pt-2 d-flex">
            <div>
                <slot name="balance" />
            </div>

            <div>
                <slot name="amount" />
            </div>

            <div>
                <slot name="result" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AdaptiveItem",
};
</script>
