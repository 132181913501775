var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('full-overlay',{model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),(_vm.category)?_c('multipane',[_c('div',{style:({
                minWidth: '25%',
                maxWidth: '75%',
            })},[_c('category-list',{attrs:{"id":_vm.category}})],1),_c('custom-resizer'),_c('div',{style:({
                minWidth: '25%',
                maxWidth: '75%',
                flexGrow: 1,
            })},[_c('product-list',{attrs:{"category":_vm.category,"pg":_vm.pg,"sort":_vm.sort,"dir":_vm.dir,"flt":_vm.flt}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }