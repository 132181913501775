<template>
    <v-menu
        v-model="menu"
        offset-y
        bottom
        max-height="40vh"
        :close-on-content-click="false"
    >
        <template #activator="{ on, attrs }">
            <v-text-field
                v-on="on"
                v-bind="attrs"

                readonly
                type="text"
                label="Новое значение"
                :value="selectedItem.name"
                :append-icon="appendIcon"

                @click:append="$emit('reset')"
            />
        </template>

        <v-list
            dense
            class="pa-0"
            style="font-size: 13px;"
            v-show="!disabled"
        >
            <recursive-list
                v-for="node in tree"
                :key="node.id"
                :node="node"
                :selected-item="selectedItem"
                :menu="menu"

                v-on="$listeners"
            />
        </v-list>
    </v-menu>
</template>

<script>
import RecursiveList from "@c/norton-commander/dialogs/transaction/edit/RecursiveList";

export default {
    name: 'CashboxMenu',

    components: {
        RecursiveList,
    },

    props: {
        tree: {
            type: Array,
            required: true,
        },

        selectedItem: {
            type: Object,
            required: true,
        },

        appendIcon: {
            type: String,
            default: '',
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        menu: false,
    }),
};
</script>
