<template>
    <v-card
        flat
        :loading="busy"

        class="item py-0"
        :class="listItemClasses"
    >
        <adaptive-item>
            <template #info>
                <v-list-item-title
                    class="d-flex align-center"
                    :style="$isMobile() ? 'font-size: 13px;' : ''"
                >
                    <text-highlight-wrapper
                        :text="`#${node.id} ${node.title}`"
                        :queries="searchText"
                    />
                </v-list-item-title>

                <v-expand-transition>
                    <v-list-item-subtitle v-if="hasError || status">
                        <span
                            v-if="hasError"
                            class="error--text"
                        >
                            При проведении транзакции произошла ошибка
                        </span>

                        <span
                            v-else-if="status"
                            :class="{ 'success--text': status === 'completed' }"
                        >
                            <span>Статус:</span>
                            <span>{{ statuses[status].name }}</span>
                        </span>
                    </v-list-item-subtitle>
                </v-expand-transition>
            </template>

            <template #balance>
                <readonly-text-field
                    :value="node.balance"
                    icon="mdi-plus"
                />
            </template>

            <template #amount>
                <v-text-field
                    v-model="value"

                    dense
                    outlined

                    type="number"
                    hide-details="auto"

                    :append-icon="!$isMobile() && value !== '' ? 'mdi-close' : ''"

                    @click:append="value = ''"
                    @focus="$event.target.select()"
                >
                    <template #append-outer>
                        <v-icon
                            dense
                            :class="{
                                'ml-1': !$isMobile(),
                                'ml-n2': $isMobile(),
                            }"
                        >
                            mdi-equal
                        </v-icon>
                    </template>
                </v-text-field>
            </template>

            <template #result>
                <readonly-text-field
                    :value="String(Number(node.balance) + Number(value))"
                    icon="mdi-currency-rub"
                />
            </template>
        </adaptive-item>
    </v-card>
</template>

<script>
import TextHighlightWrapper from "@c/TextHighlightWrapper";
import AdaptiveItem from '@c/norton-commander/dialogs/cashbox/mass-process/AdaptiveItem';
import ReadonlyTextField from '@c/norton-commander/dialogs/cashbox/mass-process/ReadonlyTextField';

export default {
    name: "CashboxListItem",

    inject: ['$isMobile'],

    components: {
        TextHighlightWrapper,
        AdaptiveItem,
        ReadonlyTextField,
    },

    props: {
        node: {
            type: Object,
            required: true,
        },

        statuses: {
            type: Object,
            required: true,
        },

        searchText: {
            type: String,
            required: true,
        },
    },

    data: () => ({
        busy: false,
        disabled: false,
        hasError: false,

        value: '',
        status: undefined,
    }),

    computed: {
        listItemClasses() {
            const margin = !this.$isMobile() ? 'mx-6' : '';

            if (this.hasError) {
                const bgColor = 'item--processed--error';
                return `${margin} ${bgColor}`;
            }

            if (!this.status || this.status === 'draft') {
                if (this.value !== '') {
                    const bgColor = `item--modified--${this.$vuetify.theme.isDark ? 'dark' : 'light'}`;
                    return `${margin} ${bgColor}`;
                }
            }

            if (this.status === 'completed') {
                const bgColor = 'item--processed--success';
                return `${margin} ${bgColor}`;
            }

            return `${margin}`;
        },
    },

    methods: {
        async submit() {
            this.disabled = true;

            if (this.value !== '') {
                this.busy = true;

                const payload = {
                    id: this.node.id,
                    amount: Number(this.value),
                    callback: this.callback,
                };

                this.$emit('submit', payload);
            }
        },

        callback({ hasError, disabled, busy, transaction }) {
            this.hasError = hasError;
            this.disabled = disabled;
            this.busy = busy;
            this.status = transaction ? transaction.status : undefined;
        },
    },
};
</script>

<style lang="scss" scoped>
.item {
    transition: all 300ms;

    &--modified {
        &--light {
            background-color: rgba(0, 0, 0, 0.075);
        }

        &--dark {
            background-color: rgba(255, 255, 255, 0.05);
        }
    }

    &--processed {
        &--success {
            background-color: rgba(0, 255, 0, 0.075);
        }

        &--error {
            background-color: rgba(255, 0, 0, 0.075);
        }
    }
}
</style>
