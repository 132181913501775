<template>
    <tr v-if="$isMobile()">
        <td class="py-2">
            <div class="font-weight-medium">
                <v-icon
                    v-if="icon"
                    v-text="icon"
                    small
                    class="mr-2"
                />

                <slot name="title" />
            </div>

            <slot name="content" />
        </td>
    </tr>

    <tr v-else>
        <td>
            <v-list-item>
                <v-list-item-icon>
                    <v-icon
                        v-text="icon"
                        small
                    />
                </v-list-item-icon>

                <v-list-item-content class="font-weight-medium text-no-wrap">
                    <slot name="title" />
                </v-list-item-content>
            </v-list-item>
        </td>

        <td class="py-1">
            <slot name="content" />
        </td>
    </tr>
</template>

<script>
export default {
    name: "AdaptiveItem",

    inject: ['$isMobile'],

    props: {
        icon: {
            type: String,
            default: '',
        },
    },
};
</script>
