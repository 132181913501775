var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"my-2",attrs:{"elevation":"1","color":_vm.color}},[_c('event-info',{attrs:{"event":_vm.event},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var info = ref.info;
return [_c('v-list-item',{on:{"click":function($event){return _vm.$emit('filter:toggle', _vm.event.id)}}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"value":_vm.checkbox,"dense":"","readonly":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',{class:((_vm.event.color) + "--text")},[_c('text-highlight-wrapper',{attrs:{"text":_vm.title,"queries":_vm.searchText}})],1),_vm._l((info),function(ref){
var label = ref.label;
var value = ref.value;
return _c('v-list-item-subtitle',{key:label,staticClass:"text--primary text-wrap"},[(label)?_c('span',{domProps:{"textContent":_vm._s((label + ": "))}}):_vm._e(),_c('span',{staticClass:"font-weight-light",domProps:{"textContent":_vm._s(value)}})])})],2),_c('v-list-item-action',[_c('event-buttons',{on:{"process":function($event){return _vm.$emit('event:process', _vm.event.id)},"edit":function($event){return _vm.$emit('event:dialog:edit', _vm.event)},"delete":function($event){return _vm.$emit('delete')}}})],1)],1)]}}])}),(_vm.event.transactions.length)?_c('v-divider'):_vm._e(),(_vm.event.transactions.length)?_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('div',{staticClass:"w-full text--secondary",domProps:{"textContent":_vm._s(("Количество транзакций: " + (_vm.event.transactions.length)))}})]},proxy:true}],null,false,3715019921)},[_c('v-divider'),_vm._l((_vm.event.transactions),function(transaction,transactionIndex){return _c('v-list-item',{key:transactionIndex},[_c('transaction-info',{attrs:{"transaction":transaction,"cashboxes":_vm.cashboxes,"cashbox-categories":_vm.cashboxCategories,"cost-templates":_vm.costTemplates,"cost-elements":_vm.costElements,"cost-categories":_vm.costCategories},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var info = ref.info;
return [_c('v-list-item-content',[_vm._l((info),function(ref){
var alias = ref.alias;
var value = ref.value;
var label = ref.label;
return [(alias === 'operations')?_vm._l((value),function(ref,index){
var path = ref.path;
var amount = ref.amount;
var amountClass = ref.amountClass;
return _c('v-list-item-subtitle',{key:("operation-" + index),staticClass:"text-wrap text--primary"},[_c('span',{domProps:{"textContent":_vm._s(path)}}),_c('span',{staticClass:"text-no-wrap ml-2",class:amountClass,domProps:{"textContent":_vm._s(amount)}})])}):[_c('v-list-item-subtitle',{key:alias,staticClass:"text--primary",domProps:{"textContent":_vm._s((label + ":"))}}),_vm._l((value),function(row,index){return _c('v-list-item-subtitle',{key:(alias + "-" + index),staticClass:"text-wrap text--primary font-weight-light"},[_c('span',{domProps:{"textContent":_vm._s(row)}})])})]]})],2)]}}],null,true)})],1)})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }