var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.$isMobile() ? 'v-dialog' : 'v-menu',{tag:"component",attrs:{"offset-y":"","top":"","eager":"","fullscreen":"","close-on-content-click":false,"max-height":_vm.$isMobile() ? '' : '90vh'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","type":"text","label":"Новое значение","value":_vm.displayedValue,"append-icon":_vm.appendIcon},on:{"click:append":function($event){return _vm.$emit('reset')}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"d-flex flex-column"},[_c('v-card-text',{staticClass:"flex-grow-1",class:{
                'px-0': _vm.$isMobile(),
            }},[_c('v-tabs',{staticClass:"pb-4",class:{
                    'pt-4': _vm.$isMobile(),
                },attrs:{"fixed-tabs":"","icons-and-text":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Дата "),_c('v-icon',[_vm._v("mdi-calendar-today-outline")])],1),_c('v-tab',[_vm._v(" Время "),_c('v-icon',[_vm._v("mdi-clock-outline")])],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-date-picker',{staticStyle:{"width":"100%"},attrs:{"scrollable":"","first-day-of-week":1},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-tab-item',[_c('v-time-picker',{staticStyle:{"width":"100%"},attrs:{"scrollable":"","use-seconds":"","format":"24hr"},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1)],1)],1),_c('v-divider'),(!_vm.$isMobile())?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","color":"secondary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Закрыть ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":_vm.submit}},[_vm._v(" Применить ")])],1)],1)],1):_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":_vm.submit}},[_vm._v(" Применить ")])],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"block":"","color":"secondary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Закрыть ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }