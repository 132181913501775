<template>
    <div>
        <v-text-field
            v-if="head.operation != '∩'"
            :value="value"
            @input="$emit('input', $event)"
            type="number"
            dense
        />
        <v-row v-else>
            <v-col>
                <v-text-field type="number" dense v-model="interval[0]" />
            </v-col>
            <v-col>
                <v-text-field
                    type="number"
                    dense
                    v-model="interval[1]"
                    :disabled="!interval[0]"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ProductOwnerFilterBaseInterval from "./BaseInterval";
export default {
    name: "ProductOwnerFilterNumber",
    extends: ProductOwnerFilterBaseInterval,
};
</script>