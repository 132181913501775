<template>
    <adaptive-dialog
        data-test-id="edit-filter-dialog"
        :data-test-state="dialog"

        :dialog="dialog"
        max-width="1000"
        dense

        @close="close"
        @submit="apply"
    >
        <template #title>
            <span v-if="isEditing">Редактирование фильтра</span>
            <span v-else>Фильтр по транзакциям</span>
        </template>

        <template #subtitle>{{ meta.name }}</template>

        <template #body>
            <div class="d-flex">
                <div style="border-right: solid #2196F3;">
                    <buttons-tabs
                        v-model="tab"
                        :fields="properties.map(({ props }) => props)"
                    />
                </div>

                <v-tabs-items
                    v-model="tab"
                    touchless
                    class="w-full"
                >
                    <v-tab-item
                        v-for="{ props, listeners } in properties"
                        :key="props.type"

                        :class="{
                            'px-4': !$isMobile(),
                            'px-2 py-2': $isMobile(),
                        }"
                    >
                        <filter-field
                            v-if="props.hasSearch"
                            v-bind="props"
                            :search-text="searchText"
                            v-on="listeners"
                        >
                            <template #menu-widget>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-text-field
                                            v-model="searchText"
                                            type="text"
                                            label="Поиск"
                                            prepend-icon="mdi-magnify"
                                            :append-icon="searchText ? 'mdi-close' : ''"

                                            dense
                                            outlined
                                            hide-details

                                            @click:append="searchText = ''"
                                            @keydown.home.stop
                                            @keydown.end.stop
                                        />
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </filter-field>

                        <filter-field
                            v-else-if="
                                [
                                    'siteTransactions',
                                    'siteSplits',
                                    'siteDeliveries',
                                ].includes(props.type)
                            "
                            v-bind="props"
                            v-on="listeners"
                        >
                            <template #body-widget>
                                <v-list class="pa-0">
                                    <v-text-field
                                        v-model="models[props.type]"

                                        type="number"
                                        label="#"
                                        min="1"

                                        dense
                                        outlined
                                        hide-details

                                        @keydown.enter.prevent="addItem(props.type);"
                                    >
                                        <v-icon
                                            slot="append"
                                            color="green"

                                            @click="addItem(props.type);"
                                        >
                                            mdi-plus
                                        </v-icon>
                                    </v-text-field>
                                </v-list>
                            </template>
                        </filter-field>

                        <filter-field
                            v-else-if="props.type === 'operationAmount'"
                            v-bind="props"
                            v-on="listeners"
                        >
                            <template #body-widget>
                                <v-list class="pa-0">
                                    <v-list-item class="px-0">
                                        <v-list-item-content>
                                            <v-text-field
                                                v-model="operationAmountFrom"
                                                type="number"
                                                label="От:"

                                                dense
                                                outlined
                                                hide-details

                                                style="max-width: 150px;"
                                            />
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item class="px-0">
                                        <v-list-item-content>
                                            <v-text-field
                                                v-model="operationAmountTo"
                                                type="number"
                                                label="До:"

                                                dense
                                                outlined
                                                hide-details

                                                style="max-width: 150px;"
                                            />
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </template>
                        </filter-field>

                        <filter-field
                            v-else-if="props.type === 'processingDate'"
                            v-bind="props"
                            v-on="listeners"
                        >
                            <template #menu-widget>
                                <v-list-item>
                                    <v-list-item-content>
                                        <date-picker
                                            :dates-from-filter="processingDate"
                                            @set-date="processingDate = $event"
                                            @clear-date="processingDate = null"
                                            @reset-date-helpers="processingDateHelpers = null"
                                        />
                                    </v-list-item-content>
                                </v-list-item>
                            </template>

                            <template #menu-widget-bottom>
                                <v-list class="py-0">
                                    <v-list-item
                                        :input-value="currentWeekActive"
                                        color="primary"
                                        @click="setCurrentWeek"
                                    >
                                        <v-list-item-action>
                                            <v-list-item-action-text>
                                                <v-icon
                                                    v-text="'mdi-calendar-week'"
                                                    :color="currentWeekActive ? 'primary' : ''"
                                                />
                                            </v-list-item-action-text>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                            <v-list-item-title v-text="'За текущую неделю'" />
                                            <v-list-item-subtitle
                                                v-text="currentWeek"
                                                class="font-weight-regular"
                                            />
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item
                                        :input-value="currentMonthActive"
                                        color="primary"
                                        @click="setCurrentMonth"
                                    >
                                        <v-list-item-action>
                                            <v-list-item-action-text>
                                                <v-icon
                                                    v-text="'mdi-calendar-month'"
                                                    :color="currentMonthActive ? 'primary' : ''"
                                                />
                                            </v-list-item-action-text>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                            <v-list-item-title v-text="'За текущий месяц'" />
                                            <v-list-item-subtitle
                                                v-text="currentMonth"
                                                class="font-weight-regular text-capitalize"
                                            />
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </template>
                        </filter-field>

                        <filter-field
                            v-else
                            v-bind="props"
                            v-on="listeners"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </div>

            <buttons-save v-if="isEditing">
                <template #left>
                    <v-btn
                        block
                        @click="edit($event, true)"
                    >
                        <v-icon
                            v-text="'mdi-check'"
                            left
                            color="success"
                        />
                        Сохранить и применить
                    </v-btn>
                </template>

                <template #right>
                    <v-btn
                        block
                        @click="edit"
                    >
                        <v-icon
                            v-text="'mdi-floppy'"
                            left
                        />
                        Сохранить без применения
                    </v-btn>
                </template>
            </buttons-save>
        </template>
    </adaptive-dialog>
</template>

<script>
import { SelectionTree, SearchTree } from "@u/treeview";
import { PathToCashbox, PathToCost } from '@u/path';

import ButtonsTabs from "@c/norton-commander/dialogs/filter/edit/ButtonsTabs";
import ButtonsSave from "@c/norton-commander/dialogs/filter/edit/ButtonsSave";
import AdaptiveDialog from "@c/norton-commander/AdaptiveDialog";
import FilterField from "@c/norton-commander/dialogs/filter/edit/FilterField";
import DatePicker from "@c/norton-commander/dialogs/filter/edit/DatePicker";

export default {
    name: "FilterEdit",

    inject: ['$isMobile'],

    components: {
        ButtonsTabs,
        ButtonsSave,
        AdaptiveDialog,
        FilterField,
        DatePicker,
    },

    props: {
        cashboxCategories: {
            type: Array,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        costCategories: {
            type: Array,
            required: true,
        },
        costTemplates: {
            type: Array,
            required: true,
        },
        dateHelpers: {
            type: Array,
            required: true,
        },
        statuses: {
            type: Object,
            required: true,
        },
        users: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        dialog: false,
        isEditing: false,

        selectedStatuses: [],
        selectedCashboxes: [],
        selectedCostTemplates: [],
        selectedUserWhoCreated: [],
        selectedUserWhoProcessed: [],
        siteSplits: [],
        siteTransactions: [],
        siteDeliveries: [],

        meta: {},
        filter: {},
        models: {
            siteSplits: '',
            siteTransactions: '',
            siteDeliveries: '',
        },

        creationDate: null,
        processingDate: null,
        processingDateHelpers: null,

        tab: 0,

        searchText: '',
        operationAmountFrom: '',
        operationAmountTo: '',
    }),

    watch: {
        dialog: function() {
            this.selectedStatuses = [];
            for (const status of this.filter.statuses) {
                this.selectedStatuses.push(status);
            }

            this.selectedCashboxes = [];
            for (const cashboxId of this.filter.cashboxes) {
                this.selectedCashboxes.push(cashboxId);
            }
            const cashboxTree = new SelectionTree({
                items: this.cashboxes,
                categories: this.cashboxCategories,
                selectedItems: this.selectedCashboxes,
                selectedCategories: this.filter.cashboxesGroup,
            });
            cashboxTree.selectedLeafs
                .map(({ id }) => id)
                .forEach(id => {
                    if (!this.selectedCashboxes.includes(id)) {
                        this.selectedCashboxes.push(id);
                    }
                });

            this.selectedCostTemplates = [];
            for (const costTemplateId of this.filter.costTemplates) {
                this.selectedCostTemplates.push(costTemplateId);
            }
            const costTree = new SelectionTree({
                items: this.costTemplates,
                categories: this.costCategories,
                selectedItems: this.selectedCostTemplates,
                selectedCategories: this.filter.costCategories,
            });
            costTree.selectedLeafs
                .map(({ id }) => id)
                .forEach(id => {
                    if (!this.selectedCostTemplates.includes(id)) {
                        this.selectedCostTemplates.push(id);
                    }
                });

            this.selectedUserWhoCreated = [];
            for (const user of this.filter.userWhoCreated) {
                this.selectedUserWhoCreated.push(user);
            }

            this.selectedUserWhoProcessed = [];
            for (const user of this.filter.userWhoProcessed) {
                this.selectedUserWhoProcessed.push(user);
            }

            this.creationDate = this.filter.creationDate;
            this.processingDate = this.filter.processingDate;

            if (this.filter.processingDateHelpers !== null) {
                for (const helper of this.dateHelpers) {
                    if (helper.value.join(';') === this.filter.processingDateHelpers.join(';')) {
                        this.processingDateHelpers = helper.value;
                        break;
                    }
                }
            } else {
                this.processingDateHelpers = null;
            }

            this.siteSplits = [];
            for (const siteSplit of this.filter.siteSplits) {
                this.siteSplits.push(siteSplit);
            }

            this.siteTransactions = [];
            for (const siteTransaction of this.filter.siteTransactions) {
                this.siteTransactions.push(siteTransaction);
            }

            this.siteDeliveries = [];
            for (const siteDelivery of this.filter.siteDeliveries) {
                this.siteDeliveries.push(siteDelivery);
            }

            this.operationAmountFrom = this.filter.operationAmount.from;
            this.operationAmountTo = this.filter.operationAmount.to;

            this.searchText = '';

            this.models = {
                siteSplits: '',
                siteTransactions: '',
                siteDeliveries: '',
            };
        },

        processingDateHelpers: function() {
            if (this.processingDateHelpers !== null) {
                this.processingDate = null;
            }
        },
    },

    computed: {
        cashboxesAfterSearch() {
            return this.cashboxes
                .filter(({ id, name, title, treeParent }) => {
                    const search = this.searchText.toLowerCase();

                    const cashboxName = `#${id} ${name || title}`;
                    const searchByName = cashboxName
                        .toLowerCase()
                        .includes(search);

                    const parent = this.cashboxCategories
                        .find(({ id }) => id == treeParent);
                    const parentName = parent ? (parent.name || parent.title) : '';
                    const searchByParent = parentName
                        .toLowerCase()
                        .includes(search);

                    return searchByName || searchByParent;
                })
                .map(({ id }) => id);
        },

        costsAfterSearch() {
            return this.costTemplates
                .filter(({ id, name, title, category }) => {
                    const search = this.searchText.toLowerCase();

                    const costName = `#${id} ${name || title}`;
                    const searchByName = costName
                        .toLowerCase()
                        .includes(search);

                    const parent = this.costCategories
                        .find(({ id }) => id == category);
                    const parentName = parent ? (parent.name || parent.title) : '';
                    const searchByParent = parentName
                        .toLowerCase()
                        .includes(search);

                    return searchByName || searchByParent;
                })
                .map(({ id }) => id);
        },

        usersAfterSearch() {
            return Object.values(this.users)
                .filter(({ name }) => {
                    const search = this.searchText.toLowerCase();

                    return name
                        .toLowerCase()
                        .includes(search);
                })
                .map(({ id }) => id);
        },

        cashboxTree() {
            return new SelectionTree({
                items: this.cashboxes,
                categories: this.cashboxCategories,
                selectedItems: this.selectedCashboxes,
            });
        },

        costTree() {
            return new SelectionTree({
                items: this.costTemplates,
                categories: this.costCategories,
                selectedItems: this.selectedCostTemplates,
            });
        },

        cashboxSearchTree() {
            return new SearchTree({
                items: this.cashboxes,
                categories: this.cashboxCategories,
                selectedItems: this.cashboxesAfterSearch,
            });
        },

        costSearchTree() {
            return new SearchTree({
                items: this.costTemplates,
                categories: this.costCategories,
                selectedItems: this.costsAfterSearch,
            });
        },

        datesToDisplay() {
            if (this.processingDateHelpers) {
                return this.dateHelpers.filter(helper => helper.value === this.processingDateHelpers);
            } else if (this.processingDate) {
                const result = this.processingDate
                    .map((d) => new Date(d).toLocaleDateString(undefined));

                if (result.length > 1) {
                    return [
                        { name: `с ${result[0]} ` },
                        { name: `по ${result[1]}` },
                    ];
                }
                return [{ name: result[0] }];
            }
            return [];
        },

        currentMonth() {
            const date = new Date();
            const options = { month: 'long' };

            return date.toLocaleDateString(undefined, options);
        },

        currentWeek() {
            const date = new Date();
            const first = new Date(date.setDate(date.getDate() - date.getDay() + 1));
            const last = new Date(date.setDate(date.getDate() - date.getDay() + 7));

            return `${first.toLocaleDateString(undefined)} - ${last.toLocaleDateString(undefined)}`;
        },

        isDateRange() {
            if (!this.processingDate) {
                return false;
            }

            return this.processingDate.length > 1;
        },

        currentMonthActive() {
            if (!this.isDateRange) {
                return false;
            }

            const date = new Date();
            const first = new Date(date.getFullYear(), date.getMonth(), 2);
            const last = new Date(date.getFullYear(), date.getMonth() + 1, 1);
            const result = [first, last]
                .map((d) => d.toISOString().slice(0, 10))
                .toString();

            return result === this.processingDate.toString();
        },

        currentWeekActive() {
            if (!this.isDateRange) {
                return false;
            }

            const date = new Date();
            const first = new Date(date.setDate(date.getDate() - date.getDay() + 1));
            const last = new Date(date.setDate(date.getDate() - date.getDay() + 7));
            const result = [first, last]
                .map((d) => d.toISOString().slice(0, 10))
                .toString();

            return result === this.processingDate.toString();
        },

        countOfUser() {
            let count = 0;
            for (const user in this.users) {
                user;
                count++;
            }
            return count;
        },

        properties() {
            const defaultStatuses = ['completed', 'draft', 'processing', 'queue'];
            const selectedStatusesSorted = this.selectedStatuses.slice().sort();
            const statusesStatus = defaultStatuses.length === selectedStatusesSorted.length && defaultStatuses.every((value, index) => value === selectedStatusesSorted[index]);

            const properties = [
                {
                    type: 'status',
                    title: 'Статусы',
                    icon: 'mdi-list-status',

                    menuTree: Object.values(this.statuses),
                    itemsToDisplay: Object.values(this.statuses).filter(status => this.selectedStatuses.includes(status.id)),
                    selectedItemsIds: this.selectedStatuses,

                    isActive: !statusesStatus,
                    hideItemId: true,

                    clear: () => this.selectedStatuses = [],
                },
                {
                    type: 'cashbox',
                    title: 'Кассы',
                    icon: 'mdi-cash-register',

                    menuTree: this.cashboxTree.nodes.filter(category => !category.treeParent),
                    categoriesToDisplay: this.cashboxTree.nodesToDisplay,
                    itemsToDisplay: this.cashboxTree.leafsToDisplay,
                    selectedItemsIds: this.selectedCashboxes,
                    itemsAfterSearch: this.cashboxesAfterSearch,
                    categoriesAfterSearch: this.cashboxSearchTree.nodesToDisplay.map(({ id }) => id),

                    busy: this.cashboxes.length === 0,
                    isActive: this.selectedCashboxes.length,
                    hasSearch: true,
                    hideCategoryId: true,

                    clear: () => this.selectedCashboxes = [],
                },
                {
                    type: 'costTemplate',
                    title: 'Расходы',
                    icon: 'mdi-cash-multiple',

                    menuTree: this.costTree.nodes.filter(category => !category.treeParent),
                    categoriesToDisplay: this.costTree.nodesToDisplay,
                    itemsToDisplay: this.costTree.leafsToDisplay,
                    selectedItemsIds: this.selectedCostTemplates,
                    itemsAfterSearch: this.costsAfterSearch,
                    categoriesAfterSearch: this.costSearchTree.nodesToDisplay.map(({ id }) => id),

                    isActive: this.selectedCostTemplates.length,
                    hasSearch: true,

                    clear: () => this.selectedCostTemplates = [],
                },
                {
                    type: 'processingDate',
                    title: 'Дата обработки',
                    icon: 'mdi-calendar',

                    menuTree: this.dateHelpers,
                    itemsToDisplay: this.datesToDisplay,
                    selectedItemsIds:
                        this.processingDateHelpers
                            ? [this.dateHelpers.find(helper => helper.value === this.processingDateHelpers).id]
                            : [],

                    isActive: this.processingDateHelpers || this.processingDate,
                    hideItemId: true,

                    clear: () => {
                        this.processingDateHelpers = null;
                        this.processingDate = null;
                    },
                },
                {
                    type: 'userWhoCreated',
                    title: 'Постановщики',
                    icon: 'mdi-account',

                    menuTree: Object.values(this.users),
                    itemsToDisplay: Object.values(this.users).filter(user => this.selectedUserWhoCreated.includes(user.id)),
                    selectedItemsIds: this.selectedUserWhoCreated,
                    itemsAfterSearch: this.usersAfterSearch,

                    isActive: this.selectedUserWhoCreated.length,
                    hasSearch: true,
                    hideItemId: true,

                    clear: () => this.selectedUserWhoCreated = [],
                },
                {
                    type: 'userWhoProcessed',
                    title: 'Исполнители',
                    icon: 'mdi-account-check',

                    menuTree: Object.values(this.users),
                    itemsToDisplay: Object.values(this.users).filter(user => this.selectedUserWhoProcessed.includes(user.id)),
                    selectedItemsIds: this.selectedUserWhoProcessed,
                    itemsAfterSearch: this.usersAfterSearch,

                    isActive: this.selectedUserWhoProcessed.length,
                    hasSearch: true,
                    hideItemId: true,

                    clear: () => this.selectedUserWhoProcessed = [],
                },
                {
                    type: 'siteTransactions',
                    title: 'Транзакции',
                    icon: 'mdi-link',

                    itemsToDisplay: this.siteTransactions.map(e => { return { name: e }; }),

                    isActive: this.siteTransactions.length || this.models.siteTransactions !== '',
                    hideMenu: true,
                    hideItemId: true,

                    clear: () => {
                        this.siteTransactions = [];
                        this.models.siteTransactions = '';
                    },
                },
                {
                    type: 'siteSplits',
                    title: 'Сплиты',
                    icon: 'mdi-link',

                    itemsToDisplay: this.siteSplits.map(e => { return { name: e }; }),

                    isActive: this.siteSplits.length || this.models.siteSplits !== '',
                    hideMenu: true,
                    hideItemId: true,

                    clear: () => {
                        this.siteSplits = [];
                        this.models.siteSplits = '';
                    },
                },
                {
                    type: 'siteDeliveries',
                    title: 'Доставки',
                    icon: 'mdi-link',

                    itemsToDisplay: this.siteDeliveries.map(e => { return { name: e }; }),

                    isActive: this.siteDeliveries.length || this.models.siteDeliveries !== '',
                    hideMenu: true,
                    hideItemId: true,

                    clear: () => {
                        this.siteDeliveries = [];
                        this.models.siteDeliveries = '';
                    },
                },
                {
                    type: 'operationAmount',
                    title: 'Сумма операции',
                    icon: 'mdi-currency-rub',

                    menuTree: [],
                    categoriesToDisplay: [],
                    itemsToDisplay: [],
                    selectedItemsIds: [],

                    isActive: this.operationAmountFrom !== '' || this.operationAmountTo !== '',
                    hideMenu: true,
                    hideClearButton: this.operationAmountFrom === '' && this.operationAmountTo === '',

                    clear: () => {
                        this.operationAmountFrom = '';
                        this.operationAmountTo = '';
                    },
                },
            ];

            const result = properties.map(property => ({
                props: {
                    type: property.type,
                    title: property.title,
                    icon: property.icon,

                    menuTree: property.menuTree || [],
                    categoriesToDisplay: property.categoriesToDisplay || [],
                    itemsToDisplay: property.itemsToDisplay || [],
                    selectedItemsIds: property.selectedItemsIds || [],
                    itemsAfterSearch: property.itemsAfterSearch || [],
                    categoriesAfterSearch: property.categoriesAfterSearch || [],

                    busy: property.busy || false,
                    isActive: property.isActive ? true : false,
                    hasSearch: property.hasSearch || false,
                    hideMenu: property.hideMenu || false,
                    hideItemId: property.hideItemId || false,
                    hideCategoryId: property.hideCategoryId || false,
                    hideClearButton: property.hideClearButton || false,
                },
                listeners: {
                    'toggle-item': (type, id) => this.toggleSelectedItem(type, id),
                    'remove-item': (type, item) => this.removeItem(type, item),
                    'clear': property.clear,
                    'clear-search-text': () => setTimeout(() => this.searchText = '', 200),
                },
            }));

            return result.filter(property => {
                if (['userWhoCreated', 'userWhoProcessed'].includes(property.type)) {
                    if (!this.countOfUser) {
                        return false;
                    }
                }
                return true;
            });
        },
    },

    methods: {
        open(filter, meta, isEditing) {
            this.dialog = true;
            this.filter = filter;
            this.meta =  meta;
            this.isEditing = isEditing;
        },

        close() {
            this.dialog = false;
        },

        apply() {
            this.addItem('siteTransactions');
            this.addItem('siteSplits');
            this.addItem('siteDeliveries');

            this.$emit(
                'filter:apply',
                this.assembleFilter(),
            );

            this.close();
        },

        edit($event, apply = false) {
            this.addItem('siteTransactions');
            this.addItem('siteSplits');
            this.addItem('siteDeliveries');

            this.$emit(
                'filter:saved:edit',
                this.assembleFilter(),
                this.meta,
            );

            if (apply) {
                this.apply();
            }
        },

        assembleFilter() {
            return {
                statuses: this.getCopyOrNull(this.selectedStatuses),

                cashboxesGroup: this.getCopyOrNull(
                    this.cashboxTree.nodesToDisplay.map(category => category.id)
                ),
                costCategories: this.getCopyOrNull(
                    this.costTree.nodesToDisplay.map(category => category.id)
                ),

                cashboxes: this.getCopyOrNull(this.selectedCashboxes),
                costTemplates: this.getCopyOrNull(this.selectedCostTemplates),

                userWhoCreated: this.getCopyOrNull(this.selectedUserWhoCreated),
                userWhoProcessed: this.getCopyOrNull(this.selectedUserWhoProcessed),

                creationDate: this.getCopyOrNull(this.creationDate),
                processingDate: this.getCopyOrNull(this.processingDate),
                processingDateHelpers: this.getCopyOrNull(this.processingDateHelpers),

                siteSplits: this.getCopyOrNull(this.siteSplits),
                siteTransactions: this.getCopyOrNull(this.siteTransactions),
                siteDeliveries: this.getCopyOrNull(this.siteDeliveries),

                operationAmount: {
                    from: this.operationAmountFrom,
                    to: this.operationAmountTo,
                },
            };
        },

        getCopyOrNull(data) {
            if (Array.isArray(data)) {
                return Array.from(data);
            }
            return data;
        },

        toggleSelectedItem(type, id) {
            if (type === 'processingDate') {
                if (this.processingDateHelpers === this.dateHelpers.find(dateHelper => dateHelper.id === id).value) {
                    this.processingDateHelpers = null;
                } else {
                    this.processingDateHelpers = this.dateHelpers.find(dateHelper => dateHelper.id === id).value;
                }
                return;
            }

            const keys = {
                'status': 'selectedStatuses',
                'cashbox': 'selectedCashboxes',
                'costTemplate': 'selectedCostTemplates',
                'userWhoCreated': 'selectedUserWhoCreated',
                'userWhoProcessed': 'selectedUserWhoProcessed',
            };
            const key = keys[type];

            if (this[key].includes(id)) {
                this[key].splice(this[key].findIndex(e => e === id), 1);
            } else {
                this[key].push(id);
            }
        },

        addItem(type) {
            if (!this[type].includes(this.models[type]) && this.models[type] !== '') {
                this[type].push(this.models[type]);
                this.models[type] = '';
            }
        },

        removeItem({ type, item: { id, name, isCategory } }) {
            if (['siteTransactions', 'siteSplits', 'siteDeliveries'].includes(type)) {
                this[type] = this[type].filter((i) => i !== name);
            } else if (isCategory) {
                if (type === 'cashbox') {
                    this.selectedCashboxes = this.selectedCashboxes.filter((cashboxId) => {
                        const cashbox = this.cashboxes
                            .find((c) => c.id === cashboxId);

                        if (!cashbox) {
                            return true;
                        }

                        const path = new PathToCashbox(
                            cashbox,
                            this.cashboxCategories,
                        );

                        return !path.array
                            .map((parent) => parent.id)
                            .includes(id);
                    });
                } else {
                    this.selectedCostTemplates = this.selectedCostTemplates.filter((costTemplateId) => {
                        const costTemplate = this.costTemplates
                            .find((c) => c.id === costTemplateId);

                        if (!costTemplate) {
                            return true;
                        }

                        const path = new PathToCost(
                            costTemplate,
                            this.costCategories,
                        );

                        return !path.array
                            .map((parent) => parent.id)
                            .includes(id);
                    });
                }
            } else {
                this.toggleSelectedItem(type, id);
            }
        },

        setCurrentMonth() {
            const date = new Date();
            const first = new Date(date.getFullYear(), date.getMonth(), 2);
            const last = new Date(date.getFullYear(), date.getMonth() + 1, 1);

            this.setDate([first, last]);
        },

        setCurrentWeek() {
            const date = new Date();
            const first = new Date(date.setDate(date.getDate() - date.getDay() + 1));
            const last = new Date(date.setDate(date.getDate() - date.getDay() + 7));

            this.setDate([first, last]);
        },

        setDate(array) {
            this.processingDateHelpers = null;

            this.processingDate = array.map((d) => d.toISOString().slice(0, 10));
        },
    },
};
</script>
