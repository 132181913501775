<template>
    <component
        :is="$isMobile() ? 'v-dialog' : 'v-menu'"
        v-model="menu"
        offset-y
        fullscreen
        max-height="50vh"
        max-width="700px"
        :close-on-content-click="false"
        transition="slide-x-transition"
    >
        <template #activator="{ on, attrs }">
            <v-btn
                data-test-id="edit-filter-dialog-menu-button-open"

                small
                block
                outlined
                v-on="on"
                v-bind="attrs"
            >
                <v-icon
                    v-text="'mdi-pencil-outline'"
                    left
                />
                Выбрать
            </v-btn>
        </template>

        <v-card
            flat
            class="h-full max-h-full d-flex flex-column"
        >
            <v-card-title
                v-if="$isMobile()"
                class="word-break px-2"
            >
                <v-btn
                    icon
                    class="mr-1"
                    @click="menu = false"
                >
                    <v-icon v-text="'mdi-arrow-left'" />
                </v-btn>
                <span v-text="title" />
            </v-card-title>

            <v-divider v-if="$isMobile()" />

            <v-list
                dense
                class="flex-grow-1 overflow-auto"
                :class="{
                    'py-0': $isMobile(),
                }"
            >
                <slot name="menu-widget" />

                <v-divider v-if="$slots['menu-widget']" />

                <slot name="menu-widget-bottom" />

                <v-divider v-if="$slots['menu-widget-bottom']" />

                <buttons-expand
                    v-if="['cashbox', 'costTemplate'].includes(type)"

                    :state="buttonState"
                    show-active

                    @open="toggleCategories('open')"
                    @active="toggleCategories('active')"
                    @close="toggleCategories('close')"
                />

                <v-divider v-if="['cashbox', 'costTemplate'].includes(type)" />

                <recursive-list
                    v-for="node in tree"
                    :key="node.id"

                    ref="recursiveLists"

                    :type="type"

                    :node="node"

                    :selected-items-ids="selectedItemsIds"
                    :items-after-search="itemsAfterSearch"
                    :categories-after-search="categoriesAfterSearch"
                    :search-text="searchText"

                    :has-search="hasSearch"
                    :hide-category-id="hideCategoryId"
                    :hide-item-id="hideItemId"
                    :menu="menu"

                    @close-menu="menu = false"
                    @toggle-category="onCategoryToggle"

                    v-on="$listeners"
                />
            </v-list>

            <v-divider v-if="$isMobile()" />

            <v-container v-if="$isMobile()">
                <v-row>
                    <v-col>
                        <v-btn
                            data-test-id="button-close"

                            block
                            color="secondary"
                            @click="menu = false"
                        >
                            Закрыть
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </component>
</template>

<script>
import { VMenu, VDialog } from 'vuetify/lib';

import ButtonsExpand from "@c/norton-commander/buttons/ButtonsExpand";
import RecursiveList from "@c/norton-commander/dialogs/filter/edit/RecursiveList";

export default {
    name: "FieldMenu",

    inject: ['$isMobile'],

    components: {
        VMenu,
        VDialog,

        ButtonsExpand,
        RecursiveList,
    },

    props: {
        type: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        tree: {
            type: Array,
            required: true,
        },

        selectedItemsIds: {
            type: Array,
            required: true,
        },

        itemsAfterSearch: {
            type: Array,
            required: true,
        },

        categoriesAfterSearch: {
            type: Array,
            required: true,
        },

        searchText: {
            type: String,
            required: true,
        },

        hasSearch: {
            type: Boolean,
            required: false,
            default: false,
        },

        hideCategoryId: {
            type: Boolean,
            required: false,
            default: false,
        },

        hideItemId: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        menu: false,
        opened: {},
    }),

    computed: {
        categories() {
            return this.tree.map(({ id }) => id);
        },

        buttonState() {
            return !Object.values(this.opened).some((v) => v);
        },
    },

    watch: {
        searchText(val) {
            if (val) {
                this.toggleCategories('open');
            }
        },

        menu() {
            this.$emit('clear-search-text');
        },
    },

    methods: {
        toggleCategories(key) {
            if (!this.$refs.recursiveLists) {
                return;
            }

            requestAnimationFrame(() => {
                switch (key) {
                    case 'active':
                        this.$refs.recursiveLists.forEach((ref) => ref.toggleCategory('close'));
                        this.$refs.recursiveLists.forEach((ref) => ref.toggleCategory('active'));
                        break;
                    default:
                        this.$refs.recursiveLists.forEach((ref) => ref.toggleCategory(key));
                        break;
                }
            });
        },

        onCategoryToggle({ id, value }) {
            if (this.categories.includes(id)) {
                this.$set(this.opened, id, value);
            }
        }
    },
};
</script>
