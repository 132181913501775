<template>
    <v-card :loading="loading" :disabled="loading" flat>
        <owner-list
            v-if="appendFlt"
            :pg="pg"
            :sort="sort"
            :dir="dir"
            :flt="flt"
            :append-flt="appendFlt"
            :meta="{ category }"
            owner="product"
            :tabs="[
                { id: 'card', name: $t('category.product.card') },
                { id: 'log', name: $t('main.logs') },
                { id: 'remains', name: $t('main.remains') },
            ]"
        >
            <template #tab-card="scope">
                <product-card v-bind="scope" />
            </template>
            <template #tab-log="scope">
                <keep-log v-bind="scope" owner="product" />
            </template>
            <template #tab-remains="scope">
                <keep-remains v-bind="scope" owner="product" />
            </template>
        </owner-list>
    </v-card>
</template>

<script>
import OwnerList from "@c/owner/List";
import ProductCard from "./ProductCard";
import KeepLog from "@c/keep/Log";
import KeepRemains from "@c/keep/Remains";
export default {
    name: "CatalogProductList",
    data: () => ({
        loading: false,
        targetField: null,
    }),
    computed: {
        appendFlt() {
            if (!this.targetField) {
                return null;
            }
            return {
                [this.targetField]: {
                    operation: "=",
                    value: this.category,
                },
            };
        },
    },
    components: {
        OwnerList,
        ProductCard,
        KeepLog,
        KeepRemains,
    },
    props: {
        category: {
            type: Number,
            required: true,
        },
        pg: {
            type: Number,
            required: true,
        },
        sort: {
            type: Number,
            required: true,
        },
        dir: {
            type: Number,
            required: true,
        },
        flt: {
            type: Object,
            required: true,
        },
    },
    async mounted() {
        this.loading = true;
        try {
            const fields = await this.$api("/product/fields");
            this.targetField = fields
                .filter((f) => {
                    return f.type == "category";
                })
                .pop().id;
        } catch (e) {
            this.$error(e.message);
        }
        this.loading = false;
    },
};
</script>
