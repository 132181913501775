export default async (api) => {
    const [users, me] = await Promise.all([
        api("/users"),
        api("/auth/getme"),
    ]);

    if (!(users && me)) {
        return;
    }

    const { list } = users;
    const { username } = me;
    const result = list.find(({ login }) => login === username);

    if (result) {
        return result;
    } else if (username === 'superadmin') {
        return {
            id: 1,
            login: 'superadmin',
            admin: 1,
        };
    }
};
