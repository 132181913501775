<template>
    <v-row>
        <v-col>
            <v-autocomplete
                :label="field.map.find((f) => f.type == 'storage').name"
                clearable
                hide-no-data
                dense
                :loading="loading"
                @update:search-input="search"
                :items="items"
                :value="value ? value.storage : null"
                :rules="storageRules"
                item-text="value.name"
                @input="input('storage', $event)"
            />
        </v-col>
        <v-col>
            <v-text-field
                clearable
                dense
                :value="value ? value.prefix : null"
                @input="input('prefix', $event)"
                :label="field.name"
                :rules="rules"
            />
        </v-col>
    </v-row>
</template>

<script>
import FieldBase from "./FieldBase";
export default {
    name: "FieldPrefix",
    extends: FieldBase,
    data: () => ({
        loading: false,
        searchItems: [],
        targetField: null,
    }),
    computed: {
        storageRules() {
            const rules = [];
            if (
                (this.value && this.value.prefix) ||
                this.field.perks.includes("required")
            ) {
                rules.push((v) => !!v || this.$t("form.required"));
            }
            return rules;
        },
        rules() {
            const rules = [];
            if (
                (this.value && this.value.storage) ||
                this.field.perks.includes("required")
            ) {
                rules.push(
                    (v) =>
                        String(v || "").length > 0 || this.$t("form.required")
                );
            }
            return rules;
        },
        items() {
            const items = [];
            if (this.value?.storage) {
                items.push({
                    value: this.value.storage,
                });
            }
            return [...items, ...this.searchItems];
        },
    },
    props: {
        value: {
            type: Object,
        },
    },
    methods: {
        input(key, value) {
            this.$emit("input", { ...this.value, [key]: value });
        },
        getDefaultValue: () => null,
        search(s) {
            clearTimeout(this.search.t);
            if (!s || !s.length || s == this.value?.storage.name) {
                return;
            }
            this.search.t = setTimeout(async () => {
                this.loading = true;
                try {
                    if (!this.targetField) {
                        this.targetField = (
                            await this.$api(`/storage/fields`)
                        ).find((f) => f.perks.includes("sort")).id;
                    }
                    const query = new URLSearchParams();
                    query.append("filter[]", `${this.targetField}|∋|${s}`);
                    const r = await this.$apiResponse(
                        `/entity/storage?${query.toString()}`
                    );
                    let data = [];
                    if (r.status == 200) {
                        ({ data } = await r.json());
                    }
                    this.searchItems = data.map((d) => ({
                        value: {
                            id: d.id,
                            name: d.name,
                        },
                    }));
                } catch (e) {
                    this.$error(e.message);
                }
                this.loading = false;
            }, 500);
        },
        getValue() {
            return {
                storage: this.value?.storage.id ?? "",
                prefix: this.value?.prefix ?? "",
            };
        },
    },
};
</script>