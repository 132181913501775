<template>
    <v-expand-x-transition>
        <v-list
            v-if="visibility"
            dense
            elevation="2"
            class="item pa-0"
        >
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="caption font-weight-regular">
                        {{ subtitle }}
                    </v-list-item-subtitle>

                    <v-list-item-title>
                        {{ titleFormatted }}
                    </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action class="flex-shrink-0">
                    <v-list-item-action-text class="text-no-wrap">
                        {{ balanceFormatted }}
                    </v-list-item-action-text>
                </v-list-item-action>

                <v-list-item-action>
                    <v-btn
                        icon
                        small
                        @click="$emit('deselect')"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </v-expand-x-transition>
</template>

<script>
import { formatNumber } from '@u/formatting';

export default {
    name: "SelectedItem",

    data: () => ({
        visibility: false,

        title: '',
        subtitle: '',
        balance: '',

        item: {
            id: 0,

            name: '',
            title: '',

            amount: 0,
            balance: 0,

            currency: '',
        },

        keys: {
            cashbox: {
                title: 'title',
                balance: 'balance',
            },
            costs: {
                title: 'name',
                balance: 'amount',
            },
        },

        subtitles: {
            cashbox: 'Выбранная касса',
            costs: 'Выбранный расход',
        },
    }),

    computed: {
        titleFormatted() {
            return `#${this.item.id} ${this.title}`;
        },

        balanceFormatted() {
            const balance = formatNumber(this.balance);
            return `${balance} ${this.item.currency}`;
        },
    },

    methods: {
        show(item, category) {
            this.title = item[this.keys[category].title];
            this.subtitle = this.subtitles[category];
            this.balance = item[this.keys[category].balance];

            this.item = item;

            this.visibility = true;
        },

        hide() {
            this.title = '';
            this.subtitle = '';
            this.balance = '';

            this.item = {};

            this.visibility = false;
        }
    },
};
</script>

<style lang="scss" scoped>
.item {
    width: 100%;
    max-width: 400px;

    position: fixed;
    top: 5px;

    z-index: 10;
}
</style>
