<template>
    <div>
        <v-list dense>
            <v-list-item v-if="selectedCategory != null">
                #{{ selectedCategory.id }} {{ selectedCategory.name }}
            </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list dense>
            <v-subheader>Расходы</v-subheader>
            <v-list-item
                v-for="template in templates"
                :key="template.id"
            >
                <v-list-item-title>
                    #{{ template.id }} {{ template.name }} - {{ template.amount }} {{ template.currency }}
                </v-list-item-title>

                <v-list-item-action>
                    <v-icon
                        @click="$emit('onEditTemplate', template.id, selectedCategory.id)"
                        title="Изменить "
                    >
                        mdi-pencil
                    </v-icon>
                </v-list-item-action>
            </v-list-item>

            <v-list-item>
                <v-icon
                    @click="$emit('onAddTemplate', selectedCategory.id)"
                    title="Добавить расход"
                >
                    mdi-plus
                </v-icon>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
export default {
    name: 'ConstructorTemplates',

    props: [
        'selectedCategory',
        'templates',
    ],
};
</script>
