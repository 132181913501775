<template>
    <v-row
        v-if="layout === 'horizontal'"
        no-gutters
    >
        <v-col
            v-for="button in buttonsToDisplay"
            :key="button.id"
            class="d-flex align-center justify-center"
        >
            <v-btn
                :data-test-id="`transaction-list-item-${transaction.id}-${button.id}-button`"

                depressed
                :class="button.textClass"
                @click="$emit('transaction-being-processed', { id: transaction.id, type: button.id })"
            >
                <v-icon dense>{{ button.icon }}</v-icon>
            </v-btn>
        </v-col>
    </v-row>

    <v-container
        v-else
        class="px-0 py-2"
    >
        <v-row dense>
            <v-col class="d-flex align-center justify-center">
                <v-btn
                    :data-test-id="`transaction-list-item-${transaction.id}-${buttonInfo.id}-button`"

                    depressed
                    :class="buttonInfo.textClass"
                    @click="$emit('transaction-being-processed', { id: transaction.id, type: buttonInfo.id })"
                >
                    <v-icon dense>{{ buttonInfo.icon }}</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-row
            v-if="transaction.status === 'draft'"
            dense
        >
            <v-col
                v-for="button in buttonsProcess"
                :key="button.id"
                class="d-flex align-center justify-center"
            >
                <v-btn
                    :data-test-id="`transaction-list-item-${transaction.id}-${button.id}-button`"

                    depressed
                    :class="button.textClass"
                    @click="$emit('transaction-being-processed', { id: transaction.id, type: button.id })"
                >
                    <v-icon dense>{{ button.icon }}</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "TransactionButtons",

    props: {
        transaction: {
            type: Object,
            required: true,
        },
        layout: {
            type: String,
            required: true,
        },
    },

    data: () => ({
        buttons: [
            {
                id: `cancel`,
                icon: 'mdi-close',
                text: 'Отменить',
                textClass: 'error--text',
            },
            {
                id: `info`,
                icon: 'mdi-information-slab-circle-outline',
                text: 'О транзакции',
                textClass: 'text--secondary',
            },
            {
                id: `process`,
                icon: 'mdi-check',
                text: 'Провести',
                textClass: 'primary--text',
            },
        ],
    }),

    computed: {
        buttonsToDisplay() {
            if (this.transaction.status === 'draft') {
                return this.buttons;
            }

            return this.buttons.filter(button => button.id === 'info');
        },

        buttonInfo() {
            return this.buttons.find(button => button.id === 'info');
        },

        buttonsProcess() {
            return this.buttons.filter(button => button.id !== 'info');
        },
    },
};
</script>
