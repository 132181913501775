<template>
    <div>
        <adaptive-dialog
            data-test-id="saved-filters-list-dialog"
            :data-test-state="dialog"

            :dialog="dialog"
            max-width="1000"
            dense

            @close="dialog = false"
        >
            <template #title>
                Сохраненные фильтры
            </template>

            <template #body>
                <v-list
                    dense
                    class="overflow-auto py-0"
                >
                    <template v-for="({ meta, filter }, index) in filters">
                        <saved-filter
                            :key="meta.id"

                            :meta="meta"
                            :filter="filter"

                            :cashbox-categories="cashboxCategories"
                            :cashboxes="cashboxes"

                            :cost-categories="costCategories"
                            :costTemplates="costTemplates"

                            :users="users"
                            :statuses="statuses"
                            :dateHelpers="dateHelpers"
                            :groupByList="groupByList"

                            @close="dialog = false"
                            @filter:saved:dialog:delete="$refs.deleteConfirmationDialog.open($event)"

                            v-on="$listeners"
                        />

                        <v-divider
                            v-if="index !== filters.length - 1"
                            :key="`${meta.id}-divider`"
                        />
                    </template>
                </v-list>
            </template>
        </adaptive-dialog>

        <dialog-delete-confirmation
            ref="deleteConfirmationDialog"

            v-on="$listeners"
        />
    </div>
</template>

<script>
import AdaptiveDialog from "@c/norton-commander/AdaptiveDialog";
import SavedFilter from "@c/norton-commander/dialogs/filter/saved-filters/SavedFilter";
import DialogDeleteConfirmation from "@c/norton-commander/dialogs/filter/saved-filters/DeleteConfirmation";

export default {
    name: "SavedFilters",

    components: {
        AdaptiveDialog,
        SavedFilter,
        DialogDeleteConfirmation,
    },

    props: {
        savedFilters: {
            type: Array,
            required: true,
        },
        cashboxCategories: {
            type: Array,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        costCategories: {
            type: Array,
            required: true,
        },
        costTemplates: {
            type: Array,
            required: true,
        },
        users: {
            type: Object,
            required: true,
        },
        statuses: {
            type: Object,
            required: true,
        },
        dateHelpers: {
            type: Array,
            required: true,
        },
        groupByList: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        dialog: false,
    }),

    computed: {
        filters() {
            const copy = JSON.parse(JSON.stringify(this.savedFilters));
            const result = copy.map(({id, name, user, link}, index) => ({
                meta: {
                    id,
                    name,
                    user,
                    index,
                },
                filter: JSON.parse(link),
            }));
            return result;
        },
    },

    methods: {
        open() {
            this.dialog = true;
        },
    },
};
</script>
