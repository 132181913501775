<template>
    <v-list-group
        v-if="node.isCategory"
        v-show="node.childrenCountTotal && show"
        v-model="open"
        sub-group
        eager
        :class="{ 'pl-4': isChild }"
    >
        <template v-slot:activator>
            <v-list-item
                :data-test-id="`cashboxes-category-${node.id}`"
                :data-test-balance="node.balance"

                :ref="`category-${node.id}`"
                class="pa-0"
                @contextmenu.prevent="showMenu"
                v-touch="{
                    left: ($event) => showMenu($event, `category-${node.id}`),
                }"
            >
                <v-list-item-title>
                    <text-highlight-wrapper
                        :text="node.name || node.title"
                        :queries="searchText"
                    />
                </v-list-item-title>

                <v-list-item-action class="flex-shrink-0">
                    <v-list-item-action-text class="text-no-wrap">
                        {{ formatNumber(node.balance) }} ₽
                    </v-list-item-action-text>
                </v-list-item-action>
            </v-list-item>
        </template>

        <cashbox-category
            v-for="child in node.children"
            :key="child.id"

            ref="recursiveLists"

            :node="child"
            :selected-item="selectedItem"
            :items-to-display="itemsToDisplay"
            :categories-to-display="categoriesToDisplay"
            :search-text="searchText"
            :isChild="true"

            v-on="$listeners"
        />
    </v-list-group>

    <v-list-item
        v-else

        v-show="show"

        :data-test-id="`cashbox-${node.type}-${node.id}`"
        :data-test-object="JSON.stringify(node)"
        data-test-category="cashbox"
        :data-test-subcategory="node.type"

        :ref="`category-${node.id}`"

        class="vue-cashbox-basket"

        :draggable="!$isMobile()"
        :title="node.title"
        :class="{ 'selected-item': node.id === selectedItemId }"

        @dragstart="startDrag($event, node)"
        @dragend="endDrag($event)"

        @dragenter="onDragEnter($event)"
        @dragleave="onDragLeave($event)"

        @dragover.prevent

        @drop="onDrop($event, node)"

        @dblclick="onDBClick(node)"
        @contextmenu.prevent="$emit('select-item', node, 'cashbox', node.type, node.balance)"

        v-touch="{
            left: () => $emit('select-item', node, 'cashbox', node.type, node.balance),
        }"
    >
        <v-list-item-content>
            <v-list-item-title>
                <text-highlight-wrapper
                    :text="`#${node.id} ${node.title}`"
                    :queries="searchText"
                />
            </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
            <v-list-item-action-text class="text-no-wrap">
                {{ formatNumber(node.balance) }} {{ node.currency }}
            </v-list-item-action-text>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import { formatNumber } from '@u/formatting';
import TextHighlightWrapper from "@c/TextHighlightWrapper";

export default {
    name: "CashboxCategory",

    inject: ['$isMobile'],

    components: {
        TextHighlightWrapper,
    },

    props: {
        node: {
            type: Object,
            required: true,
        },
        selectedItem: {
            type: Object,
            required: true,
        },
        itemsToDisplay: {
            type: Array,
            required: true,
        },
        categoriesToDisplay: {
            type: Array,
            required: true,
        },
        searchText: {
            type: String,
            required: true,
        },
        isChild: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        open: false,
    }),

    computed: {
        show() {
            return this.node.isCategory
                ? this.categoriesToDisplay.includes(this.node.id)
                : this.itemsToDisplay.includes(this.node.id);
        },

        selectedItemId() {
            if (this.selectedItem.category === 'cashbox') {
                return this.selectedItem.id;
            }

            return null;
        },
    },

    watch: {
        open(value) {
            const { id, isCategory } = this.node;

            if (isCategory) {
                this.$emit('toggle-category', { id, value });
            }
        },

        'node.balance': {
            handler() {
                const ref = this.$refs[`category-${this.node.id}`];

                if (!ref) {
                    return;
                }

                ref.$el.classList.add('balance-changed');

                setTimeout(() => {
                    ref.$el.classList.remove('balance-changed');
                }, 2000);
            },
        },
    },

    methods: {
        formatNumber,

        startDrag(evt, cashbox) {
            evt.currentTarget.classList.add('dragged-item');
            evt.dataTransfer.effectAllowed = 'move';
            evt.dataTransfer.dropEffect = 'move';

            evt.dataTransfer.setData('id', cashbox.id);
            evt.dataTransfer.setData('title', cashbox.title);

            evt.dataTransfer.setData('category', 'cashbox');
            evt.dataTransfer.setData('subcategory', cashbox.type);

            evt.dataTransfer.setData('balance', cashbox.balance);
            evt.dataTransfer.setData('currency', cashbox.currency);

            this.$emit('drag:start');
        },

        endDrag(evt) {
            evt.currentTarget.classList.remove('dragged-item');
            this.$emit('drag:end');
        },

        onDrop(evt, node) {
            evt.target.classList.remove('drop-point');

            const fromId = Number(evt.dataTransfer.getData('id'));
            const fromTitle = evt.dataTransfer.getData('title');

            const fromCategory = evt.dataTransfer.getData('category');
            const fromSubcategory = evt.dataTransfer.getData('subcategory');

            const fromBalance = evt.dataTransfer.getData('balance');
            const fromCurrency = evt.dataTransfer.getData('currency');

            const from = {
                id: fromId,
                title: fromTitle,
                category: fromCategory,
                subcategory: fromSubcategory,
            };

            const to = {
                id: node.id,
                title: node.title,
                category: 'cashbox',
                subcategory: node.type,
            };

            let amount = 0;
            const vendorTypes = ['vendor', 'salary', 'installments', 'storage'];
            if (fromCategory === 'cashbox' && vendorTypes.includes(node.type)) {
                amount = Number(node.balance);
            } else if (fromSubcategory === 'manager') {
                amount = Number(fromBalance);
            }

            this.$emit('transaction:dialog:create', from, to, fromCurrency, amount, '');
        },

        onDragEnter(evt) {
            if (evt.target.classList.contains('vue-cashbox-basket')) {
                evt.target.classList.add('drop-point');
            }
        },

        onDragLeave(evt) {
            if (evt.target.classList.contains('vue-cashbox-basket')) {
                evt.target.classList.remove('drop-point');
            }
        },

        onDBClick({ id, title, balance }) {
            const payload = {
                category: 'cashbox',
                id,
                title,
                balance,
            };
            this.$emit('dialog:set-amount', payload);
        },

        showMenu(e, ref) {
            const coordinates = {
                x: 0,
                y: 0,
            };

            if (e.type === 'contextmenu') {
                coordinates.x = e.clientX;
                coordinates.y = e.clientY;
            } else {
                const { x, y, height } = this.$refs[ref].$el.getBoundingClientRect();
                coordinates.x = x;
                coordinates.y = y + height;
            }

            this.$emit(
                'cashbox:menu:mass-process',
                {
                    coordinates,
                    node: this.node,
                }
            );
        },

        toggleCategory(key) {
            switch (key) {
                case 'open':
                    this.open = true;
                    break;

                case 'close':
                    this.open = false;
                    break;
            }

            if (this.$refs.recursiveLists) {
                this.$refs.recursiveLists.forEach((ref) => ref.toggleCategory(key));
            }
        },
    },
};
</script>
