<template>
    <v-list-group
        v-if="node.isCategory"

        v-show="categoriesToDisplay.includes(node.id)"

        eager
        sub-group
        :value="true"
        :class="{ 'pl-2': isChild && !$isMobile() }"
    >
        <template v-slot:activator>
            <v-list-item-content>
                <v-list-item-title>
                    <span :class="{ 'pl-2': isChild && $isMobile() }">
                        <text-highlight-wrapper
                            :text="node.title || node.name"
                            :queries="searchText"
                        />
                    </span>
                </v-list-item-title>
            </v-list-item-content>
        </template>

        <cashbox-list
            v-for="child in node.children"
            :key="child.id"

            ref="recursiveLists"

            :node="child"
            :statuses="statuses"
            :items-to-display="itemsToDisplay"
            :categories-to-display="categoriesToDisplay"
            :search-text="searchText"
            :isChild="true"

            v-on="$listeners"
        />
    </v-list-group>

    <cashbox-list-item
        v-else

        v-show="itemsToDisplay.includes(node.id)"

        ref="item"

        :node="node"
        :statuses="statuses"
        :search-text="searchText"

        v-on="$listeners"
    />
</template>

<script>
import TextHighlightWrapper from "@c/TextHighlightWrapper";
import CashboxListItem from '@c/norton-commander/dialogs/cashbox/mass-process/CashboxListItem';

export default {
    name: "CashboxList",

    inject: ['$isMobile'],

    components: {
        TextHighlightWrapper,
        CashboxListItem,
    },

    props: {
        node: {
            type: Object,
            required: true,
        },

        statuses: {
            type: Object,
            required: true,
        },

        itemsToDisplay: {
            type: Array,
            required: true,
        },

        categoriesToDisplay: {
            type: Array,
            required: true,
        },

        searchText: {
            type: String,
            required: true,
        },

        isChild: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        submit() {
            if (this.$refs.recursiveLists) {
                this.$refs.recursiveLists.forEach((ref) => ref.submit());
            }

            if (this.$refs.item) {
                this.$refs.item.submit();
            }
        },
    },
};
</script>
