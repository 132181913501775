var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(false)?_c('v-card',{staticClass:"mt-8"},[_c('v-card-title',{staticClass:"word-break subtitle-1 font-weight-medium"},[_vm._v(" Доступ к новым записям справочника ")]),_c('v-divider'),_c('access-new',_vm._g({attrs:{"user":_vm.user,"headers":_vm.headers,"keys":_vm.keys}},_vm.$listeners))],1):_vm._e(),_c('div',{staticClass:"d-flex align-center",class:{
            'mt-1': _vm.$isMobile(),
            'mt-3': !_vm.$isMobile(),
        }},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"large":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var ref_item = ref.item;
        var query = ref_item.query;
        var text = ref_item.text;
        var disabled = ref_item.disabled;
return [(_vm.$isMobile())?_c('v-chip',{staticClass:"my-1",attrs:{"link":"","to":{
                        name: 'AccessControl',
                        params: Object.assign({}, _vm.$route.params),
                        query: Object.assign({}, _vm.$route.query,
                            query),
                    },"disabled":disabled,"exact":"","label":"","outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")]):_c('v-breadcrumbs-item',{attrs:{"to":{
                        name: 'AccessControl',
                        params: Object.assign({}, _vm.$route.params),
                        query: Object.assign({}, _vm.$route.query,
                            query),
                    },"disabled":disabled,"exact":""}},[_vm._v(" "+_vm._s(text)+" ")])]}}])}),(!_vm.$isMobile())?_c('span',{staticClass:"text--secondary",domProps:{"textContent":_vm._s('/')}}):_vm._e()],1),_c('v-card',[_c('v-card-title',{staticClass:"word-break subtitle-1 font-weight-medium"},[_vm._v(" Доступ к существующим записям справочника ")]),_c('v-divider'),_c('access-existing',_vm._g({attrs:{"user":_vm.user,"headers":_vm.headers,"keys":_vm.keys,"tree":_vm.tree}},_vm.$listeners))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }