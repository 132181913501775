<template>
    <component
        :is="component"
        :busy="busy"
        :disabled="disabled"
    >
        <template #calendar>
            <slot name="calendar-slot" />
        </template>

        <template #tabs>
            <slot name="tabs-slot" />
        </template>

        <template #event-list>
            <slot name="event-list-slot" />
        </template>
    </component>
</template>

<script>
import MobileLayout from "@c/event-planner/layouts/MobileLayout";
import DesktopLayout from "@c/event-planner/layouts/DesktopLayout";

export default {
    name: "AdaptiveLayout",

    inject: ['$isMobile'],

    components: {
        MobileLayout,
        DesktopLayout,
    },

    props: {
        busy: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        component() {
            return this.$isMobile()
                ? "MobileLayout"
                : "DesktopLayout";
        }
    },
};
</script>
