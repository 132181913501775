<template>
    <div v-if="$isMobile()">
        <v-toolbar flat>
            <v-toolbar-items>
                <v-btn
                    text
                    @click="$emit('calendar:prev')"
                >
                    <v-icon v-text="'mdi-chevron-left'" />
                </v-btn>
            </v-toolbar-items>

            <v-list-item
                v-if="title"
                @click="$emit('calendar:set-type', 'month')"
            >
                <div
                    v-text="title"
                    class="w-full text-center text-capitalize"
                />
            </v-list-item>

            <v-toolbar-items>
                <v-btn
                    text
                    @click="$emit('calendar:next')"
                >
                    <v-icon v-text="'mdi-chevron-right'" />
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-divider />

        <v-toolbar flat>
            <v-menu offset-y>
                <v-list>
                    <v-list-item
                        v-for="{ alias, label, icon } in types"
                        :key="alias"
                        @click="$emit('calendar:set-type', alias)"
                    >
                        <v-icon
                            v-text="icon"
                            left
                        />
                        <v-list-item-title v-text="label" />
                    </v-list-item>
                </v-list>

                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        outlined
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon
                            v-text="currentType.icon"
                            left
                        />
                        <span v-text="currentType.label" />
                        <v-icon
                            v-text="'mdi-menu-down'"
                            right
                        />
                    </v-btn>
                </template>
            </v-menu>

            <v-btn
                outlined
                class="ml-4"
                @click="$emit('calendar:set-today')"
            >
                Сегодня
            </v-btn>
        </v-toolbar>
    </div>

    <v-toolbar
        v-else
        elevation="1"
    >
        <v-btn-toggle
            v-model="model"
            group
            color="primary"
        >
            <v-btn
                v-for="{ alias, label, icon } in types"
                :key="alias"
                :value="alias"
                class="px-4"
            >
                <v-icon
                    v-text="icon"
                    left
                />
                <span v-text="label" />
            </v-btn>
        </v-btn-toggle>

        <v-divider
            vertical
            class="ml-6"
        />

        <v-spacer />

        <v-divider
            vertical
            class="mr-6"
        />

        <v-btn
            outlined
            @click="$emit('calendar:set-today')"
        >
            Сегодня
        </v-btn>

        <v-divider
            vertical
            class="mx-6"
        />

        <v-btn
            small
            outlined
            @click="$emit('calendar:prev')"
        >
            <v-icon v-text="'mdi-chevron-left'" />
        </v-btn>

        <v-toolbar-title
            class="text-capitalize mx-2"
            style="width: 10em;"
        >
            <v-list-item
                v-if="title"
                @click="$emit('calendar:set-type', 'month')"
            >
                <div
                    v-text="title"
                    class="w-full text-center"
                />
            </v-list-item>
        </v-toolbar-title>

        <v-btn
            small
            outlined
            @click="$emit('calendar:next')"
        >
            <v-icon v-text="'mdi-chevron-right'" />
        </v-btn>
    </v-toolbar>
</template>

<script>
export default {
    name: "CalendarToolbar",

    inject: ['$isMobile'],

    props: {
        type: {
            type: String,
            required: true,
        },
        types: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
    },

    computed: {
        model: {
            get() {
                return this.type;
            },
            set(alias) {
                this.$emit('calendar:set-type', alias);
            },
        },

        currentType() {
            return this.types
                .find(({ alias }) => alias === this.type);
        },
    },
};
</script>
