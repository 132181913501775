<template>
    <v-autocomplete
        :label="field.name"
        clearable
        hide-no-data
        dense
        :loading="loading"
        @update:search-input="search"
        :items="items"
        no-filter
        :value="value"
        @change="$emit('input', $event)"
        :rules="rules"
        item-text="value.address"
    />
</template>

<script>
import FieldBase from "./FieldBase";
export default {
    name: "FieldAddress",
    extends: FieldBase,
    props: {
        value: {
            type: Object,
        },
    },
    data: () => ({
        current: null,
        loading: false,
        searchItems: [],
    }),
    computed: {
        rules() {
            const rules = [];
            if (this.field.perks.includes("required")) {
                rules.push((v) => !!v || this.$t("form.required"));
            }
            return rules;
        },
        items() {
            const items = [];
            if (this.value) {
                items.push({
                    value: this.value,
                });
            }
            return [...items, ...this.searchItems];
        },
    },
    methods: {
        getDefaultValue: () => null,
        search(s) {
            clearTimeout(this.search.t);
            if (!s || !s.length || s == this.value?.address) {
                return;
            }
            this.search.t = setTimeout(async () => {
                this.loading = true;
                try {
                    const { suggestions } = await fetch(
                        "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorization: `Token ${process.env.VUE_APP_DADATA}`,
                            },
                            body: JSON.stringify({
                                query: s,
                                language: this.$i18n.locale,
                            }),
                        }
                    ).then((r) => r.json());
                    this.searchItems = suggestions
                        .filter((s) => {
                            if (this.value && this.value.address == s.value) {
                                return false;
                            }
                            return true;
                        })
                        .map((s) => ({
                            value: {
                                address: s.value,
                                kladrid: s.data.kladr_id,
                                coords: {
                                    lon: Number(s.data.geo_lon),
                                    lat: Number(s.data.geo_lat),
                                },
                            },
                        }));
                } catch (e) {
                    this.$error(e.message);
                }
                this.loading = false;
            }, 500);
        },
    },
};
</script>