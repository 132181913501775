<template>
    <v-menu offset-y>
        <template #activator="{ attrs, on }">
            <v-btn icon v-on="on" v-bind="attrs">
                <v-icon>mdi-translate</v-icon>
            </v-btn>
        </template>
        <v-list dense>
            <v-list-item
                v-for="(name, key) in locales"
                :key="key"
                @click="setLocale(key)"
            >
                <v-list-item-title>{{ name }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: "LocaleChanger",
    computed: {
        locales: () => JSON.parse(process.env.VUE_APP_I18N_LOCALES),
    },
    methods: {
        setLocale(key) {
            localStorage.locale = key;
            this.$root.$i18n.locale = key;
            this.$vuetify.lang.current = key;
            this.$apiLocale(key);
            this.$router.push({
                params: { ...this.$route.params },
                query: {
                    ...this.$route.query,
                    t: Date.now(),
                },
            });
        },
    },
};
</script>