<template>
    <div
        v-if="$isMobile()"
        class="py-1"
    >
        <item-title
            :title="title"
            :icon="icon"
            :condition="condition"
        >
            <template #default>
                <slot name="separator" />
            </template>
        </item-title>

        <div>
            <slot name="current" />
        </div>

        <div>
            <slot name="new" />
        </div>
    </div>

    <v-container
        v-else
        fluid
        class="py-1"
    >
        <v-row no-gutters>
            <v-col>
                <item-title
                    :title="title"
                    :icon="icon"
                    :condition="condition"
                />
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col cols="5">
                <slot name="current" />
            </v-col>

            <v-col
                cols="2"
                class="d-flex justify-center"
            >
                <slot name="separator">
                    <v-icon :color="condition ? 'primary' : ''">
                        mdi-arrow-right-thin
                    </v-icon>
                </slot>
            </v-col>

            <v-col cols="5">
                <slot name="new" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ItemTitle from "@c/norton-commander/dialogs/transaction/edit/ItemTitle";

export default {
    name: "AdaptiveItem",

    inject: ['$isMobile'],

    components: {
        ItemTitle,
    },

    props: {
        title: {
            type: String,
            default: '',
        },

        icon: {
            type: String,
            default: '',
        },

        condition: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
