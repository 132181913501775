<template>
    <div>
        <full-overlay v-model="loading" />
        <multipane v-if="category">
            <div
                :style="{
                    minWidth: '25%',
                    maxWidth: '75%',
                }"
            >
                <category-list :id="category" />
            </div>
            <custom-resizer />
            <div
                :style="{
                    minWidth: '25%',
                    maxWidth: '75%',
                    flexGrow: 1,
                }"
            >
                <product-list
                    :category="category"
                    :pg="pg"
                    :sort="sort"
                    :dir="dir"
                    :flt="flt"
                />
            </div>
        </multipane>
    </div>
</template>

<script>
import { Multipane } from "vue-multipane";
import FullOverlay from "@c/FullOverlay";
import CustomResizer from "@c/CustomResizer";
import CategoryList from "@c/catalog/CategoryList";
import ProductList from "@c/catalog/ProductList";
export default {
    name: "Catalog",
    components: {
        Multipane,
        FullOverlay,
        CustomResizer,
        CategoryList,
        ProductList,
    },
    data: () => ({
        loading: false,
    }),
    watch: {
        $route: {
            immediate: true,
            async handler() {
                if (this.category == 0) {
                    this.loading = true;
                    try {
                        const { data } = await this.$api("/entity/category");
                        const { id } = data.find((category) => category.parent === null);
                        this.$router.push({
                            params: {
                                category: id,
                            },
                        });
                    } catch (e) {
                        this.$error(e.message);
                    }
                    this.loading = false;
                }
            },
        },
    },
    props: {
        category: {
            type: Number,
            required: true,
        },
        pg: {
            type: Number,
            required: true,
        },
        sort: {
            type: Number,
            required: true,
        },
        dir: {
            type: Number,
            required: true,
        },
        flt: {
            type: Object,
            required: true,
        },
    },
};
</script>
