import VuetifyDialog from 'vuetify-dialog';
import 'vuetify-dialog/dist/vuetify-dialog.css';
export default {
    install(Vue, { vuetify, i18n }) {
        Vue.use(VuetifyDialog, {
            context: {
                vuetify,
            },
        });
        Vue.prototype.$error = function (message, options = {}) {
            return this.$dialog.notify.error(message, { position: "bottom-right", ...options });
        };
        Vue.prototype.$success = function (message, options = {}) {
            return this.$dialog.notify.success(message, { position: "bottom-right", ...options });
        };
        Vue.prototype.$prompt = function (options = {}) {
            return this.$dialog.prompt({
                actions: {
                    false: {
                        text: i18n.t('main.close'),
                        color: 'error',
                    },
                    true: {
                        text: i18n.t('main.proceed'),
                        color: 'primary',
                    }
                },
                ...options,
            });
        };
        Vue.prototype.$confirm = function (options = {}) {
            return this.$dialog.confirm({
                title: i18n.t('main.warning'),
                text: i18n.t('dialog.confirm'),
                actions: {
                    false: {
                        text: i18n.t('main.close'),
                    },
                    true: {
                        text: i18n.t('main.proceed'),
                        color: 'error',
                    }
                },
                ...options,
            });
        };
    }
};
