<script>
import { formatNumber } from '@u/formatting';
import { PathToCashbox, getPathToCost } from '@u/path';

export default {
    name: "TransactionInfo",

    props: {
        transaction: {
            type: Object,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        cashboxCategories: {
            type: Array,
            required: true,
        },
        costTemplates: {
            type: Array,
            required: true,
        },
        costElements: {
            type: Array,
            required: true,
        },
        costCategories: {
            type: Array,
            required: true,
        },
    },

    render() {
        return this.$scopedSlots.default({
            info: this.infoFiltered,
        });
    },

    computed: {
        info() {
            return [
                {
                    alias: 'operations',
                    value: this.operations,
                    label: 'Операции',
                },
                {
                    alias: 'links',
                    value: this.links,
                    label: 'Связи',
                },
                {
                    alias: 'comment',
                    value: this.comment,
                    label: 'Комментарии',
                },
            ];
        },

        infoFiltered() {
            return this.info.filter(({ value }) => value.length);
        },

        operations() {
            return this.transaction.operations
                .map(({ cashbox, amount }) => {
                    let path = `#${cashbox}`;
                    const item = this.cashboxes.find((c) => c.id === cashbox);

                    if (item) {
                        const itemPath = new PathToCashbox(item, this.cashboxCategories);
                        const title = `#${item.id} ${item.title}`;

                        const pathChunks = itemPath.array.map(({ title }) => title);
                        pathChunks.push(title);
                        path = pathChunks.join(' / ');
                    }

                    const sign = amount > 0 ? '+' : '';
                    const amountText = `${sign}${formatNumber(amount)} ₽`;
                    const amountClass = amount > 0 ? 'success--text' : 'error--text';

                    return {
                        path,
                        amount: amountText,
                        amountClass,
                    };
                });
        },

        links() {
            return this.transaction.links
                .reduce((acc, { id, type }) => {
                    switch (type) {
                        case 'costs.template':
                        case 'costs.template.manual': {
                            const template = this.costTemplates.find((t) => t.id === id);
                            return [
                                ...acc,
                                template ? getPathToCost(template, this.costCategories) : `#${id}`,
                            ];
                        }
                        case 'costs.element': {
                            const element = this.costElements.find((e) => e.id === id);
                            return [
                                ...acc,
                                element ? getPathToCost(element, this.costCategories) : `#${id}`,
                            ];
                        }
                        default:
                            return [...acc, `#${id}`];
                    }
                }, []);
        },

        comment() {
            return this.transaction.comment ? [this.transaction.comment] : [''];
        }
    },
};
</script>
