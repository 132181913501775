<template>
    <adaptive-layout :busy="busy">
        <template #calendar-slot>
            <event-calendar
                    :type="calendarType"
                    :types="calendarTypes"
                    :events="events"
                    :selected-events="selectedEvents"
                    :cashboxes="cashboxes"
                    :cashbox-categories="cashboxCategories"
                    :cost-templates="costTemplates"
                    :cost-elements="costElements"
                    :cost-categories="costCategories"
                    @calendar:set-type="setCalendarType"
                    @event:dialog:edit="$event => $refs.eventCreateDialog.open($event, true)"
                />
        </template>

        <template #tabs-slot>
            <event-tabs :tabs="eventTypes" />
        </template>

        <template #event-list-slot>
            <event-list
                :events="events"
                :selected-events="selectedEvents"
                :cashboxes="cashboxes"
                :cashbox-categories="cashboxCategories"
                :cost-templates="costTemplates"
                :cost-elements="costElements"
                :cost-categories="costCategories"
                @event:dialog:edit="$event => $refs.eventCreateDialog.open($event, true)"
                @filter:toggle="toggleEvent"
                @filter:clear="selectedEvents = []"
            />

            <event-create-dialog
                ref="eventCreateDialog"
            />
        </template>
    </adaptive-layout>
</template>

<script>
import { getCashboxCategories, getCashboxes } from "@u/cashboxes/";

import AdaptiveLayout from "@c/event-planner/layouts/AdaptiveLayout";
import EventCalendar from "@c/event-planner/EventCalendar";
import EventTabs from "@c/event-planner/EventTabs";
import EventList from "@c/event-planner/EventList";
import EventCreateDialog from "@c/event-planner/dialogs/EventCreate";

export default {
    name: "EventPlanner",

    components: {
        AdaptiveLayout,
        EventCalendar,
        EventTabs,
        EventList,
        EventCreateDialog,
    },

    props: {
        eventTypeProp: {
            type: String,
            required: true,
        },
        calendarTypeProp: {
            type: String,
            default: 'month',
        },
    },

    data: () => ({
        busy: false,

        calendarType: 'month',

        eventTypes: [
            {
                alias: 'transaction',
                label: 'Транзакции',
                icon: 'mdi-currency-rub',
            },
        ],

        calendarTypes: [
            {
                alias: 'month',
                label: 'Месяц',
                icon: 'mdi-calendar-month',
            },
            {
                alias: 'week',
                label: 'Неделя',
                icon: 'mdi-calendar-week',
            },
            {
                alias: '4day',
                label: '4 Дня',
                icon: 'mdi-calendar-range',
            },
            {
                alias: 'day',
                label: 'День',
                icon: 'mdi-calendar-today',
            },
        ],

        events: [
            {
                "id": 1,
                "name": "Каждый понедельник и среду",
                "color": "blue",
                "formula": "every-week",
                "repeatUntil": "none",
                "maxCount": 1,
                "interval": 1,
                "start": "2023-08-09T17:00:00.000Z",
                "end": "2023-08-10T16:59:59.059Z",
                "rule": {
                    "type": "weekdays",
                    "value": [
                        1,
                        3
                    ]
                },
                "transactions": [
                    {
                        "operations": [
                            {
                                "cashbox": 102,
                                "amount": 500
                            }
                        ],
                        "links": [
                            {
                                "id": 18,
                                "type": "costs.template"
                            }
                        ],
                        "comment": "Test"
                    }
                ],
            },
            {
                "id": 2,
                "name": "В 4-ю пятницу каждого месяца",
                "color": "purple",
                "formula": "every-month",
                "repeatUntil": "none",
                "maxCount": 1,
                "interval": 1,
                "start": "2023-08-09T17:00:00.000Z",
                "end": "2023-08-10T16:59:59.059Z",
                "rule": {
                    "type": "friday",
                    "value": [
                        4
                    ]
                },
                "transactions": [
                    {
                        "operations": [
                            {
                                "cashbox": 122,
                                "amount": 500
                            }
                        ],
                        "links": [
                            {
                                "id": 1,
                                "type": "costs.template.manual"
                            }
                        ],
                        "comment": "Массовое начисление"
                    },
                    {
                        "operations": [
                            {
                                "cashbox": 123,
                                "amount": 1500
                            }
                        ],
                        "links": [
                            {
                                "id": 1,
                                "type": "costs.template.manual"
                            }
                        ],
                        "comment": "Массовое начисление"
                    }
                ],
            },
            {
                "id": 3,
                "name": "Каждый рабочий день",
                "color": "pink",
                "formula": "every-working-day",
                "repeatUntil": "date",
                "maxCount": 1,
                "interval": 1,
                "start": "2023-07-30T17:00:00.000Z",
                "end": "2023-08-20T16:59:00.000Z",
                "transactions": [],
            },
            {
                "id": 4,
                "name": "Каждое 3-е число месяца",
                "color": "green",
                "formula": "every-month",
                "repeatUntil": "none",
                "maxCount": 1,
                "interval": 1,
                "start": "2023-08-09T17:00:00.000Z",
                "end": "2023-08-10T16:59:59.059Z",
                "rule": {
                    "type": "date",
                    "value": [
                        3
                    ]
                },
                "transactions": [],
            },
            {
                "id": 5,
                "name": "Каждый 3-й рабочий день месяца",
                "color": "orange",
                "formula": "every-month",
                "repeatUntil": "none",
                "maxCount": 1,
                "interval": 1,
                "start": "2023-03-31T23:30:00.000Z",
                "end": "2023-08-10T16:59:59.059Z",
                "rule": {
                    "type": "working-day",
                    "value": [
                        3
                    ]
                },
                "transactions": [],
            },
            {
                "id": 6,
                "name": "Раз в 3 дня",
                "color": "indigo",
                "formula": "every-day",
                "repeatUntil": "none",
                "maxCount": 1,
                "interval": 3,
                "start": "2023-08-09T17:00:00.000Z",
                "end": "2023-08-10T16:59:59.059Z",
                "transactions": [],
            },
            {
                "id": 7,
                "name": "Каждый четверг",
                "color": "red",
                "formula": "every-week",
                "repeatUntil": "none",
                "maxCount": 1,
                "interval": 1,
                "start": "2023-08-02T17:00:00.000Z",
                "end": "2023-08-10T16:59:59.059Z",
                "rule": {
                    "type": "weekdays",
                    "value": [
                        4
                    ]
                },
                "transactions": [],
            },
        ],

        selectedEvents: [],

        cashboxes: [],
        cashboxCategories: [],

        costTemplates: [],
        costElements: [],
        costCategories: [],
    }),

    async mounted() {
        this.busy = true;
        await Promise.all([
            this.getCashboxes(),
            this.getCosts(),
        ]);
        this.busy = false;
    },

    watch: {
        '$route.params': {
            immediate: true,
            async handler(value) {
                const keys = ['event', 'calendar'];

                const allParamsValid = keys.every((key) => {
                    const param = value[key];

                    const isValid = this[`${key}Types`]
                        .find(({ alias }) => alias === param);

                    return param && isValid;
                });

                if (allParamsValid) {
                    this.calendarType = value.calendar;
                    // ...
                    return;
                }

                const params = keys.reduce((acc, key) => ({
                    ...acc,
                    [key]: this[`${key}Types`][0].alias,
                }), {});

                this.$router.push({
                    name: 'EventPlanner',
                    params,
                });
            },
        },
    },

    methods: {
        setCalendarType(type) {
            if (this.$route.params.calendar === type) {
                return;
            }

            if (this.busy) {
                return;
            }

            this.busy = true;
            this.$router.push({
                name: 'EventPlanner',
                params: {
                    event: this.$route.params.event,
                    calendar: type,
                },
            })
                .catch((e) => {
                    this.busy = false;
                    this.$error(e.message);
                })
                .finally(() => {
                    this.busy = false;
                });
        },

        toggleEvent(id) {
            const index = this.selectedEvents.findIndex((i) => i === id);

            if (index > -1) {
                this.selectedEvents.splice(index, 1);
            } else {
                this.selectedEvents.push(id);
            }
        },

        async getCashboxes() {
            try {
                const [cashboxes, categories] = await Promise.all([
                    getCashboxes(this.$api, this.$apiResponse),
                    getCashboxCategories(this.$api),
                ]);

                this.cashboxes = cashboxes;
                this.cashboxCategories = categories;
            } catch (e) {
                this.$error(e.message);
            }
        },

        async getCosts() {
            try {
                const [templates, elements, categories] = await Promise.all([
                    this.$api("/finance/costs/templates"),
                    this.$api("/finance/costs/elements"),
                    this.$api("/finance/costs/categories"),
                ]);

                this.costTemplates = templates;
                this.costElements = elements.list;
                this.costCategories = categories;
            } catch (e) {
                this.$error(e.message);
            }
        },
    },
};
</script>
