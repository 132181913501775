import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@v/Home';
import Login from '@v/Login';
import i18n from '@/i18n';
import { decode } from '@u/flt';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: i18n.t('pages.home'),
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: i18n.t('pages.login'),
        }
    },
    {
        path: '/catalog/:category?/:pg?',
        name: 'Catalog',
        meta: {
            title: i18n.t('pages.catalog'),
        },
        props: r => ({
            category: Number(r.params.category ?? 0),
            pg: Number(r.params.pg ?? 1),
            sort: Number(r.query.sort ?? 0),
            dir: Number(r.query.dir ?? 1),
            flt: decode(r.query),
        }),
        component: () => import('@v/Catalog'),
    },
    {
        path: '/cellranges/:pg?',
        name: 'Cellranges',
        meta: {
            title: i18n.t('pages.cellranges'),
        },
        props: r => ({
            pg: Number(r.params.pg ?? 1),
            sort: Number(r.query.sort ?? 0),
            dir: Number(r.query.dir ?? 1),
            flt: decode(r.query),
        }),
        component: () => import('@v/Cellranges'),
    },
    {
        path: '/storages/:storage?/:room?/:rack?',
        name: 'Storages',
        meta: {
            title: i18n.t('pages.storages'),
        },
        props: r => ({
            pg: Number(r.query.pg ?? 1),
            storage: Number(r.params.storage ?? 0),
            room: Number(r.params.room ?? 0),
            rack: Number(r.params.rack ?? 0),
        }),
        component: () => import('@v/Storages'),
    },
    {
        path: '/cells/:pg?',
        name: 'Cells',
        meta: {
            title: i18n.t('pages.cells'),
        },
        props: r => ({
            pg: Number(r.params.pg ?? 1),
            sort: Number(r.query.sort ?? 0),
            dir: Number(r.query.dir ?? 1),
            flt: decode(r.query),
        }),
        component: () => import('@v/Cells'),
    },
    {
        path: '/products/:pg?',
        name: 'Products',
        meta: {
            title: i18n.t('pages.products'),
        },
        props: r => ({
            pg: Number(r.params.pg ?? 1),
            sort: Number(r.query.sort ?? 0),
            dir: Number(r.query.dir ?? 1),
            flt: decode(r.query),
        }),
        component: () => import('@v/Products'),
    },
    {
        path: '/users',
        name: 'Users',
        meta: {
            title: i18n.t('pages.users'),
        },
        component: () => import('@v/Users'),
    },
    {
        path: '/access-control/:category?',
        name: 'AccessControl',
        meta: {
            title: i18n.t('pages.accessControl'),
        },
        props: r => ({
            category: r.params.category,
            categoryId: r.query.categoryId,
            userId: Number(r.query.userId),
        }),
        component: () => import('@v/AccessControl'),
    },
    {
        path: '/event-planner/:event?/:calendar?',
        name: 'EventPlanner',
        meta: {
            title: i18n.t('pages.eventPlanner'),
        },
        props: r => ({
            eventTypeProp: r.params.event ?? 'transaction',
            calendarTypeProp: r.params.calendar ?? 'month',
        }),
        component: () => import('@v/EventPlanner'),
    },
    {
        path: '/fields',
        component: {
            name: 'FieldsRoot',
            render: h => h('router-view'),
        },
        children: [
            {
                path: 'products',
                name: 'FieldsProducts',
                meta: {
                    title: i18n.t('pages.fields'),
                },
                component: () => import('@v/Field'),
                props: {
                    owner: 'product',
                }
            },
            {
                path: 'categories',
                name: 'FieldsCategories',
                meta: {
                    title: i18n.t('pages.fields'),
                },
                component: () => import('@v/Field'),
                props: {
                    owner: 'category',
                }
            },
            {
                path: 'storages',
                name: 'FieldsStorages',
                meta: {
                    title: i18n.t('pages.fields'),
                },
                component: () => import('@v/Field'),
                props: {
                    owner: 'storage',
                }
            },
            {
                path: 'cellranges',
                name: 'FieldsCellranges',
                meta: {
                    title: i18n.t('pages.fields'),
                },
                component: () => import('@v/Field'),
                props: {
                    owner: 'cellrange',
                }
            },
            {
                path: 'cells',
                name: 'FieldsCells',
                meta: {
                    title: i18n.t('pages.fields'),
                },
                component: () => import('@v/Field'),
                props: {
                    owner: 'cell',
                }
            },
            {
                path: 'racks',
                name: 'FieldsRacks',
                meta: {
                    title: i18n.t('pages.fields'),
                },
                component: () => import('@v/Field'),
                props: {
                    owner: 'rack',
                }
            },
            {
                path: 'rooms',
                name: 'FieldsRooms',
                meta: {
                    title: i18n.t('pages.fields'),
                },
                component: () => import('@v/Field'),
                props: {
                    owner: 'room',
                }
            },
        ]
    },
    {
        path: '/norton-commander',
        name: 'NortonCommander',
        meta: {
            title: i18n.t('pages.nortonCommander'),
        },
        component: () => import('@v/NortonCommander'),
        props: r => {
            const q = { filter: {} };
            if (typeof r.query.filter !== 'undefined') {
                q.filter = JSON.parse(r.query.filter);
            }
            return q;
        },
    },
    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

import seo from './seo';
router.beforeEach(seo);

export default router;
