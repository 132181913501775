<template>
    <v-autocomplete
        hide-no-data
        dense
        :value="value ? Number(value) : value"
        return-object
        :loading="loading"
        :items="items"
        @update:search-input="search"
        @change="setCat"
    />
</template>

<script>
import ProductOwnerFilterBase from "./Base";
export default {
    name: "ProductOwnerFilterCategory",
    extends: ProductOwnerFilterBase,
    data: () => ({
        cat: null,
        loading: false,
        searchItems: [],
        targetField: null,
    }),
    async mounted() {
        this.loading = true;
        const fields = await this.$api(`/category/fields`);
        this.targetField = fields.filter((f) => f.type == "catname").pop();
        if (this.value) {
            const cat = await this.$api(`/entity/category/${this.value}`);
            this.cat = {
                value: cat.id,
                text: cat.values
                    .filter((v) => v.field == this.targetField.id)
                    .pop().value,
            };
        }
        this.loading = false;
    },
    computed: {
        items() {
            return [
                ...[this.cat].filter((c) => c != null),
                ...this.searchItems,
            ];
        },
    },
    methods: {
        setCat(cat) {
            this.$emit("input", cat.value);
            this.cat = cat;
        },
        search(s) {
            clearTimeout(this.search.t);
            if (!s || !s.length || this.cat?.text == s) {
                return;
            }
            this.search.t = setTimeout(async () => {
                this.loading = true;
                try {
                    const query = new URLSearchParams();
                    query.append("filter[]", `${this.targetField.id}|∋|${s}`);
                    const r = await this.$apiResponse(
                        `/entity/category?${query.toString()}`
                    );
                    let data = [];
                    if (r.status == 200) {
                        ({ data } = await r.json());
                    }
                    this.searchItems = data.map((d) => ({
                        value: d.id,
                        text: d.values
                            .filter((v) => v.field == this.targetField.id)
                            .pop().value,
                    }));
                } catch (e) {
                    this.$error(e.message);
                }
                this.loading = false;
            }, 500);
        },
    },
};
</script>
