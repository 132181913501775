<template>
    <v-dialog v-model="show" width="800">
        <v-card :loading="loading">
            <v-card-title>
                {{ $t("category.move.move") }}
                {{ $tc("category.move.cats", selected.length) }}
            </v-card-title>
            <v-form @submit.prevent="save">
                <v-card-text>
                    <v-radio-group v-model="target" class="tree">
                        <v-treeview
                            :items="cats"
                            hoverable
                            :load-children="getItems"
                            dense
                        >
                            <template #prepend="{ item }">
                                <v-radio
                                    :value="item"
                                    :disabled="disabled(item)"
                                />
                            </template>
                        </v-treeview>
                    </v-radio-group>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text color="error" @click="show = false">
                        {{ $t("main.close") }}
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        :disabled="loading || !target"
                        type="submit"
                    >
                        {{ $t("main.save") }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<style lang="scss" scoped>
.tree {
    max-height: 500px;
    overflow: auto;
}
</style>

<script>
export default {
    name: "CatalogMove",
    data: () => ({
        selected: [],
        cats: [],
        target: null,
        show: false,
        loading: false,
    }),
    watch: {
        show(dir) {
            if (dir) {
                return;
            }
            this.target = null;
            this.cats = [];
        },
    },
    methods: {
        disabled(item) {
            if (this.selected.map((s) => s.parent).includes(item.id)) {
                return true;
            }
            if (this.selected.map((s) => s.id).includes(item.id)) {
                return true;
            }
            return false;
        },
        async open(selected) {
            this.selected = selected;
            this.show = true;
            this.cats = await this.getItems();
        },
        async getItems(item = { id: 0 }) {
            if (this.selected.map((s) => s.id).includes(item.id)) {
                return [];
            }
            this.loading = true;
            let list = [];
            try {
                const { children, id, name } = await this.$api(
                    `/entity/category/${item.id}`
                );
                if (item.id == 0) {
                    list.push({
                        id,
                        name,
                        children: children.map((c) => ({
                            id: c.id,
                            name: c.name,
                            parent: c.parent,
                            children: [],
                        })),
                    });
                } else {
                    item.children = children.map((c) => ({
                        id: c.id,
                        name: c.name,
                        parent: c.parent,
                        children: [],
                    }));
                }
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
            return list;
        },
        async save() {
            if (!this.target) {
                return;
            }
            this.loading = true;
            try {
                for (let s of this.selected) {
                    await this.$api(`/entity/category/${s.id}/move`, {
                        method: "PATCH",
                        body: {
                            parent: this.target.id,
                        },
                    });
                }
                this.show = false;
                this.$router.push({
                    params: {
                        category: this.target.id,
                        pg: null,
                    },
                });
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
    },
};
</script>