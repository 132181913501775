<template>
    <v-card v-if="isRich" flat>
        <v-card-subtitle>{{ field.name }}</v-card-subtitle>
        <tip-tap :value="value" @input="$emit('input', $event)" />
        <v-alert dense v-if="isEmpty" type="error">
            {{ $t("form.required") }}
        </v-alert>
    </v-card>
    <v-textarea
        v-else
        :label="field.name"
        :value="value"
        @input="$emit('input', $event)"
        :rules="rules"
        clearable
        dense
    />
</template>

<script>
import TipTap from "@c/TipTap";
import FieldBase from "./FieldBase";
export default {
    name: "FieldText",
    extends: FieldBase,
    components: {
        TipTap,
    },
    computed: {
        isRich() {
            return this.field.perks.includes("wysiwyg");
        },
        isEmpty() {
            return (
                this.field.perks.includes("required") &&
                (this.value || "").length == 0
            );
        },
    },
};
</script>