var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tabs',{staticStyle:{"border-bottom":"solid 1px #2196F3"}},_vm._l((_vm.tabs),function(ref){
var name = ref.name;
var alias = ref.alias;
var icon = ref.icon;
return _c('v-tab',{key:alias,attrs:{"to":{
            name: 'AccessControl',
            params: { category: alias },
            query: Object.assign({}, _vm.$route.query,
                {categoryId: undefined}),
        },"exact-path":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dense":""},domProps:{"textContent":_vm._s(icon)}}),_vm._v(" "+_vm._s(name)+" ")],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }