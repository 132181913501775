<template>
    <component
        :is="component"
        ref="card"
        :adaptive="adaptive"
        :busy="busy"
        :title="title"
        :balance="balance"
        currency="₽"
        :drag-scroll-area-visibility="dragScrollAreaVisibility"
        :stop-scroll="stopScroll"
        :index="index"
    >
        <template #header-action-left="{ open }">
            <v-btn
                v-if="hasSearch"
                icon
                small
                :color="showSearch ? 'error' : ''"
                @click="toggleSearch($event, open)"
            >
                <v-icon v-text="searchIcon" />
            </v-btn>
        </template>

        <template #header-action-right>
            <slot name="header-action-right" />
        </template>

        <template #subheader>
            <slot name="subheader" />
        </template>

        <template #body>
            <v-expand-transition v-if="hasSearch">
                <v-list
                    v-if="showSearch"
                    class="pa-0"
                >
                    <v-list-item>
                        <v-list-item-content>
                            <v-text-field
                                ref="textField"

                                v-model="model"
                                type="string"
                                label="Поиск"
                                dense
                                outlined
                                hide-details
                                prepend-icon="mdi-magnify"
                                :append-icon="model ? 'mdi-close' : ''"

                                @click:append="model = ''"
                            />
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider />

                    <buttons-expand
                        :state="buttonState"
                        @open="$emit('open')"
                        @close="$emit('close')"
                    />

                    <v-divider />
                </v-list>
            </v-expand-transition>

            <slot name="body" />
        </template>
    </component>
</template>

<script>
import { formatNumber } from '@u/formatting';

import MobileLayoutCard from "@c/norton-commander/layouts/mobile/LayoutCard";
import DesktopLayoutCard from "@c/norton-commander/layouts/desktop/LayoutCard";
import ButtonsExpand from "@c/norton-commander/buttons/ButtonsExpand";

export default {
    name: "AdaptiveCard",

    inject: ['$isMobile'],

    components: {
        MobileLayoutCard,
        DesktopLayoutCard,
        ButtonsExpand,
    },

    props: {
        adaptive: {
            type: Boolean,
            default: true,
        },
        hasSearch: {
            type: Boolean,
            default: true,
        },
        buttonState: {
            type: Boolean,
            default: false,
        },
        search: {
            type: String,
            default: '',
        },
        busy: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        total: {
            type: Number,
            default: 0,
        },
        dragScrollAreaVisibility: {
            type: Boolean,
            default: false,
        },
        stopScroll: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: null,
        },
    },

    data: () => ({
        showSearch: false,
    }),

    computed: {
        component() {
            if (!this.adaptive) {
                return 'DesktopLayoutCard';
            }

            return this.$isMobile()
                ? 'MobileLayoutCard'
                : 'DesktopLayoutCard';
        },

        searchIcon() {
            return this.showSearch ? 'mdi-magnify-close' : 'mdi-magnify';
        },

        model: {
            get() {
                return this.search;
            },
            set(val) {
                this.$emit('update:search', val);
            },
        },

        balance() {
            return formatNumber(this.total);
        },
    },

    watch: {
        showSearch(val) {
            this.model = '';

            if (val) {
                this.$nextTick(() => {
                    this.$refs.textField.focus();
                });
            }
        },
    },

    methods: {
        toggleSearch($event, isOpen) {
            this.showSearch = !this.showSearch;

            if (isOpen) {
                $event.stopPropagation();
            }
        },

        scrollTop() {
            if (this.$isMobile()) {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            } else {
                if (this.$refs.card) {
                    this.$refs.card.scrollTop();
                }
            }
        },
    },
};
</script>
