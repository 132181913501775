<template>
    <v-select
        :items="field.thesaurus || field.thesauruses"
        item-value="id"
        item-text="name"
        :value="value"
        @input="$emit('input', $event)"
        :rules="rules"
        :label="field.name"
        clearable
        dense
    />
</template>

<script>
import FieldBase from "./FieldBase";
export default {
    name: "FieldEnum",
    extends: FieldBase,
    props: {
        value: {
            type: Number,
        },
    },
    computed: {
        rules() {
            const rules = [];
            if (this.field.perks.includes("required")) {
                rules.push((v) => (v || 0) > 0 || this.$t("form.required"));
            }
            return rules;
        },
    },
    methods: {
        getDefaultValue: () => 0,
        setValue(value) {
            this.$emit("input", value.id);
        },
    },
};
</script>