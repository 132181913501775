class Path {
    constructor(item, categories) {
        this.item = item;
        this.categories = categories;
    }

    get array() {
        return this.getPathChunks(this.item)
            .reverse();
    }

    get string() {
        return this.array
            .map(({ title }) => title)
            .join(' / ');
    }

    getPathChunks(node, pathChunks = []) {
        const parent = this.findParent(node);

        if (!parent) {
            return pathChunks;
        }

        return this.getPathChunks(parent, [
            ...pathChunks,
            {
                id: parent.id,
                title: this.getTitle(parent),
            },
        ]);
    }

    findParent({ treeParent }) {
        return this.categories
            .find(({ id }) => id == treeParent);
    }

    getTitle({ title, name }) {
        return title ?? name;
    }
}

export class PathToCashbox extends Path {
    constructor(item, categories) {
        super(item, categories);
    }

    get array() {
        return super.array;
    }

    get string() {
        return super.string;
    }
}

export class PathToCost extends Path {
    constructor(item, categories) {
        super(item, categories);
    }

    get array() {
        return super.array;
    }

    get string() {
        return super.string;
    }

    findParent(node) {
        const parent = this.categories
            .find(({ id }) => id == node.treeParent);

        const category = this.categories
            .find(({ id }) => id == node.category);

        return parent ?? category;
    }
}

const getPath = (path, full, title) => {
    if (full) {
        const pathChunks = path.array.map(({ title }) => title);
        pathChunks.push(title);
        return pathChunks.join(' / ');
    }

    return path.string;
};

export const getPathToCost = (cost, categories, full = true) => {
    const path = new PathToCost(cost, categories);
    const title = `#${cost.id} ${cost.name}`;

    return getPath(path, full, title);
};

export const getPathToCostTemplate = (id, templates, categories, full = true) => {
    const cost = templates.find((c) => c.id == id);

    if (!cost) {
        return `#${id}`;
    }

    return getPathToCost(cost, categories, full);
};

export const getPathToCostByName = (name, templates, elements, categories, result = '', full = true) => {
    // @todo переделать, когда сервер начнёт нормально присылать связи
    const arr = name.split(' ');

    const [idString, ...rest] = arr.slice(1);
    const id = Number(idString.substring(1));
    const title = rest.join(' ');
    const isElement = title.includes('Элемент');

    let cost;
    if (isElement) {
        cost = elements.find((c) => c.id == id);
    } else {
        cost = templates.find((c) => c.id == id);
    }

    if (!cost) {
        return result || name;
    }

    return getPathToCost(cost, categories, full);
};
