<template>
    <v-card :loading="loading" :disabled="loading">
        <entity-editor ref="editor" owner="category" />
        <category-remove ref="remove" />
        <category-move ref="move" />
        <v-toolbar flat dense>
            <category-search />
        </v-toolbar>
        <v-fade-transition>
            <v-breadcrumbs
                :items="category.breadcrumbs"
                v-if="category.breadcrumbs.length"
            />
        </v-fade-transition>
        <v-list>
            <v-list-item>
                <v-list-item-action>
                    <v-btn icon @click="setCat(category.parent)">
                        <v-icon>mdi-arrow-right-bottom</v-icon>
                    </v-btn>
                </v-list-item-action>
                <v-list-item-title>{{ category.name }}</v-list-item-title>
                <v-menu>
                    <template #activator="{ attrs, on }">
                        <v-btn icon v-on="on" v-bind="attrs">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item @click="edit(0)">
                            <v-list-item-icon>
                                <v-icon>mdi-plus</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                {{ $t("main.create") }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list-item>
        </v-list>
        <v-divider />
        <v-list>
            <draggable
                v-model="category.children"
                handle=".drag"
                @change="sortEnd"
            >
                <v-list-item
                    v-for="c in category.children"
                    :key="c.id"
                    :value="c.id"
                    :input-value="selected.includes(c.id)"
                    color="primary"
                >
                    <v-list-item-icon class="drag">
                        <v-icon>mdi-drag</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title
                        @dblclick="setCat(c.id)"
                        @contextmenu.prevent="setCat(c.id)"
                        @click="setSelected(c.id)"
                    >
                        {{ c.name }}
                    </v-list-item-title>
                    <v-list-item-action>
                        <v-menu>
                            <template #activator="{ attrs, on }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item @click="setCat(c.id)">
                                    <v-list-item-icon>
                                        <v-icon>mdi-location-enter</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        {{ $t("main.enter") }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="edit(c.id)">
                                    <v-list-item-icon>
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        {{ $t("main.edit") }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="move(c.id)">
                                    <v-list-item-icon>
                                        <v-icon>mdi-arrow-u-right-top</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        {{ $t("main.move") }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="remove(c.id)">
                                    <v-list-item-icon>
                                        <v-icon color="error"
                                            >mdi-delete</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        {{ $t("main.remove") }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-list-item-action>
                </v-list-item>
            </draggable>
        </v-list>
        <v-fade-transition>
            <v-toolbar flat class="tool" dense v-if="selected.length">
                {{ selected.length }}
                <v-spacer />
                <v-btn icon color="error" @click="selected = []">
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
                <v-btn icon color="error" @click="remove(...selected)">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn icon color="primary" @click="move(...selected)">
                    <v-icon>mdi-arrow-u-right-top</v-icon>
                </v-btn>
            </v-toolbar>
        </v-fade-transition>
    </v-card>
</template>

<style lang="scss">
.tool {
    position: sticky;
    bottom: 0;
}
</style>

<script>
import EntityEditor from "@c/EntityEditor";
import CategoryRemove from "./CategoryRemove";
import CategoryMove from "./CategoryMove";
import CategorySearch from "./CategorySearch";
import Draggable from "vuedraggable";
export default {
    name: "CatalogCategoryList",
    components: {
        CategoryRemove,
        CategoryMove,
        CategorySearch,
        EntityEditor,
        Draggable,
    },
    data: () => ({
        loading: false,
        category: {
            id: 0,
            name: "",
            breadcrumbs: [],
            children: [],
            parent: null,
        },
        selected: [],
    }),
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                this.selected = [];
                this.getList();
            },
        },
    },
    methods: {
        setSelected(id) {
            if (this.selected.includes(id)) {
                this.selected = this.selected.filter((s) => s != id);
            } else {
                this.selected.push(id);
            }
        },
        move(...ids) {
            this.$refs.move.open(
                this.category.children.filter((c) => ids.includes(c.id))
            );
        },
        async sortEnd({
            moved: {
                element: { id },
                newIndex,
            },
        }) {
            if (this.category.children.length <= 1) {
                return;
            }
            try {
                await this.$api(`/entity/category/${id}/move`, {
                    method: "PATCH",
                    body: {
                        [newIndex == 0 ? "before" : "after"]:
                            newIndex == 0
                                ? this.category.children[newIndex + 1].id
                                : this.category.children[newIndex - 1].id,
                    },
                });
            } catch (e) {
                this.$error(e.message);
            }
        },
        async edit(id) {
            const needUpdate = await this.$refs.editor.open(id, {
                category: {
                    id: this.category.id,
                    name: this.category.name,
                },
            });
            if (needUpdate) {
                this.getList();
            }
        },
        setCat(id) {
            if (!id) {
                return;
            }
            this.$router.push({
                params: {
                    category: id,
                },
            });
        },
        async remove(...ids) {
            const needUpdate = await this.$refs.remove.open(...ids);
            if (needUpdate) {
                this.getList();
                this.selected = [];
            }
        },
        async getList() {
            this.loading = true;
            try {
                const category = await this.$api(`/entity/category/${this.id}`);
                category.breadcrumbs = category.breadcrumbs.map((b) => ({
                    text: b.name,
                    to: {
                        params: {
                            category: b.id,
                        },
                    },
                }));
                this.category = category;
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
    },
};
</script>
