<template>
    <v-alert type="error">{{ head }} {{ value }}</v-alert>
</template>

<script>
export default {
    name: "ProductOwnerFilterBase",
    props: {
        value: {
            required: true,
        },
        head: {
            type: Object,
            required: true,
        },
    },
};
</script>