var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.busy || _vm.disabledItems.length > 0,"hide-default-footer":_vm.$isMobile(),"footer-props":{
            showCurrentPage: true,
        },"page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-list',[_c('v-list-item',[_c('v-text-field',{attrs:{"type":"string","label":"Поиск","dense":"","outlined":"","hide-details":"","prepend-icon":"mdi-magnify","append-icon":_vm.search ? 'mdi-close' : ''},on:{"click:append":function($event){_vm.search = ''}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-divider')]},proxy:true},_vm._l((_vm.keys),function(key){return {key:("header." + key),fn:function(ref){
        var ref_header = ref.header;
        var text = ref_header.text;
        var icon = ref_header.icon;
return [_c('span',{key:key},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(icon))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(text))])],1)]}}}),{key:"item.title",fn:function(ref){
        var item = ref.item;
return [(item.isCategory)?_c('v-list-item',{attrs:{"to":{
                    name: 'AccessControl',
                    params: Object.assign({}, _vm.$route.params),
                    query: Object.assign({}, _vm.$route.query,
                        {categoryId: String(item.id)}),
                },"exact":""}},[(!_vm.$isMobile())?_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-folder-outline')}})],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_c('text-highlight-wrapper',{attrs:{"text":item.title,"queries":_vm.search}})],1),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.childrenCountTotal)}})],1),(_vm.$isMobile())?_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-folder-outline')}})],1):_vm._e()],1):_c('v-list-item',[_c('v-list-item-content',[_c('text-highlight-wrapper',{attrs:{"text":item.title,"queries":_vm.search}})],1)],1)]}},_vm._l((_vm.keys),function(key){return {key:("item." + key),fn:function(ref){
                var item = ref.item;
return [(item.isCategory)?_c('div',{key:key},[(_vm.user.id)?_c('span',{staticClass:"text--secondary",domProps:{"textContent":_vm._s(("\n                        " + (_vm.user.records.length ? item[key] : item.childrenCountTotal) + "\n                        /\n                        " + (item.childrenCountTotal) + "\n                    "))}}):_vm._e()]):_c('v-simple-checkbox',{key:key,attrs:{"value":item[key],"disabled":_vm.user.isDummy || _vm.disabledItems.includes(item.id),"ripple":false,"color":"primary"},on:{"input":function($event){return _vm.toggleCheckbox($event, key, item.id)}}})]}}})],null,true)}),_c('v-divider',{staticClass:"my-2"}),(_vm.$isMobile())?_c('v-sheet',{staticClass:"mx-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('v-text-field',{staticClass:"mt-2",attrs:{"value":_vm.itemsPerPage,"label":"Строк на странице","type":"number","min":"-1","max":"15"},on:{"input":_vm.setItemsPerPage}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }