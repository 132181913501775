<template>
    <component :is="component">
        <template #left>
            <slot name="left" />
        </template>

        <template #middle>
            <slot name="middle" />
        </template>

        <template #right>
            <slot name="right" />
        </template>
    </component>
</template>

<script>
import MobileLayout from "@c/norton-commander/layouts/mobile/LayoutComponent";
import DesktopLayout from "@c/norton-commander/layouts/desktop/LayoutComponent";

export default {
    name: "AdaptiveLayout",

    inject: ['$isMobile'],

    components: {
        MobileLayout,
        DesktopLayout,
    },

    computed: {
        component() {
            return this.$isMobile()
                ? "MobileLayout"
                : "DesktopLayout";
        }
    },
};
</script>
