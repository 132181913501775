<template>
    <v-menu
        open-on-hover
        nudge-top="4"
        :close-on-content-click="false"
    >
        <template #activator="{ on, attrs }">
            <span
                v-bind="attrs"
                v-on="on"
                class="my-n1 ml-1"
                style="cursor: default;"
            >
                <v-icon
                    dense
                    :style="`opacity: ${attrs['aria-expanded'] === 'true' ? 1 : 0.5};`"
                >
                    mdi-information
                </v-icon>
                <span
                    v-if="$vuetify.breakpoint.mobile"
                    class="ml-1 text--secondary font-weight-medium text-uppercase"
                >
                    Флаги
                </span>
            </span>
        </template>

        <v-card :max-height="$vuetify.breakpoint.mobile ?'50vh' : '70vh'">
            <v-list :dense="!$vuetify.breakpoint.mobile">
                <v-list-item
                    v-for="{ id, icon, name } in perks"
                    :key="id"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-{{ icon }}</v-icon>
                    </v-list-item-icon>
                    <span style="font-size: 14px;">{{ name }}</span>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: "FlagLegend",
    props: {
        perks: {
            type: Array,
            required: true,
        },
    },
};
</script>