<template>
    <div>
        <v-list-group
            v-for="category in categories"
            :key="category.id"

            dense
            sub-group

            @click="$emit('onSelectCategory', category.id)"
        >
            <template #activator>
                <v-list-item-title>
                    #{{ category.id }} {{ category.name }} ({{ category.templates.length }})
                </v-list-item-title>

                <v-list-item-action v-show="unlock">
                    <v-icon
                        title="Изменить категорию"
                        @click="$emit('onEditCategory', category.id)"
                    >
                        mdi-pencil
                    </v-icon>
                </v-list-item-action>
            </template>

            <constructor-categories
                class="pl-4"
                :unlock="unlock"
                :categories="category.children"

                v-on="$listeners"
            />

            <v-list-item v-show="unlock">
                <v-icon
                    title="Добавить подкатегорию"
                    @click="$emit('onAddSubcategory', category.id)"
                >
                    mdi-plus
                </v-icon>
            </v-list-item>
        </v-list-group>
    </div>
</template>

<script>
export default {
    name: 'ConstructorCategories',

    props: [
        'unlock',
        'categories',
    ],
};
</script>
