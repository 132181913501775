<template>
    <div>
        <v-text-field
            :label="field.name"
            :rules="rules"
            v-for="v in value"
            :key="v.id"
            :value="v.value"
            :clearable="!readonly"
            :readonly="readonly"
            :placeholder="readonly ? $t('form.auto') : null"
            dense
            :prepend-icon="isMultiple ? 'mdi-plus' : null"
            @click:prepend="addField"
            :append-outer-icon="v.id > 0 ? 'mdi-delete' : null"
            @click:append-outer="removeField(v.id)"
            @input="updateField(v.id, $event)"
        />
    </div>
</template>

<script>
import FieldBase from "./FieldBase";
export default {
    name: "FieldString",
    extends: FieldBase,
    props: {
        value: {
            type: Array,
        },
    },
    data: () => ({
        t: 1,
    }),
    computed: {
        isMultiple() {
            return this.field.perks.includes("multiple");
        },
    },
    methods: {
        addField() {
            if (!this.isMultiple) {
                return;
            }
            this.$emit("input", [...this.value, { id: this.t++, value: "" }]);
        },
        removeField(id) {
            if (id == 0) {
                return;
            }
            this.$emit(
                "input",
                this.value.filter((v) => v.id != id)
            );
        },
        updateField(id, value) {
            this.$emit(
                "input",
                this.value.map((v) => {
                    if (v.id == id) {
                        v.value = value;
                    }
                    return v;
                })
            );
        },
        getDefaultValue: () => [{ id: 0, value: "" }],
        setValue(value) {
            let input = [];
            if (Array.isArray(value)) {
                let id = 0;
                input = value.map((v) => ({
                    id: id++,
                    value: v,
                }));
            } else {
                input.push({ id: 0, value });
            }
            this.$emit("input", input);
        },
        getValue() {
            const list = this.value
                .filter((v) => (v.value || "").length > 0)
                .map((v) => v.value);
            if (!list.length) {
                return "";
            }
            return list;
        },
    },
};
</script>