export default [
    {
        alias: 'every-day',
        label: 'каждый день',
        suffix: 'дн.',
    },
    {
        alias: 'every-working-day',
        label: 'каждый рабочий день',
        suffix: 'дн.',
    },
    {
        alias: 'every-week',
        label: 'каждую неделю',
        suffix: 'нед.',
    },
    {
        alias: 'every-month',
        label: 'каждый месяц',
        suffix: 'мес.',
    },
];
