import { render, staticRenderFns } from "./EventComponent.vue?vue&type=template&id=1ffb3400&"
import script from "./EventComponent.vue?vue&type=script&lang=js&"
export * from "./EventComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCheckbox,VDivider,VListGroup,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle,VSheet})
