<template>
    <v-sheet>
        <v-toolbar
            dense
            elevation="1"
            :color="$vuetify.theme.dark ? '' : 'grey lighten-5'"
        >
            <span
                v-text="title"
                class="subtitle-2 text-no-wrap mr-2"
            />

            <slot name="header-action-left" />

            <v-spacer />

            <span class="caption ml-4">
                <slot name="header-action-right">
                    {{ balance }} {{ currency }}
                </slot>
            </span>
        </v-toolbar>

        <slot name="subheader" />
    </v-sheet>
</template>

<script>
export default {
    name: 'CardHeader',

    props: {
        title: {
            type: String,
            default: '',
        },
        balance: {
            type: String,
            default: '0',
        },
        currency: {
            type: String,
            default: '₽',
        },
    },
};
</script>
