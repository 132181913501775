<template>
    <v-checkbox
        :value="value"
        @change="$emit('input', $event)"
        :label="field.name"
        dense
        :true-value="1"
        :false-value="0"
        :readonly="field.perks.includes('systemfill')"
    />
</template>

<script>
import FieldBase from "./FieldBase";
export default {
    name: "FieldBool",
    extends: FieldBase,
    props: {
        value: {
            type: Number,
        },
    },
    methods: {
        getDefaultValue: () => 0,
        getValue() {
            return this.value;
        },
    },
};
</script>