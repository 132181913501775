var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"open-on-hover":"","nudge-top":"4","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"my-n1 ml-1",staticStyle:{"cursor":"default"}},'span',attrs,false),on),[_c('v-icon',{style:(("opacity: " + (attrs['aria-expanded'] === 'true' ? 1 : 0.5) + ";")),attrs:{"dense":""}},[_vm._v(" mdi-information ")]),(_vm.$vuetify.breakpoint.mobile)?_c('span',{staticClass:"ml-1 text--secondary font-weight-medium text-uppercase"},[_vm._v(" Флаги ")]):_vm._e()],1)]}}])},[_c('v-card',{attrs:{"max-height":_vm.$vuetify.breakpoint.mobile ?'50vh' : '70vh'}},[_c('v-list',{attrs:{"dense":!_vm.$vuetify.breakpoint.mobile}},_vm._l((_vm.perks),function(ref){
var id = ref.id;
var icon = ref.icon;
var name = ref.name;
return _c('v-list-item',{key:id},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-"+_vm._s(icon))])],1),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(name))])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }