<template>
    <div>
        <adaptive-dialog
            data-test-id="process-transaction-dialog"
            :data-test-state="dialog"

            ref="dialog"

            :dialog="dialog"
            :busy="busy"
            max-width="1000"
            :buttonSubmit="buttonSubmit"
            :disableSubmit="!formValidation || busy || hasSubmitted"
            :disableClose="busy"

            @close="handleCloseButton"
            @submit="submit"
        >
            <template #title>
                Массовое начисление
            </template>

            <template #subtitle>
                {{ node.name || node.title }}
            </template>

            <template #body>
                <div>
                    <v-form
                        ref="form"
                        v-model="formValidation"
                        lazy-validation
                    >
                        <cost-menu
                            :disabled="busy || hasSubmitted"
                            :tree="costTemplatesTree"
                            :selected-cost-id="selectedCostId"
                            :selected-cost="selectedCost"
                            :is-required="isCostRequired"

                            @select-cost="selectCost"
                            @clear="selectedCostId = NaN"
                        />

                        <v-textarea
                            ref="textarea"

                            v-model="comment"
                            :label="`Комментарий${comment ? '' : ' (опционально)'}`"

                            dense
                            counter
                            outlined
                            auto-grow
                            no-resize

                            :rules="rules"
                            :disabled="busy || hasSubmitted"
                            :append-icon="comment ? 'mdi-close' : ''"

                            @click:append="comment = ''"
                        />
                    </v-form>
                </div>

                <v-list
                    elevation="1"
                    :dense="$isMobile()"
                >
                    <v-list-item>
                        <v-list-item-content>
                            <v-text-field
                                v-model="search"
                                type="string"
                                label="Поиск"
                                dense
                                outlined
                                hide-details
                                prepend-icon="mdi-magnify"
                                :append-icon="search ? 'mdi-close' : ''"
                                @click:append="search = ''"
                            />
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-list
                    v-for="child in node.children"
                    :key="`${child.id}-item`"

                    class="py-0 my-2"
                    :elevation="$isMobile() ? '' : '1'"
                    :dense="$isMobile()"
                >
                    <cashbox-list
                        ref="recursiveLists"

                        :node="child"
                        :statuses="statuses"
                        :items-to-display="cashboxesAfterSearch"
                        :categories-to-display="categoriesToDisplay"
                        :search-text="search"

                        @submit="submitCashbox"

                        v-on="$listeners"
                    />
                </v-list>

                <event-dialog-create
                    ref="eventDialogCreate"
                    @input="event = $event"
                />

                <event-dialog-activator
                    :event="event"
                    class="pt-2"
                    @toggle="openDialog"
                    @edit="$refs.eventDialogCreate.open(event)"
                />
            </template>
        </adaptive-dialog>

        <dialog-close-confirmation
            ref="closeConfirmationDialog"

            @close="close"
        />
    </div>
</template>

<script>
import { SearchTree } from "@u/treeview";

import AdaptiveDialog from "@c/norton-commander/AdaptiveDialog";
import CostMenu from '@c/norton-commander/dialogs/cashbox/mass-process/CostMenu';
import CashboxList from '@c/norton-commander/dialogs/cashbox/mass-process/CashboxList';
import DialogCloseConfirmation from '@c/norton-commander/dialogs/cashbox/mass-process/CloseConfirmation';
import EventDialogCreate from "@c/event-planner/dialogs/EventCreate";
import EventDialogActivator from "@c/norton-commander/events/DialogActivator";

export default {
    name: "MassProcess",

    inject: ['$isMobile'],

    components: {
        AdaptiveDialog,
        CostMenu,
        CashboxList,
        DialogCloseConfirmation,
        EventDialogCreate,
        EventDialogActivator,
    },

    props: {
        cashboxCategories: {
            type: Array,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        costCategories: {
            type: Array,
            required: true,
        },
        costTemplates: {
            type: Array,
            required: true,
        },
        statuses: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        dialog: false,
        hasSubmitted: false,
        formValidation: true,

        search: '',
        comment: '',
        selectedCostId: NaN,

        node: {},
        event: null,

        modifiedCashboxes: [],
        transactionsBeingCreated: [],
        transactionsBeingProcessed: [],
        rules: [v => v.length < 255 || 'Превышена максимальная длина комментария'],
    }),

    computed: {
        cashboxesAfterSearch() {
            return this.cashboxes
                .filter(({ id, name, title, treeParent }) => {
                    const search = this.search.toLowerCase();

                    const cashboxName = `#${id} ${name || title}`;
                    const searchByName = cashboxName
                        .toLowerCase()
                        .includes(search);

                    const parent = this.cashboxCategories
                        .find(({ id }) => id == treeParent);
                    const parentName = parent ? (parent.name || parent.title) : '';
                    const searchByParent = parentName
                        .toLowerCase()
                        .includes(search);

                    return searchByName || searchByParent;
                })
                .map(({ id }) => id);
        },

        categoriesToDisplay() {
            const tree = new SearchTree({
                items: this.cashboxes,
                categories: this.cashboxCategories,
                selectedItems: this.cashboxesAfterSearch,
            });

            return tree.nodesToDisplay.map(({ id }) => id);
        },

        costTemplatesTree() {
            const categories = this.costCategories.map((cat) => ({
                ...cat,

                title: cat.name,

                isCategory: true,
                treeParent: cat.treeParent,

                children: [],
            }));

            this.costTemplates.forEach((cost) => {
                const parentId = cost.category;
                const parent = categories.find((c) => c.id == parentId);
                if (parent) {
                    parent.children.push(cost);
                }
            });

            categories.forEach((cat) => {
                const parent = categories.find((c) => c.id == cat.treeParent);
                if (parent) {
                    parent.children.push(cat);
                }
            });

            return categories.filter((cat) => !cat.treeParent);
        },

        selectedCost() {
            if (!this.selectedCostId) {
                return {};
            }

            return this.costTemplates.find((cost) => cost.id == this.selectedCostId);
        },

        busy() {
            return this.transactionsBeingCreated.length > 0 || this.transactionsBeingProcessed.length > 0;
        },

        buttonSubmit() {
            const text = this.event ? 'Запланировать' : 'Создать';
            const color = this.event ? 'success' : 'primary';

            return {
                text,
                color,
            };
        },

        isCostRequired() {
            return this.node.category === 'salary';
        },
    },

    methods: {
        async open(node) {
            this.node = node;
            this.dialog = true;

            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        },

        close() {
            this.dialog = false;

            setTimeout(() => {
                this.hasSubmitted = false;
                this.formValidation = true;

                this.search = '';
                this.comment = '';
                this.selectedCostId = NaN;

                this.node = {};
                this.event = null;

                this.modifiedCashboxes = [];
            }, 200);
        },

        async submit() {
            if (!this.$refs.form.validate()) {
                this.$refs.dialog.scrollTop({ behavior: 'smooth' });
                return;
            }

            if (this.event) {
                if (this.$refs.recursiveLists) {
                    this.$refs.recursiveLists.forEach((ref) => ref.submit());
                }

                const event = {
                    ...this.event,
                    transactions: this.modifiedCashboxes.map(({ id, amount }) => {
                        const transaction = {
                            operations: [
                                {
                                    cashbox: id,
                                    amount,
                                },
                            ],
                            links: [
                                {
                                    id: this.selectedCost.id,
                                    type: 'costs.template.manual',
                                },
                            ],
                        };

                        if (this.comment) {
                            transaction.comment = this.comment;
                        }

                        return transaction;
                    }),
                };

                this.$emit('event:create', event);

                this.modifiedCashboxes.forEach(async (cashbox) => {
                    const payload = {
                        hasError: false,
                        disabled: false,
                        busy: false,
                    };
                    cashbox.callback(payload);
                });

                this.modifiedCashboxes = [];

                this.close();
            } else {
                this.search = '';
                this.hasSubmitted = true;
                this.$refs.recursiveLists.forEach((ref) => ref.submit());

                await Promise.all(this.modifiedCashboxes.map(async ({ id, amount, callback }) => {
                    const { hasError, transaction } = await this.createTransaction(id, amount, callback);

                    if (!hasError) {
                        await this.processTransaction(transaction, callback);
                    }
                }));

                // @todo this.$emit('update-cashboxes');
            }
        },

        async createTransaction(id, amount, callback) {
            const post = {
                operations: [
                    {
                        cashbox: id,
                        amount,
                    },
                ],

                links: [
                    {
                        id: this.selectedCost.id,
                        type: 'costs.template.manual',
                    },
                ],
            };

            if (this.comment !== '') {
                post.comment = this.comment;
            }

            this.transactionsBeingCreated.push(id);

            try {
                const transaction = await this.$api(
                    "/finance/transaction",
                    {
                        method: "POST",
                        body: post,
                    }
                );

                this.transactionsBeingCreated
                    = this.transactionsBeingCreated
                        .filter((value) => value !== id);

                const payload = {
                    hasError: false,
                    disabled: true,
                    busy: true,
                    transaction: transaction,
                };
                callback(payload);

                return payload;
            } catch (e) {
                this.$error(e.message);

                this.transactionsBeingCreated
                    = this.transactionsBeingCreated
                        .filter((value) => value !== id);

                const payload = {
                    hasError: true,
                    disabled: true,
                    busy: false,
                };
                callback(payload);

                return payload;
            }
        },

        async processTransaction({ id }, callback) {
            this.transactionsBeingProcessed.push(id);

            try {
                const processedTransaction = await this.$api(
                    "/finance/transaction/" + id,
                    {
                        method: "PATCH",
                        body: {
                            status: 'queue',
                        },
                    }
                );

                this.transactionsBeingProcessed
                    = this.transactionsBeingProcessed
                        .filter((value) => value !== id);

                const payload = {
                    hasError: false,
                    disabled: true,
                    busy: false,
                    transaction: processedTransaction,
                };
                callback(payload);

                return payload;
            } catch (e) {
                this.$error(e.message);

                this.transactionsBeingProcessed =
                    this.transactionsBeingProcessed
                        .filter((value) => value !== id);

                const payload = {
                    hasError: true,
                    disabled: true,
                    busy: false,
                };
                callback(payload);

                return payload;
            }
        },

        handleCloseButton() {
            if (this.hasSubmitted) {
                this.close();
                return;
            }

            if (this.$refs.recursiveLists) {
                this.$refs.recursiveLists.forEach((ref) => ref.submit());
            }

            if (
                this.modifiedCashboxes.length
                || this.comment.length
                || this.selectedCostId
            ) {
                this.$refs.closeConfirmationDialog.open();
            } else {
                this.close();
            }

            this.modifiedCashboxes.forEach(async (cashbox) => {
                const payload = {
                    hasError: false,
                    disabled: false,
                    busy: false,
                };
                cashbox.callback(payload);
            });

            this.modifiedCashboxes = [];
        },

        submitCashbox(payload) {
            this.modifiedCashboxes.push(payload);
        },

        selectCost(costId) {
            this.selectedCostId = costId;
        },

        openDialog($event) {
            if (this.event) {
                this.event = null;

                return;
            }

            this.$refs.eventDialogCreate.open($event);
        },
    },
};
</script>
