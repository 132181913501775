<template>
    <v-expansion-panels
        data-test-id="expansion-panels"
        :data-test-panel="panel"

        v-model="panel"
        accordion
        focusable
    >
        <slot name="left" />

        <slot name="middle" />

        <slot name="right" />
    </v-expansion-panels>
</template>

<script>

export default {
    name: "MobileLayout",

    data: () => ({
        panel: 2,
    }),
};
</script>
