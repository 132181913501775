<script>
import FieldBaseDateTime from "./FieldBaseDateTime";
export default {
    name: "FieldTime",
    extends: FieldBaseDateTime,
    computed: {
        currentPerkName: () => "curtime",
        showDate: () => false,
    },
};
</script>