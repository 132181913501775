import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import apiPlugin from '@p/api';
import api from '@u/api';
import dialog from '@p/dialog';
import './scss/global.scss';

Vue.config.productionTip = false;

Vue.use(apiPlugin, { api, i18n, router });

Vue.use(dialog, { vuetify, i18n });

Vue.filter('apiDate', v => {
    if (!v) {
        return v;
    }
    v = v.split(' ');
    v[0] = v[0].split('-').reverse().join('.');
    return v.join(' ');
});

Vue.filter('capitalize', function (value) {
    if (!value) {
        return '';
    }

    value = value.toString();

    return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
});

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');
