<template>
    <v-container
        fluid
        class="pa-0"
    >
        <v-row no-gutters>
            <v-col>
                <v-btn
                    block
                    plain
                    small
                    @click="$emit(button.emit)"
                >
                    <v-icon
                        v-text="button.icon"
                        left
                        small
                    />
                    <span
                        v-text="button.text"
                        class="caption"
                    />
                </v-btn>
            </v-col>

            <v-col v-if="showActive">
                <v-btn
                    block
                    plain
                    small
                    @click="$emit('active')"
                >
                    <v-icon
                        v-text="'mdi-expand-all'"
                        left
                        small
                        color="primary"
                    />
                    <span
                        v-text="'Раскрыть активные'"
                        class="caption"
                    />
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "ButtonsExpand",

    inject: ['$isMobile'],

    props: {
        state: {
            type: Boolean,
            default: false,
        },
        showActive: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        button() {
            return this.state
                ? {
                    text: 'Раскрыть все',
                    icon: 'mdi-arrow-collapse-down',
                    emit: 'open',
                }
                : {
                    text: 'Свернуть все',
                    icon: 'mdi-arrow-collapse-up',
                    emit: 'close',
                };
        },
    },
};
</script>
