<template>
    <v-dialog
        v-model="dialog"
        eager
        persistent
        :max-width="maxWidth"
        :fullscreen="$isMobile()"
    >
        <v-card
            class="d-flex flex-column"
            :loading="busy"
        >
            <v-card-title
                ref="title"
                class="word-break"
            >
                <v-btn
                    v-if="showCloseButton"
                    icon
                    class="mr-2"
                    :disabled="disableClose"
                    @click="$emit('close')"
                >
                    <v-icon v-text="'mdi-arrow-left'" />
                </v-btn>
                <slot name="title" />
            </v-card-title>

            <v-card-subtitle v-if="$slots.subtitle">
                <slot name="subtitle" />
            </v-card-subtitle>

            <v-divider />

            <v-card-text
                class="flex-grow-1"
                :class="{
                    'pa-0': $isMobile() && dense,
                    'pt-5': !($isMobile() && dense)
                }"
            >
                <slot name="body" />
            </v-card-text>

            <v-divider />

            <v-container
                v-if="$isMobile()"
                fluid
            >
                <v-row dense>
                    <v-col v-if="$slots.submit || showSubmitButton">
                        <slot name="submit">
                            <v-btn
                                v-if="showSubmitButton"
                                data-test-id="button-submit"
                                block
                                :color="btnSubmit.color || 'primary'"
                                :disabled="disableSubmit"
                                @click="$emit('submit')"
                            >
                                {{ btnSubmit.text || 'Применить' }}
                            </v-btn>
                        </slot>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col>
                        <slot name="close">
                            <v-btn
                                v-if="showCloseButton"
                                data-test-id="button-close"
                                block
                                :color="btnClose.color || 'secondary'"
                                :disabled="disableClose"
                                @click="$emit('close')"
                            >
                                {{ btnClose.text || 'Закрыть' }}
                            </v-btn>
                        </slot>
                    </v-col>
                </v-row>
            </v-container>

            <v-container
                v-else
                fluid
            >
                <v-row>
                    <v-col cols="6">
                        <slot name="close">
                            <v-btn
                                v-if="showCloseButton"
                                data-test-id="button-close"
                                block
                                :color="btnClose.color || 'secondary'"
                                :disabled="disableClose"
                                @click="$emit('close')"
                            >
                                {{ btnClose.text || 'Закрыть' }}
                            </v-btn>
                        </slot>
                    </v-col>

                    <v-col cols="6">
                        <slot name="submit">
                            <v-btn
                                v-if="showSubmitButton"
                                data-test-id="button-submit"
                                block
                                :color="btnSubmit.color || 'primary'"
                                :disabled="disableSubmit"
                                @click="$emit('submit')"
                            >
                                {{ btnSubmit.text || 'Применить' }}
                            </v-btn>
                        </slot>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'AdaptiveDialog',

    inject: ['$isMobile'],

    props: {
        dialog: {
            type: Boolean,
            required: true,
        },
        busy: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        maxWidth: {
            type: String,
            default: '',
        },
        btnClose: {
            type: Object,
            default() {
                return {};
            },
        },
        btnSubmit: {
            type: Object,
            default() {
                return {};
            },
        },
        disableSubmit: {
            type: Boolean,
            default: false,
        },
        disableClose: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        showCloseButton() {
            return Object.keys(this.$listeners).includes('close');
        },

        showSubmitButton() {
            return Object.keys(this.$listeners).includes('submit');
        },
    },

    watch: {
        dialog() {
            this.scrollTop();
        },
    },

    methods: {
        scrollTop(options = {}) {
            this.$refs.title.scrollIntoView(options);
        },
    },
};
</script>
