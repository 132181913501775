<template>
    <v-card class="w-full h-full d-flex flex-column">
        <v-sheet>
            <calendar-toolbar
                :type="type"
                :types="types"
                :title="title"

                @calendar:prev="$refs.calendar.prev()"
                @calendar:next="$refs.calendar.next()"
                @calendar:set-today="focus = ''"

                v-on="$listeners"
            />
        </v-sheet>

        <v-divider />

        <div class="pa-1 flex-grow-1 overflow-auto">
            <v-calendar
                ref="calendar"
                v-model="focus"
                :type="type"
                :events="eventsCalculated"

                show-week
                color="primary"
                :event-color="getEventColor"
                :weekdays="[1, 2, 3, 4, 5, 6, 0]"

                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="updateRange"
            />
        </div>

        <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
            max-height="500"
            max-width="30rem"
        >
            <v-card
                color="grey lighten-4"
                flat
            >
                <v-toolbar
                    :color="selectedEvent.color"
                    dark
                >
                    <v-toolbar-title v-html="selectedEvent.name" />

                    <v-spacer />

                    <v-btn
                        icon
                        @click="$emit('event:dialog:edit', selectedEvent)"
                    >
                        <v-icon v-text="'mdi-calendar-edit'" />
                    </v-btn>
                </v-toolbar>

                <v-card-text class="pa-0">
                    <v-list
                        light
                        class="bg-transparent pb-0"
                    >
                        <v-list-item>
                            <event-info
                                #default="{ info }"
                                :event="selectedEvent"
                            >
                                <v-list-item-content>
                                    <v-list-item-title
                                        v-for="{ label, value } in info"
                                        :key="label"
                                        class="text-wrap"
                                    >
                                        <span
                                            v-if="label"
                                            v-text="`${label}: `"
                                            class="font-weight-medium"
                                        />
                                        <span v-text="value" />
                                    </v-list-item-title>
                                </v-list-item-content>
                            </event-info>
                        </v-list-item>
                    </v-list>

                    <v-divider light />

                    <v-list
                        dense
                        light
                        class="bg-transparent py-0"
                    >
                        <v-list-item
                            v-for="(transaction, transactionIndex) in selectedEvent.transactions"
                            :key="transactionIndex"
                        >
                            <transaction-info
                                #default="{ info }"
                                :transaction="transaction"
                                :cashboxes="cashboxes"
                                :cashbox-categories="cashboxCategories"
                                :cost-templates="costTemplates"
                                :cost-elements="costElements"
                                :cost-categories="costCategories"
                            >
                                <v-list-item-content>
                                    <template v-for="{ alias, value, label } in info">
                                        <template v-if="alias === 'operations'">
                                            <v-list-item-subtitle
                                                v-for="({ path, amount, amountClass }, index) in value"
                                                :key="`operation-${index}`"
                                                class="text-wrap"
                                            >
                                                <span v-text="path" />
                                                <span
                                                    v-text="amount"
                                                    :class="amountClass"
                                                    class="text-no-wrap ml-2"
                                                />
                                            </v-list-item-subtitle>
                                        </template>

                                        <template v-else>
                                            <v-list-item-subtitle
                                                :key="alias"
                                                v-text="`${label}:`"
                                            />

                                            <v-list-item-subtitle
                                                v-for="(row, index) in value"
                                                :key="`${alias}-${index}`"
                                                class="text-wrap font-weight-light"
                                            >
                                                <span v-text="row" />
                                            </v-list-item-subtitle>
                                        </template>
                                    </template>
                                </v-list-item-content>
                            </transaction-info>
                        </v-list-item>
                    </v-list>
                </v-card-text>

                <v-divider light />

                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="selectedOpen = false"
                    >
                        Закрыть
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </v-card>
</template>

<script>
import { updateRange } from '@u/events/update-range';

import CalendarToolbar from "@c/event-planner/CalendarToolbar";
import EventInfo from "@c/event-planner/EventInfo";
import TransactionInfo from "@c/event-planner/TransactionInfo";

export default {
    name: "EventCalendar",

    components: {
        CalendarToolbar,
        EventInfo,
        TransactionInfo,
    },

    props: {
        type: {
            type: String,
            required: true,
        },
        types: {
            type: Array,
            required: true,
        },
        events: {
            type: Array,
            required: true,
        },
        selectedEvents: {
            type: Array,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        cashboxCategories: {
            type: Array,
            required: true,
        },
        costTemplates: {
            type: Array,
            required: true,
        },
        costElements: {
            type: Array,
            required: true,
        },
        costCategories: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        title: '',
        focus: '',
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,

        minDate: new Date(),
        maxDate: new Date(),
    }),

    mounted () {
        this.$refs.calendar.checkChange();

        if (this.$refs.calendar) {
            this.title = this.$refs.calendar.title;
        }
    },

    updated() {
        if (this.$refs.calendar) {
            this.title = this.$refs.calendar.title;
        }
    },

    computed: {
        eventsFiltered() {
            if (!this.selectedEvents.length) {
                return this.events;
            }

            return this.events.filter(({ id }) => this.selectedEvents.includes(id));
        },

        eventsCalculated() {
            return updateRange(this.minDate, this.maxDate, this.eventsFiltered);
        },
    },

    methods: {
        viewDay ({ date }) {
            this.focus = date;
            this.$emit('calendar:set-type', 'day');
        },

        getEventColor (event) {
            return event.color;
        },

        showEvent ({ nativeEvent, event }) {
            const open = () => {
                const { id } = event;
                this.selectedEvent = this.events.find((e) => e.id === id);
                this.selectedElement = nativeEvent.target;
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));
            };

            if (this.selectedOpen) {
                this.selectedOpen = false;
                requestAnimationFrame(() => requestAnimationFrame(() => open()));
            } else {
                open();
            }

            nativeEvent.stopPropagation();
        },

        updateRange ({ start, end }) {
            const min = new Date(`${start.date}T00:00:00`);
            min.setDate(min.getDate() - 7);
            this.minDate = min;

            const max = new Date(`${end.date}T23:59:59`);
            max.setDate(max.getDate() + 7);
            this.maxDate = max;
        },
    },
};
</script>
