<template>
    <adaptive-dialog
        :dialog="dialog"
        max-width="1000"
        :btnSubmit="{ text: 'Изменить транзакцию' }"
        :disableSubmit="!formValidation"

        @close="close"
        @submit="submit"
    >
        <template #title>
            Редактирование транзкции #{{ id }}
        </template>

        <template #body>
            <v-form
                ref="form"
                v-model="formValidation"
                lazy-validation
            >
                <adaptive-item
                    v-for="({ id }, index) in operations"
                    :key="id"
                    :title="`Касса ${operations.length > 1 ? index + 1 : ''}`"
                    icon="mdi-cash-register"
                    :condition="cashboxes[index].id !== selectedCashboxes[index].id"
                >
                    <template #current>
                        <v-text-field
                            :value="cashboxes[index].name"
                            label="Текущее значение"
                            type="text"
                            disabled
                        />
                    </template>

                    <template #new>
                        <cashbox-menu
                            :tree="cashboxesTree"
                            :selectedItem="selectedCashboxes[index]"
                            :append-icon="cashboxes[index].id !== selectedCashboxes[index].id ? 'mdi-restore' : ''"
                            :disabled="!isDraft"

                            @select-item="selectCashbox($event, index)"
                            @reset="selectCashbox(cashboxes[index].id, index)"
                        />
                    </template>
                </adaptive-item>

                <adaptive-item
                    title="Сумма"
                    icon="mdi-currency-rub"
                    :condition="Number(newAmount) !== Number(amount)"
                >
                    <template #current>
                        <v-text-field
                            :value="String(amount)"
                            label="Текущее значение"
                            type="number"
                            disabled
                        />
                    </template>

                    <template #separator>
                        <div
                            class="d-flex align-center"
                            :class="{
                                'success--text': Number(newAmount) > Number(amount),
                                'error--text': Number(newAmount) < Number(amount),
                            }"
                        >
                            {{ Number(amount) > Number(newAmount) ? '-' : '+' }}
                            {{ formatNumber(Math.abs(Number(newAmount) - Number(amount))) }}
                            ₽
                        </div>
                    </template>

                    <template #new>
                        <v-text-field
                            v-model="newAmount"
                            ref="input"
                            type="number"
                            label="Новое значение"
                            :append-icon="Number(newAmount) !== Number(amount) ? 'mdi-restore' : ''"
                            :rules="rules.amount"
                            :disabled="!isDraft"
                            @click:append="newAmount = amount"
                        />
                    </template>
                </adaptive-item>

                <adaptive-item
                    title="Время создания"
                    icon="mdi-calendar-clock-outline"
                    :condition="draftTime !== newDraftTime"
                >
                    <template #current>
                        <v-text-field
                            :value="displayedDateTime"
                            label="Текущее значение"
                            type="text"
                            disabled
                        />
                    </template>

                    <template #new>
                        <date-time-picker
                            :draft-time="newDraftTime"
                            :append-icon="draftTime !== newDraftTime ? 'mdi-restore' : ''"
                            @submit="newDraftTime = $event"
                            @reset="newDraftTime = draftTime"
                        />
                    </template>
                </adaptive-item>

                <v-textarea
                    v-model="comment"
                    :rules="rules.comment"

                    dense
                    counter
                    outlined
                    auto-grow
                    no-resize

                    hide-details="auto"
                    placeholder="Комментарий (опционально)"

                    :append-icon="comment ? 'mdi-close' : ''"

                    @click:append="comment = ''"
                />
            </v-form>
        </template>
    </adaptive-dialog>
</template>

<script>
import { Tree } from "@u/treeview";
import { formatNumber } from '@u/formatting';
import AdaptiveDialog from "@c/norton-commander/AdaptiveDialog";
import AdaptiveItem from "@c/norton-commander/dialogs/transaction/edit/AdaptiveItem";
import CashboxMenu from "@c/norton-commander/dialogs/transaction/edit/CashboxMenu";
import DateTimePicker from "@c/norton-commander/dialogs/transaction/edit/DateTimePicker";

export default {
    name: 'TransactionEdit',

    components: {
        AdaptiveDialog,
        AdaptiveItem,
        CashboxMenu,
        DateTimePicker,
    },

    props: {
        cashboxCategories: {
            type: Array,
            required: true,
        },

        cashboxesProp: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        dialog: false,
        formValidation: true,

        id: '',
        status: '',
        newAmount: '',
        draftTime: '',
        newDraftTime: '',
        comment: '',

        cashboxes: {},
        selectedCashboxes: {},

        operations: [],
        rules: {
            amount: [v => !!v || 'Поле не может быть пустым'],
            comment: [v => v.length < 255 || 'Превышена максимальная длина комментария'],
        },
    }),

    computed: {
        amount() {
            let result = 0;

            if (this.operations.length) {
                result = Math.abs(this.operations[0].amount);
            }

            return result;
        },

        cashboxesTree() {
            const { nodes } = new Tree({
                items: this.cashboxesProp,
                categories: this.cashboxCategories,
            });

            return nodes.filter(({ treeParent }) => !treeParent);
        },

        displayedDateTime() {
            const date = new Date(this.draftTime).toLocaleDateString();
            const time = new Date(this.draftTime).toLocaleTimeString([]);
            return `${date} ${time}`;
        },

        isDraft() {
            return (this.status == 'draft');
        }
    },

    methods: {
        formatNumber,

        async open({ id, operations, status, draft_time }) {
            this.$refs.form.resetValidation();

            this.id = id;
            this.operations = operations;
            this.draftTime = draft_time;
            this.status = status;
            this.newDraftTime = this.draftTime;
            this.newAmount = this.amount.toString();

            this.operations.forEach((operation, index) => {
                this.$set(this.selectedCashboxes, index, {
                    id: null,
                    name: '',
                });
            });

            this.operations.forEach(({ cashbox }, index) => {
                this.selectCashbox(cashbox, index);
            });

            this.cashboxes = JSON.parse(JSON.stringify(this.selectedCashboxes));

            this.dialog = true;
        },

        close() {
            this.dialog = false;

            setTimeout(() => {
                this.id = '';
                this.operations = [];
                this.draftTime = '';
                this.newDraftTime = '';
                this.newAmount = '';
                this.comment = '';
                this.selectedCashboxes = {};
            }, 200);
        },

        submit() {
            this.$refs.form.validate();
            if (!this.formValidation) {
                return;
            }

            this.close();

            const operations = JSON.parse(JSON.stringify(this.operations));

            if (Number(this.amount) !== Number(this.newAmount)) {
                operations.forEach((operation) => {
                    operation.amount = Number(this.newAmount) * Math.sign(operation.amount);
                });
            }

            operations.forEach((operation, index) => {
                if (this.selectedCashboxes[index].id !== operation.cashbox) {
                    if (this.selectedCashboxes[index].id) {
                        operation.cashbox = this.selectedCashboxes[index].id;
                    }
                }
            });

            const body = {};

            if (JSON.stringify(operations) !== JSON.stringify(this.operations)) {
                body.operations = operations;
            }

            if (this.draftTime !== this.newDraftTime) {
                body.draft_time = this.newDraftTime;
            }

            if (this.comment !== '') {
                body.comment = this.comment;
            }

            if (!Object.keys(body).length) {
                return;
            }

            const payload = {
                id: this.id,
                body,
            };

            this.$emit('transaction:edit', payload);
        },

        selectCashbox(id, index) {
            if (this.selectedCashboxes[index].id !== id) {
                const cashbox = this.cashboxesProp.find((cashbox) => cashbox.id === id);

                this.$set(this.selectedCashboxes, index, {
                    id,
                    name: cashbox ? `#${id} ${cashbox.title}` : '',
                });
            }
        }
    },
};
</script>
