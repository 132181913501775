<template>
    <adaptive-card
        :adaptive="false"
        :search="search"
        :button-state="buttonState"
        :busy="busy"
        title="Прочие расходы"
        :total="total"

        @update:search="search = $event"
        @open="toggleCategories('open')"
        @close="toggleCategories('close')"
        v-on="$listeners"
    >
        <template #body>
            <v-list
                dense
                class="pa-0"
            >
                <cost-category
                    v-for="category in categories"
                    :key="category.id"

                    ref="recursiveLists"

                    :category="category"
                    :selected-item="selectedItem"
                    :templates-to-display="templatesAfterSearch"
                    :elements-to-display="elementsAfterSearch"
                    :categories-to-display="categoriesToDisplay"
                    :search-text="search"

                    @on-drop-to-costs="onDropToCosts"
                    @toggle-category="onCategoryToggle"

                    v-on="$listeners"
                />
            </v-list>
        </template>
    </adaptive-card>
</template>

<script>
import { SearchTree } from '@u/treeview';

import AdaptiveCard from '@c/norton-commander/layouts/AdaptiveCard';
import CostCategory from '@c/norton-commander/costs/CostCategory';

export default {
    name: "CostList",

    components: {
        AdaptiveCard,
        CostCategory,
    },

    props: {
        selectedItem: {
            type: Object,
            required: true,
        },

        updateCostsList: {
            type: Number,
            default: null,
        },

        costCategories: {
            type: Array,
            required: true,
        },

        costTemplates: {
            type: Array,
            required: true,
        },

        costElements: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        busy: false,
        denyAccess: false,
        showSearch: false,

        search: '',

        categories: [],
        templates: [],
        opened: {},

        total: 0,
    }),

    mounted() {
        this.getCategories();
    },

    computed: {
        templatesAfterSearch() {
            return this.costTemplates
                .filter(this.checkCost)
                .map(({ id }) => id);
        },

        elementsAfterSearch() {
            return this.costElements
                .filter(this.checkCost)
                .map(({ id }) => id);
        },

        categoriesToDisplay() {
            const templateTree = new SearchTree({
                items: this.costTemplates,
                categories: this.costCategories,
                selectedItems: this.templatesAfterSearch,
            });

            const elementTree = new SearchTree({
                items: this.costElements,
                categories: this.costCategories,
                selectedItems: this.templatesAfterSearch,
            });

            return [
                ...templateTree.nodesToDisplay.map(({ id }) => id),
                ...elementTree.nodesToDisplay.map(({ id }) => id),
            ];
        },

        buttonState() {
            return !Object.values(this.opened).some((v) => v);
        },
    },

    watch: {
        search(val) {
            if (val) {
                this.toggleCategories('open');
            }
        },

        updateCostsList: function() {
            this.getCategories();
        },

        total(val) {
            this.$emit('update:costTotal', val);
        },
    },

    methods: {
        async getCategories() {
            if (this.denyAccess) {
                return;
            }
            this.busy = true;
            try {
                const result = await this.$api("/finance/costs/categories");
                this.fillCategories(result);
                this.getTemplates();
                this.getElements();
            } catch (e) {
                this.busy = false;
                if (e.httpStatus == 403) {
                    this.denyAccess = true;
                }
            }
            this.busy = false;
        },

        async getTemplates() {
            if (this.denyAccess) {
                return;
            }
            try {
                const result = await this.$api("/finance/costs/templates");
                this.fillTemplates(result);
                this.calcAmountOfCategories();
            } catch (e) {
                if (e.httpStatus == 403) {
                    this.denyAccess = true;
                }
            }
        },

        async getElements() {
            if (this.denyAccess) {
                return;
            }
            try {
                const result = await this.$api("/finance/costs/elements");
                this.fillElements(result);
                this.removeExpireElements(this.categories);
                this.calcAmountOfCategories();
            } catch (e) {
                if (e.httpStatus == 403) {
                    this.denyAccess = true;
                }
            }
        },

        fillCategories(categories) {
            for (const category of categories) {
                if (category.treeParent != null) {
                    continue;
                }

                const current = this.getCategoryById(
                    category.id,
                    this.categories
                );

                if (current == null) {
                    category['children'] = [];
                    category['templates'] = [];
                    category['elements'] = [];
                    category['amount'] = 0;
                    category['currency'] = '₽';
                    this.categories.push(category);
                }
            }

            for (const category of categories) {
                if (category.treeParent == null) {
                    continue;
                }

                const parent = this.getCategoryById(
                    category.treeParent,
                    this.categories
                );

                if (parent == null) {
                    continue;
                }

                const current = this.getCategoryById(
                    category.id,
                    this.categories
                );

                if (current == null) {
                    category['children'] = [];
                    category['templates'] = [];
                    category['elements'] = [];
                    category['amount'] = 0;
                    category['currency'] = '₽';
                    parent.children.push(category);
                }
            }
        },

        fillTemplates(templates) {
            for (const template of templates) {
                const category = this.getCategoryById(
                    template.category,
                    this.categories
                );

                if (category == null) {
                    continue;
                }

                let currentIndex = -1;
                for (let index in category.templates) {
                    if (category.templates[index].id == template.id) {
                        currentIndex = index;
                    }
                }

                if (currentIndex < 0) {
                    this.$emit('new-cost-template', JSON.stringify(template));
                }

                template['currency'] = '₽';

                if (currentIndex < 0) {
                    category.templates.push(template);
                } else {
                    category.templates.splice(currentIndex, 1, template);
                }
            }
        },

        fillElements(elements) {
            for (const element of elements.list) {
                const category = this.getCategoryById(
                    element.category,
                    this.categories
                );

                if (category == null) {
                    continue;
                }

                let currentIndex = -1;
                for (let index in category.elements) {
                    if (category.elements[index].id == element.id) {
                        currentIndex = index;
                    }
                }

                if (currentIndex < 0) {
                    this.$emit('new-cost-element', JSON.stringify(element));
                }

                element['updatAt'] = this.updateCostsList;
                element['currency'] = '₽';
                element['links'] = [];

                for (const link of elements.links) {
                    if (element.id != link.element) {
                        continue;
                    }
                    element.links.push(link);
                }

                if (currentIndex < 0) {
                    category.elements.push(element);
                } else {
                    category.elements.splice(currentIndex, 1, element);
                }
            }
        },

        removeExpireElements(categories) {
            for (const category of categories) {
                this.removeExpireElements(category.children);

                let elementForRemove = [];
                for (let index in category.elements) {
                    if (category.elements[index].updatAt != this.updateCostsList) {
                        elementForRemove.push(index);
                    }
                }
                for (let index of elementForRemove) {
                    category.elements.splice(index, 1);
                }
            }
        },

        getCategoryById(id, categories) {
            for (const category of categories) {
                if (category.id == id) {
                    return category;
                }
                if (!category.children.length) {
                    continue;
                }
                const cat = this.getCategoryById(id, category.children);
                if (cat != null) {
                    return cat;
                }
            }
            return null;
        },

        calcAmountOfCategories() {
            for (const category of this.categories) {
                this.getAmountOfCategory(category);
            }

            this.total = 0;
            for (const category of this.categories) {
                this.total += category.amount;
            }
        },

        getAmountOfCategory(category) {
            let amount = 0;
            for (const child of category.children) {
                amount += Number(this.getAmountOfCategory(child));
            }
            for (const template of category.templates) {
                amount += Number(template.remainsAmount);
            }
            for (const element of category.elements) {
                amount += Number(element.amount);
            }
            category.amount = amount;
            return amount;
        },

        onDropToCosts(evt, template, subcategory, amount) {
            const fromId = Number(evt.dataTransfer.getData('id'));
            const fromTitle = evt.dataTransfer.getData('title');

            const fromCategory = evt.dataTransfer.getData('category');
            const fromSubcategory = evt.dataTransfer.getData('subcategory');

            const currency = evt.dataTransfer.getData('currency');

            const from = {
                id: fromId,
                title: fromTitle,
                category: fromCategory,
                subcategory: fromSubcategory,
            };

            const to = {
                id: template.id,
                title: template.name,
                category: 'costs',
                subcategory: subcategory,
            };

            this.$emit('transaction:dialog:create', from, to, currency, amount, '');
        },

        checkCost({ id, name, title, category }) {
            const search = this.search.toLowerCase();

            const costName = `#${id} ${name || title}`;
            const searchByName = costName
                .toLowerCase()
                .includes(search);

            const parent = this.costCategories
                .find(({ id }) => id == category);
            const parentName = parent ? (parent.name || parent.title) : '';
            const searchByParent = parentName
                .toLowerCase()
                .includes(search);

            return searchByName || searchByParent;
        },

        toggleCategories(key) {
            if (!this.$refs.recursiveLists) {
                return;
            }

            requestAnimationFrame(() => {
                this.$refs.recursiveLists.forEach((ref) => ref.toggleCategory(key));
            });
        },

        onCategoryToggle({ id, value }) {
            const category = this.costCategories.find((c) => c.id === id);

            if (category && !category.treeParent) {
                this.$set(this.opened, id, value);
            }
        },
    },
};
</script>
