<template>
    <v-form @submit.prevent="send" ref="form">
        <v-card width="640" :loading="loading" :disabled="loading">
            <v-card-title>{{ $t("register.title") }}</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            :label="$t('register.login')"
                            :rules="req"
                            v-model="username"
                        />
                    </v-col>
                    <v-col cols="6"></v-col>
                    <v-col cols="6">
                        <v-text-field
                            :label="$t('register.password')"
                            type="password"
                            :rules="req"
                            v-model="password"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            :label="$t('register.password2')"
                            type="password"
                            :rules="req"
                            v-model="repeat"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-select
                            :items="modes"
                            :label="$t('register.recovery')"
                            :rules="reqSelect"
                            v-model="mode"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            key="mail"
                            v-if="mode == 1"
                            label="Email"
                            placeholder="my@mail.com"
                            type="email"
                            :rules="reqMail"
                            v-model="email"
                        />
                        <v-text-field
                            key="phone"
                            v-if="mode == 2"
                            :label="$t('register.phone')"
                            placeholder="0000000000"
                            type="tel"
                            :rules="reqPhone"
                            v-model="phone"
                        >
                            <template #prepend>+7</template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" type="submit" text>
                    {{ $t("main.proceed") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
export default {
    name: "LoginRegister",
    data() {
        return {
            username: "",
            password: "",
            repeat: "",
            email: "",
            phone: "",
            mode: 0,
            req: [(v) => v.length > 0 || this.$t("form.required")],
            reqSelect: [(v) => v > 0 || this.$t("form.required")],
            reqMail: [
                (v) => v.length > 0 || this.$t("form.required"),
                (v) => /^.+@.+\..+$/.test(v) || this.$t("form.email"),
            ],
            reqPhone: [
                (v) => v.length > 0 || this.$t("form.required"),
                (v) => /^\d{10}$/.test(v) || this.$t("form.phone"),
            ],
            loading: false,
        };
    },
    computed: {
        modes() {
            return [
                {
                    text: "Email",
                    value: 1,
                },
                {
                    text: this.$t("register.phone"),
                    value: 2,
                },
            ];
        },
    },
    methods: {
        async send() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.loading = true;
            try {
                await this.$api("/auth/register", {
                    method: "POST",
                    body: {
                        username: this.username,
                        new: this.password,
                        repeat: this.repeat,
                        recovery: this.mode == 1 ? this.email : this.phone,
                    },
                });
                this.$emit("done");
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
    },
};
</script>