<template>
    <v-card
        :height="adaptive ? '100%' : ''"
        :max-height="adaptive ? '100%' : ''"
        :flat="!adaptive"
        class="d-flex flex-column"
    >
        <div
            ref="scrollable"
            class="h-full flex-grow-1 overflow-x-hidden"
            :class="{
                'overflow-y-scroll': adaptive,
            }"
        >
            <drag-scroll-area
                up
                :visibility="dragScrollAreaVisibility"
                @scroll="scroll"
                v-on="$listeners"
            />

            <card-header
                :title="title"
                :balance="balance"
                :currency="currency"
            >
                <template #header-action-left>
                    <slot name="header-action-left" />
                </template>

                <template #header-action-right>
                    <slot name="header-action-right" />
                </template>

                <template #subheader>
                    <slot name="subheader" />
                </template>
            </card-header>

            <slot name="body" />

            <drag-scroll-area
                down
                :visibility="dragScrollAreaVisibility"
                @scroll="scroll"
                v-on="$listeners"
            />
        </div>
    </v-card>
</template>

<script>
import CardHeader from "@c/norton-commander/layouts/desktop/CardHeader";
import DragScrollArea from "@c/norton-commander/DragScrollArea";

export default {
    name: "DesktopLayoutCard",

    components: {
        CardHeader,
        DragScrollArea,
    },

    props: {
        adaptive: {
            type: Boolean,
            default: true,
        },

        busy: {
            type: Boolean,
            default: false,
        },

        title: {
            type: String,
            default: '',
        },

        balance: {
            type: String,
            default: '0',
        },

        currency: {
            type: String,
            default: '₽',
        },

        dragScrollAreaVisibility: {
            type: Boolean,
            default: false,
        },

        stopScroll: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        scroll(step) {
            if (!this.$refs.scrollable) {
                return;
            }

            this.$refs.scrollable.scrollTo(0, this.$refs.scrollable.scrollTop + step);

            if (!this.stopScroll && this.dragScrollAreaVisibility) {
                setTimeout(() => { this.scroll(step); }, 20);
            }
        },

        scrollTop() {
            if (this.$refs.scrollable) {
                this.$refs.scrollable.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        },
    },
};
</script>
