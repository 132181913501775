<template>
    <adaptive-dialog
        data-test-id="process-transaction-dialog"
        :data-test-state="dialog"

        :dialog="dialog"
        :busy="busy"
        dense
        max-width="1000"

        @close="close"
    >
        <template #title>
            <span v-if="type === 'process'">Подтверждение транзакции</span>
            <span v-if="type === 'cancel'">Отмена транзакции</span>
            <span v-if="type === 'info'">Информация о транзакции</span>
        </template>

        <template #body>
            <div
                v-if="type !== 'info'"
                :class="{
                    'px-4 pt-5': $isMobile()
                }"
            >
                <span
                    class="text--primary"
                    :class="{
                        'subtitle-1': !$isMobile(),
                        'body-2': $isMobile(),
                    }"
                >
                    <span>Подтверждаете </span>
                    <span class="text-decoration-underline">
                        <span v-if="type === 'process'">обработку</span>
                        <span v-if="type === 'cancel'">отмену</span>
                    </span>
                    <span> транзакции </span>
                    <span class="font-weight-bold">№{{ transaction.id }}</span>?
                </span>
            </div>

            <div
                class="d-flex flex-column justify-center pb-1"
                :class="{
                    'pt-3': type !== 'info',
                }"
            >
                <v-sheet :elevation="$isMobile() ? 0 : 2">
                    <transaction-info
                        :transaction="transaction"

                        :cashbox-categories="cashboxCategories"
                        :cashboxes="cashboxes"

                        :cost-categories="costCategories"
                        :cost-templates="costTemplates"
                        :cost-elements="costElements"

                        :statuses="statuses"
                        :users="users"
                    />
                </v-sheet>
            </div>

            <v-container
                v-if="type === 'info'"
                fluid
                :class="{
                    'px-0': !$isMobile(),
                }"
            >
                <v-row dense>
                    <v-col>
                        <v-btn
                            block
                            :small="!$isMobile()"
                            :ripple="false"
                            :disabled="cancelMode"
                            @click="toggleCommentInput"
                        >
                            <v-icon
                                dense
                                color="success"
                                class="plus-icon mr-1"
                                :class="{ 'plus-icon--active': showCommentInput }"
                            >
                                mdi-plus
                            </v-icon>

                            <span v-if="$isMobile()">Комментарий</span>

                            <span v-else>
                                <span v-if="!showCommentInput">Добавить комментарий</span>
                                <span v-else>Отменить добавление комментария</span>
                            </span>
                        </v-btn>
                    </v-col>

                    <v-col>
                        <v-tooltip
                            top
                            open-delay="150"
                            :disabled="enableCancelButton"
                        >
                            <template #activator="{ on }">
                                <div v-on="on">
                                    <v-btn
                                        data-test-id="process-transaction-dialog-button-cancel"

                                        block
                                        :small="!$isMobile()"
                                        :ripple="false"
                                        :disabled="!enableCancelButton"
                                        @click="cancelMode = !cancelMode"
                                    >
                                        <v-icon
                                            dense
                                            color="error"
                                            class="mr-1"
                                        >
                                            mdi-close
                                        </v-icon>

                                        <span v-if="$isMobile()">Отменить</span>

                                        <span v-else>
                                            <span v-if="!cancelMode">Отменить транзакцию</span>
                                            <span v-else>Отменить отмену транзакции</span>
                                        </span>
                                    </v-btn>
                                </div>
                            </template>

                            <span>Эту транзакцию отменить нельзя</span>
                        </v-tooltip>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col>
                        <v-tooltip
                            top
                            open-delay="150"
                            :disabled="disableEditButton"
                        >
                            <template #activator="{ on }">
                                <div v-on="on">
                                    <v-btn
                                        block
                                        :small="!$isMobile()"
                                        :disabled="disableEditButton"
                                        @click="
                                            $emit('transaction:dialog:edit', transaction)
                                            close()
                                        "
                                    >
                                        <v-icon
                                            dense
                                            class="mr-1 text--secondary"
                                        >
                                            mdi-receipt-text-edit-outline
                                        </v-icon>

                                        <span v-if="$isMobile()">Редактировать</span>

                                        <span v-else>Редактировать транзакцию</span>
                                    </v-btn>
                                </div>
                            </template>

                            <span>Редактировать можно только черновики транзакций</span>
                        </v-tooltip>
                    </v-col>

                    <v-col>
                        <v-tooltip
                            top
                            open-delay="150"
                            :disabled="enableRepeatButton"
                        >
                            <template #activator="{ on }">
                                <div v-on="on">
                                    <v-btn
                                        data-test-id="process-transaction-dialog-button-repeat"

                                        block
                                        :small="!$isMobile()"
                                        :disabled="!enableRepeatButton"
                                        @click="openCreateTransactionDialog"
                                    >
                                        <v-icon
                                            dense
                                            color="warning"
                                            class="mr-1"
                                        >
                                            mdi-rotate-left
                                        </v-icon>

                                        <span v-if="$isMobile()">Повторить</span>

                                        <span v-else>Повторить транзакцию</span>
                                    </v-btn>
                                </div>
                            </template>

                            <span>Эту транзакцию повторить нельзя</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-container>

            <div
                v-if="(type !== 'info') || showCommentInput || cancelMode"
                :class="{
                    'px-4': $isMobile(),
                }"
            >
                <v-form
                    ref="form"
                    v-model="formValidation"
                    lazy-validation
                >
                    <v-textarea
                        ref="textarea"

                        v-model="comment"
                        :rules="rules"

                        dense
                        counter
                        outlined
                        auto-grow
                        no-resize
                        hide-details="auto"
                        class="pt-3"

                        :append-icon="comment ? 'mdi-close' : ''"
                        :placeholder="`Комментарий${(type !== 'info') || cancelMode ? ' (опционально)' : ''}`"

                        @click:append="comment = ''"
                        @focus="$refs.form.resetValidation()"
                    />
                </v-form>
            </div>
        </template>

        <template #submit>
            <v-btn
                data-test-id="button-submit"

                v-if="type === 'process'"
                block
                color="primary"
                :disabled="!formValidation"

                @click="processTransaction"
            >
                Подтверждаю обработку
            </v-btn>

            <v-btn
                data-test-id="button-submit"

                v-else-if="type === 'cancel'"
                block
                color="error"
                :disabled="!formValidation"

                @click="cancelTransaction"
            >
                Подтверждаю отмену
            </v-btn>

            <v-btn
                data-test-id="button-submit"

                v-else-if="!cancelMode && showCommentInput"
                block
                color="success"
                :disabled="!formValidation"

                @click="addComment"
            >
                Добавить комментарий
            </v-btn>

            <v-btn
                data-test-id="button-submit"

                v-else-if="cancelMode"
                block
                color="error"
                :disabled="!formValidation"

                @click="cancelTransaction"
            >
                Подтверждаю отмену
            </v-btn>
        </template>
    </adaptive-dialog>
</template>

<script>
import AdaptiveDialog from "@c/norton-commander/AdaptiveDialog";
import TransactionInfo from "@c/norton-commander/dialogs/transaction/process/TransactionInfo";
import getCurrentPermissions from "@u/api/current-permissions";

export default {
    name: "TransactionProcess",

    inject: ['$isMobile'],

    components: {
        AdaptiveDialog,
        TransactionInfo,
    },

    props: {
        cashboxCategories: {
            type: Array,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        costCategories: {
            type: Array,
            required: true,
        },
        costTemplates: {
            type: Array,
            required: true,
        },
        costElements: {
            type: Array,
            required: true,
        },
        statuses: {
            type: Object,
            required: true,
        },
        users: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        busy: false,
        dialog: false,
        cancelMode: false,
        formValidation: true,
        showCommentInput: false,

        type: '',
        comment: '',

        transaction: {},
        permissions: [],
    }),

    computed: {
        enableCancelButton() {
            switch (this.transaction.status) {
                case 'draft':
                case 'completed':
                    return true;
                default:
                    return false;
            }
        },

        disableEditButton() {
            if (this.transaction.status === 'draft') {
                return false;
            } else {
                return (this.permissions.indexOf('transaction.modify') == -1);
            }
        },

        enableRepeatButton() {
            if (this.transaction.operations.length === 1 && this.transaction.links.length === 0) {
                return false;
            }

            return true;
        },

        rules() {
            const rules = [v => v.length < 255 || 'Превышена максимальная длина комментария'];
            if (this.type === 'info' && !this.cancelMode) {
                rules.push(v => v.length > 0 || 'Введите комментарий');
            }
            return rules;
        },
    },

    methods: {
        async open({ transaction, type }) {
            this.type = type;
            this.comment = '';
            this.transaction = transaction;

            this.busy = false;
            this.dialog = true;

            this.permissions = await getCurrentPermissions(this.$api);
        },

        close() {
            this.busy = false;
            this.dialog = false;

            setTimeout(() => {
                this.type = '';
                this.comment = '';
                this.transaction = {};
                this.cancelMode = false;
                this.formValidation = true;
                this.showCommentInput = false;
            }, 200);
        },

        toggleCommentInput() {
            this.showCommentInput = !this.showCommentInput;

            if (this.showCommentInput) {
                this.$nextTick(() => {
                    if (this.$refs.textarea) {
                        this.$refs.textarea.focus();
                    }
                });
            }
        },

        processTransaction() {
            this.$emit(
                'transaction:process',
                {
                    id: this.transaction.id,
                    status: 'queue',
                    comment: this.comment,
                }
            );

            this.close();
        },

        cancelTransaction() {
            const desiredStatus = this.transaction.status === 'draft' ? 'canceled' : 'cancel';
            this.$emit(
                'transaction:process',
                {
                    id: this.transaction.id,
                    status: desiredStatus,
                    comment: this.comment,
                }
            );

            this.close();
        },

        addComment() {
            if (this.showCommentInput) {
                this.$refs.form.validate();
                if (!this.formValidation) {
                    return;
                }
            } else {
                this.comment = '';
            }

            this.$emit(
                'transaction:process',
                {
                    id: this.transaction.id,
                    status: this.transaction.status,
                    comment: this.comment,
                }
            );

            this.close();
        },

        openCreateTransactionDialog() {
            const assembleObjectFromOperationIndex = (idx) => {
                const operation = this.transaction.operations[idx];
                const id = operation.cashbox;
                const currency = operation.currency;

                const cashbox = this.cashboxes.find((c) => c.id === id);
                if (typeof cashbox === 'undefined') {
                    return {
                        id,
                        title: '',
                        category: 'cashbox',
                        subcategory: '',

                        currency,
                        amount: 0,
                    };
                }

                const title = cashbox.title;
                const subcategory = cashbox.category;

                return {
                    id,
                    title,
                    category: 'cashbox',
                    subcategory,

                    currency,
                    amount: operation.amount,
                };
            };

            const comments = this.transaction.comments;
            const comment = comments && comments.length ? this.transaction.comments[0].comment : '';

            if (this.transaction.operations.length > 1) {
                const from = assembleObjectFromOperationIndex(0);
                const to = assembleObjectFromOperationIndex(1);

                this.$emit('transaction:dialog:create', from, to, to.currency, Math.abs(to.amount), comment);
            } else if (this.transaction.operations.length > 0 && this.transaction.links.length > 0) {
                const operation = assembleObjectFromOperationIndex(0);

                // @todo переделать, когда сервер начнёт нормально присылать связи
                const { name, type } = this.transaction.links[0];
                const category = type;

                const arr = name.split(' ');
                const id = Number(arr[1].substring(1));

                let title = '';
                let subcategory = '';

                if (category === 'costs') {
                    title = arr[2];
                    if (title.includes('Элемент')) {
                        subcategory = 'element';
                    } else {
                        subcategory = 'template';
                    }
                } else {
                    title = arr[0];
                }

                const link = {
                    id,
                    title,
                    category,
                    subcategory,
                };

                if (operation.amount > 0) {
                    this.$emit('transaction:dialog:create', link, operation, operation.currency, Math.abs(operation.amount), comment);
                } else {
                    this.$emit('transaction:dialog:create', operation, link, operation.currency, Math.abs(operation.amount), comment);
                }
            }

            this.close();
        },
    },
};
</script>

<style lang="scss" scoped>
.plus-icon {
    transition: transform 1s;

    &--active {
        transform: rotate(135deg);
    }
}
</style>
