<template>
    <v-list-group
        v-if="node.isCategory"
        eager
        sub-group
        :class="{ 'pl-4': isChild }"
    >
        <template v-slot:activator>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ node.title }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>

        <cost-list
            v-for="child in node.children"
            :key="child.id"

            :node="child"
            :selected-cost-id="selectedCostId"
            :isChild="true"

            v-on="$listeners"
        />
    </v-list-group>

    <v-list-item
        v-else
        :input-value="selectedCostId === node.id"
        @click="selectItem"
    >
        <v-list-item-content>
            <v-list-item-title>
                #{{ node.id }} {{ node.name }}
            </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: "CostList",

    props: {
        node: {
            type: Object,
            required: true,
        },

        selectedCostId: {
            type: Number,
            required: true,
        },

        isChild: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        selectItem() {
            this.$emit('select-cost', this.node.id);
            this.$emit('close-menu');
        },
    },
};
</script>
