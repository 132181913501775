<template>
    <div>
        <div class="d-flex justify-space-between">
            <filter-info-renderless
                #default="{ info }"

                :filter="filter"

                :cashbox-categories="cashboxCategories"
                :cashboxes="cashboxes"

                :cost-categories="costCategories"
                :cost-templates="costTemplates"

                :users="users"
                :statuses="statuses"
                :dateHelpers="dateHelpers"
                :groupByList="groupByList"
            >
                <v-list
                    data-test-id="filter-stats"
                    :data-test-filter="JSON.stringify(filter)"

                    dense
                    class="px-4"
                >
                    <v-list-item
                        v-for="{ alias, label, value, icon } in info"
                        :key="alias"
                        dense
                        class="px-0"
                        style="min-height: 30px;"
                    >
                        <v-list-item-content
                            v-if="alias === 'statuses'"
                            class="py-1"
                        >
                            <v-list-item-title class="font-weight-regular text-wrap">
                                <v-icon
                                    v-text="icon"
                                    small
                                    class="mr-2 my-n1"
                                />
                                <span v-text="`${label}: `" />
                                <span
                                    v-for="({ text, textClass }, index) in value"
                                    :key="index"
                                    v-text="`${text}${index !== value.length - 1 ? ', ' : ''}`"
                                    :class="textClass"
                                />
                            </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-content
                            v-else-if="
                                [
                                    'cashboxCategories',
                                    'cashboxes',
                                    'costCategories',
                                    'costTemplates',
                                ].includes(alias)
                            "
                            class="py-1"
                        >
                            <v-list-item-title>
                                <v-icon
                                    v-text="icon"
                                    small
                                    class="mr-2 my-n1"
                                />
                                <span
                                    v-text="`${label}:`"
                                    class="font-weight-regular"
                                />
                            </v-list-item-title>
                            <v-list-item-subtitle
                                v-for="(row, index) in value"
                                :key="index"
                                v-text="row"
                                class="font-weight-regular text-wrap"
                            />
                        </v-list-item-content>

                        <v-list-item-content
                            v-else
                            class="py-1"
                        >
                            <v-list-item-title class="font-weight-regular text-wrap">
                                <v-icon
                                    v-text="icon"
                                    small
                                    class="mr-2 my-n1"
                                />
                                <span v-text="`${label}: `" />
                                <span
                                    v-text="value"
                                    class="text--secondary"
                                />
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </filter-info-renderless>

            <div
                class="d-flex"
                :class="{
                    'align-center pr-4': $isMobile(),
                    'pr-2 pt-2': !$isMobile()
                }"
            >
                <v-btn
                    icon
                    @click="$emit('report:menu:group-by', $event)"
                >
                    <v-icon v-text="'mdi-format-list-group'" />
                </v-btn>

                <filter-dialog-save v-on="$listeners" />
            </div>
        </div>

        <v-divider v-if="filterModifier.type" />

        <v-expand-transition>
            <filter-modifier
                v-if="filterModifier.type"

                :filter-modifier="filterModifier"
                :cashboxes="cashboxes"
                :cost-templates="costTemplates"

                v-on="$listeners"
            />
        </v-expand-transition>
    </div>
</template>

<script>
import FilterInfoRenderless from "@c/norton-commander/FilterInfoRenderless";
import FilterModifier from "@c/norton-commander/transactions/FilterModifier";
import FilterDialogSave from "@c/norton-commander/dialogs/filter/FilterSave";

export default {
    name: "FilterInfo",

    inject: ['$isMobile'],

    components: {
        FilterInfoRenderless,
        FilterModifier,
        FilterDialogSave,
    },

    props: {
        filter: {
            type: Object,
            required: true,
        },
        filterModifier: {
            type: Object,
            required: true,
        },
        cashboxCategories: {
            type: Array,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        costCategories: {
            type: Array,
            required: true,
        },
        costTemplates: {
            type: Array,
            required: true,
        },
        users: {
            type: Object,
            required: true,
        },
        statuses: {
            type: Object,
            required: true,
        },
        dateHelpers: {
            type: Array,
            required: true,
        },
        groupByList: {
            type: Array,
            required: true,
        },
    },
};
</script>
