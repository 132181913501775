<template>
    <v-app :class="{
        'max-h-screen': fillHeight,
    }">
        <app-drawer v-model="drawer" />

        <app-bar v-model="drawer" />

        <v-main :class="{
            'h-full': fillHeight,
        }">
            <v-container
                fluid
                :fill-height="fillHeight"
                :class="{
                    'h-full pa-0': fillHeight,
                }"
            >
                <router-view />
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import AppBar from "@c/AppBar";
import AppDrawer from "@c/AppDrawer";

export default {
    name: "App",

    provide() {
        return {
            $isMobile: () => this.isMobile,
        };
    },

    data: () => ({
        drawer: false,
    }),

    components: {
        AppBar,
        AppDrawer,
    },

    computed: {
        isMobile() {
            if (this.$vuetify.breakpoint.mobile) {
                return true;
            }

            if (this.$vuetify.breakpoint.height < 500) {
                return true;
            }

            return false;
        },

        fillHeight() {
            const pages = [
                'Login',
                'NortonCommander',
                'AccessControl',
                'EventPlanner',
            ];

            return pages.includes(this.$route.name);
        },
    },
};
</script>
