<template>
    <v-container
        v-if="layout === 'horizontal'"
        fluid
        class="py-2"
    >
        <v-row no-gutters>
            <v-col>
                <slot name="info" />
            </v-col>
        </v-row>

        <slot name="buttons" />
    </v-container>

    <div
        v-else
        class="d-flex"
    >
        <div class="word-break flex-grow-1 py-1 px-2">
            <slot name="info" />
        </div>

        <v-divider vertical />

        <div
            class="d-flex align-center justify-center"
            style="min-width: 150px;"
        >
            <slot name="buttons" />
        </div>
    </div>
</template>

<script>
export default {
    name: "AdaptiveItem",

    props: {
        layout: {
            type: String,
            required: true,
        },
    },
};
</script>
