<template>
    <adaptive-dialog
        data-test-id="create-transaction-dialog"
        :data-test-state="dialog"
        :data-test-from="JSON.stringify(from)"
        :data-test-to="JSON.stringify(to)"

        :dialog="dialog"
        max-width="500"
        :buttonSubmit="buttonSubmit"
        :disableSubmit="!formValidation"

        @close="close"
        @submit="submit"
    >
        <template #title>
            Создание черновика транзакции
        </template>

        <template #body>
            <div class="pb-5 body-1 text--primary">
                <span v-if="to.category == 'cashbox'">Перевод между счетами ({{ currency }})</span>
                <span v-else>Оплата расхода со счёта ({{ currency }})</span>
            </div>

            <v-text-field
                :value="`С #${from.id} ${from.title}`"
                solo
                readonly
            />

            <v-text-field
                :value="`На #${to.id} ${to.title}`"
                solo
                readonly
            />

            <v-divider />

            <v-form
                ref="form"
                v-model="formValidation"
                lazy-validation
                class="pt-5"
            >
                <v-text-field
                    data-test-id="create-transaction-dialog-input-summ"

                    v-model="summ"
                    ref="summ"
                    type="number"
                    label="Сумма"
                    clearable
                    :rules="rules.summ"

                    @keydown.enter.prevent
                />

                <v-textarea
                    v-model="comment"
                    :rules="rules.comment"

                    dense
                    counter
                    outlined
                    auto-grow
                    no-resize

                    class="pt-5"
                    hide-details="auto"
                    placeholder="Комментарий (опционально)"

                    :append-icon="comment ? 'mdi-close' : ''"

                    @click:append="comment = ''"
                />
            </v-form>

            <event-dialog-create
                ref="eventDialogCreate"
                @input="event = $event"
            />

            <event-dialog-activator
                :event="event"
                @toggle="openDialog"
                @edit="$refs.eventDialogCreate.open(event)"
            />
        </template>
    </adaptive-dialog>
</template>

<script>
import AdaptiveDialog from "@c/norton-commander/AdaptiveDialog";
import EventDialogCreate from "@c/event-planner/dialogs/EventCreate";
import EventDialogActivator from "@c/norton-commander/events/DialogActivator";

export default {
    name: 'TransactionCreate',

    components: {
        AdaptiveDialog,
        EventDialogCreate,
        EventDialogActivator,
    },

    data: () => ({
        dialog: false,
        formValidation: true,

        summ: '',
        comment: '',
        currency: '',

        from: {},
        to: {},
        event: null,

        rules: {
            summ: [v => !!v || 'Поле не может быть пустым'],
            comment: [v => v.length < 255 || 'Превышена максимальная длина комментария'],
        },
    }),

    computed: {
        buttonSubmit() {
            const text = this.event ? 'Запланировать' : 'Создать';
            const color = this.event ? 'success' : 'primary';

            return {
                text,
                color,
            };
        },
    },

    methods: {
        open(from, to, currency, initialAmount, initialComment) {
            this.$refs.form.resetValidation();
            setTimeout(() => {
                this.$refs.summ.focus();
            }, 100);

            this.summ = initialAmount !== 0 ? initialAmount.toString() : '';
            this.comment = initialComment;
            this.currency = currency;
            this.from = from;
            this.to = to;

            this.dialog = true;
        },

        close() {
            this.dialog = false;

            setTimeout(() => {
                this.summ = '';
                this.comment = '';
                this.from = {};
                this.to = {};
                this.event = null;
            }, 200);
        },

        submit() {
            this.$refs.form.validate();
            if (!this.formValidation) {
                return;
            }

            if (this.event) {
                const operations = [];
                const links = [];

                [this.from, this.to]
                    .forEach((item) => {
                        if (item.category === 'cashbox') {
                            operations.push({
                                cashbox: item.id,
                                amount: Number(this.summ),
                            });
                        } else {
                            links.push({
                                id: item.id,
                                type:
                                    item.subcategory
                                        ? [item.category, item.subcategory].join('.')
                                        : item.category,
                            });
                        }
                    });

                const transaction = {
                    operations,
                    links,
                };

                if (this.comment) {
                    transaction.comment = this.comment;
                }

                const event = {
                    ...this.event,
                    transactions: [transaction],
                };

                this.$emit('event:create', event);
            } else {
                this.$emit(
                    'transaction:create',
                    this.from,
                    this.to,
                    this.summ,
                    this.comment,
                );
            }

            this.close();
        },

        openDialog($event) {
            if (this.event) {
                this.event = null;

                return;
            }

            this.$refs.eventDialogCreate.open($event);
        },
    },
};
</script>
