<template>
    <v-menu
        v-model="menu"
        offset-y
        max-height="50vh"

        :disabled="disabled"
        :close-on-content-click="false"
    >
        <template #activator="{ on, attrs }">
            <v-text-field
                v-on="on"
                v-bind="attrs"
                :value="value"
                :rules="rules"
                :disabled="disabled"

                readonly
                outlined
                dense

                :label="label"
                prepend-icon="mdi-cash-multiple"
                :append-icon="costSelected ? 'mdi-close' : ''"

                @click:append="$emit('clear')"
            />
        </template>

        <v-list
            :dense="$isMobile()"
            class="pa-0"
        >
            <cost-list
                v-for="node in tree"
                :key="node.id"

                :node="node"
                :selected-cost-id="selectedCostId"

                @close-menu="menu = false"

                v-on="$listeners"
            />
        </v-list>
    </v-menu>
</template>

<script>
import CostList from "@c/norton-commander/dialogs/cashbox/mass-process/CostList";

export default {
    name: "CostMenu",

    inject: ['$isMobile'],

    components: {
        CostList,
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },

        tree: {
            type: Array,
            required: true,
        },

        selectedCostId: {
            type: Number,
            required: true,
        },

        selectedCost: {
            type: Object,
            required: true,
        },

        isRequired: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        menu: false,
    }),

    computed: {
        costSelected() {
            return Object.keys(this.selectedCost).length ? true : false;
        },

        value() {
            return this.costSelected
                ? `#${this.selectedCost.id} ${this.selectedCost.name}`
                : '';
        },

        label() {
            return `Выбранный расход${this.isRequired ? ' (обязательно)' : ''}`;
        },

        rules() {
            return this.isRequired
                ? [v => v.length > 0 || 'Обязательно к заполнению']
                : [];
        }
    },
};
</script>
