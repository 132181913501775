<template>
    <v-menu
        v-model="menu"

        right
        offset-x
        max-width="300px"

        :activator="activator"
        :close-on-content-click="false"

        @input="close"
    >
        <v-date-picker
            v-model="dateString"
            :allowed-dates="allowedDates"
            scrollable
            :first-day-of-week="1"
            class="w-full"
        >
            <v-btn
                text
                color="error"
                @click="close"
            >
                Закрыть
            </v-btn>

            <v-btn
                text
                color="primary"
                @click="submit"
            >
                Применить
            </v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>
export default {
    name: 'DatePicker',

    data: () => ({
        menu: false,

        activator: undefined,
        date: new Date(),
        allowedDates: () => true,

        dateString: new Date()
            .toISOString()
            .slice(0, 10),

        res: null,
    }),

    methods: {
        open({ activator, date, allowedDates }) {
            this.activator = activator;
            this.date = date;
            this.allowedDates = allowedDates;

            const timezoneOffset = new Date().getTimezoneOffset() * 60000;
            this.dateString = new Date(this.date - timezoneOffset)
                .toISOString()
                .slice(0, 10);

            this.menu = true;

            return new Promise((r) => (this.res = r));
        },

        close() {
            this.activator = undefined;
            this.allowedDates = () => true;
            this.menu = false;
            this.res(null);
        },

        submit() {
            this.res(new Date(this.dateString));
            this.close();
        },
    },
};
</script>
