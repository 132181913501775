<template>
    <v-data-table
        :headers="headers"
        :items="[item]"
        :loading="disabled"
        disable-sort
        hide-default-footer
    >
        <template #top>
            <v-list>
                <v-list-item @click="onChange(!create, 'create')">
                    <v-simple-checkbox
                        ref="create"
                        :value="create"
                        :disabled="user.isDummy || disabled"
                        :ripple="false"
                        color="primary"
                        @input="onChange($event, 'create')"
                    />
                    <span>Может добавлять новые записи в справочник</span>
                </v-list-item>
            </v-list>

            <v-divider />
        </template>

        <template
            v-for="key in keys"
            #[`header.${key}`]="{ header: { text, icon } }"
        >
            <span :key="key">
                <v-icon small>{{ icon }}</v-icon>
                <span class="ml-2">{{ text }}</span>
            </span>
        </template>

        <template
            v-for="key in keys"
            #[`item.${key}`]="{ item }"
        >
            <v-simple-checkbox
                :key="key"
                :value="item[key]"
                :disabled="user.isDummy || disabled"
                :ripple="false"
                color="primary"
                @input="onChange($event, key)"
            />
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "AccessNew",

    props: {
        user: {
            type: Object,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
        keys: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        disabled: false,
    }),

    computed: {
        item() {
            const result = { title: "Доступ к новым записям справочника" };

            this.keys.forEach((key) => {
                result[key] = Boolean(this.user.new[key]);
            });

            return result;
        },

        create() {
            return Boolean(this.user.new.create);
        },
    },

    methods: {
        onChange($event, key) {
            if (this.user.isDummy || this.disabled) {
                return;
            }

            const body = {};

            body.create = this.create;

            this.keys.forEach((k) => {
                body[k] = this.item[k];
            });

            body[key] = $event;

            this.disabled = true;

            this.$emit('update:new', {
                body,
                callback: () => { this.disabled = false; },
            });
        },
    },
};
</script>
