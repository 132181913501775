<template>
    <text-highlight
        :queries="queries"
        :highlightStyle="{
            backgroundColor: color,
        }"
    >
        <slot>
            {{ text }}
        </slot>
    </text-highlight>
</template>

<script>
import TextHighlight from 'vue-text-highlight';

export default {
    name: "TextHighlightWrapper",

    components: {
        TextHighlight,
    },

    props: {
        queries: {
            type: [Array, String, RegExp],
            required: true,
        },
        text: {
            type: String,
            default: '',
        },
    },

    computed: {
        color() {
            return '#BBDEFB';
        },
    },
};
</script>

