import store from "@/store";
import events from '@u/events';
import { addTestFunction } from "@/utils/tests";

export default {
    install(Vue, { router, i18n, api }) {
        api.base = process.env.VUE_APP_API;
        api.locale = i18n.locale;

        store.registerModule('events', {
            state: {
                connected: false,
            },
            mutations: {
                connected: (state, connected) => state.connected = connected,
            },
            getters: {
                connected: state => state.connected,
            },
        });

        events.on('connect', () => store.commit('connected', true));
        events.on('disconnect', () => store.commit('connected', false));

        let userId = null;
        const getUserId = async () => {
            if (userId) {
                return userId;
            }
            const data = await api.fetch('/auth/events').then(r => r.json());
            return data.id;
        };

        const connect = async () => {
            if (!events.isConnected()) {
                const data = await api.fetch('/auth/events').then(r => r.json());
                events.setToken(data.token);
                events.connect();
            }
        };

        const subscribe = async (name) => {
            const userId = await getUserId();
            const sub = events.subscribe(`hand:${name}#${userId}`);
            return sub;
        };

        const login = token => {
            api.token = token;
            connect();
        };

        addTestFunction('login', login);

        const logout = () => {
            api.token = '';
            events.disconnect();
            router.push({ name: "Login" });
        };

        Vue.prototype.$apiLocale = (locale) => api.locale = locale;
        Vue.prototype.$apiUserName = () => api.username;

        Vue.prototype.$apiLogin = login;
        Vue.prototype.$apiLogout = logout;
        Vue.prototype.$apiSubscribe = subscribe;

        Vue.prototype.$apiResponse = async (url, options = {}) => {
            let r = await api.fetch(url, options);
            if (r.status != 451) {
                if (!r.ok) {
                    const { error = i18n.t('main.error') } = await r.json();
                    const e = (new Error(error));
                    e['httpStatus'] = r.status;
                    throw e;
                }
                return r;
            }

            // Токен протух
            api.token = '';

            r = await api.fetch('/auth/refresh', {
                credentials: 'include',
            });
            if (!r.ok) {
                logout();
                const { error = i18n.t('main.error') } = await r.json();
                throw new Error(error);
            }
            const { token } = await r.json();
            login(token);

            r = await api.fetch(url, options);
            if (!r.ok) {
                const { error = i18n.t('main.error') } = await r.json();
                const e = (new Error(error));
                e['httpStatus'] = r.status;
                throw e;
            }
            return r;
        };

        Vue.prototype.$api = async (url, options = {}) => Vue.prototype.$apiResponse(url, options).then(r => r.json());

        router.beforeEach(async (to, from, next) => {
            const logout = () => {
                api.token = '';
                next({ name: 'Login' });
            };
            if (to.name != 'Login') {
                if (api.token.length == 0) {
                    // Токена нет вовсе. Попробуем сходить в рефреш
                    const r = await api.fetch('/auth/refresh', {
                        credentials: 'include',
                    });
                    if (r.ok) {
                        // Рефреш удачен
                        const { token } = await r.json();
                        login(token);
                        return next();
                    }
                    return logout();
                }
                // Токен есть, надо бы его проверить
                const response = await api.fetch('/auth/getme');
                const { ok, status } = response;
                if (status === 200) {
                    let json = await response.json();
                    api.username = json.username;
                }
                if (!ok) {
                    // Что-то не так
                    api.token = '';
                    if (status == 451) {
                        // Тухлый токен, попробуем рефреш
                        const r = await api.fetch('/auth/refresh', {
                            credentials: 'include',
                        });
                        if (!r.ok) {
                            // Рефреш не удался
                            return logout();
                        }
                        const { token } = await r.json();
                        login(token);
                        return next();
                    }
                    return logout();
                }
                connect();
            }
            if (api.token.length > 0 && to.name == 'Login') {
                next({ name: 'Home' });
                return;
            }
            next();
        });
    }
};
