<template>
    <v-text-field
        :label="field.name"
        :rules="rules"
        :value="value"
        @input="$emit('input', $event)"
        clearable
        dense
    />
</template>

<script>
import FieldBase from "./FieldBase";
export default {
    name: "FieldCatname",
    extends: FieldBase,
};
</script>