<template>
    <v-menu
        v-model="menu"
        absolute
        offset-y
        :position-x="x"
        :position-y="y"
    >
        <v-card>
            <v-card-title class="body-1 font-weight-medium">Способ группировки итогов</v-card-title>

            <v-divider />

            <v-list>
                <v-list-item-group
                    v-model="model"
                    mandatory
                    color="primary"
                    @change="submit"
                >
                    <v-list-item
                        v-for="({ text, value }, index) in groupByList"
                        :key="index"
                        :value="value"
                    >
                        {{ text }}
                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <v-divider />

            <v-card-actions>
                <v-btn
                    text
                    color="error"
                    @click="dialog = false"
                >
                    Закрыть
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: "GroupBy",

    props: {
        filter: {
            type: Object,
            required: true,
        },

        groupByList: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        menu: false,
        x: 0,
        y: 0,
        model: undefined,
    }),

    methods: {
        show({ clientX, clientY }) {
            this.menu = false;

            this.$nextTick(() => {
                this.x = clientX;
                this.y = clientY;
                this.model = this.filter.group;
                this.menu = true;
            });
        },

        async submit() {
            const filter = {
                group: this.model,
            };

            this.$emit('filter:apply', filter);

            this.menu = false;
        }
    },
};
</script>
