<template>
    <v-menu
        ref="datePicker"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="currentDates"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-on="on"
                v-bind="attrs"
                :value="displayedDates"

                dense
                outlined
                readonly
                hide-details

                label="Дата обработки"
                prepend-icon="mdi-calendar-blank"
                :append-icon="displayedDates ? 'mdi-close' : ''"

                @click="$emit('reset-date-helpers')"
                @click:append="$emit('clear-date')"
            />
        </template>

        <v-date-picker
            v-model="currentDates"
            range
            no-title
            scrollable
            style="flex: wrap"
        >
            <v-btn
                text
                color="primary"
                @click="menu = false"
            >
                Закрыть
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.datePicker.save(currentDates)"
            >
                OK
            </v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>
export default {
    name: "DatePicker",

    props: {
        datesFromFilter: {
            type: Array,
            required: false,
            default: () => [],
        },
    },

    data: () => ({
        menu: false,
        currentDates: null,
    }),

    mounted() {
        this.currentDates = this.datesFromFilter;
    },

    computed: {
        displayedDates() {
            if (!this.currentDates) {
                return '';
            }

            return this.currentDates
                .map((d) => new Date(d).toLocaleDateString(undefined))
                .join(' - ');
        },
    },

    watch: {
        currentDates: function() {
            this.$emit('set-date', this.currentDates);
        },

        datesFromFilter: function() {
            if (this.datesFromFilter) {
                this.currentDates = this.datesFromFilter;
            } else {
                this.currentDates = null;
            }
        },
    },
};
</script>
