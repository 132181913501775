<template>
    <adaptive-dialog
        data-test-id="delete-saved-filter-dialog"
        :data-test-state="dialog"

        :dialog="dialog"
        max-width="500"
        :btnSubmit="{
            text: 'Удалить',
            color: 'error'
        }"

        @close="close"
        @submit="deleteSavedFilter"
    >
        <template #title>
            Подтвердите удаление фильтра
        </template>

        <template #body>
            <span class="body-1 text--primary">
                {{ name }}
            </span>
        </template>
    </adaptive-dialog>
</template>

<script>
import AdaptiveDialog from "@c/norton-commander/AdaptiveDialog";

export default {
    name: "DeleteConfirmation",

    components: {
        AdaptiveDialog,
    },

    data: () => ({
        dialog: false,
        name: '',
        id: null,
    }),

    methods: {
        open({ name, id }) {
            this.name = name;
            this.id = id;
            this.dialog = true;
        },

        close() {
            this.dialog = false;
            this.name = '';
            this.id = null;
        },

        deleteSavedFilter() {
            this.$emit('filter:saved:delete', this.id);
            this.close();
        },
    },
};
</script>
