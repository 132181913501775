<template>
    <v-btn-toggle
        v-model="tab"
        mandatory
        group
        class="flex-column"
        color="primary"
    >
        <template v-for="(group, key, index) in groups">
            <v-btn
                v-for="{ type, isActive, title, icon } in group"
                :key="type"
                class="ma-0"
                :class="{
                    'px-1': $isMobile(),
                    'primary--text': isActive,
                }"
            >
                <v-icon
                    v-if="!$isMobile()"
                    v-text="icon"
                    left
                />

                <span
                    v-text="title"
                    class="flex-grow-1 text-left pr-1 caption"
                    :class="{
                        'font-weight-bold': isActive,
                    }"
                />

                <v-icon
                    v-text="'mdi-circle'"
                    right
                    size="7"
                    :style="`opacity: ${isActive ? 1 : 0};`"
                />
            </v-btn>

            <v-divider
                v-if="index !== Object.keys(groups).length - 1"
                :key="`${key}-divider`"
                class="my-2"
            />
        </template>
    </v-btn-toggle>
</template>

<script>
export default {
    name: "ButtonsTabs",

    inject: ['$isMobile'],

    props: {
        fields: {
            type: Array,
            required: true,
        },
    },

    computed: {
        tab: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('input', value);
            },
        },

        groups() {
            const result = {
                'status': [],
                'cashboxesAndCosts': [],
                'processingDate': [],
                'users': [],
                'links': [],
                'operationAmount': [],
            };

            this.fields.forEach(({ type, isActive, title, icon }) => {
                const payload = {
                    type,
                    isActive,
                    title,
                    icon,
                };

                switch (type) {
                    case 'cashbox':
                    case 'costTemplate':
                        result.cashboxesAndCosts.push(payload);
                        break;
                    case 'userWhoCreated':
                    case 'userWhoProcessed':
                        result.users.push(payload);
                        break;
                    case 'siteTransactions':
                    case 'siteSplits':
                    case 'siteDeliveries':
                        result.links.push(payload);
                        break;
                    default:
                        result[type].push(payload);
                }
            });

            return result;
        },
    },
};
</script>
