<template>
    <v-radio-group
        v-model="model"
        dense
        mandatory
        hide-details
        class="mt-0"
    >
        <template #label>Повторять</template>

        <v-radio value="date">
            <template #label>
                <div class="w-full d-flex align-center">
                    <v-text-field
                        v-model="date"
                        type="number"
                        min="1"
                        :rules="validation.date"

                        dense
                        outlined
                        hide-details

                        class="mr-2"
                        style="max-width: 65px;"
                    />

                    <span v-text="'числа'" />
                </div>
            </template>
        </v-radio>

        <v-radio value="day">
            <template #label>
                <div class="w-full d-flex align-center">
                    <span v-text="'В'" />

                    <v-text-field
                        v-model="day"
                        type="number"
                        min="1"
                        :rules="validation.day"

                        dense
                        outlined
                        hide-details

                        class="ml-2"
                        style="max-width: 65px;"
                    />

                    <v-select
                        v-model="dayType"
                        :items="dayTypes"

                        dense
                        outlined
                        hide-details

                        class="mx-2"
                        style="max-width: 170px;"
                    />

                    <span v-text="'месяца'" />
                </div>
            </template>
        </v-radio>
    </v-radio-group>
</template>

<script>
export default {
    name: 'RuleMonth',

    props: {
        rule: {
            type: Object,
            required: true,
        },
        dialog: {
            type: Boolean,
            required: true,
        },
    },

    data: () => ({
        date: 1,
        day: 1,

        model: 'date',
        dayType: 'date',

        dayTypes: [
            {
                value: 'date',
                text: 'день',
            },
            {
                value: 'working-day',
                text: 'рабочий день',
            },
            {
                value: 'monday',
                text: 'понедельник',
            },
            {
                value: 'tuesday',
                text: 'вторник',
            },
            {
                value: 'wednesday',
                text: 'среду',
            },
            {
                value: 'thursday',
                text: 'четверг',
            },
            {
                value: 'friday',
                text: 'пятницу',
            },
            {
                value: 'saturday',
                text: 'субботу',
            },
            {
                value: 'sunday',
                text: 'воскресенье',
            },
        ],
    }),

    mounted() {
        this.reset();
    },

    computed: {
        computedRule() {
            const isDate = this.model === 'date';
            const value = isDate ? this.date : this.day;

            return {
                type: isDate ? this.model : this.dayType,
                value: [Number(value)],
            };
        },

        validation() {
            const rules = {
                'date': [
                    v => v >= 1,
                    v => v <= 31,
                ],
                'working-day': [
                    v => v >= 1,
                    v => v <= 31, // ...
                ],
            };

            const date =
                this.model === 'date'
                    ? rules['date']
                    : [];

            const weekday = [
                v => v >= 1,
                v => v <= 5,
            ];

            const day =
                this.model === 'day'
                    ? rules[this.dayType] ? rules[this.dayType] : weekday
                    : [];

            return {
                date,
                day,
            };
        },
    },

    watch: {
        computedRule(val) {
            this.$emit('update', val);
        },

        dialog() {
            this.reset();
        },
    },

    methods: {
        reset() {
            const { type, value: [val] } = this.rule;

            this.dayType = type;

            switch (type) {
                case 'date':
                    this.model = 'date';
                    this.date = val;
                    this.day = 1;
                    break;
                default:
                    this.model = 'day';
                    this.date = 1;
                    this.day = val;
                    break;
            }
        },
    },
};
</script>
