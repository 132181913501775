<template>
    <full-overlay :value="loading" />
</template>

<script>
import FullOverlay from "@c/FullOverlay";
export default {
    name: "FieldRemove",
    data: () => ({
        loading: false,
    }),
    components: {
        FullOverlay,
    },
    props: {
        owner: {
            type: String,
            required: true,
        },
    },
    methods: {
        async open(id, name) {
            const c = await this.$confirm({
                text: this.$t("fields.remove", { name }),
                actions: {
                    false: {
                        text: this.$t('main.close'),
                    },
                    true: {
                        text: this.$t('main.remove'),
                        color: 'error',
                    }
                },
            });
            if (!c) {
                return;
            }
            this.loading = true;
            let status = false;
            try {
                await this.$api(`/${this.owner}/fields/${id}`, {
                    method: "DELETE",
                });
                status = true;
            } catch (e) {
                this.$error(e.message);
            }

            this.loading = false;
            return status;
        },
    },
};
</script>