<template>
    <v-container
        v-if="$isMobile()"
        fluid
    >
        <v-row dense>
            <v-col>
                <span class="mr-2">
                    <slot name="index" />
                </span>

                <slot name="body" />
            </v-col>
        </v-row>

        <slot name="buttons" />
    </v-container>

    <v-container
        v-else
        fluid
    >
        <v-row>
            <v-col
                cols="1"
                class="d-flex align-center justify-center subtitle-1"
            >
                <slot name="index" />
            </v-col>

            <v-col cols="6" class="d-flex flex-column justify-center">
                <slot name="body" />
            </v-col>

            <v-col cols="5" class="d-flex align-center justify-space-between">
                <v-container fluid>
                    <slot name="buttons" />
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "AdaptiveItem",

    inject: ['$isMobile'],
};
</script>
