<template>
    <splitpanes
        vertical
        class="default-theme"
        @resized="onResized"
    >
        <pane
            :min-size="columnSizes.left.min"
            :size="columnSizes.left.last || columnSizes.left.default"
        >
            <slot name="left" />
        </pane>

        <pane
            :min-size="columnSizes.middle.min"
            :size="columnSizes.middle.last || columnSizes.middle.default"
        >
            <slot name="middle" />
        </pane>

        <pane
            :min-size="columnSizes.right.min"
            :size="columnSizes.right.last || columnSizes.right.default"
        >
            <slot name="right" />
        </pane>
    </splitpanes>
</template>

<script>
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

export default {
    name: "DesktopLayout",

    components: {
        Splitpanes,
        Pane,
    },

    data: () => ({
        columnSizes: {
            left: {
                min: 10,
                default: 25,
                last: null,
            },
            middle: {
                min: 10,
                default: 25,
                last: null,
            },
            right: {
                min: 20,
                default: 50,
                last: null,
            },
        },
    }),

    mounted() {
        const result = localStorage.columnSizes;

        if (result) {
            const sizes = JSON.parse(result);

            this.columnSizes.left.last = sizes.left;
            this.columnSizes.middle.last = sizes.middle;
            this.columnSizes.right.last = sizes.right;
        }
    },

    methods: {
        onResized($event) {
            const sizes = $event.map((obj) => obj.size);

            localStorage.columnSizes = JSON.stringify({
                left: sizes[0],
                middle: sizes[1],
                right: sizes[2],
            });
        },
    },
};
</script>

<style lang="scss">
.splitpanes {
    height: 100%;

    &__pane {
        background-color: inherit !important;
    }
}

.splitpanes--vertical > .splitpanes__splitter {
    min-width: 10px;
    opacity: 0.3;
    background-color: #bebebe !important;

    &:hover {
        opacity: 0.7;
    }
}
</style>
