<template>
    <v-sheet
        elevation="1"
        class="my-2"
        :color="color"
    >
        <event-info
            #default="{ info }"
            :event="event"
        >
            <v-list-item @click="$emit('filter:toggle', event.id)">
                <v-list-item-action>
                    <v-checkbox
                        :value="checkbox"
                        dense
                        readonly
                    />
                </v-list-item-action>

                <v-list-item-content>
                    <v-list-item-title :class="`${event.color}--text`">
                        <text-highlight-wrapper
                            :text="title"
                            :queries="searchText"
                        />
                    </v-list-item-title>

                    <v-list-item-subtitle
                        v-for="{ label, value } in info"
                        :key="label"
                        class="text--primary text-wrap"
                    >
                        <span
                            v-if="label"
                            v-text="`${label}: `"
                        />
                        <span
                            v-text="value"
                            class="font-weight-light"
                        />
                    </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <event-buttons
                        @process="$emit('event:process', event.id)"
                        @edit="$emit('event:dialog:edit', event)"
                        @delete="$emit('delete')"
                    />
                </v-list-item-action>
            </v-list-item>
        </event-info>

        <v-divider v-if="event.transactions.length" />

        <v-list-group v-if="event.transactions.length">
            <template #activator>
                <div
                    v-text="`Количество транзакций: ${event.transactions.length}`"
                    class="w-full text--secondary"
                />
            </template>

            <v-divider />

            <v-list-item
                v-for="(transaction, transactionIndex) in event.transactions"
                :key="transactionIndex"
            >
                <transaction-info
                    #default="{ info }"
                    :transaction="transaction"
                    :cashboxes="cashboxes"
                    :cashbox-categories="cashboxCategories"
                    :cost-templates="costTemplates"
                    :cost-elements="costElements"
                    :cost-categories="costCategories"
                >
                    <v-list-item-content>
                        <template v-for="{ alias, value, label } in info">
                            <template v-if="alias === 'operations'">
                                <v-list-item-subtitle
                                    v-for="({ path, amount, amountClass }, index) in value"
                                    :key="`operation-${index}`"
                                    class="text-wrap text--primary"
                                >
                                    <span v-text="path" />
                                    <span
                                        v-text="amount"
                                        :class="amountClass"
                                        class="text-no-wrap ml-2"
                                    />
                                </v-list-item-subtitle>
                            </template>

                            <template v-else>
                                <v-list-item-subtitle
                                    :key="alias"
                                    v-text="`${label}:`"
                                    class="text--primary"
                                />

                                <v-list-item-subtitle
                                    v-for="(row, index) in value"
                                    :key="`${alias}-${index}`"
                                    class="text-wrap text--primary font-weight-light"
                                >
                                    <span v-text="row" />
                                </v-list-item-subtitle>
                            </template>
                        </template>
                    </v-list-item-content>
                </transaction-info>
            </v-list-item>
        </v-list-group>
    </v-sheet>
</template>

<script>
import EventInfo from "@c/event-planner/EventInfo";
import EventButtons from "@c/event-planner/event-list/EventButtons";
import TransactionInfo from "@c/event-planner/TransactionInfo";
import TextHighlightWrapper from "@c/TextHighlightWrapper";

export default {
    name: "EventComponent",

    components: {
        EventInfo,
        EventButtons,
        TransactionInfo,
        TextHighlightWrapper,
    },

    props: {
        event: {
            type: Object,
            required: true,
        },
        selectedEvents: {
            type: Array,
            required: true,
        },
        color: {
            type: String,
            default: '',
        },
        searchText: {
            type: String,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        cashboxCategories: {
            type: Array,
            required: true,
        },
        costTemplates: {
            type: Array,
            required: true,
        },
        costElements: {
            type: Array,
            required: true,
        },
        costCategories: {
            type: Array,
            required: true,
        },
    },

    computed: {
        title() {
            return `#${this.event.id} ${this.event.name}`;
        },

        checkbox() {
            return this.selectedEvents.includes(this.event.id);
        },
    },
};
</script>
