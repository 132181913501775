export function encode(flt) {
    return Object.keys(flt).reduce((acc, key) => {
        acc[`flt[${key}][${flt[key].operation}]`] = flt[key].value;
        return acc;
    }, {});
}

export function decode(query) {
    return Object.keys(query).reduce((acc, key) => {
        const match = key.match(/flt\[(\d+)\]\[(.)\]/);
        if (match) {
            acc[match[1]] = {
                operation: match[2],
                value: query[key],
            };
        }
        return acc;
    }, {});
}

export default {
    encode,
    decode,
};
