<template>
    <v-text-field
        :label="field.name"
        readonly
        :value="name"
        :loading="loading"
    />
</template>

<script>
import FieldBase from "./FieldBase";
export default {
    name: "FieldRoom",
    extends: FieldBase,
    data: () => ({
        name: null,
        loading: false,
    }),
    watch: {
        value: {
            immediate: true,
            async handler(v) {
                this.name = null;
                if (v) {
                    this.loading = true;
                    try {
                        const { name } = await this.$api(`/entity/room/${v}`);
                        this.name = name;
                    } catch (e) {
                        this.$error(e.message);
                    }
                    this.loading = false;
                }
            },
        },
    },
    props: {
        value: {
            type: Number,
        },
    },
    methods: {
        getDefaultValue() {
            return this.meta.room;
        },
        setValue(value) {
            this.$emit("input", value.id);
        },
    },
};
</script>