export default {
    draft: {
        id: 'draft',
        name: 'Черновик',
        textClass: 'text--secondary',
        icon: 'mdi-progress-helper',
    },
    completed: {
        id: 'completed',
        name: 'Обработана',
        textClass: 'primary--text',
        icon: 'mdi-progress-check',
    },
    queue: {
        id: 'queue',
        name: 'В очереди',
        textClass: 'text--secondary',
        icon: 'mdi-progress-question',
    },
    processing: {
        id: 'processing',
        name: 'Обрабатывается',
        textClass: 'primary--text',
        icon: 'mdi-progress-clock'
    },
    canceled: {
        id: 'canceled',
        name: 'Отменена',
        textClass: 'error--text',
        icon: 'mdi-progress-close',
    },
    error: {
        id: 'error',
        name: 'Ошибка',
        textClass: 'error--text',
        icon: 'mdi-progress-alert',
    },
};
