class Event {
    constructor(event) {
        Object.assign(this, event);
        this.timed = true;
    }

    get flattened() {
        const { type, value } = this.rule;

        return value.map((v) => new Event({
            ...this,
            rule: {
                type,
                value: [v],
            },
        }));
    }

    incrementDate(date) {
        switch (this.formula) {
            case 'every-day':
            case 'every-working-day':
                date.setDate(date.getDate() + 1 * this.interval);
                break;
            case 'every-week':
                date.setDate(date.getDate() + 7 * this.interval);
                break;
            case 'every-month':
                date.setMonth(date.getMonth() + 1 * this.interval);
        }
    }

    weekdays = [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
    ]

    addDay(date) {
        date.setDate(date.getDate() + 1);
    }

    skipWeekend(date) {
        while ([0, 6].includes(date.getDay())) {
            this.addDay(date);
        }
    }

    findClosestDate(date) {
        if (this.formula === 'every-working-day') {
            this.skipWeekend(date);
        }

        if (this.formula === 'every-week') {
            const [weekday] = this.rule.value;
            while (date.getDay() !== weekday) {
                this.addDay(date);
            }
        }

        if (this.formula === 'every-month') {
            if (this.rule.type === 'date') {
                date.setDate(this.rule.value);
            } else if (this.rule.type === 'working-day') {
                date.setDate(1);

                this.skipWeekend(date);

                let count = this.rule.value - 1;
                while (count) {
                    this.skipWeekend(date);
                    this.addDay(date);
                    count--;
                }

                this.skipWeekend(date);
            } else {
                date.setDate(1);

                const weekdayIndex = this.weekdays
                    .findIndex((weekday) => weekday === this.rule.type);

                let count = this.rule.value;
                while (count) {
                    if (date.getDay() !== weekdayIndex) {
                        this.addDay(date);
                    }
                    while (date.getDay() !== weekdayIndex) {
                        this.addDay(date);
                    }
                    this.addDay(date);
                    count--;
                }

                date.setDate(date.getDate() - 1);
            }
        }
    }
}

const processEvents = (events) => {
    const result = [];

    events.forEach((event) => {
        if (['every-week', 'every-month'].includes(event.formula)) {
            const { flattened } = new Event(event);
            result.push(...flattened);
        } else {
            result.push(new Event(event));
        }
    });

    return result;
};

export const updateRange = (minDate, maxDate, events) => {
    const result = [];

    processEvents(events).forEach((event) => {
        const date = new Date(event.start);
        let count = 0;

        while (date <= maxDate) {
            if (
                event.repeatUntil === 'count'
                && count >= event.maxCount
            ) {
                break;
            }

            event.findClosestDate(date);

            if (
                event.repeatUntil === 'date'
                && date >= new Date(event.end)
            ) {
                break;
            }

            if (date >= minDate) {
                count++;
                result.push({
                    ...event,
                    start: new Date(date),
                    end: new Date(date),
                });
            }

            event.incrementDate(date);
        }
    });

    return result;
};
