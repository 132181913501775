<template>
    <div class="d-flex">
        <v-btn
            v-for="{ icon, color, emit } in buttons"
            :key="emit"
            icon
            @click.stop="$emit(emit)"
        >
            <v-icon
                v-text="icon"
                :color="color"
            />
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "EventButtons",

    data: () => ({
        buttons: [
            // {
            //     icon: 'mdi-calendar-check',
            //     color: 'primary',
            //     emit: 'process',
            // },
            {
                icon: 'mdi-calendar-edit',
                color: 'grey',
                emit: 'edit',
            },
            // {
            //     icon: 'mdi-calendar-remove',
            //     color: 'red',
            //     emit: 'delete',
            // },
        ],
    }),
};
</script>
