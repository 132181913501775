<template>
    <v-list-group
        v-show="categoriesToDisplay.includes(category.id)"

        v-model="open"
        dense
        sub-group
        eager
    >
        <template #activator>
            <v-list-item
                :data-test-id="`costs-category-${level}-${category.id}`"
                :data-test-balance="category.amount"

                ref="category"
                class="pa-0"
            >
                <v-list-item-title>
                    <text-highlight-wrapper
                        :text="category.name"
                        :queries="searchText"
                    />
                </v-list-item-title>
                <v-list-item-action class="flex-shrink-0">
                    <v-list-item-action-text class="text-no-wrap">
                        {{ formatNumber(category.amount) }} {{ category.currency }}
                    </v-list-item-action-text>
                </v-list-item-action>
            </v-list-item>
        </template>

        <cost-component
            v-for="template in category.templates"
            :key="template.id"

            :balance="template.remainsAmount"
        >
            <v-list-item
                :data-test-id="`costs-template-${template.category}-${template.id}`"
                :data-test-object="JSON.stringify(template)"
                data-test-category="costs"
                data-test-subcategory="template"

                v-show="templatesToDisplay.includes(template.id)"

                dense
                color="primary"

                :draggable="!$isMobile()"
                :title="template.name"
                :class="{ 
                    'selected-item': (template.id === selectedItemId) && ('template' === selectedItemSubcategory),
                    'vue-cashbox-basket': template.remainsAmount > 0,
                }"

                @dragstart="startDrag($event, template, 'template')"
                @dragend="endDrag($event)"

                @dragenter="onDragEnter($event)"
                @dragleave="onDragLeave($event)"

                @dragover.prevent

                @drop="onDrop($event, template, 'template')"

                @dblclick="onDBClickOnTemplate(template)"
                @contextmenu.prevent="$emit('select-item', template, 'costs', 'template', template.remainsAmount)"

                v-touch="{
                    left: () => $emit('select-item', template, 'costs', 'template', template.remainsAmount),
                }"
            >
                <v-list-item-content>
                    <v-list-item-title>
                        <text-highlight-wrapper
                            :text="`#${template.id} ${template.name}`"
                            :queries="searchText"
                        />
                    </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                    <v-list-item-action-text class="text-no-wrap">
                        {{ formatNumber(template.remainsAmount) }} {{ template.currency }}
                    </v-list-item-action-text>
                </v-list-item-action>
            </v-list-item>
        </cost-component>

        <cost-component
            v-for="element in category.elements"
            :key="element.id"

            :balance="element.amount"
        >
            <v-list-item
                :data-test-id="`costs-element-${element.category}-${element.id}`"
                :data-test-object="JSON.stringify(element)"
                data-test-category="costs"
                data-test-subcategory="element"

                v-show="elementsToDisplay.includes(element.id)"

                dense
                color="primary"
                class="vue-cashbox-basket"

                :draggable="!$isMobile()"
                :title="element.name"
                :class="{
                    'selected-item': (element.id === selectedItemId) && ('element' === selectedItemSubcategory),
                    'vue-cashbox-basket': element.amount > 0,
                }"

                @dragstart="startDrag($event, element, 'element')"
                @dragend="endDrag($event)"

                @dragenter="onDragEnter($event)"
                @dragleave="onDragLeave($event)"

                @dragover.prevent

                @drop="onDrop($event, element, 'element')"

                @dblclick="onDBClickOnElement(element)"
                @contextmenu.prevent="$emit('select-item', element, 'costs', 'element', element.amount)"

                v-touch="{
                    left: () => $emit('select-item', element, 'costs', 'element', element.amount),
                }"
            >
                <v-list-item-content>
                    <v-list-item-title>
                        <span>
                            <text-highlight-wrapper
                                :text="`#${element.id} ${element.name}`"
                                :queries="searchText"
                            />
                        </span>

                        <div
                            v-show="element.links.length"
                            v-for="link in element.links"
                            :key="link.id"
                        >
                            <span v-show="!link.link">
                                {{ link.name }}
                            </span>

                            <a
                                v-show="link.link"
                                :href="link.link"
                                target="_blank"
                            >
                                {{ link.name }}
                            </a>
                        </div>
                    </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                    <v-list-item-action-text class="text-no-wrap">
                        {{ formatNumber(element.amount) }} {{ element.currency }}
                    </v-list-item-action-text>
                </v-list-item-action>
            </v-list-item>
        </cost-component>

        <cost-category
            v-for="child in category.children"
            :key="child.id"

            ref="recursiveLists"

            v-show="categoriesToDisplay.includes(child.id)"

            class="pl-4"

            :category="child"
            :selected-item="selectedItem"
            :templates-to-display="templatesToDisplay"
            :elements-to-display="elementsToDisplay"
            :categories-to-display="categoriesToDisplay"
            :search-text="searchText"
            :level="level + 1"

            v-on="$listeners"
        />
    </v-list-group>
</template>

<script>
import { formatNumber } from '@u/formatting';
import CostComponent from "@c/norton-commander/costs/CostComponent";
import TextHighlightWrapper from "@c/TextHighlightWrapper";

export default {
    name: "CostCategory",

    inject: ['$isMobile'],

    components: {
        CostComponent,
        TextHighlightWrapper,
    },

    props: {
        category: {
            type: Object,
            required: true,
        },
        selectedItem: {
            type: Object,
            required: true,
        },
        templatesToDisplay: {
            type: Array,
            required: true,
        },
        elementsToDisplay: {
            type: Array,
            required: true,
        },
        categoriesToDisplay: {
            type: Array,
            required: true,
        },
        searchText: {
            type: String,
            required: true,
        },
        level: {
            type: Number,
            default: 0,
        },
    },

    data: () => ({
        open: false,
    }),

    computed: {
        selectedItemId() {
            if (this.selectedItem.category === 'costs') {
                return this.selectedItem.id;
            }

            return null;
        },

        selectedItemSubcategory() {
            if (this.selectedItem.category === 'costs') {
                return this.selectedItem.subcategory;
            }

            return null;
        },
    },

    watch: {
        open(value) {
            const { id } = this.category;

            this.$emit('toggle-category', { id, value });
        },

        'category.amount': {
            handler() {
                const ref = this.$refs[`category`];

                if (!ref) {
                    return;
                }

                ref.$el.classList.add('balance-changed');

                setTimeout(() => {
                    ref.$el.classList.remove('balance-changed');
                }, 2000);
            },
        },
    },


    methods: {
        formatNumber,

        onDBClickOnTemplate({ id, name, remainsAmount }) {
            const payload = {
                category: 'costs.template',
                id,
                title: name,
                balance: remainsAmount,
            };
            this.$emit('dialog:set-amount', payload);
        },

        onDBClickOnElement({ id, name, amount }) {
            const payload = {
                category: 'costs.element',
                id,
                title: name,
                balance: amount,
            };
            this.$emit('dialog:set-amount', payload);
        },

        startDrag(evt, template, subcategory) {
            evt.currentTarget.classList.add('dragged-item');
            evt.dataTransfer.effectAllowed = 'move';
            evt.dataTransfer.dropEffect = 'move';

            evt.dataTransfer.setData('id', template.id);
            evt.dataTransfer.setData('title', template.name);

            evt.dataTransfer.setData('category', 'costs');
            evt.dataTransfer.setData('subcategory', subcategory);

            evt.dataTransfer.setData('currency', template.currency);

            this.$emit('drag:start');
        },

        endDrag(evt) {
            evt.currentTarget.classList.remove('dragged-item');

            this.$emit('drag:end');
        },

        onDrop(evt, item, subcategory) {
            evt.target.classList.remove('drop-point');
            
            if (evt.currentTarget.classList.contains('vue-cashbox-basket')) {
                const amount = subcategory === 'element' ? item.amount : item.remainsAmount;
                this.$emit('on-drop-to-costs', evt, item, subcategory, amount);
            }
        },

        onDragEnter(evt) {
            if (evt.target.classList.contains('vue-cashbox-basket')) {
                evt.target.classList.add('drop-point');
            }
        },

        onDragLeave(evt) {
            if (evt.target.classList.contains('vue-cashbox-basket')) {
                evt.target.classList.remove('drop-point');
            }
        },

        toggleCategory(key) {
            switch (key) {
                case 'open':
                    this.open = true;
                    break;

                case 'close':
                    this.open = false;
                    break;
            }

            if (this.$refs.recursiveLists) {
                this.$refs.recursiveLists.forEach((ref) => ref.toggleCategory(key));
            }
        },
    },
};
</script>
