<template>
    <v-dialog v-model="show" persistent>
        <v-toolbar dense>
            <v-tabs v-model="tab">
                <v-tab>{{ $t("main.editor") }}</v-tab>
                <v-tab v-for="t in tabs" :key="t.id">{{ t.name }}</v-tab>
            </v-tabs>
            <v-spacer />
            <v-btn icon color="error" @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <v-form ref="form" @submit.prevent="save">
                    <v-card :loading="loading" :disabled="loading">
                        <v-card-text>
                            <v-row v-for="f in fields" :key="f.id">
                                <v-col>
                                    <component
                                        :id="id"
                                        :is="getFieldName(f.type)"
                                        :field="f"
                                        :meta="meta"
                                        v-model="f.value"
                                        :ref="`field-${f.id}`"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn text color="primary" type="submit">
                                {{ $t("main.save") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-tab-item>
            <v-tab-item v-for="(t, i) in tabs" :key="t.id">
                <slot
                    :name="`tab-${t.id}`"
                    :fields="fields"
                    :show="show"
                    :id="id"
                    :current="i + 1 == tab"
                />
            </v-tab-item>
        </v-tabs-items>
    </v-dialog>
</template>

<script>
import FieldAddress from "@c/entity-editor-fields/FieldAddress";
import FieldCatname from "@c/entity-editor-fields/FieldCatname";
import FieldCoords from "@c/entity-editor-fields/FieldCoords";
import FieldStorage from "@c/entity-editor-fields/FieldStorage";
import FieldRack from "@c/entity-editor-fields/FieldRack";
import FieldRoom from "@c/entity-editor-fields/FieldRoom";
import FieldTime from "@c/entity-editor-fields/FieldTime";
import FieldNumber from "@c/entity-editor-fields/FieldNumber";
import FieldString from "@c/entity-editor-fields/FieldString";
import FieldEnum from "@c/entity-editor-fields/FieldEnum";
import FieldText from "@c/entity-editor-fields/FieldText";
import FieldRoot from "@c/entity-editor-fields/FieldRoot";
import FieldBool from "@c/entity-editor-fields/FieldBool";
import FieldDate from "@c/entity-editor-fields/FieldDate";
import FieldDatetime from "@c/entity-editor-fields/FieldDatetime";
import FieldCategory from "@c/entity-editor-fields/FieldCategory";
import FieldPrefix from "@c/entity-editor-fields/FieldPrefix";
import FieldCellrange from "@c/entity-editor-fields/FieldCellrange";
export default {
    name: "EntityEditor",
    components: {
        FieldCoords,
        FieldStorage,
        FieldRoom,
        FieldRack,
        FieldAddress,
        FieldCatname,
        FieldTime,
        FieldNumber,
        FieldString,
        FieldEnum,
        FieldText,
        FieldRoot,
        FieldBool,
        FieldDate,
        FieldDatetime,
        FieldCategory,
        FieldPrefix,
        FieldCellrange,
    },
    data: () => ({
        show: false,
        loading: false,
        id: 0,
        fields: [],
        initialValues: [],
        meta: {},
        res: null,
        tab: null,
    }),
    props: {
        owner: {
            type: String,
            required: true,
        },
        tabs: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        async save() {
            if (!this.$refs.form.validate()) {
                return false;
            }
            const values = this.fields.reduce((acc, f) => {
                const value = this.$refs[`field-${f.id}`][0].getValue();
                if (f.batch) {
                    acc.push({
                        field: f.id,
                        value: value ? value[f.type] : "",
                    });
                    f.map.forEach(({ id, type }) => {
                        acc.push({
                            field: id,
                            value: value ? value[type] : "",
                        });
                    });
                } else {
                    acc.push({
                        field: f.id,
                        value,
                    });
                }
                return acc;
            }, []);

            this.loading = true;
            try {
                if (this.id) {
                    const valuesFiltered = values.filter((v) => {
                        if (v.value === null) {
                            return false;
                        }
                        if (v.value !== '') {
                            return true;
                        }
                        if (this.initialValues.find((iV) => iV.field == v.field)) {
                            return true;
                        }
                        return false;
                    });
                    await this.$api(`/entity/${this.owner}/${this.id}`, {
                        method: "PATCH",
                        body: {
                            values: valuesFiltered,
                        },
                    });
                } else {
                    await this.$api(`/entity/${this.owner}`, {
                        method: "POST",
                        body: {
                            values: values.filter((v) => v.value != ""),
                        },
                    });
                }
                this.res(true);
                this.show = false;
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
        close() {
            this.show = false;
            this.res(false);
        },
        async open(id, meta = {}) {
            this.tab = null;
            this.id = id;
            this.meta = meta;
            this.fields = [];
            this.initialValues = [];
            this.show = true;
            this.loading = true;
            try {
                const fields = await this.$api(`/${this.owner}/fields`);
                this.fields = fields
                    .map((f) => {
                        f.main = true;
                        if (f.batch) {
                            switch (f.batch) {
                                case "address":
                                    f.main = f.type == f.batch;
                                    break;
                                case "prefix":
                                    f.main = f.type == "string";
                                    if (f.main) {
                                        f.type = "prefix";
                                    }
                                    break;
                            }
                            if (f.main) {
                                f.map = fields
                                    .filter(
                                        (a) =>
                                            a.batch == f.batch && a.id != f.id
                                    )
                                    .map((a) => ({
                                        type: a.type,
                                        name: a.name,
                                        id: a.id,
                                    }));
                            }
                        }
                        f.value = null;
                        return f;
                    })
                    .filter((f) => f.main);
                await this.$nextTick();
                this.fields.map((f) => {
                    f.value = this.$refs[`field-${f.id}`][0].getDefaultValue();
                });
                this.$refs.form.resetValidation();
                if (id) {
                    const { values } = await this.$api(`/entity/${this.owner}/${id}`);
                    this.initialValues = values;
                    values.map((v) => {
                        const field = fields.find((f) => f.id == v.field);
                        if (!field.main) {
                            return true;
                        }
                        if (!field.batch) {
                            this.$refs[`field-${v.field}`][0].setValue(v.value);
                            return true;
                        }
                        this.$refs[`field-${v.field}`][0].setValue(
                            values.reduce((acc, v) => {
                                const valueField = fields.find(
                                    (f) => f.id == v.field
                                );
                                if (valueField.batch != field.batch) {
                                    return acc;
                                }
                                acc[valueField.type] = v.value;
                                return acc;
                            }, {})
                        );
                    });
                    this.$refs.form.resetValidation();
                }
            } catch (e) {
                this.$error(e.message);
                this.show = false;
            }
            this.loading = false;
            return new Promise((res) => (this.res = res));
        },
        getFieldName: (type) =>
            `Field${type.charAt(0).toUpperCase()}${type.slice(1)}`,
    },
};
</script>
