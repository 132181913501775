<template>
    <v-card flat outlined>
        <v-toolbar dense v-if="editor">
            <v-btn
                icon
                @click="editor.chain().focus().toggleBold().run()"
                :input-value="editor.isActive('bold')"
            >
                <v-icon>mdi-format-bold</v-icon>
            </v-btn>
            <v-btn
                icon
                @click="editor.chain().focus().toggleItalic().run()"
                :input-value="editor.isActive('italic')"
            >
                <v-icon>mdi-format-italic</v-icon>
            </v-btn>
            <v-btn
                icon
                @click="editor.chain().focus().toggleStrike().run()"
                :input-value="editor.isActive('strike')"
            >
                <v-icon>mdi-format-strikethrough-variant</v-icon>
            </v-btn>
        </v-toolbar>
        <editor-content :editor="editor" class="mb-n4" />
    </v-card>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";

export default {
    name: "TipTap",
    components: {
        EditorContent,
    },
    props: {
        value: {
            type: String,
        },
    },
    watch: {
        value(value) {
            if (this.editor.getHTML() === value) {
                return;
            }

            this.editor.commands.setContent(value, false);
        },
    },
    data: () => ({ editor: null }),
    mounted() {
        this.editor = new Editor({
            content: this.modelValue,
            onUpdate: () => {
                this.$emit("input", this.editor.getHTML());
            },
            extensions: [StarterKit],
        });
    },

    beforeDestroy() {
        this.editor.destroy();
    },
};
</script>

<style lang="scss">
.ProseMirror p {
    min-height: 60px;
    padding: 12px;
}
</style>