class Node {
    constructor(node) {
        Object.assign(this, node);

        this.name = node.title ?? node.name ?? '';

        this.isCategory = true;
        this.canBeActive = false;
        this.isEveryChildSelected = false;

        this.childrenCount = 0;
        this.childrenCountTotal = 0;
        this.childrenCountSelected = 0;
        this.childrenCountSelectedTotal = 0;

        this.parents = [];
        this.children = [];
    }
}

class Leaf extends Node {
    constructor(node) {
        super(node);

        this.isCategory = false;

        this.category = node.category ?? node.type ?? '';
        this.treeParent = node.treeParent || node.category;
    }
}

export class Tree {
    constructor({ items, categories, selectedItems = [], selectedCategories = [] }) {
        this.items = items.map((item) => new Leaf({
            ...item,
            selected: selectedItems.includes(item.id),
        }));

        this.categories = categories.map((category) => new Node(category));

        this.items.forEach((item) => this.addToParent(item));

        this.categories.forEach(({ id, children }) => {
            if (selectedCategories.includes(id)) {
                children.forEach(child => {
                    child.selected = true;
                });
            }
        });

        this.categoriesParents = {};

        this.categories.forEach(({ id, treeParent }) => {
            if (treeParent) {
                this.categoriesParents[id] = this.categoriesParents[id] || [];
                const parentId = String(treeParent);
                if (!this.categoriesParents[id].includes(parentId)) {
                    this.categoriesParents[id].push(parentId);
                }
            }
        });

        Object.keys(this.categoriesParents).forEach((childId) => {
            const parentIdsArray = this.categoriesParents[childId];

            parentIdsArray.forEach((parentId1) => {
                if (Object.keys(this.categoriesParents).includes(parentId1)) {
                    this.categoriesParents[parentId1].forEach((parentId2) => {
                        if (!this.categoriesParents[childId].includes(parentId2)) {
                            this.categoriesParents[childId].push(parentId2);
                        }
                    });
                }
            });
        });

        this.items.forEach((item) => {
            item.parents.push(String(item.treeParent));

            Object.keys(this.categoriesParents).forEach((parentId) => {
                if (parentId === String(item.treeParent)) {
                    item.parents.push(...this.categoriesParents[parentId]);
                }
            });
        });

        this.categories.forEach((category) => {
            let childrenCount = 0;
            let childrenCountSelected = 0;

            category.children.forEach(({ selected }) => {
                childrenCount++;
                childrenCountSelected += selected ? 1 : 0;
            });

            category.childrenCount = childrenCount;
            category.childrenCountSelected = childrenCountSelected;
        });

        this.categories.forEach((category) => {
            let total = 0;

            category.children.forEach(({ balance }) => {
                total += Number(balance);
            });

            category.balance = total;
        });

        this.categories.forEach((category) => this.addToParent(category));

        this.categories.forEach((category) => {
            let childrenCountTotal = 0;
            let childrenCountSelectedTotal = 0;

            const countChildren = (node) => {
                if (!node.children.length) {
                    return;
                }

                if (!node.children[0].isCategory) {
                    childrenCountTotal += node.childrenCount;
                    childrenCountSelectedTotal += node.childrenCountSelected;
                } else {
                    node.children.forEach((child) => countChildren(child));
                }
            };

            countChildren(category);

            category.childrenCountTotal = childrenCountTotal;
            category.childrenCountSelectedTotal = childrenCountSelectedTotal;
        });

        this.categories.forEach((category) => {
            let total = 0;

            category.children.forEach(({ balance }) => {
                total += Number(balance);
            });

            category.balance = total;
        });

        this.categories.forEach((category) => {
            if (
                !category.children.length
                || category.childrenCountTotal === 0
                || category.childrenCountSelectedTotal === 0
                || category.childrenCountSelectedTotal !== category.childrenCountTotal
            ) {
                return;
            }

            category.isEveryChildSelected = true;
        });

        this.categories.forEach((category) => {
            const parentsStates = [];

            if (this.categoriesParents[category.id]) {
                this.categoriesParents[category.id].forEach((parentId) => {
                    const parent = this.categories.find((parent) => String(parent.id) === String(parentId));
                    if (parent) {
                        const parentState = parent.isEveryChildSelected;
                        parentsStates.push(parentState);
                    }
                });
            }

            category.canBeActive = !parentsStates.some((state) => state);
        });

        this.categories.forEach((category) => {
            category.parents = this.categoriesParents[category.id];
        });

        this.nodes = Array.from(this.categories);
    }

    addToParent = (node) => {
        const { id, treeParent } = node;

        const parent = this.categories
            .find((category) => String(category.id) === String(treeParent));

        if (!parent) {
            return;
        }

        const index = parent.children
            .findIndex((child) => String(child.id) === String(id));

        if (index > -1) {
            parent.children.splice(index, 1);
        } else {
            parent.children.push(node);
        }
    };

    get selectedLeafs() {
        return this.nodes
            .map(({ children }) => children.filter(({ selected }) => selected))
            .flat();
    }
}

export class SelectionTree extends Tree {
    constructor(...args) {
        super(...args);
    }

    get nodesToDisplay() {
        return this.nodes.filter(
            ({ canBeActive, isEveryChildSelected }) => canBeActive && isEveryChildSelected
        );
    }

    get leafsToDisplay() {
        return this.selectedLeafs.filter(({ parents }) => {
            return !parents.some((parentId) => {
                return this.nodesToDisplay
                    .map(({ id }) => String(id))
                    .includes(String(parentId));
            });
        });
    }
}

export class SearchTree extends Tree {
    constructor(...args) {
        super(...args);
    }

    get nodesToDisplay() {
        return this.nodes
            .filter(({ childrenCountSelectedTotal }) => childrenCountSelectedTotal);
    }
}
