<template>
    <v-card class="h-full">
        <v-card-title class="justify-space-between subtitle-1">
            <span class="font-weight-medium">Пользователи</span>
            <span
                v-if="users.length"
                class="text--disabled"
            >
                {{ usersAfterSearch.length }} / {{ users.length }}
            </span>
        </v-card-title>

        <v-divider />

        <v-list class="pa-0">
            <v-list-item>
                <v-list-item-content>
                    <v-text-field
                        v-model="search"
                        type="string"
                        label="Поиск"
                        dense
                        outlined
                        hide-details
                        prepend-icon="mdi-magnify"
                        :append-icon="search ? 'mdi-close' : ''"

                        @click:append="search = ''"
                    />
                </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-list-item>
                <v-checkbox
                    v-model="showAll"
                    :disabled="Boolean(search)"
                    :indeterminate="Boolean(search)"
                    label="Показывать всех"
                    class="w-full"
                />
            </v-list-item>

            <v-divider />

            <v-list-item-group color="primary">
                <template v-for="{ id, login, admin } in usersWithSelected">
                    <v-list-item
                        :key="`${id}-item`"
                        :to="{
                            name: 'AccessControl',
                            params: { ...$route.params },
                            query: {
                                ...$route.query,
                                userId: id,
                            },
                        }"
                        exact
                        exact-active-class="font-weight-medium"
                    >
                        <v-list-item-icon>
                            <v-icon v-text="'mdi-account-outline'" />
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>
                                <text-highlight-wrapper
                                    :text="`#${id} ${login}`"
                                    :queries="search"
                                />
                            </v-list-item-title>

                            <v-list-item-subtitle
                                v-if="admin"
                                class="caption"
                            >
                                {{ $t("users.admin") }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider :key="`${id}-divider`" />
                </template>
            </v-list-item-group>
        </v-list>
    </v-card>
</template>

<script>
import { PathToCashbox } from '@u/path';

import TextHighlightWrapper from "@c/TextHighlightWrapper";

export default {
    name: "UserList",

    components: {
        TextHighlightWrapper,
    },

    props: {
        userId: {
            required: false,
        },
        users: {
            type: Array,
            required: true,
        },
        cashboxCategories: {
            type: Array,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        search: '',
        showAll: false,
    }),

    computed: {
        usersFiltered() {
            if (this.showAll || this.search) {
                return this.users;
            }

            const usersWithRecords = this.users
                .filter(({ records }) => records.length);

            if (!this.$route.query.categoryId) {
                return usersWithRecords;
            }

            return usersWithRecords.filter(({ records }) => {
                return records.some((record) => {
                    const cashbox = this.cashboxes
                        .find(({ id }) => id === record.id);

                    if (!cashbox) {
                        return false;
                    }

                    const path = new PathToCashbox(
                        cashbox,
                        this.cashboxCategories,
                    );

                    return path.array
                        .map(({ id }) => String(id))
                        .includes(this.$route.query.categoryId);
                });
            });
        },

        usersAfterSearch() {
            return this.usersFiltered.filter(({ id, login }) => {
                const search = this.search.toLowerCase();

                return `#${id} ${login}`
                    .toLowerCase()
                    .includes(search);
            });
        },

        usersWithSelected() {
            if (!this.userId) {
                return this.usersAfterSearch;
            }

            const currentUser = this.users
                .find(({ id }) => id === this.userId);

            if (!currentUser) {
                return this.usersAfterSearch;
            }

            const hasCurrentUser = this.usersAfterSearch
                .find((({ id }) => id === this.userId));

            if (hasCurrentUser) {
                return this.usersAfterSearch;
            }

            return [
                currentUser,
                ...this.usersAfterSearch,
            ];
        },
    },
};
</script>
