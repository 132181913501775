<template>
    <div>
        <v-btn
            data-test-id="save-filter-dialog-button-open"

            icon
            @click="dialog = true"
        >
            <v-icon v-text="'mdi-filter-plus-outline'" />
        </v-btn>

        <adaptive-dialog
            data-test-id="save-filter-dialog"
            :data-test-state="dialog"

            :dialog="dialog"
            max-width="500"
            :btnSubmit="{ text: 'Сохранить' }"

            @close="dialog = false"
            @submit="submit"
        >
            <template #title>
                Сохранить текущий фильтр
            </template>

            <template #body>
                <v-text-field
                    data-test-id="save-filter-dialog-input-name"

                    ref="input"

                    v-model="name"
                    :rules="rules"
                    label="Название"
                    hide-details="auto"

                    @keydown.enter.prevent="submit"
                />
            </template>
        </adaptive-dialog>
    </div>
</template>

<script>
import AdaptiveDialog from "@c/norton-commander/AdaptiveDialog";

export default {
    name: "FilterSave",

    components: {
        AdaptiveDialog,
    },

    data: () => ({
        dialog: false,
        name: '',
        rules: [v => !!v || 'Поле не может быть пустым'],
    }),

    watch: {
        dialog(val) {
            this.name = '';

            if (val) {
                setTimeout(() => {
                    this.$refs.input?.focus();
                }, 100);
            }

            if (this.$refs.input) {
                this.$refs.input.resetValidation();
            }
        },
    },

    methods: {
        async submit() {
            if (!this.$refs.input.validate()) {
                return;
            }

            this.$emit('filter:save', this.name);
            this.dialog = false;
        }
    },
};
</script>
