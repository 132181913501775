<template>
    <v-text-field
        :value="formattedValue"

        dense
        :solo="!$vuetify.theme.isDark"
        :outlined="$vuetify.theme.isDark"

        readonly
        :disabled="$vuetify.theme.isDark"

        hide-details="auto"
        background-color="transparent"
    >
        <template #append-outer>
            <v-icon
                dense
                :class="{
                    'ml-1': !$isMobile(),
                    'ml-n2': $isMobile(),
                }"
            >
                {{ icon }}
            </v-icon>
        </template>
    </v-text-field>
</template>

<script>
import { formatNumber } from '@u/formatting';

export default {
    name: "ReadonlyTextField",

    inject: ['$isMobile'],

    props: {
        value: {
            type: String,
            default: '',
        },

        icon: {
            type: String,
            default: '',
        },
    },

    computed: {
        formattedValue() {
            return formatNumber(this.value);
        }
    },
};
</script>
