<template>
    <adaptive-layout
        :busy="busy || fetching.users || fetching.cashboxes"
        :disabled="denyAccess"
    >
        <template #subtitle-slot>{{ username }}</template>

        <template #user-list-slot>
            <user-list
                :user-id="userId"
                :users="users"
                :cashbox-categories="cashboxCategories"
                :cashboxes="cashboxes"
            />
        </template>

        <template #tabs-slot>
            <category-tabs :tabs="categories" />
        </template>

        <template #user-info-slot>
            <user-info
                :user="currentUser"
                :tree="tree"
                :breadcrumbs="breadcrumbs"
                @update:new="updateNew"
                @update:existing="updateExisting"
            />
        </template>
    </adaptive-layout>
</template>

<script>
import { Tree } from "@u/treeview";
import { getAllPages } from "@u/api/paginated-data";
import { getCashboxCategories, getCashboxes } from "@u/cashboxes/";

import AdaptiveLayout from "@c/access-control/layouts/AdaptiveLayout";
import CategoryTabs from "@c/access-control/CategoryTabs";
import UserList from "@c/access-control/UserList";
import UserInfo from "@c/access-control/UserInfo";

export default {
    name: "AccessControl",

    components: {
        AdaptiveLayout,
        CategoryTabs,
        UserList,
        UserInfo,
    },

    props: {
        category: {
            required: false,
        },
        categoryId: {
            required: false,
        },
        userId: {
            required: false,
        },
    },

    data: () => ({
        busy: false,
        denyAccess: false,

        categories: [
            {
                name: 'Кассы',
                alias: 'cashbox',
                icon: 'mdi-cash-register',
            },
            // {
            //     name: 'Товары',
            //     alias: 'product',
            //     icon: 'mdi-currency-rub',
            // },
        ],

        users: [],
        dummyUser: {
            isDummy: true,
            id: null,
            login: '',
            admin: 0,
            records: [],
            new: {
                create: 0,
                read: 0,
                update: 0,
                delete: 0,
            },
            current: {
                create: 0,
                read: 0,
                update: 0,
                delete: 0,
            },
        },

        records: [],

        cashboxes: [],
        cashboxCategories: [],

        fetching: {
            users: false,
            cashboxes: false,
        },
    }),

    watch: {
        '$route.params.category': {
            immediate: true,
            async handler() {
                const isValid = this.categories.
                    find(({ alias }) => alias === this.category);

                if (this.category && isValid) {
                    const { hasError, e } = await this.getUsers();

                    if (hasError && e.httpStatus == 403) {
                        this.denyAccess = true;
                    }

                    if (!hasError) {
                        await this.getCashboxes();
                    }
                } else {
                    this.$router.push({
                        name: 'AccessControl',
                        params: { category: this.categories[0].alias },
                        query: {
                            ...this.$route.query,
                            categoryId: undefined,
                        },
                    });
                }
            },
        },
    },

    computed: {
        currentUser() {
            const user = this.users.find(({ id }) => id === this.userId);

            return user ? user : this.dummyUser;
        },

        username() {
            const { id, login } = this.currentUser;

            if (!(id && login)) {
                return '~';
            }

            return `#${id} ${login}`;
        },

        noCashboxes() {
            if (!this.cashboxCategories || !this.cashboxes) {
                return true;
            }

            return !(this.cashboxCategories.length && this.cashboxes.length);
        },

        tree() {
            if (this.noCashboxes) {
                return [];
            }

            const { nodes } = new Tree({
                items: this.cashboxes,
                categories: this.cashboxCategories,
            });

            if (!this.categoryId) {
                return nodes
                    .filter(({ treeParent }) => !treeParent);
            }

            const category = nodes
                .find(({ id }) => String(id) === this.categoryId);

            return category.children;
        },

        breadcrumbs() {
            const text = this.categories
                .find(({ alias }) => alias === this.category)
                ?.name;

            const result = [
                {
                    text,
                    query: { categoryId: undefined },
                },
            ];

            if (this.noCashboxes || !this.categoryId) {
                return result;
            }

            const { nodes } = new Tree({
                items: this.cashboxes,
                categories: this.cashboxCategories,
            });

            const category = nodes
                .find(({ id }) => String(id) === this.categoryId);

            if (category.parents) {
                category.parents.forEach(parentId => {
                    const parent = nodes
                        .find(({ id }) => String(id) === parentId);

                    result.splice(1, 0, {
                        text: parent.name ?? parent.title,
                        query: { categoryId: String(parent.id) },
                    });
                });
            }

            result.push({
                text: category.name ?? category.title,
                disabled: true,
                query: { categoryId: String(category.id) },
            });

            return result;
        },
    },

    methods: {
        async getUsers() {
            this.fetching.users = true;

            try {
                this.stats = await this.fetchStats();
                const users = await this.fetchUsers();
                this.records = await this.fetchRecords(users);
                this.users = await this.fillUsers(users);

                this.fetching.users = false;

                return { hasError: false };
            } catch (e) {
                this.$error(e.message);

                this.fetching.users = false;

                return {
                    hasError: true,
                    e,
                };
            }
        },

        async updateCurrentUser() {
            try {
                const route = `/access/${this.category}/users/${this.userId}/records`;
                const records = await getAllPages(this.$apiResponse, route);

                const index = this.users.findIndex(({ id }) => id === this.userId);
                const currentUser = this.users[index];

                this.users.splice(index, 1, {
                    ...currentUser,
                    records,
                });
            } catch (e) {
                this.$error(e.message);
            }
        },

        async getCashboxes() {
            if (!(this.$route.params.category === 'cashbox' && this.noCashboxes)) {
                return;
            }

            this.fetching.cashboxes = true;

            try {
                const [cashboxes, categories] = await Promise.all([
                    getCashboxes(this.$api, this.$apiResponse),
                    getCashboxCategories(this.$api),
                ]);

                this.cashboxes = cashboxes;
                this.cashboxCategories = categories;
            } catch (e) {
                this.$error(e.message);
            }

            this.fetching.cashboxes = false;
        },

        async fetchUsers() {
            try {
                return (await this.$api("/users")).list;
            } catch (e) {
                this.$error(e.message);
            }
        },

        async fetchStats() {
            try {
                return this.$api(`/access/${this.category}/stats`);
            } catch (e) {
                this.$error(e.message);
            }
        },

        async fetchRecords(users) {
            try {
                const usersWithRecords = this.stats.users
                    .map(({ user: { id } }) => id);

                const list = await Promise.all(
                    users.map(async ({ id }) => {
                        const result = {
                            id,
                            records: [],
                        };

                        if (!usersWithRecords.includes(id)) {
                            return result;
                        }

                        const route = `/access/${this.category}/users/${id}/records`;
                        result.records = await getAllPages(this.$apiResponse, route);

                        return result;
                    })
                );

                const result = list
                    .reduce((acc, { id, records }) => ({
                        ...acc,
                        [id]: records,
                    }), {});

                return result;
            } catch (e) {
                this.$error(e.message);
            }
        },

        async fillUsers(users) {
            return users.map((user) => {
                const stats = this.stats.users
                    .find(({ user: { id } }) => id === user.id);

                const records = this.records[user.id];

                if (stats) {
                    return {
                        ...user,
                        records,
                        new: stats.new,
                        current: stats.current,
                    };
                }

                return {
                    ...user,
                    records,
                    new: this.stats.default,
                    current: this.stats.default,
                };
            });
        },

        async updateNew({ body, callback }) {
            const route = `/access/${this.category}/users/${this.userId}/new`;
            this.updateAccess(route, body, callback);
        },

        async updateExisting({ id, body, callback }) {
            const route = `/access/${this.category}/users/${this.userId}/records/${id}`;
            this.updateAccess(route, body, callback);
        },

        async updateAccess(route, body, callback) {
            try {
                await this.$api(route, {
                    method: "POST",
                    body: Object.entries(body)
                        .reduce((acc, [key, value]) => ({
                            ...acc,
                            [key]: Number(!value),
                        }), {}),
                });
                await this.updateCurrentUser();
            } catch (e) {
                this.$error(e.message);
            }

            callback();
        },
    },
};
</script>
