const formatter = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

export const formatNumber = number => formatter.format(number);

export const formatDate = (date) => {
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };

    const dateString = date.toLocaleDateString(undefined, options);

    return `${dateString.charAt(0).toUpperCase()}${dateString.slice(1)}`;
};

export const formatTime = (date) => {
    const options = {
        hour: '2-digit',
        minute:'2-digit',
    };

    return date.toLocaleTimeString(undefined, options);
};
