<template>
    <owner-list
        :pg="pg"
        :sort="sort"
        :dir="dir"
        :flt="flt"
        owner="product"
    />
</template>

<script>
import OwnerList from "@c/owner/List";
export default {
    name: "Cells",
    components: {
        OwnerList,
    },
    props: {
        pg: {
            type: Number,
            required: true,
        },
        sort: {
            type: Number,
            required: true,
        },
        dir: {
            type: Number,
            required: true,
        },
        flt: {
            type: Object,
            required: true,
        },
    },
};
</script>