var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('filter-info-renderless',{attrs:{"filter":_vm.filter,"cashbox-categories":_vm.cashboxCategories,"cashboxes":_vm.cashboxes,"cost-categories":_vm.costCategories,"cost-templates":_vm.costTemplates,"users":_vm.users,"statuses":_vm.statuses,"dateHelpers":_vm.dateHelpers,"groupByList":_vm.groupByList},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var info = ref.info;
return [_c('v-list',{staticClass:"px-4",attrs:{"data-test-id":"filter-stats","data-test-filter":JSON.stringify(_vm.filter),"dense":""}},_vm._l((info),function(ref){
var alias = ref.alias;
var label = ref.label;
var value = ref.value;
var icon = ref.icon;
return _c('v-list-item',{key:alias,staticClass:"px-0",staticStyle:{"min-height":"30px"},attrs:{"dense":""}},[(alias === 'statuses')?_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',{staticClass:"font-weight-regular text-wrap"},[_c('v-icon',{staticClass:"mr-2 my-n1",attrs:{"small":""},domProps:{"textContent":_vm._s(icon)}}),_c('span',{domProps:{"textContent":_vm._s((label + ": "))}}),_vm._l((value),function(ref,index){
var text = ref.text;
var textClass = ref.textClass;
return _c('span',{key:index,class:textClass,domProps:{"textContent":_vm._s(("" + text + (index !== value.length - 1 ? ', ' : '')))}})})],2)],1):(
                            [
                                'cashboxCategories',
                                'cashboxes',
                                'costCategories',
                                'costTemplates' ].includes(alias)
                        )?_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 my-n1",attrs:{"small":""},domProps:{"textContent":_vm._s(icon)}}),_c('span',{staticClass:"font-weight-regular",domProps:{"textContent":_vm._s((label + ":"))}})],1),_vm._l((value),function(row,index){return _c('v-list-item-subtitle',{key:index,staticClass:"font-weight-regular text-wrap",domProps:{"textContent":_vm._s(row)}})})],2):_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',{staticClass:"font-weight-regular text-wrap"},[_c('v-icon',{staticClass:"mr-2 my-n1",attrs:{"small":""},domProps:{"textContent":_vm._s(icon)}}),_c('span',{domProps:{"textContent":_vm._s((label + ": "))}}),_c('span',{staticClass:"text--secondary",domProps:{"textContent":_vm._s(value)}})],1)],1)],1)}),1)]}}])}),_c('div',{staticClass:"d-flex",class:{
                'align-center pr-4': _vm.$isMobile(),
                'pr-2 pt-2': !_vm.$isMobile()
            }},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('report:menu:group-by', $event)}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-format-list-group')}})],1),_c('filter-dialog-save',_vm._g({},_vm.$listeners))],1)],1),(_vm.filterModifier.type)?_c('v-divider'):_vm._e(),_c('v-expand-transition',[(_vm.filterModifier.type)?_c('filter-modifier',_vm._g({attrs:{"filter-modifier":_vm.filterModifier,"cashboxes":_vm.cashboxes,"cost-templates":_vm.costTemplates}},_vm.$listeners)):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }