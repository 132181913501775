<template>
    <v-simple-table
        v-if="report.show"
        dense
    >
        <thead>
            <tr>
                <th
                    v-for="(column, key) in report.head"
                    :key="key"
                    :class="column.class"
                >
                    {{ column.value }}
                </th>
            </tr>
        </thead>

        <tbody>
            <report-table-row
                v-for="(row, index) in report.body"
                :key="index"

                :row="row"

                :cashbox-categories="cashboxCategories"
                :cashboxes="cashboxes"

                :cost-categories="costCategories"
                :cost-templates="costTemplates"

                :filter-modifier="filterModifier"

                v-on="$listeners"
            />
        </tbody>
    </v-simple-table>

    <v-container
        v-else
        fluid
        class="text-center overline"
    >
        <div>Нет данных</div>
        <div class="font-weight-light">За выбранный период</div>
    </v-container>
</template>

<script>
import ReportTableRow from "@c/norton-commander/transactions/ReportTableRow";

export default {
    name: "ReportTable",

    components: {
        ReportTableRow,
    },

    props: {
        report: {
            type: Object,
            required: true,
        },
        cashboxCategories: {
            type: Array,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        costCategories: {
            type: Array,
            required: true,
        },
        costTemplates: {
            type: Array,
            required: true,
        },
        filterModifier: {
            type: Object,
            required: true,
        },
    },
};
</script>
