<template>
    <v-tabs style="border-bottom: solid 1px #2196F3;">
        <v-tab
            v-for="{ name, alias, icon } in tabs"
            :key="alias"
            :to="{
                name: 'AccessControl',
                params: { category: alias },
                query: {
                    ...$route.query,
                    categoryId: undefined,
                },
            }"
            exact-path
        >
            <v-icon
                dense
                class="mr-2"
                v-text="icon"
            />
            {{ name }}
        </v-tab>
    </v-tabs>
</template>

<script>
export default {
    name: "CategoryTabs",

    props: {
        tabs: {
            type: Array,
            required: true,
        },
    },
};
</script>
