<template>
    <div
        :loading="loading"
        :disabled="loading"
    >
        <v-btn
            v-show="hasAccessToConstructor"
            color="primary"
            class="ma-2"
            @click="constructorOpened = true"
        >
            Открыть конструктор
        </v-btn>

        <v-dialog
            v-model="constructorOpened"
            width="auto"
        >
            <v-card>
                <v-card-title>
                    Конструктор расходов
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="6"
                                md="4"
                            >
                                <v-subheader>
                                    <v-icon
                                            @click="toogleCategoryEditor()"
                                        >{{ editor.unlock ? 'mdi-lock-open' : 'mdi-lock' }}
                                    </v-icon>
                                    Категории расходов
                                </v-subheader>
                                <constructor-categories
                                    v-bind:categories="categories"
                                    v-bind:unlock="editor.unlock"
                                    v-on="$listeners"
                                    v-on:onSelectCategory="onSelectCategory"
                                    v-on:onAddSubcategory="onAddSubcategory"
                                    v-on:onEditCategory="onEditCategory"
                                />
                                <v-icon
                                        @click="onAddSubcategory(0)"
                                        title="Добавить категорию"
                                        v-show="editor.unlock"
                                    >mdi-plus
                                </v-icon>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="8"
                            >
                                <constructor-templates
                                    v-show="selectedCategory != null"
                                    v-bind:selectedCategory="selectedCategory"
                                    v-bind:templates="templates"
                                    v-on="$listeners"
                                    v-on:onAddTemplate="onAddTemplate"
                                    v-on:onEditTemplate="onEditTemplate"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        variant="text"
                        @click="$emit('onUpdate'); constructorOpened = false"
                    >
                        Закрыть
                    </v-btn>
                </v-card-actions>
            </v-card>

            <dialog-add-category
                v-bind:dialog="dialogCategory"
                v-on="$listeners"
                v-on:onSaveCategory="saveCategory"
            />

            <dialog-add-template
                v-bind:dialog="dialogTemplate"
                v-on="$listeners"
                v-on:onSaveTemplate="saveTemplate"
            />

        </v-dialog>
    </div>
</template>

<script>
import ConstructorCategories from "@c/norton-commander/costs/ConstructorCategories";
import ConstructorTemplates from "@c/norton-commander/costs/ConstructorTemplates";
import DialogAddCategory from "@c/norton-commander/dialogs/costs-constructor/AddCategory";
import DialogAddTemplate from "@c/norton-commander/dialogs/costs-constructor/AddTemplate";

export default {
    name: "CostConstructor",

    components: {
        ConstructorCategories,
        ConstructorTemplates,
        DialogAddCategory,
        DialogAddTemplate,
    },

    data: () => ({
        loading: false,
        denyAccess: false,
        constructorOpened: false,
        categories: [],
        templates: [],
        selectedCategory: null,
        editor: {
            unlock: false,
        },
        dialogCategory: {
            parent: {},
            item: {},
            opened: false,
        },
        dialogTemplate: {
            parent: {},
            item: {},
            opened: false,
        },
    }),

    mounted() {
        this.getCategories();
    },

    computed: {
        hasAccessToConstructor() {
            if (this.denyAccess) {
                return false;
            }
            const costsOfIpId = 15;
            if (this.getCategoryById(costsOfIpId, this.categories) == null) {
                return false;
            }
            return true;
        },
    },

    methods: {
        async getCategories() {
            if (this.denyAccess) {
                return;
            }
            this.loading = true;
            try {
                const result = await this.$api("/finance/costs/categories");
                this.fillCategories(result);
                this.getTemplates();
            } catch (e) {
                if (e.httpStatus == 403) {
                    this.denyAccess = true;
                } else {
                    this.$error(e.message);
                }
            }
            this.loading = false;
        },

        async getTemplates() {
            if (this.denyAccess) {
                return;
            }
            this.loading = true;
            try {
                const result = await this.$api("/finance/costs/templates");
                this.fillTemplates(result);
            } catch (e) {
                if (e.httpStatus == 403) {
                    this.denyAccess = true;
                } else {
                    this.$error(e.message);
                }
            }
            this.loading = false;
        },

        fillCategories(categories) {
            for (const category of categories) {
                category['children'] = [];
                category['templates'] = [];
                if (category.treeParent == null) {
                    this.categories.push(category);
                }
            }
            for (const category of categories) {
                if (category.treeParent == null) {
                    continue;
                }
                const parent = this.getCategoryById(
                    category.treeParent,
                    this.categories
                );
                if (parent == null) {
                    continue;
                }
                category['children'] = [];
                category['templates'] = [];
                parent.children.push(category);
            }
        },

        fillTemplates(templates) {
            for (const template of templates) {
                const category = this.getCategoryById(
                    template.category,
                    this.categories
                );
                if (category == null) {
                    continue;
                }
                template['currency'] = '₽';
                category.templates.push(template);
            }
        },

        getCategoryById(id, categories) {
            for (const category of categories) {
                if (category.id == id) {
                    return category;
                }
                if (!category.children.length) {
                    continue;
                }
                const cat = this.getCategoryById(id, category.children);
                if (cat != null) {
                    return cat;
                }
            }
            return null;
        },

        getTemplateById(id, category) {
            for (const template of category.templates) {
                if (template.id == id) {
                    return template;
                }
            }
            return null;
        },

        onSelectCategory(id) {
            const category = this.getCategoryById(id, this.categories);
            this.selectedCategory = category;
            this.templates.splice(0, this.templates.length);
            if (category == null) {
                return;
            }
            for (const template of category.templates) {
                this.templates.push(template);
            }
        },

        onAddSubcategory(id) {
            const newItem = {
                id: null,
                name: 'Новая категория',
            };
            this.dialogCategory.item = Object.assign(
                {},
                this.dialogCategory.item,
                newItem,
            );
            this.dialogCategory.parent = this.getCategoryById(id, this.categories);
            this.dialogCategory.opened = true;
        },

        onEditCategory(id) {
            const category = this.getCategoryById(id, this.categories);
            const parent = this.getCategoryById(category.treeParent, this.categories);
            this.dialogCategory.item = category;
            this.dialogCategory.parent = parent;
            this.dialogCategory.opened = true;
        },

        onAddTemplate(parentId) {
            const newItem = {
                id: null,
                name: 'Новый расход',
            };
            this.dialogTemplate.item = Object.assign(
                {},
                this.dialogTemplate.item,
                newItem,
            );
            this.dialogTemplate.parent = this.getCategoryById(parentId, this.categories);
            this.dialogTemplate.opened = true;
        },

        onEditTemplate(id, parentId) {
            const parent = this.getCategoryById(parentId, this.categories);
            const template = this.getTemplateById(id, parent);
            this.dialogTemplate.item = template;
            this.dialogTemplate.parent = parent;
            this.dialogTemplate.opened = true;
        },

        async saveCategory(dialog) {
            try {
                const method = dialog.item.id == null ? 'POST': 'PATCH';
                const body = {
                    id: dialog.item.id,
                    treeParent: dialog.parent != null ? dialog.parent.id : null,
                    name: dialog.item.name,
                };
                const result = await this.$api("/finance/costs/categories", {
                    method: method,
                    body: body
                });
                if (result.id != null) {
                    this.updateCategory(result);
                }
            } catch (e) {
                this.$error(e.message);
            }
        },

        updateCategory(newCategory) {
            let category = this.getCategoryById(newCategory.id, this.categories);
            if (category != null) {
                category = Object.assign(
                    {},
                    category,
                    newCategory,
                );
            } else {
                const parent = this.getCategoryById(newCategory.treeParent, this.categories);
                newCategory['children'] = [];
                newCategory['templates'] = [];
                if (parent != null) {
                    parent.children.push(newCategory);
                } else {
                    this.categories.push(newCategory);
                }
            }
        },

        toogleCategoryEditor() {
            this.editor.unlock = !this.editor.unlock;
        },

        async saveTemplate(dialog) {
            try {
                const method = dialog.item.id == null ? 'POST': 'PATCH';
                const body = {
                    id: dialog.item.id,
                    category: dialog.parent != null ? dialog.parent.id : null,
                    name: dialog.item.name,
                    amount: dialog.item.amount,
                };
                const result = await this.$api("/finance/costs/templates", {
                    method: method,
                    body: body
                });
                if (result.id != null) {
                    this.updateTemplate(result);
                }
            } catch (e) {
                this.$error(e.message);
            }
        },

        updateTemplate(newTemplate) {
            const category = this.getCategoryById(newTemplate.category, this.categories);
            let template = this.getTemplateById(newTemplate.id, category);
            if (template != null) {
                template = Object.assign(
                    {},
                    template,
                    newTemplate,
                );
            } else {
                category.templates.push(newTemplate);
            }
            this.onSelectCategory(category.id);
        },
    },
};
</script>
