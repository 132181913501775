var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"w-full h-full d-flex flex-column"},[_c('v-sheet',[_c('calendar-toolbar',_vm._g({attrs:{"type":_vm.type,"types":_vm.types,"title":_vm.title},on:{"calendar:prev":function($event){return _vm.$refs.calendar.prev()},"calendar:next":function($event){return _vm.$refs.calendar.next()},"calendar:set-today":function($event){_vm.focus = ''}}},_vm.$listeners))],1),_c('v-divider'),_c('div',{staticClass:"pa-1 flex-grow-1 overflow-auto"},[_c('v-calendar',{ref:"calendar",attrs:{"type":_vm.type,"events":_vm.eventsCalculated,"show-week":"","color":"primary","event-color":_vm.getEventColor,"weekdays":[1, 2, 3, 4, 5, 6, 0]},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"change":_vm.updateRange},model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":"","max-height":"500","max-width":"30rem"},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"color":"grey lighten-4","flat":""}},[_c('v-toolbar',{attrs:{"color":_vm.selectedEvent.color,"dark":""}},[_c('v-toolbar-title',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.name)}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('event:dialog:edit', _vm.selectedEvent)}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-calendar-edit')}})],1)],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',{staticClass:"bg-transparent pb-0",attrs:{"light":""}},[_c('v-list-item',[_c('event-info',{attrs:{"event":_vm.selectedEvent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var info = ref.info;
return [_c('v-list-item-content',_vm._l((info),function(ref){
var label = ref.label;
var value = ref.value;
return _c('v-list-item-title',{key:label,staticClass:"text-wrap"},[(label)?_c('span',{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s((label + ": "))}}):_vm._e(),_c('span',{domProps:{"textContent":_vm._s(value)}})])}),1)]}}])})],1)],1),_c('v-divider',{attrs:{"light":""}}),_c('v-list',{staticClass:"bg-transparent py-0",attrs:{"dense":"","light":""}},_vm._l((_vm.selectedEvent.transactions),function(transaction,transactionIndex){return _c('v-list-item',{key:transactionIndex},[_c('transaction-info',{attrs:{"transaction":transaction,"cashboxes":_vm.cashboxes,"cashbox-categories":_vm.cashboxCategories,"cost-templates":_vm.costTemplates,"cost-elements":_vm.costElements,"cost-categories":_vm.costCategories},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var info = ref.info;
return [_c('v-list-item-content',[_vm._l((info),function(ref){
var alias = ref.alias;
var value = ref.value;
var label = ref.label;
return [(alias === 'operations')?_vm._l((value),function(ref,index){
var path = ref.path;
var amount = ref.amount;
var amountClass = ref.amountClass;
return _c('v-list-item-subtitle',{key:("operation-" + index),staticClass:"text-wrap"},[_c('span',{domProps:{"textContent":_vm._s(path)}}),_c('span',{staticClass:"text-no-wrap ml-2",class:amountClass,domProps:{"textContent":_vm._s(amount)}})])}):[_c('v-list-item-subtitle',{key:alias,domProps:{"textContent":_vm._s((label + ":"))}}),_vm._l((value),function(row,index){return _c('v-list-item-subtitle',{key:(alias + "-" + index),staticClass:"text-wrap font-weight-light"},[_c('span',{domProps:{"textContent":_vm._s(row)}})])})]]})],2)]}}],null,true)})],1)}),1)],1),_c('v-divider',{attrs:{"light":""}}),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.selectedOpen = false}}},[_vm._v(" Закрыть ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }