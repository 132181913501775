<template>
    <v-text-field :value="value" @input="$emit('input', $event)" dense />
</template>

<script>
import ProductOwnerFilterBase from "./Base";
export default {
    name: "ProductOwnerFilterString",
    extends: ProductOwnerFilterBase,
};
</script>