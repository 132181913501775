<template>
    <v-card :loading="loading" :disabled="loading">
        <v-toolbar dense flat>
            <v-spacer />
            <v-btn icon color="primary" @click="createUser">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-toolbar>
        <v-data-table
            :headers="headers"
            disable-pagination
            hide-default-footer
            :items="list"
        >
            <template #[`item.admin`]="{ item }">
                <v-switch
                    :input-value="item.admin"
                    :readonly="checkId(item.id)"
                    @change="changeAdmin(item.id, $event)"
                />
            </template>
            <template #[`item.settings`]="{ item: { id } }">
                <v-btn
                    icon
                    color="error"
                    :disabled="checkId(id)"
                    @click="changePassword(id)"
                >
                    <v-icon>mdi-lock</v-icon>
                </v-btn>
                <v-btn
                    icon
                    color="error"
                    :disabled="checkId(id)"
                    @click="loginAs(id)"
                >
                    <v-icon>mdi-login</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
export default {
    name: "Users",
    data: () => ({
        loading: false,
        list: [],
        current: 1,
    }),
    computed: {
        headers() {
            return [
                { text: "#", value: "id" },
                { text: this.$t("register.login"), value: "login" },
                { text: this.$t("users.admin"), value: "admin" },
                { text: "", value: "settings", sortable: false },
            ];
        },
    },
    mounted() {
        this.getList();
    },
    methods: {
        async changePassword(id) {
            const body = {};
            const list = {
                new: this.$t("register.password"),
                repeat: this.$t("register.password2"),
            };
            for (const k in list) {
                body[k] = await this.$prompt({
                    title: list[k],
                    rules: [(v) => !!v || this.$t("form.required")],
                    persistent: true,
                    textField: {
                        type: "password",
                    },
                });
                if (!body[k]) {
                    return;
                }
            }

            this.loading = true;
            try {
                await this.$api(`/users/${id}/password`, {
                    method: "PATCH",
                    body,
                });
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
        async changeAdmin(id, dir) {
            for (let l of this.list) {
                if (l.id == id) {
                    l.admin = dir;
                    if (!(await this.$confirm())) {
                        l.admin = !dir;
                        return;
                    }
                    break;
                }
            }
            this.loading = true;
            try {
                await this.$api(`/users/${id}/admin`, {
                    method: "PATCH",
                    body: {
                        dir,
                    },
                });
            } catch (e) {
                this.$error(e.message);
                for (let l of this.list) {
                    if (l.id == id) {
                        l.admin = !dir;
                        break;
                    }
                }
            }
            this.loading = false;
        },
        async createUser() {
            const login = await this.$prompt({
                title: this.$t("register.login"),
                rules: [(v) => !!v || this.$t("form.required")],
                persistent: true,
            });
            if (!login) {
                return;
            }
            this.loading = true;
            try {
                const { password } = await this.$api("/users", {
                    method: "POST",
                    body: {
                        login,
                    },
                });
                this.$prompt({
                    title: this.$t("users.pass"),
                    value: password,
                });
                this.getList();
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
        checkId(id) {
            return id == this.current;
        },
        async getList() {
            this.loading = true;
            try {
                const { list, current } = await this.$api("/users");
                this.list = list;
                this.current = current;
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },

        async loginAs(userId) {
            this.loading = true;
            try {
                const data = await this.$api("/auth/loginas", {
                    method: "POST",
                    body: {
                        userId: userId
                    }
                });
                if (typeof data.token !== 'undefined') {
                    this.$apiLogin(data.token);
                }
                if (typeof data.message !== 'undefined') {
                    this.$error(data.message);
                }
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
    },
};
</script>