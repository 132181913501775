<template>
    <div>
        <v-dialog v-model="confirm" width="450">
            <v-card :loading="loading" :disabled="loading">
                <v-card-title>{{ $t("main.warning") }}</v-card-title>
                <v-card-text>
                    <div>{{ $t("category.remove.cats") }} {{ stats.cats }}</div>
                    <div>
                        {{ $t("category.remove.products") }}
                        {{ stats.products }}
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text color="error" @click="confirm = false">
                        {{ $t("main.close") }}
                    </v-btn>
                    <v-btn text color="primary" @click="proceed">
                        {{ $t("main.proceed") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="show">
            <v-card :loading="loading" :disabled="loading">
                <v-card-title>{{ $t("category.remove.title") }}</v-card-title>
                <v-card-text>
                    <v-autocomplete
                        hide-no-data
                        :items="items"
                        @update:search-input="search"
                        @change="remove"
                        v-model="selected"
                        :item-disabled="(i) => ids.includes(i.value)"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="error" text @click="show = false">
                        {{ $t("main.close") }}
                    </v-btn>
                    <v-btn text color="error" @click="remove(null)">
                        {{ $t("category.remove.destroy") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "CatalogCategoryRemove",
    data: () => ({
        loading: false,
        show: false,
        confirm: false,
        targetField: null,
        res: null,
        ids: [],
        items: [],
        selected: null,
        stats: {
            cats: 1,
            products: 0,
        },
    }),
    methods: {
        search(s) {
            clearTimeout(this.search.t);
            if (!s || !s.length) {
                return;
            }
            this.search.t = setTimeout(async () => {
                this.loading = true;
                try {
                    if (!this.targetField) {
                        const fields = await this.$api(`/category/fields`);
                        this.targetField = fields
                            .filter((f) => f.type == "catname")
                            .pop();
                    }
                    const query = new URLSearchParams();
                    query.append("filter[]", `${this.targetField.id}|∋|${s}`);
                    const r = await this.$apiResponse(
                        `/entity/category?${query.toString()}`
                    );
                    let data = [];
                    if (r.status == 200) {
                        ({ data } = await r.json());
                    }
                    this.items = data.map((d) => ({
                        value: d.id,
                        text: d.values
                            .filter((v) => v.field == this.targetField.id)
                            .pop().value,
                    }));
                } catch (e) {
                    this.$error(e.message);
                }
                this.loading = false;
            }, 500);
        },
        async remove(target = null) {
            this.loading = true;
            try {
                const query = new URLSearchParams();
                if (target) {
                    query.append("move", target);
                }
                for (let id of this.ids) {
                    await this.$api(`/entity/category/${id}?${query.toString()}`, {
                        method: "DELETE",
                    });
                }
                this.show = false;
                this.confirm = false;
                this.res(true);
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
        proceed() {
            if (this.stats.products > 0) {
                this.confirm = false;
                this.show = true;
            } else {
                this.remove();
            }
        },
        async open(...ids) {
            this.ids = ids;
            this.loading = true;
            this.items = [];
            this.selected = null;
            this.confirm = true;
            try {
                this.stats.cats = 1;
                this.stats.products = 0;
                for (let id of ids) {
                    const { cats, products } = await this.$api(
                        `/entity/category/${id}/stats`
                    );
                    this.stats.cats += cats;
                    this.stats.products += products;
                }
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
            return new Promise((res) => (this.res = res));
        },
    },
};
</script>
