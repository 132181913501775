var permissions = null;
export default async (api) => {

    if (permissions === null) {
        [ permissions ] = await Promise.all([
            api("/permissions"),
        ]);
    }

    return permissions;
};
