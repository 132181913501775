<template>
    <v-card
        :disabled="disabled"
        class="w-full h-full max-h-full d-flex flex-column bg-transparent"
    >
        <v-list
            :color="`${$vuetify.theme.isDark ? 'primary' : 'primary lighten-1'}`"
            dark
            elevation="2"
            class="py-0 mt-4 mb-2 mx-2"
        >
            <v-list-item @click="dialog = true">
                <v-list-item-action>
                    <v-icon v-text="'mdi-account'" />
                </v-list-item-action>

                <v-list-item-content>
                    <v-list-item-title>
                        <slot name="subtitle" />
                    </v-list-item-title>

                    <v-list-item-subtitle v-text="'Выбранный пользователь'" />
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-dialog
            v-model="dialog"
            fullscreen
            transition="slide-x-transition"
        >
            <v-card class="h-full max-h-full d-flex flex-column">
                <div
                    ref="scrollable"
                    class="flex-grow-1 overflow-auto"
                    style="min-height: 0px;"
                >
                    <slot name="user-list" />
                </div>

                <v-divider />

                <v-container>
                    <v-row>
                        <v-col>
                            <v-btn
                                :outlined="$vuetify.theme.isDark"
                                block
                                large
                                @click="dialog = false"
                            >
                                <v-icon
                                    v-text="'mdi-arrow-left'"
                                    left
                                />
                                Закрыть
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>

        <v-sheet
            elevation="2"
            class="mt-2 mx-2"
        >
            <slot name="tabs" />
        </v-sheet>

        <slot name="user-info" />
    </v-card>
</template>

<script>
export default {
    name: "MobileLayout",

    props: {
        busy: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        dialog: false,
    }),

    watch: {
        dialog() {
            if (!this.$refs.scrollable) {
                return;
            }

            this.$refs.scrollable.scrollTo({ top: 0 });
        },

        '$route.query.userId': {
            handler() {
                this.dialog = false;
            },
        },
    },
};
</script>
