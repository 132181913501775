<template>
    <adaptive-card
        ref="wrapper"

        :has-search="false"
        :busy="busy.transactions || busy.report"
        title="Транзакции"

        :drag-scroll-area-visibility="dragScrollAreaVisibility"
        :stop-scroll="stopScroll"

        :index="2"

        v-on="$listeners"
    >
        <template #header-action-right>
            <div class="d-flex ml-4 my-n1">
                <v-icon
                    :disabled="!isFilterActive"
                    @click.stop="$emit('filter:clear')"
                >
                    mdi-filter-remove-outline
                </v-icon>

                <v-icon
                    data-test-id="saved-filters-list-dialog-button-open"

                    class="mx-4"
                    @click.stop="$emit('filter:dialog:saved-filters')"
                >
                    mdi-filter-multiple-outline
                </v-icon>

                <v-icon
                    data-test-id="edit-filter-dialog-button-open"

                    :color="isFilterActive ? 'blue darken-2' : 'none'"
                    @click.stop="$emit('filter:dialog:edit')"
                >
                    mdi-filter-outline
                </v-icon>
            </div>
        </template>

        <template #subheader>
            <filter-info
                :filter="filter"
                :filter-modifier="filterModifier"

                :cashbox-categories="cashboxCategories"
                :cashboxes="cashboxes"

                :cost-categories="costCategories"
                :cost-templates="costTemplates"

                :users="users"
                :statuses="statuses"
                :dateHelpers="dateHelpers"
                :groupByList="groupByList"

                v-on="$listeners"
            />

            <v-divider />

            <v-btn-toggle
                v-model="tab"
                mandatory
                tile
                style="width: 100%;"
            >
                <v-container
                    fluid
                    class="pa-0"
                >
                    <v-row no-gutters>
                        <v-col>
                            <v-btn
                                text
                                block
                                small
                                depressed
                                class="rounded-0"
                            >
                                <v-icon
                                    left
                                    small
                                >
                                    mdi-currency-rub
                                </v-icon>

                                <span>Транзакции</span>
                            </v-btn>
                        </v-col>

                        <v-col>
                            <v-btn
                                text
                                block
                                small
                                depressed
                                class="rounded-0"
                            >
                                <v-icon
                                    left
                                    small
                                >
                                    mdi-table
                                </v-icon>

                                <span>Таблица</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-btn-toggle>

            <v-divider v-if="showPagination" />

            <v-pagination
                v-if="showPagination"
                v-model="page"
                :length="pageCount"
            />

            <v-divider />
        </template>

        <template #body>
            <v-tabs-items
                v-model="tab"
                touchless
            >
                <v-tab-item>
                    <v-card
                        flat
                        style="min-height: 10px;"
                    >
                        <v-list
                            dense
                            class="pa-0"
                        >
                            <transition-group name="list-transition">
                                <template v-for="transaction in transactions">
                                    <transaction
                                        :key="`${transaction.id}-transaction`"

                                        :transaction="transaction"

                                        :cashbox-categories="cashboxCategories"
                                        :cashboxes="cashboxes"

                                        :cost-categories="costCategories"
                                        :cost-templates="costTemplates"
                                        :cost-elements="costElements"

                                        :statuses="statuses"
                                        :users="users"
                                        :layout="layout"

                                        @transaction-being-processed="openProcessTransactionDialog"
                                        @show-full-link="showFullLink"

                                        v-on="$listeners"
                                    />

                                    <v-divider :key="`${transaction.id}-divider`" />
                                </template>
                            </transition-group>
                        </v-list>

                        <v-divider v-if="showPagination" />

                        <v-pagination
                            v-if="showPagination"
                            v-model="page"
                            :length="pageCount"
                        />
                    </v-card>
                </v-tab-item>

                <v-tab-item>
                    <v-card
                        flat
                    >
                        <report-table
                            :report="report"

                            :cashbox-categories="cashboxCategories"
                            :cashboxes="cashboxes"

                            :cost-categories="costCategories"
                            :cost-templates="costTemplates"

                            :filter-modifier="filterModifier"

                            v-on="$listeners"
                        />
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </template>
    </adaptive-card>
</template>

<script>
import AdaptiveCard from '@c/norton-commander/layouts/AdaptiveCard';
import FilterInfo from "@c/norton-commander/transactions/FilterInfo";
import ReportTable from "@c/norton-commander/transactions/ReportTable";
import Transaction from "@c/norton-commander/transactions/list/TransactionComponent";

export default {
    name: "TransactionList",

    components: {
        AdaptiveCard,
        FilterInfo,
        ReportTable,
        Transaction,
    },

    props: {
        filter: {
            type: Object,
            required: true,
        },
        filterModifier: {
            type: Object,
            required: true,
        },
        cashboxCategories: {
            type: Array,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        costCategories: {
            type: Array,
            required: true,
        },
        costTemplates: {
            type: Array,
            required: true,
        },
        costElements: {
            type: Array,
            required: true,
        },
        users: {
            type: Object,
            required: true,
        },
        statuses: {
            type: Object,
            required: true,
        },
        dateHelpers: {
            type: Array,
            required: true,
        },
        groupByList: {
            type: Array,
            required: true,
        },
        isFilterActive: {
            type: Boolean,
            default: false,
        },
        doUpdate: {
            type: Number,
            default: 0,
        },
        dragScrollAreaVisibility: {
            type: Boolean,
            default: false,
        },
        stopScroll: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        denyAccess: false,

        busy: {
            transactions: false,
            report: false,
        },

        report: {
            show: false,
            head: [],
            body: [],
        },

        pagination: {
            current: 1,
            hasNext: false,
            onPage: null,
        },
        visitedPages: [],
        transactions: [],

        tab: null,
        page: 1,
        lastFilter: '',

        width: 0,
        resizeObserver: {},
    }),

    watch: {
        filter: {
            handler(){
                if (this.$refs.wrapper && this.tab === 0) {
                    this.$refs.wrapper.scrollTop();
                }

                this.page = 1;
                this.visitedPages = [];
            },
            deep: true,
        },

        filterModifier: {
            handler(){
                this.tab = 0;

                if (this.$refs.wrapper) {
                    this.$refs.wrapper.scrollTop();
                }

                this.page = 1;
                this.visitedPages = [];
            },
            deep: true,
        },

        doUpdate: function() {
            this.getTransactions(true);
            this.getReport();
        },

        page: function() {
            if (this.$refs.wrapper) {
                this.$refs.wrapper.scrollTop();
            }

            this.getTransactions(true);
        },

        tab: function() {
            if (this.$refs.wrapper) {
                this.$refs.wrapper.scrollTop();
            }
        },
    },

    mounted() {
        this.resizeObserver = new ResizeObserver(this.onResize);
        this.resizeObserver.observe(this.$refs.wrapper.$el);
    },

    beforeDestroy() {
        this.resizeObserver.disconnect();
    },

    computed: {
        filterString() {
            return this.getFilterString(this.filter);
        },

        filterStringModified() {
            const filter = JSON.parse(JSON.stringify(this.filter));

            if (this.filterModifier.type === 'cashbox') {
                filter.cashboxes = [Number(this.filterModifier.value)];
            }

            if (this.filterModifier.type === 'costs.template') {
                filter.costTemplates = [Number(this.filterModifier.value)];
            }

            if (this.filterModifier.type === 'processingDate') {
                filter.processingDate = this.filterModifier.value.split(',');
                filter.processingDateHelpers = null;
            }

            if (this.filterModifier.type === 'transactionId') {
                filter.transactionId = [Number(this.filterModifier.value)];
            }

            return this.getFilterString(filter);
        },

        showPagination() {
            const moreThenOnePage = this.pageCount > 1 || this.pagination.hasNext;
            const onTransactionsTab = this.tab === 0;
            return moreThenOnePage && onTransactionsTab;
        },

        pageCount() {
            const count = this.visitedPages.length;
            const hasNext = this.pagination.hasNext;
            const onLastPage = this.page === Math.max(...this.visitedPages);

            if (hasNext && onLastPage) {
                return count + 1;
            }

            return count;
        },

        layout() {
            return this.width > 500 ? 'vertical' : 'horizontal';
        },
    },

    methods: {
        async getTransactions(withClear = false) {
            if (this.denyAccess) {
                return;
            }

            this.busy.transactions = true;
            try {
                const transactions = await this.$api(`/finance/transaction${this.filterStringModified}&page=${this.page}`);

                if (withClear) {
                    this.transactions.splice(0, this.transactions.length);
                }
                this.fillTransactions(transactions);

                this.pagination = transactions.pagination;
                if (!this.visitedPages.includes(this.page)) {
                    this.visitedPages.push(this.page);
                }
            } catch (e) {
                this.busy.transactions = false;
                if (e.httpStatus == 403) {
                    this.denyAccess = true;
                }
            }
            this.busy.transactions = false;
        },

        fillTransactions(transactions) {
            for (const transaction of transactions.list) {
                for (let oIndex in transaction.operations) {
                    transaction.operations[oIndex].currency = '₽';
                }
                transaction['links'] = [];
                this.transactions.push(transaction);
            }

            for (const link of transactions.links) {
                for (const tIndex in this.transactions) {
                    if (this.transactions[tIndex].id != link.transaction) {
                        continue;
                    }
                    link['showFull'] = link.name.length <= 50;
                    this.transactions[tIndex].links.push(link);
                }
            }
        },

        async getReport() {
            if (this.denyAccess) {
                return;
            }

            const newFilter = this.filterString;
            let isNowFilterWithLiveMode = false;
            if (Array.isArray(this.filter.processingDateHelpers)) {
                if ((this.filter.processingDateHelpers.length == 1)
                    && (this.filter.processingDateHelpers[0] == '-0d')) {
                    isNowFilterWithLiveMode = true;
                }
            }

            if ((newFilter == this.lastFilter) && !isNowFilterWithLiveMode) {
                return;
            }

            this.busy.report = true;
            this.lastFilter = newFilter;
            try {
                const report = await this.$api("/finance/transaction/filter/report" + this.filterString);
                this.fillReport(report);
            } catch (e) {
                this.busy.report = false;
            }
            this.busy.report = false;
        },

        fillReport(report) {
            this.report.head.splice(0, this.report.head.length);
            this.report.body.splice(0, this.report.body.length);
            for (const column of report.head) {
                this.report.head.push(column);
            }
            for (const row of report.body) {
                this.report.body.push(row);
            }
            this.report.show = report.active && this.report.body.length;
        },

        getFilterString(filter) {
            const result = [];

            if (filter.statuses.length) {
                result.push("filter%5B%5D=status%7C%3D%7C" + filter.statuses.join('%3B'));
            }

            if (filter.cashboxes.length) {
                result.push("filter%5B%5D=cashBox%7C%3D%7C" + filter.cashboxes.join('%3B'));
            }

            if (filter.userWhoCreated.length) {
                result.push("filter%5B%5D=userWhoCreated%7C%3D%7C" + filter.userWhoCreated.join('%3B'));
            }

            if (filter.userWhoProcessed.length) {
                result.push("filter%5B%5D=userWhoProcessed%7C%3D%7C" + filter.userWhoProcessed.join('%3B'));
            }

            if (filter.creationDate != null) {
                result.push("filter%5B%5D=creationDate%7C∩%7C" + this.getDateWithHMS(filter.creationDate));
            }

            if (filter.processingDateHelpers !== null) {
                result.push("filter%5B%5D=processingDate%7C∩%7C" + this.getDateWithHMSFromHelper(filter.processingDateHelpers));
            } else if (filter.processingDate !== null) {
                result.push("filter%5B%5D=processingDate%7C∩%7C" + this.getDateWithHMS(filter.processingDate));
            }

            if (filter.costTemplates.length) {
                result.push("filter%5B%5D=costsTemplates%7C%3D%7C" + filter.costTemplates.join('%3B'));
            }

            if (filter.siteTransactions.length) {
                result.push("filter%5B%5D=siteTransactions%7C%3D%7C" + filter.siteTransactions.join('%3B'));
            }

            if (filter.siteSplits.length) {
                result.push("filter%5B%5D=siteSplits%7C%3D%7C" + filter.siteSplits.join('%3B'));
            }

            if (filter.siteDeliveries.length) {
                result.push("filter%5B%5D=siteDeliveries%7C%3D%7C" + filter.siteDeliveries.join('%3B'));
            }

            if ('operationAmount' in filter) {
                const from = filter.operationAmount.from;
                const to = filter.operationAmount.to;
                if (from !== '' && to !== '') {
                    result.push(`filter%5B%5D=operationAmount%7C∩%7C${from}%7C${to}`);
                } else if (from !== '') {
                    result.push(`filter%5B%5D=operationAmount%7C≥%7C${from}`);
                } else if (to !== '') {
                    result.push(`filter%5B%5D=operationAmount%7C≤%7C${to}`);
                }
            }

            if (filter.transactionId && filter.transactionId.length) {
                result.push("filter%5B%5D=transactionId%7C%3D%7C" + filter.transactionId.join('%3B'));
            }

            if (!result.length) {
                return '';
            }

            if (filter.group.length) {
                result.push('group=' + filter.group);
            }

            return '?' + result.join('&');
        },

        getDateWithHMS(aDate) {
            if (aDate.length == 2) {
                aDate = this.sortDate(aDate);
                return aDate[0] + '%2000:00:00%7C' + aDate[1] + '%2023:59:59';
            }
            return aDate[0] + '%2000:00:00%7C' + aDate[0] + '%2023:59:59';
        },

        getDateWithHMSFromHelper(aDate) {
            if (aDate.length == 2) {
                aDate = [
                    this.getRelativeDate(aDate[0]),
                    this.getRelativeDate(aDate[1])
                ];
            } else {
                aDate = [
                    this.getRelativeDate(aDate[0])
                ];
            }
            return this.getDateWithHMS(aDate);
        },

        getRelativeDate(date) {
            let curDate = new Date;
            date = String(date);
            if (date.substring(date.length - 1) === 'd') {
                let delte = Number(date.substring(0, date.length - 1));
                curDate.setDate(curDate.getDate() + delte);
            }
            if (date.substring(date.length - 1) === 'm') {
                let delte = Number(date.substring(0, date.length - 1));
                curDate.setMonth(curDate.getMonth() + delte);
            }
            const dateStr = [
                curDate.getFullYear(),
                String(curDate.getMonth() + 1).padStart(2, '0'),
                String(curDate.getDate()).padStart(2, '0'),
            ].join('-');

            return dateStr;
        },

        sortDate(aDate) {
            const dateNumber1 = Number(aDate[0].split('-').join(''));
            const dateNumber2 = Number(aDate[1].split('-').join(''));
            if (dateNumber1 <= dateNumber2) {
                return aDate;
            }
            return [aDate[1], aDate[0]];
        },

        showFullLink(linkId) {
            for (const transaction of this.transactions) {
                for (const link of transaction.links) {
                    if (link.id == linkId) {
                        link.showFull = true;
                        return;
                    }
                }
            }
        },

        openProcessTransactionDialog({ id, type }) {
            const transactionIndex = this.transactions.findIndex(e => e.id === id);

            const payload = {
                transaction: this.transactions[transactionIndex],
                type,
            };

            this.$emit('transaction:dialog:process', payload);
        },

        onResize($event) {
            const entry = $event[0];
            const { contentRect: { width } } = entry;
            this.width = width;
        }
    },
};
</script>

<style scoped lang="scss">
.list-transition-enter-active,
.list-transition-leave-active {
    transition: background-color 3s ease;
}

.theme--light {
    .list-transition-enter,
    .list-transition-leave-to {
        background-color: #90CAF9; /** blue lighten-3 */
    }
}

.theme--dark {
    .list-transition-enter,
    .list-transition-leave-to {
        background-color: #0D47A1; /** blue darken-4 */
    }
}
</style>
