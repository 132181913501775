<script>
import ProductOwnerFilterBase from "./Base";
export default {
    name: "ProductOwnerFilterBaseInterval",
    extends: ProductOwnerFilterBase,
    data: () => ({
        interval: [null, null],
    }),
    watch: {
        "head.operation"(next, prev) {
            if (next == "∩" || prev == "∩") {
                this.$emit("input", null);
            }
        },
        interval(v) {
            if (v[0] && v[1]) {
                this.$emit("input", `${v[0]}|${v[1]}`);
            }
        },
        value: {
            immediate: true,
            handler(v) {
                if (this.head.operation == "∩") {
                    if (v) {
                        this.interval = v.split("|");
                    } else {
                        this.interval = [null, null];
                    }
                }
            },
        },
    },
};
</script>
