export default [
    {
        id: '-0d',
        value: ['-0d'],
        name: 'Сегодня',
        icon: 'mdi-calendar-today',
    },
    {
        id: '-1d',
        value: ['-1d'],
        name: 'Вчера',
        icon: 'mdi-calendar-today',
    },
    {
        id: '-7d,-0d',
        value: ['-7d', '-0d'],
        name: 'За неделю',
        icon: 'mdi-calendar-week',
    },
    {
        id: '-1m,-0d',
        value: ['-1m', '-0d'],
        name: 'За месяц',
        icon: 'mdi-calendar-month',
    },
];
