<template>
    <v-card
        :disabled="disabled"
        class="w-full h-full max-h-full d-flex flex-column bg-transparent"
    >
        <v-card-title>Управление правами доступа</v-card-title>

        <v-card-subtitle>
            <slot name="subtitle" />
        </v-card-subtitle>

        <v-divider />

        <div
            class="flex-grow-1"
            style="min-height: 0;"
        >
            <div class="h-full d-flex">
                <div
                    class="h-full overflow-y-scroll"
                    :style="{
                        width: '35rem',
                        borderRight: 'solid #2196F3',
                    }"
                >
                    <slot name="user-list" />
                </div>

                <div class="w-full h-full overflow-y-scroll">
                    <slot name="tabs" />

                    <slot name="user-info" />
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
export default {
    name: "DesktopLayout",

    props: {
        busy: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
