var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"disable-pagination":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header.flags",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(header.text))]),(!_vm.$vuetify.breakpoint.mobile)?_c('flag-legend',{attrs:{"perks":_vm.perks}}):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[(_vm.$vuetify.breakpoint.mobile)?_c('flag-legend',{attrs:{"perks":_vm.perks}}):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$emit('add')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("fields.list.add"))+" ")],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var name = ref.item.name;
return [_vm._v(" "+_vm._s(name)+" ")]}},{key:"item.type",fn:function(ref){
var type = ref.item.type;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-"+_vm._s(_vm.typeById(type).icon))]),_c('span',{staticClass:"one-line"},[_vm._v(_vm._s(_vm.typeById(type).name))])]}},{key:"item.flags",fn:function(ref){
var perks = ref.item.perks;
return _vm._l((perks),function(p){return _c('v-icon',{key:p,attrs:{"title":_vm.perkById(p).name}},[_vm._v(" mdi-"+_vm._s(_vm.perkById(p).icon)+" ")])})}},{key:"item.actions",fn:function(ref){
var ref_item = ref.item;
var id = ref_item.id;
var perks = ref_item.perks;
var name = ref_item.name;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('edit', id)}}},[(_vm.checkCanEdit(perks))?_c('v-icon',[_vm._v("mdi-pencil")]):_c('v-icon',[_vm._v("mdi-information")])],1),_c('v-btn',{attrs:{"icon":"","color":"error","disabled":!_vm.checkCanDelete(perks)},on:{"click":function($event){return _vm.$emit('remove', { id: id, name: name })}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }