<template>
    <v-autocomplete
        :label="field.name"
        clearable
        hide-no-data
        :rules="rules"
        :value="value"
        :items="items"
        dense
        @update:search-input="search"
        @change="setCat"
        :loading="loading"
        item-value="id"
        item-text="name"
    />
</template>

<script>
import FieldBase from "./FieldBase";
export default {
    name: "FieldCategory",
    props: {
        value: {
            type: Number,
        },
    },
    extends: FieldBase,
    data: () => ({
        loading: false,
        targetField: null,
        cat: null,
        searchItems: [],
    }),
    computed: {
        rules() {
            const rules = [];
            if (this.field.perks.includes("required")) {
                rules.push((v) => (v || 0) > 0 || this.$t("form.required"));
            }
            return rules;
        },
        items() {
            const items = [];
            if (this.cat) {
                items.push(this.cat);
            }
            return [...items, ...this.searchItems];
        },
    },
    methods: {
        getDefaultValue() {
            if (!this.id) {
                this.$api(`/entity/category/${this.meta.category}`).then((cat) => {
                    this.cat = {
                        id: cat.id,
                        name: cat.name,
                    };
                });
            }
            return this.meta.category;
        },
        async setValue(value) {
            this.$emit("input", value.id);
            this.cat = {
                id: value.id,
                name: value.name,
            };
        },
        setCat(id) {
            if (!id) {
                this.$emit("input", 0);
                return;
            }
            this.$emit("input", id);
            for (let i of this.searchItems) {
                if (i.id == id) {
                    this.cat = {
                        id: i.id,
                        name: i.name,
                    };
                    break;
                }
            }
        },
        search(s) {
            clearTimeout(this.search.t);
            if (!s || !s.length || this.cat?.name == s) {
                return;
            }
            this.search.t = setTimeout(async () => {
                this.loading = true;
                try {
                    if (!this.targetField) {
                        const fields = await this.$api(`/category/fields`);
                        this.targetField = fields
                            .filter((f) => f.type == "catname")
                            .pop().id;
                    }
                    const query = new URLSearchParams();
                    query.append("filter[]", `${this.targetField}|∋|${s}`);
                    const r = await this.$apiResponse(
                        `/entity/category?${query.toString()}`
                    );
                    let data = [];
                    if (r.status == 200) {
                        ({ data } = await r.json());
                    }
                    this.searchItems = data.map((d) => ({
                        id: d.id,
                        name: d.name,
                    }));
                } catch (e) {
                    this.$error(e.message);
                }
                this.loading = false;
            }, 500);
        },
    },
};
</script>