<template>
    <div ref="cost">
        <slot />
    </div>
</template>

<script>
export default {
    name: "CostComponent",

    props: {
        balance: {
            type: Number,
            required: true,
        },
    },

    watch: {
        balance() {
            const ref = this.$refs.cost;

            if (!ref) {
                return;
            }

            ref.classList.add('balance-changed');

            setTimeout(() => {
                ref.classList.remove('balance-changed');
            }, 2000);
        },
    },
};
</script>
