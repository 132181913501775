<template>
    <multipane-resizer class="custom-resizer" />
</template>

<style lang="scss" scoped>
.custom-resizer {
    height: auto !important;
    margin: 0 !important;
    left: 0 !important;
    position: relative;
    background: #bebebe;
    border-radius: 5px;
    opacity: 0.3;
    &:hover {
        opacity: 0.7;
    }
}
</style>

<script>
import { MultipaneResizer } from "vue-multipane";
export default {
    name: "CustomResizer",
    components: {
        MultipaneResizer,
    },
};
</script>