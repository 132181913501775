export const getPage = async (apiResponse, route, page) => {
    const response = await apiResponse(`${route}?page=${page}`);

    let hasNext = false;
    let data = [];
    if (response.status == 200) {
        ({ pagination: { hasNext }, data } = await response.json());
    }

    return {
        hasNext,
        data,
    };
};

export const getAllPages = async (apiResponse, route) => {
    let result = [];

    let current = 1;
    let condition = true;
    while (condition) {
        const page = await getPage(apiResponse, route, current);

        if (!page) {
            break;
        }

        const { hasNext, data } = page;
        condition = hasNext;
        current++;
        result = [
            ...result,
            ...data
        ];
    }

    return result;
};
