<template>
    <div>
        <v-card
            v-if="false"
            class="mt-8"
        >
            <v-card-title class="word-break subtitle-1 font-weight-medium">
                Доступ к новым записям справочника
            </v-card-title>

            <v-divider />

            <access-new
                :user="user"
                :headers="headers"
                :keys="keys"
                v-on="$listeners"
            />
        </v-card>

        <div
            class="d-flex align-center"
            :class="{
                'mt-1': $isMobile(),
                'mt-3': !$isMobile(),
            }"
        >
            <v-breadcrumbs
                :items="breadcrumbs"
                large
            >
                <template v-slot:item="{ item: { query, text, disabled } }">
                    <v-chip
                        v-if="$isMobile()"

                        link
                        :to="{
                            name: 'AccessControl',
                            params: { ...$route.params },
                            query: {
                                ...$route.query,
                                ...query,
                            },
                        }"
                        :disabled="disabled"
                        exact
                        label
                        outlined
                        color="primary"
                        class="my-1"
                    >
                        {{ text }}
                    </v-chip>

                    <v-breadcrumbs-item
                        v-else
                        :to="{
                            name: 'AccessControl',
                            params: { ...$route.params },
                            query: {
                                ...$route.query,
                                ...query,
                            },
                        }"
                        :disabled="disabled"
                        exact
                    >
                        {{ text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>

            <span
                v-if="!$isMobile()"
                v-text="'/'"
                class="text--secondary"
            />
        </div>

        <v-card>
            <v-card-title class="word-break subtitle-1 font-weight-medium">
                Доступ к существующим записям справочника
            </v-card-title>

            <v-divider />

            <access-existing
                :user="user"
                :headers="headers"
                :keys="keys"
                :tree="tree"
                v-on="$listeners"
            />
        </v-card>
    </div>
</template>

<script>
import AccessNew from "@c/access-control/AccessNew";
import AccessExisting from "@c/access-control/AccessExisting";

export default {
    name: "UserInfo",

    inject: ['$isMobile'],

    props: {
        user: {
            type: Object,
            required: true,
        },
        tree: {
            type: Array,
            required: true,
        },
        breadcrumbs: {
            type: Array,
            required: true,
        },
    },

    components: {
        AccessNew,
        AccessExisting,
    },

    data: () => ({
        headers: [
            {
                value: 'title',
                text: '',
                sortable: false,
                icon: '',
                width: '40%'
            },
            {
                value: 'read',
                text: 'Просмотр',
                align: 'center',
                icon: 'mdi-eye',
            },
            {
                value: 'update',
                text: 'Редактирование',
                align: 'center',
                icon: 'mdi-pencil',
            },
            {
                value: 'delete',
                text: 'Удаление',
                align: 'center',
                icon: 'mdi-delete',
            },
        ],
    }),

    computed: {
        keys() {
            return this.headers
                .map((h) => h.value)
                .filter((h) => h !== 'title');
        },
    },
};
</script>
