<template>
    <div>
        <full-overlay :value="loading" />
        <field-list
            ref="list"
            :loading="loading"
            :types="types"
            :perks="perks"
            @remove="remove"
            @add="add"
            @edit="edit"
            :owner="owner"
        />
        <field-remove ref="remove" :owner="owner" />
        <field-edit ref="edit" :types="types" :perks="perks" :owner="owner" />
    </div>
</template>

<script>
import FieldList from "@c/field/List";
import FieldRemove from "@c/field/Remove";
import FieldEdit from "@c/field/Edit";
import FullOverlay from "@c/FullOverlay";
export default {
    name: "Field",
    props: {
        owner: {
            type: String,
            required: true,
        },
    },
    components: {
        FieldList,
        FieldRemove,
        FieldEdit,
        FullOverlay,
    },
    data: () => ({
        loading: false,
        perks: [],
        types: [],
    }),
    watch: {
        $route: {
            immediate: true,
            handler() {
                this.getData();
            },
        },
    },
    methods: {
        async add() {
            const needUpdate = await this.$refs.edit.open();
            if (needUpdate) {
                this.getList();
            }
        },
        async edit(id) {
            const needUpdate = await this.$refs.edit.open(id);
            if (needUpdate) {
                this.getList();
            }
        },
        async remove({ id, name }) {
            const needUpdate = await this.$refs.remove.open(id, name);
            if (needUpdate) {
                this.getList();
            }
        },
        getList() {
            this.$refs.list.getData();
        },
        async getData() {
            this.loading = true;
            try {
                const types = await this.$api(`/${this.owner}/fields/types`);
                this.types = types;

                const perks = await this.$api(`/${this.owner}/fields/perks`);
                this.perks = perks;
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
            this.getList();
        },
    },
};
</script>
