<template>
    <v-breadcrumbs :items="items" />
</template>

<script>
export default {
    name: "StorageBread",
    data: () => ({
        items: [],
    }),
    props: {
        storage: {
            type: Number,
            required: true,
        },
        room: {
            type: Number,
            required: true,
        },
        rack: {
            type: Number,
            required: true,
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                this.updateItems();
            },
        },
    },
    methods: {
        async updateItems() {
            const items = [
                {
                    text: "/",
                    exact: true,
                    to: {
                        params: {
                            storage: null,
                            room: null,
                            rack: null,
                        },
                    },
                    type: "main",
                },
            ];
            if (this.storage) {
                const prevStorage = this.items.find((i) => i.type == "storage");
                if (
                    prevStorage &&
                    prevStorage.to.params.storage == this.storage
                ) {
                    items.push(prevStorage);
                } else {
                    const storage = await this.$api(
                        `/entity/storage/${this.storage}`
                    );
                    items.push({
                        text: storage.name,
                        exact: true,
                        to: {
                            params: {
                                storage: this.storage,
                                room: null,
                                rack: null,
                            },
                        },
                        type: "storage",
                    });
                }
            }
            if (this.room) {
                const prevRoom = this.items.find((i) => i.type == "room");
                if (prevRoom && prevRoom.to.params.room == this.room) {
                    items.push(prevRoom);
                } else {
                    const room = await this.$api(`/entity/room/${this.room}`);
                    items.push({
                        text: room.name,
                        exact: true,
                        to: {
                            params: {
                                storage: this.storage,
                                room: this.room,
                                rack: null,
                            },
                        },
                        type: "room",
                    });
                }
            }
            if (this.rack) {
                const prevRack = this.items.find((i) => i.type == "rack");
                if (prevRack && prevRack.to.params.rack == this.rack) {
                    items.push(prevRack);
                } else {
                    const rack = await this.$api(`/entity/rack/${this.rack}`);
                    items.push({
                        text: rack.name,
                        exact: true,
                        to: {
                            params: {
                                storage: this.storage,
                                room: this.room,
                                rack: this.rack,
                            },
                        },
                        _time: "rack",
                    });
                }
            }
            this.items = items;
        },
    },
};
</script>