<script>
import eventFormulas from "@u/events/event-formulas";
import { formatDate, formatTime } from "@u/formatting";

export default {
    name: "EventInfo",

    props: {
        event: {
            type: Object,
            required: true,
        },
    },

    render() {
        return this.$scopedSlots.default({
            info: this.infoFiltered,
        });
    },

    computed: {
        info() {
            return [
                {
                    label: 'Период',
                    value: this.period,
                    alias: 'period',
                },
                {
                    label: this.rule.label,
                    value: this.rule.value,
                    alias: 'rule',
                },
                {
                    label: 'Интервал',
                    value: this.interval,
                    alias: 'interval',
                },
                {
                    label: 'Начало',
                    value: this.startFormatted,
                    alias: 'start',
                },
                {
                    label: 'Окончание',
                    value: this.repeatUntil,
                    alias: 'end',
                },
                {
                    label: 'Время',
                    value: this.time,
                    alias: 'time',
                },
            ];
        },

        infoFiltered() {
            return this.info.filter(({ value }) => value);
        },

        start() {
            return new Date(this.event.start);
        },

        end() {
            return new Date(this.event.end);
        },

        startFormatted() {
            return formatDate(this.start);
        },

        endFormatted() {
            return formatDate(this.end);
        },

        time() {
            const h = this.start.getHours();
            const m = this.start.getMinutes();

            if (!(h || m)) {
                return '';
            }

            return formatTime(this.start);
        },

        period() {
            const { label } = eventFormulas
                .find(({ alias }) => alias === this.event.formula);

            return `${label.charAt(0).toUpperCase()}${label.slice(1)}`;
        },

        rule() {
            const { formula, rule } = this.event;

            let label = '';
            let value = '';

            switch (formula) {
                case 'every-week': {
                    label = 'Дни недели';
                    const weekdays = [
                        'вс',
                        'пн',
                        'вт',
                        'ср',
                        'чт',
                        'пт',
                        'cб',
                    ];
                    value = weekdays
                        .filter((w, index) => rule.value.includes(index))
                        .toSorted((a, b) => {
                            if (a === 'вс') {
                                return 1;
                            }

                            if (b === 'вс') {
                                return -1;
                            }

                            return 0;
                        })
                        .join(', ');
                    break;
                }
                case 'every-month': {
                    label = 'День';
                    const [day] = rule.value;
                    const weekdays = {
                        'monday': 'понедельник',
                        'tuesday': 'вторник',
                        'wednesday': 'среда',
                        'thursday': 'четверг',
                        'friday': 'пятница',
                        'saturday': 'суббота',
                        'sunday': 'воскресенье',
                    };
                    if (rule.type === 'date') {
                        value = `${day} число месяца`;
                    } else if (rule.type === 'working-day') {
                        value = `${day} рабочий день месяца`;
                    } else {
                        value = `${day} ${weekdays[rule.type]} месяца`;
                    }
                }
            }

            return {
                label,
                value,
            };
        },

        interval () {
            if (this.event.interval === 1) {
                return '';
            }

            const { suffix } = eventFormulas
                .find(({ alias }) => alias === this.event.formula);

            return `${this.event.interval} ${suffix}`;
        },

        repeatUntil() {
            if (this.event.repeatUntil === 'count') {
                return `После {${this.event.maxCount}} повторений`;
            }

            if (this.event.repeatUntil === 'date') {
                return this.endFormatted;
            }

            return '';
        },
    },
};
</script>
