<template>
    <v-list
        :dense="$isMobile()"
        class="pa-0"
    >
        <v-list-item
            v-if="false"
            @click="$emit('event:dialog:edit')"
        >
            <v-list-item-icon>
                <v-icon
                    v-text="'mdi-plus'"
                    color="primary"
                />
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title class="primary--text">
                    Добавить событие
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-sheet
            elevation="1"
            class="mb-2"
            :color="color"
        >
            <v-list-item>
                <v-list-item-content>
                    <v-text-field
                        v-model="searchText"
                        dense
                        outlined
                        hide-details
                        label="Поиск"
                        :append-icon="searchText ? 'mdi-close' : 'mdi-magnify'"
                        @click:append="searchText = ''"
                    />
                </v-list-item-content>

                <v-list-item-icon>
                    <v-menu
                        v-model="menu"
                        offset-x
                        left
                        offset-y
                        bottom
                        :close-on-content-click="false"
                    >
                        <template #activator="{ attrs, on }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                            >
                                <v-icon
                                    v-text="'mdi-cog-outline'"
                                    :color="searchActive ? 'primary' : ''"
                                />
                            </v-btn>
                        </template>

                        <v-card>
                            <v-list
                                dense
                                subheader
                            >
                                <v-subheader
                                    v-text="'Период повторения'"
                                />

                                <v-list-item
                                    v-for="{ label, alias } in eventFormulas"
                                    :key="alias"
                                    @click="toggleFormula(alias)"
                                >
                                    <v-list-item-action>
                                        <v-checkbox
                                            :value="searchParams.formulas.includes(alias)"
                                            dense
                                            readonly
                                        />
                                    </v-list-item-action>

                                    <v-list-item-content>
                                        <v-list-item-title v-text="label" />
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>

                            <v-divider />

                            <v-card-actions>
                                <v-btn
                                    text
                                    small
                                    @click="menu = false"
                                >
                                    Закрыть
                                </v-btn>

                                <v-btn
                                    text
                                    small
                                    color="red"
                                    @click="searchParams.formulas = []"
                                >
                                    Очистить
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </v-list-item-icon>
            </v-list-item>
        </v-sheet>

        <v-sheet
            elevation="1"
            class="my-2"
            :color="color"
        >
            <v-list-item v-on="filterListener">
                <v-list-item-icon>
                    <v-icon
                        v-if="filterActive"
                        v-text="'mdi-filter-off-outline'"
                        color="red"
                    />
                    <v-icon
                        v-else
                        v-text="'mdi-filter-outline'"
                    />
                </v-list-item-icon>

                <v-list-item-content>
                    <v-slide-x-transition>
                        <span
                            v-if="filterActive"
                            v-text="filterText"
                        />
                    </v-slide-x-transition>
                </v-list-item-content>
            </v-list-item>
        </v-sheet>

        <event-component
            v-for="event in eventsAfterSearch"
            :key="event.id"
            :event="event"
            :selected-events="selectedEvents"
            :color="color"
            :search-text="searchText"
            :cashboxes="cashboxes"
            :cashbox-categories="cashboxCategories"
            :cost-templates="costTemplates"
            :cost-elements="costElements"
            :cost-categories="costCategories"
            @delete="deleteEvent(event.id)"
            v-on="$listeners"
        />
    </v-list>
</template>

<script>
import eventFormulas from "@/utils/events/event-formulas";
import EventComponent from "@c/event-planner/event-list/EventComponent";

export default {
    name: "EventList",

    inject: ['$isMobile'],

    components: {
        EventComponent,
    },

    props: {
        events: {
            type: Array,
            required: true,
        },
        selectedEvents: {
            type: Array,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        cashboxCategories: {
            type: Array,
            required: true,
        },
        costTemplates: {
            type: Array,
            required: true,
        },
        costElements: {
            type: Array,
            required: true,
        },
        costCategories: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        eventFormulas,
        menu: false,
        searchText: '',
        searchParams: {
            formulas: [],
        },
    }),

    computed: {
        searchActive() {
            return this.searchParams.formulas.length > 0;
        },

        eventsAfterSearch() {
            return this.events
                .filter(({ formula }) => {
                    const { formulas } = this.searchParams;

                    if (!formulas.length) {
                        return true;
                    }

                    return formulas.includes(formula);
                })
                .filter(({ id, name }) => {
                    const search = this.searchText.toLowerCase();

                    return `#${id} ${name}`
                        .toLowerCase()
                        .includes(search);
                });
        },

        filterActive() {
            return this.selectedEvents.length > 0;
        },

        filterListener() {
            return this.filterActive
                ? { click: this.clearFilter }
                : {};
        },

        filterText() {
            return `Выбрано событий: ${this.selectedEvents.length}`;
        },

        color() {
            return this.$vuetify.theme.dark
                ? 'grey darken-4'
                : 'grey lighten-5';
        },
    },

    methods: {
        async deleteEvent(id) {
            const r = await this.$confirm({
                text: this.$t("owner.remove", { id: `#${id}` }),
                actions: {
                    false: {
                        text: this.$t('main.close'),
                    },
                    true: {
                        text: this.$t('main.remove'),
                        color: 'error',
                    }
                },
            });

            if (r) {
                this.$emit('event:delete', id);
            }
        },

        toggleFormula(alias) {
            const index = this.searchParams.formulas.findIndex((a) => a === alias);

            if (index > -1) {
                this.searchParams.formulas.splice(index, 1);
            } else {
                this.searchParams.formulas.push(alias);
            }
        },

        clearFilter() {
            this.$emit('filter:clear');
        },
    },
};
</script>
