<template>
    <div>
        <v-dialog
            v-model="dialog.opened"
            persistent
            width="auto"
        >
            <v-card>
                <v-card-title>
                    {{ dialog.item.id == null ? "Добавление категории" : "Изменение категории" }}
                </v-card-title>
                <v-card-text>
                    Категория родителя: {{ getParentName }}
                </v-card-text>
                <v-card-text>
                    <v-text-field
                        label="Наименование категории *"
                        v-model="dialog.item.name"
                        required
                    ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                            color="primary"
                            variant="text"
                            @click="dialog.opened = false"
                        >
                        Закрыть
                    </v-btn>
                    <v-btn
                            color="primary"
                            variant="text"
                            @click="$emit('onSaveCategory', dialog); dialog.opened = false"
                        >
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'AddCategory',

    props: [
        'dialog',
    ],

    computed: {
        getParentName() {
            if (this.dialog.parent == null) {
                return '-';
            }
            return this.dialog.parent.name;
        }
    },
};
</script>
