var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.node.isCategory)?_c('v-list-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.node.childrenCountTotal && _vm.show),expression:"node.childrenCountTotal && show"}],attrs:{"eager":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"activator pa-0",class:{
                'activator--light': !_vm.$vuetify.theme.isDark,
                'activator--dark': _vm.$vuetify.theme.isDark,
                'pl-4': _vm.isChild,
            },on:{"contextmenu":function($event){$event.preventDefault();return _vm.toggleAllChildren.apply(null, arguments)}}},[(!_vm.hideCategoryId)?_c('v-list-item-action',[_c('v-list-item-action-text',{staticClass:"font-weight-medium text--primary",domProps:{"textContent":_vm._s(("#" + (_vm.node.id)))}})],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_c('text-highlight-wrapper',{attrs:{"text":_vm.node.name,"queries":_vm.searchText}})],1),(_vm.node.isEveryChildSelected && !_vm.isEveryChildSelectedProp)?_c('v-list-item-subtitle',{staticClass:"font-weight-light",domProps:{"textContent":_vm._s('Выбрана вся категория')}}):_vm._e()],1),_c('v-list-item-action',[_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(((_vm.node.childrenCountSelectedTotal) + " / " + (_vm.node.childrenCountTotal)))}})],1)],1)]},proxy:true}],null,false,392764022),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},_vm._l((_vm.node.children),function(child){return _c('recursive-list',_vm._g({key:child.id,ref:"recursiveLists",refInFor:true,class:{ 'pl-4': _vm.isChild },attrs:{"type":_vm.type,"node":child,"selected-items-ids":_vm.selectedItemsIds,"items-after-search":_vm.itemsAfterSearch,"categories-after-search":_vm.categoriesAfterSearch,"search-text":_vm.searchText,"has-search":_vm.hasSearch,"hide-category-id":_vm.hideCategoryId,"hide-item-id":_vm.hideItemId,"menu":_vm.menu,"is-every-child-selected-prop":_vm.node.isEveryChildSelected,"isChild":true}},_vm.$listeners))}),1):_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],attrs:{"data-test-id":"edit-filter-dialog-menu-list-item","data-test-state":_vm.node.selected || _vm.selectedItemsIds.includes(_vm.node.id),"input-value":_vm.node.selected || _vm.selectedItemsIds.includes(_vm.node.id),"color":"primary"},on:{"click":_vm.toggleItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var active = ref.active;
return [(!_vm.hideItemId)?_c('v-list-item-action',[_c('v-list-item-action-text',{staticClass:"text--primary",domProps:{"textContent":_vm._s(("#" + (_vm.node.id)))}})],1):_vm._e(),(_vm.type === 'status')?_c('v-list-item-action',[_c('v-list-item-action-text',[_c('v-icon',{class:_vm.node.textClass,domProps:{"textContent":_vm._s(_vm.node.icon)}})],1)],1):_vm._e(),(_vm.type === 'processingDate')?_c('v-list-item-action',[_c('v-list-item-action-text',[_c('v-icon',{attrs:{"color":active ? 'primary' : ''},domProps:{"textContent":_vm._s(_vm.node.icon)}})],1)],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{class:[
                _vm.node.textClass,
                ("" + (['status', 'processingDate'].includes(_vm.type)
                        ? ''
                        : 'font-weight-regular')) ]},[_c('text-highlight-wrapper',{attrs:{"text":_vm.node.name,"queries":_vm.searchText}})],1),(_vm.type === 'processingDate')?_c('v-list-item-subtitle',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.hints[_vm.node.id]))+" ")]):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }