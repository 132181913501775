<template>
    <adaptive-item
        data-test-type="saved-filters-list-item"
        :data-test-id="`saved-filters-list-item-${meta.id}`"
    >
        <template #index>{{ meta.index + 1 }}</template>

        <template #body>
            <span class="font-weight-medium">{{ meta.name }}</span>

            <filter-info-renderless
                #default="{ info }"

                :filter="filter"

                :cashbox-categories="cashboxCategories"
                :cashboxes="cashboxes"

                :cost-categories="costCategories"
                :cost-templates="costTemplates"

                :users="users"
                :statuses="statuses"
                :dateHelpers="dateHelpers"
                :groupByList="groupByList"
            >
                <v-list
                    data-test-id="filter-stats"
                    :data-test-filter="JSON.stringify(filter)"

                    dense
                    class="py-0"
                >
                    <v-list-item
                        v-for="{ alias, label, value, icon } in info"
                        :key="alias"
                        dense
                        class="px-0"
                        style="min-height: 30px;"
                    >
                        <v-list-item-content
                            v-if="alias === 'statuses'"
                            class="py-1"
                        >
                            <v-list-item-title class="font-weight-regular text-wrap">
                                <v-icon
                                    v-text="icon"
                                    small
                                    class="mr-2 my-n1"
                                />
                                <span v-text="`${label}: `" />
                                <span
                                    v-for="({ text, textClass }, index) in value"
                                    :key="index"
                                    v-text="`${text}${index !== value.length - 1 ? ', ' : ''}`"
                                    :class="textClass"
                                />
                            </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-content
                            v-else-if="
                                [
                                    'cashboxCategories',
                                    'cashboxes',
                                    'costCategories',
                                    'costTemplates',
                                ].includes(alias)
                            "
                            class="py-1"
                        >
                            <v-list-item-title>
                                <v-icon
                                    v-text="icon"
                                    small
                                    class="mr-2 my-n1"
                                />
                                <span
                                    v-text="`${label}:`"
                                    class="font-weight-regular"
                                />
                            </v-list-item-title>
                            <v-list-item-subtitle
                                v-for="(row, index) in value"
                                :key="index"
                                v-text="row"
                                class="font-weight-regular text-wrap"
                            />
                        </v-list-item-content>

                        <v-list-item-content
                            v-else
                            class="py-1"
                        >
                            <v-list-item-title class="font-weight-regular text-wrap">
                                <v-icon
                                    v-text="icon"
                                    small
                                    class="mr-2 my-n1"
                                />
                                <span v-text="`${label}: `" />
                                <span
                                    v-text="value"
                                    class="text--secondary"
                                />
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </filter-info-renderless>

        </template>

        <template #buttons>
            <filter-buttons
                :id="meta.id"

                @apply="apply"
                @edit="edit"
                @delete="$emit('filter:saved:dialog:delete', meta)"
            />
        </template>
    </adaptive-item>
</template>

<script>
import AdaptiveItem from "@c/norton-commander/dialogs/filter/saved-filters/AdaptiveItem";
import FilterInfoRenderless from "@c/norton-commander/FilterInfoRenderless";
import FilterButtons from "@c/norton-commander/dialogs/filter/saved-filters/FilterButtons";

export default {
    name: "SavedFilter",

    components: {
        AdaptiveItem,
        FilterInfoRenderless,
        FilterButtons,
    },

    props: {
        meta: {
            type: Object,
            required: true,
        },
        filter: {
            type: Object,
            required: true,
        },
        cashboxCategories: {
            type: Array,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        costCategories: {
            type: Array,
            required: true,
        },
        costTemplates: {
            type: Array,
            required: true,
        },
        users: {
            type: Object,
            required: true,
        },
        statuses: {
            type: Object,
            required: true,
        },
        dateHelpers: {
            type: Array,
            required: true,
        },
        groupByList: {
            type: Array,
            required: true,
        },
    },

    methods: {
        apply() {
            this.$emit('filter:saved:apply', this.filter);
            this.$emit('close');
        },

        edit() {
            this.$emit(
                'filter:dialog:edit',
                this.filter,
                this.meta,
                true
            );
            this.$emit('close');
        },
    },
};
</script>
