<template>
    <v-alert color="error">{{ field }} {{ meta }} {{ value }}</v-alert>
</template>

<script>
export default {
    name: "FieldBase",
    props: {
        id: {
            type: Number,
            required: true,
        },
        field: {
            type: Object,
            required: true,
        },
        meta: {
            type: Object,
            required: true,
        },
        value: {
            type: String,
        },
    },
    computed: {
        readonly() {
            return this.field.perks.includes("systemfill");
        },
        rules() {
            const rules = [];
            if (this.field.perks.includes("required")) {
                rules.push(
                    (v) =>
                        String(v || "").length > 0 || this.$t("form.required")
                );
            }
            return rules;
        },
    },
    methods: {
        getDefaultValue: () => "",
        setValue(value) {
            this.$emit("input", value);
        },
        getValue() {
            return this.value || "";
        },
    },
};
</script>