<template>
    <v-overlay z-index="99001" :value="value">
        <v-progress-circular indeterminate />
    </v-overlay>
</template>

<script>
export default {
    name: "FullOverlay",
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
};
</script>