<template>
    <v-card :loading="loading" :disabled="loading || !this.id">
        <v-simple-table fixed-header max-height="400px">
            <thead>
                <tr>
                    <th>#</th>
                    <th>{{ $t("keep.log.type") }}</th>
                    <th>{{ $t("keep.log.product") }}</th>
                    <th>{{ $t("keep.log.cell") }}</th>
                    <th>{{ $t("keep.log.count") }}</th>
                    <th style="width: 40px">
                        <v-btn icon @click="getList" color="primary">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="l in list" :key="l.id">
                    <td>{{ l.id }}</td>
                    <td>{{ l.list }}</td>
                    <td>{{ l.product }}</td>
                    <td>{{ l.cell }}</td>
                    <td>{{ l.count }}</td>
                    <td></td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>

<script>
export default {
    name: "KeepRemains",
    data() {
        return this.getDefaultData();
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
        show: {
            type: Boolean,
            required: true,
        },
        current: {
            type: Boolean,
            required: true,
        },
        owner: {
            type: String,
            required: true,
        },
    },
    watch: {
        show: {
            immediate: true,
            handler() {
                for (const [key, value] of Object.entries(
                    this.getDefaultData()
                )) {
                    this[key] = value;
                }
            },
        },
        current: {
            immediate: true,
            handler(dir) {
                if (dir && !this.loaded) {
                    this.getList();
                }
            },
        },
    },
    methods: {
        getDefaultData: () => ({
            loading: false,
            list: [],
            loaded: false,
        }),
        async getList() {
            if (!this.id) {
                return;
            }
            this.loading = true;
            try {
                const r = await this.$apiResponse(
                    `/keep/remains/${this.owner}/${this.id}`
                );
                this.list = [];
                let data = [];
                let meta = {
                    products: {},
                    cells: {},
                    lists: {},
                };
                if (r.status == 200) {
                    ({ data, meta } = await r.json());
                }
                this.list = data.map((d) => {
                    d.list = meta.list[d.list]?.name ?? d.list;
                    d.product =
                        meta.products[d.keep.product]?.name ?? d.keep.product;
                    d.cell = meta.cells[d.keep.cell]?.name ?? d.keep.cell;
                    return d;
                });
                this.loaded = true;
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
    },
};
</script>