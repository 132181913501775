import { getAllPages } from "@u/api/paginated-data";
import cashboxGroups from "@u/cashboxes/cashbox-groups";

export const getCashboxCategories = async (api) => {
    const categories = await api("/finance/cashbox/categories");
    return fillCashboxCategories(categories);
};

const fillCashboxCategories = (categories) => {
    const list = [];

    for (const categoryKey in categories) {
        const category = categories[categoryKey];

        category.forEach((cat) => {
            const payload = {
                ...cat,

                parents: [],
                children: [],

                category: categoryKey,
                treeParent: cat.treeParent || categoryKey,
            };

            list.push(payload);
        });
    }

    for (const groupKey in cashboxGroups) {
        const group = cashboxGroups[groupKey];

        const payload = {
            ...group,

            parents: [],
            children: [],

            category: groupKey,
            treeParent: null,
        };

        list.push(payload);
    }

    return list;
};

export const getCashboxes = async (api, apiResponse, fields = []) => {
    const list = await getAllPages(apiResponse, '/entity/cashbox');

    if (fields.length) {
        return list.map((cashbox) => fillCashbox(fields, cashbox));
    } else {
        const apiFields = await api("/cashbox/fields");
        return list.map((cashbox) => fillCashbox(apiFields, cashbox));
    }
};

const fillCashbox = (fields, cashbox) => {
    const { id, values } = cashbox;

    let result = {
        id,
        title: String(id),
        type: 'none',
        treeParent: null,
        balance: 0,
        currency: '₽',
    };

    const type = getType(fields, values);
    const title = getField(fields, values, 'sort');
    const treeParent = getField(fields, values, 'tree_parent');
    const balance = getField(fields, values, 'balance');

    return {
        ...result,
        type: type || result.type,
        title: title || result.title,
        treeParent: treeParent || type || result.treeParent,
        balance: balance || result.balance,
    };
};

const getField = (fields, values, key) => {
    const perk = fields.find(({ perks }) => perks.includes(key));

    return getValue(values, perk);
};

const getType = (fields, values) => {
    const perk = fields.find(({ type }) => type === 'cbtype');

    return getValue(values, perk);
};

const getValue = (values, perk) => {
    if (!perk) {
        return;
    }

    const id = perk.id;

    if (!id) {
        return;
    }

    const value = values.find(({ field }) => field === id);

    if (!value) {
        return;
    }

    return value.value;
};
