export default {
    username: '',
    _token: localStorage.token ?? '',
    get token() {
        return this._token;
    },
    set token(token) {
        this._token = token;
        localStorage.token = token;
    },
    locale: 'ru',
    base: '',
    /**
     * Запрос к апи
     * @param {RequestInfo} url
     * @param {RequestInit} options
     * @returns {Promise<Response>}
     */
    fetch(url, options = {}) {
        if ('body' in options) {
            options.body = JSON.stringify(options.body);
        }
        options.headers = {
            'Content-type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${this.token}`,
            'Accept-Language': this.locale,
            ...(options.headers ?? {}),
        };
        return fetch(`${this.base}/api${url}`, options);
    },
};