<template>
    <div>
        <v-text-field
            :value="value | apiDate"
            :rules="rules"
            :label="field.name"
            readonly
            :placeholder="readonly ? $t('form.auto') : null"
            @click="setDate"
            :clearable="!readonly"
            dense
            @input="$emit('input', $event)"
        />
        <date-time-picker
            ref="picker"
            :show-date="showDate"
            :show-time="showTime"
        />
    </div>
</template>

<script>
import FieldBase from "./FieldBase";
import DateTimePicker from "@c/DateTimePicker";
export default {
    name: "FieldBaseDateTime",
    components: {
        DateTimePicker,
    },
    extends: FieldBase,
    computed: {
        showDate: () => true,
        showTime: () => true,
        currentPerkName: () => "curdate",
        readonly() {
            return this.field.perks.includes(this.currentPerkName);
        },
        rules() {
            const rules = [];
            if (!this.readonly && this.field.perks.includes("required")) {
                rules.push(
                    (v) => (v || "").length > 0 || this.$t("form.required")
                );
            }
            return rules;
        },
    },
    methods: {
        getValue() {
            if (this.readonly) {
                return null;
            }
            return this.value;
        },
        async setDate() {
            if (this.readonly) {
                return;
            }
            const value = await this.$refs.picker.open(this.value);
            this.$emit("input", value);
        },
    },
};
</script>
