<template>
    <v-row justify="center">
        <v-col cols="auto">
            <full-overlay v-model="loading" />
            <login-main v-if="mode == 1" />
            <login-register v-if="mode == 2" @done="mode = 1" />
        </v-col>
    </v-row>
</template>

<script>
import FullOverlay from "@c/FullOverlay";
import LoginMain from "@c/login/Main";
import LoginRegister from "@c/login/Register";
import api from "@u/api";
export default {
    name: "Login",
    components: {
        FullOverlay,
        LoginMain,
        LoginRegister,
    },
    data: () => ({
        loading: false,
        mode: 0,
    }),
    async mounted() {
        this.loading = true;
        try {
            const r = await api.fetch("/auth/register", {
                method: "HEAD",
            });
            switch (r.status) {
                case 200:
                    this.mode = 1;
                    break;
                case 404:
                    this.mode = 2;
                    break;
            }
        } catch (e) {
            this.$error(e.message);
        }
        this.loading = false;
    },
};
</script>
