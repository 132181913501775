<template>
    <v-menu
        v-model="menu"

        absolute
        offset-y
        close-on-click

        :position-x="x"
        :position-y="y"
    >
        <v-list
            dense
            class="pa-0"
            style="border: solid 1px grey;"
        >
            <template v-for="{ isLink, title, icon, content } in buttons">
                <v-list-item
                    :key="`${title}-item`"
                    @click="onClick($event, isLink, content)"
                    :to="content"
                    target="_blank"
                >
                    <v-list-item-icon>
                        <v-icon dense>{{ icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ title }}
                            <v-icon
                                v-if="isLink"
                                x-small
                            >
                                mdi-open-in-new
                            </v-icon>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider :key="`${title}-divider`" />
            </template>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: "ContextMenu",

    props: {
        user: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        menu: false,
        x: 0,
        y: 0,
        node: {},
    }),

    computed: {
        isAdmin() {
            return Boolean(this.user.admin);
        },

        supportsMassProcess() {
            const allowedCategories = ['vendor', 'salary', 'installments', 'storage'];
            const { id, category } = this.node;

            return [id, category].some((foo) => allowedCategories.includes(foo));
        },

        buttons() {
            const result = [];

            if (this.supportsMassProcess) {
                result.push({
                    isLink: false,
                    title: 'Сделать начисление',
                    icon: 'mdi-currency-rub',
                    content: 'cashbox:dialog:mass-process',
                });
            }

            if (this.isAdmin) {
                result.push({
                    isLink: true,
                    title: 'Изменить доступ',
                    icon: 'mdi-security',
                    content: {
                        name: 'AccessControl',
                        params: { category: 'cashbox' },
                        query: { categoryId: String(this.node.id) },
                    },
                });
            }

            return result;
        },

        hasButtons() {
            return Boolean(this.buttons.length);
        },
    },

    methods: {
        show({ coordinates, node }) {
            this.menu = false;
            this.node = node;

            if (this.hasButtons) {
                this.$nextTick(() => {
                    const { x, y } = coordinates;
                    this.x = x;
                    this.y = y;
                    this.menu = true;
                });
            }
        },

        onClick($event, isLink, content) {
            if (isLink) {
                return;
            }

            $event.preventDefault();
            this.$emit(content, this.node);
        },
    },
};
</script>
