var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('adaptive-item',{attrs:{"data-test-type":"saved-filters-list-item","data-test-id":("saved-filters-list-item-" + (_vm.meta.id))},scopedSlots:_vm._u([{key:"index",fn:function(){return [_vm._v(_vm._s(_vm.meta.index + 1))]},proxy:true},{key:"body",fn:function(){return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.meta.name))]),_c('filter-info-renderless',{attrs:{"filter":_vm.filter,"cashbox-categories":_vm.cashboxCategories,"cashboxes":_vm.cashboxes,"cost-categories":_vm.costCategories,"cost-templates":_vm.costTemplates,"users":_vm.users,"statuses":_vm.statuses,"dateHelpers":_vm.dateHelpers,"groupByList":_vm.groupByList},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var info = ref.info;
return [_c('v-list',{staticClass:"py-0",attrs:{"data-test-id":"filter-stats","data-test-filter":JSON.stringify(_vm.filter),"dense":""}},_vm._l((info),function(ref){
var alias = ref.alias;
var label = ref.label;
var value = ref.value;
var icon = ref.icon;
return _c('v-list-item',{key:alias,staticClass:"px-0",staticStyle:{"min-height":"30px"},attrs:{"dense":""}},[(alias === 'statuses')?_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',{staticClass:"font-weight-regular text-wrap"},[_c('v-icon',{staticClass:"mr-2 my-n1",attrs:{"small":""},domProps:{"textContent":_vm._s(icon)}}),_c('span',{domProps:{"textContent":_vm._s((label + ": "))}}),_vm._l((value),function(ref,index){
var text = ref.text;
var textClass = ref.textClass;
return _c('span',{key:index,class:textClass,domProps:{"textContent":_vm._s(("" + text + (index !== value.length - 1 ? ', ' : '')))}})})],2)],1):(
                            [
                                'cashboxCategories',
                                'cashboxes',
                                'costCategories',
                                'costTemplates' ].includes(alias)
                        )?_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 my-n1",attrs:{"small":""},domProps:{"textContent":_vm._s(icon)}}),_c('span',{staticClass:"font-weight-regular",domProps:{"textContent":_vm._s((label + ":"))}})],1),_vm._l((value),function(row,index){return _c('v-list-item-subtitle',{key:index,staticClass:"font-weight-regular text-wrap",domProps:{"textContent":_vm._s(row)}})})],2):_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',{staticClass:"font-weight-regular text-wrap"},[_c('v-icon',{staticClass:"mr-2 my-n1",attrs:{"small":""},domProps:{"textContent":_vm._s(icon)}}),_c('span',{domProps:{"textContent":_vm._s((label + ": "))}}),_c('span',{staticClass:"text--secondary",domProps:{"textContent":_vm._s(value)}})],1)],1)],1)}),1)]}}])})]},proxy:true},{key:"buttons",fn:function(){return [_c('filter-buttons',{attrs:{"id":_vm.meta.id},on:{"apply":_vm.apply,"edit":_vm.edit,"delete":function($event){return _vm.$emit('filter:saved:dialog:delete', _vm.meta)}}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }