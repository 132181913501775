<template>
    <v-dialog v-model="show" max-width="450">
        <v-form @submit.prevent="save">
            <v-card :loading="loading" :disabled="loading">
                <v-card-title>{{ $t("owner.head") }}</v-card-title>
                <v-card-text>
                    <v-menu offset-y>
                        <template #activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                text
                                v-on="on"
                                v-bind="attrs"
                                :disabled="fields.length == 0"
                            >
                                <v-icon left>mdi-plus</v-icon>
                                {{ $t("main.add") }}
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item
                                v-for="f in fields"
                                :key="f.id"
                                @click="moveToHead(f)"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ f.name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-list>
                        <draggable v-model="head">
                            <v-list-item v-for="h in head" :key="h.id">
                                <v-list-item-icon>
                                    <v-icon>mdi-drag</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ h.name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn
                                        icon
                                        color="error"
                                        :disabled="head.length < 2"
                                        @click="removeHead(h)"
                                    >
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </draggable>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text color="error" @click="close">
                        {{ $t("main.close") }}
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        type="submit"
                        :disabled="head.length == 0"
                    >
                        {{ $t("main.save") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import Draggable from "vuedraggable";
export default {
    name: "OwnerHead",
    props: {
        owner: {
            type: String,
            required: true,
        },
        disabled: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        Draggable,
    },
    data: () => ({
        show: false,
        loading: false,
        res: null,
        fields: [],
        head: [],
    }),
    methods: {
        removeHead(head) {
            if (this.head.length < 2) {
                return;
            }
            for (const [i, h] of this.head.entries()) {
                if (h.id == head.id) {
                    this.head.splice(i, 1);
                    break;
                }
            }
            this.fields.push(head);
        },
        moveToHead(field) {
            for (const [i, f] of this.fields.entries()) {
                if (f.id == field.id) {
                    this.fields.splice(i, 1);
                    break;
                }
            }
            this.head.push(field);
        },
        async open() {
            this.show = true;
            this.loading = true;
            try {
                this.head = (
                    await this.$api(`/${this.owner}/fields/head`)
                ).filter((h) => !this.disabled.includes(h.id));
                const fields = (
                    await this.$api(`/${this.owner}/fields`)
                ).filter((f) => !this.disabled.includes(f.id));
                this.fields = fields.filter(
                    (f) => !this.head.map((h) => h.id).includes(f.id)
                );
            } catch (e) {
                this.$error(e.message);
                this.show = false;
                return false;
            }
            this.loading = false;
            return new Promise((res) => (this.res = res));
        },
        close() {
            this.show = false;
            this.res(false);
        },
        async save() {
            if (!this.head.length) {
                return;
            }
            this.loading = true;
            try {
                await this.$api(`/${this.owner}/fields/head`, {
                    method: "PATCH",
                    body: {
                        sort: this.head.map((h) => h.id),
                    },
                });
                this.show = false;
                this.res(true);
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
    },
};
</script>