<template>
    <component
        :is="component"
        :value="value"
        :head="head"
        @input="$emit('input', $event)"
    />
</template>

<script>
import OwnerFilterString from "./filter/String";
import OwnerFilterEnum from "./filter/Enum";
import OwnerFilterBool from "./filter/Bool";
import OwnerFilterNumber from "./filter/Number";
import OwnerFilterDateTime from "./filter/DateTime";
import OwnerFilterCategory from "./filter/Category";
export default {
    components: {
        OwnerFilterString,
        OwnerFilterEnum,
        OwnerFilterBool,
        OwnerFilterNumber,
        OwnerFilterDateTime,
        OwnerFilterCategory,
    },
    props: {
        value: {
            required: true,
        },
        head: {
            type: Object,
            required: true,
        },
    },
    name: "OwnerFilter",
    computed: {
        types: () => ["enum", "bool", "number", "category"],
        dateTimeTypes: () => ["date", "time", "datetime"],
        component() {
            if (this.types.includes(this.head.type)) {
                return `OwnerFilter${this.head.type
                    .substr(0, 1)
                    .toUpperCase()}${this.head.type.substr(1)}`;
            }
            if (this.dateTimeTypes.includes(this.head.type)) {
                return "OwnerFilterDateTime";
            }
            return "OwnerFilterString";
        },
    },
};
</script>