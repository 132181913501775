<template>
    <v-card :loading="loading" :disabled="loading" flat>
        <entity-editor :owner="owner" ref="editor" />
        <v-toolbar flat dense>
            <v-spacer />
            <v-btn icon color="primary" @click="edit(0)">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-row>
                <v-col v-for="l in list" :key="l.id" cols="6">
                    <v-card>
                        <v-toolbar dense flat>
                            <v-toolbar-title>
                                <router-link :to="calcLink(l.id)">
                                    {{ l.name }}
                                </router-link>
                            </v-toolbar-title>
                            <v-spacer />
                            <v-btn icon color="primary" @click="edit(l.id)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon color="error" @click="remove(l.id)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <v-row justify="center">
                                <v-col
                                    cols="auto"
                                    v-for="h in l.health"
                                    :key="h.type"
                                    :title="h.name"
                                >
                                    <v-progress-circular
                                        rotate="90"
                                        :color="h.color"
                                        size="100"
                                        width="15"
                                        :value="h.perc"
                                    >
                                        {{ h.has }} /
                                        {{ h.total }}
                                    </v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
        <v-toolbar class="pagi" v-if="total > 1 || pg > 1" dense flat>
            <v-spacer />
            <v-pagination :value="pg" :length="total" @input="changePg" />
        </v-toolbar>
    </v-card>
</template>

<style lang="scss" scoped>
.pagi {
    position: sticky;
    bottom: 0;
}
</style>

<script>
import EntityEditor from "@c/EntityEditor";
export default {
    name: "StorageList",
    components: {
        EntityEditor,
    },
    data: () => ({
        loading: false,
        list: [],
        total: 1,
    }),
    props: {
        pg: {
            type: Number,
            required: true,
        },
        storage: {
            type: Number,
            required: true,
        },
        room: {
            type: Number,
            required: true,
        },
    },
    computed: {
        owner() {
            if (!this.storage) {
                return "storage";
            }
            if (!this.room) {
                return "room";
            }
            return "rack";
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                this.getList();
            },
        },
    },
    methods: {
        calcLink(id) {
            if (!this.storage) {
                return {
                    params: {
                        storage: id,
                    },
                };
            }
            if (!this.room) {
                return {
                    params: {
                        storage: this.storage,
                        room: id,
                    },
                };
            }
            return {
                params: {
                    storage: this.storage,
                    room: this.room,
                    rack: id,
                },
            };
        },
        async edit(id) {
            const needUpdate = await this.$refs.editor.open(id, {
                storage: this.storage,
                room: this.room,
            });
            if (needUpdate) {
                this.getList();
            }
        },
        async remove(id) {
            const c = await this.$confirm({
                text: this.$t("owner.remove", { id: `#${id}` }),
                actions: {
                    false: {
                        text: this.$t('main.close'),
                    },
                    true: {
                        text: this.$t('main.remove'),
                        color: 'error',
                    }
                },
            });
            if (!c) {
                return;
            }
            this.loading = true;
            try {
                await this.$api(`/entity/${this.owner}/${id}`, {
                    method: "DELETE",
                });
                this.getList();
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
        async getList() {
            this.list = [];
            this.total = 1;
            this.loading = true;
            try {
                if (!this.storage) {
                    const query = new URLSearchParams();
                    query.append("page", this.pg);
                    const r = await this.$apiResponse(
                        `/entity/${this.owner}?${query.toString()}`
                    );
                    let data = [];
                    let hasNext = false;
                    if (r.status == 200) {
                        ({
                            data,
                            pagination: { hasNext },
                        } = await r.json());
                    }
                    if (hasNext) {
                        this.total = this.pg + 1;
                    } else {
                        this.total = this.pg;
                    }
                    this.list = this.convertHealth(data);
                } else {
                    const data = await this.$api(
                        `/entity/${this.room ? "room" : "storage"}/${
                            this.room ? this.room : this.storage
                        }`
                    );
                    this.list = this.convertHealth(
                        data[this.room ? "rack" : "room"]
                    );
                }
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
        convertHealth(data) {
            return data.map((d) => {
                const map = [
                    {
                        type: "fill",
                        color: "success",
                        name: this.$t("storage.type.fill"),
                    },
                    {
                        type: "revision",
                        color: "primary",
                        name: this.$t("storage.type.revision"),
                    },
                    {
                        type: "damage",
                        color: "error",
                        name: this.$t("storage.type.damage"),
                    },
                ];
                d.health = map.reduce((acc, m) => {
                    acc.push({
                        ...m,
                        total: d.health[m.type].total,
                        has: d.health[m.type].has,
                        perc: Math.round(d.health[m.type].percents * 100),
                    });
                    return acc;
                }, []);
                return d;
            });
        },
        changePg(pg) {
            this.$router.push({
                query: {
                    pg: pg > 1 ? pg : null,
                    t: Date.now(),
                },
            });
        },
    },
};
</script>