<template>
    <component
        :is="component.is"
        :rule="component.rule"
        :dialog="dialog"
        @update="$event =>$emit(component.emit, $event)"
    />
</template>

<script>
import RuleMonth from '@c/event-planner/dialogs/event-create/rules/RuleMonth.vue';
import RuleWeek from '@c/event-planner/dialogs/event-create/rules/RuleWeek.vue';

export default {
    name: 'RepeatRule',

    components: {
        RuleMonth,
        RuleWeek,
    },

    props: {
        formula: {
            type: String,
            required: true,
        },
        weekdays: {
            type: Object,
            required: true,
        },
        monthDay: {
            type: Object,
            required: true,
        },
        dialog: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        component() {
            const components = {
                'every-month': {
                    is: 'RuleMonth',
                    rule: this.monthDay,
                    emit: 'update:monthDay',
                },
                'every-week': {
                    is: 'RuleWeek',
                    rule: this.weekdays,
                    emit: 'update:weekdays',
                },
            };

            return components[this.formula];
        },
    },
};
</script>
