var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"h-full"},[_c('v-card-title',{staticClass:"justify-space-between subtitle-1"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Пользователи")]),(_vm.users.length)?_c('span',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(_vm.usersAfterSearch.length)+" / "+_vm._s(_vm.users.length)+" ")]):_vm._e()]),_c('v-divider'),_c('v-list',{staticClass:"pa-0"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-text-field',{attrs:{"type":"string","label":"Поиск","dense":"","outlined":"","hide-details":"","prepend-icon":"mdi-magnify","append-icon":_vm.search ? 'mdi-close' : ''},on:{"click:append":function($event){_vm.search = ''}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-checkbox',{staticClass:"w-full",attrs:{"disabled":Boolean(_vm.search),"indeterminate":Boolean(_vm.search),"label":"Показывать всех"},model:{value:(_vm.showAll),callback:function ($$v) {_vm.showAll=$$v},expression:"showAll"}})],1),_c('v-divider'),_c('v-list-item-group',{attrs:{"color":"primary"}},[_vm._l((_vm.usersWithSelected),function(ref){
var id = ref.id;
var login = ref.login;
var admin = ref.admin;
return [_c('v-list-item',{key:(id + "-item"),attrs:{"to":{
                        name: 'AccessControl',
                        params: Object.assign({}, _vm.$route.params),
                        query: Object.assign({}, _vm.$route.query,
                            {userId: id}),
                    },"exact":"","exact-active-class":"font-weight-medium"}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-account-outline')}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('text-highlight-wrapper',{attrs:{"text":("#" + id + " " + login),"queries":_vm.search}})],1),(admin)?_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t("users.admin"))+" ")]):_vm._e()],1)],1),_c('v-divider',{key:(id + "-divider")})]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }