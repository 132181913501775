<template>
    <v-dialog v-model="show">
        <v-date-picker v-model="date" v-if="showDate" />
        <v-time-picker v-model="time" format="24hr" v-if="show && showTime" />
        <v-card flat>
            <v-card-actions>
                <v-spacer />
                <v-btn color="error" text @click="clear" v-if="!hideClear">
                    {{ $t("main.clear") }}
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="save"
                    :disabled="disableSave"
                >
                    {{ $t("main.save") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DateTimePicker",
    props: {
        showDate: {
            type: Boolean,
        },
        showTime: {
            type: Boolean,
        },
        hideClear: {
            type: Boolean,
        },
    },
    data: () => ({
        show: false,
        date: null,
        time: null,
        res: null,
    }),
    computed: {
        disableSave() {
            if (this.showDate && !this.date) {
                return true;
            }
            if (this.showTime && !this.time) {
                return true;
            }
            return false;
        },
    },
    methods: {
        clear() {
            this.res(null);
            this.show = false;
        },
        open(value) {
            this.date = null;
            this.time = null;

            if (value) {
                const values = value.split(" ");
                if (this.showDate) {
                    this.date = values.shift();
                }
                if (this.showTime) {
                    this.time = values.shift();
                }
            }

            this.show = true;
            return new Promise((r) => (this.res = r));
        },
        save() {
            if (this.disableSave) {
                return;
            }
            const values = [];

            if (this.showDate) {
                values.push(this.date);
            }
            if (this.showTime) {
                values.push(this.time);
            }
            this.res(values.join(" "));
            this.show = false;
        },
    },
};
</script>