<template>
    <v-container>
        <template v-if="$isMobile()">
            <v-row>
                <v-col>
                    <slot name="left" />
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <slot name="right" />
                </v-col>
            </v-row>
        </template>

        <template v-else>
            <v-row>
                <v-col>
                    <slot name="left" />
                </v-col>

                <v-col>
                    <slot name="right" />
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
export default {
    name: "AdaptiveItem",

    inject: ['$isMobile'],
};
</script>
