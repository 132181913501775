<template>
    <div>
        <adaptive-dialog
            :dialog="dialog"
            max-width="900"
        >
            <template #title>
                Настройка повторения
            </template>

            <template #body>
                <v-form
                    ref="form"
                    lazy-validation
                    :readonly="readonly"
                >
                    <adaptive-item>
                        <template #left>
                            <v-select
                                v-model="formula"
                                :items="eventFormulas"

                                dense
                                outlined
                                hide-details

                                label="Повторяется"
                                prepend-icon="mdi-repeat"
                            />
                        </template>

                        <template #right>
                            <v-text-field
                                v-model="repeatInterval"
                                :disabled="!hasInterval"
                                :suffix="suffix"
                                type="number"
                                min="1"
                                :rules="hasInterval ? validation : []"

                                dense
                                outlined
                                hide-details

                                label="Повторять с интервалом"
                                prepend-icon="mdi-calendar-range-outline"
                            />
                        </template>
                    </adaptive-item>

                    <adaptive-item>
                        <template #left>
                            <v-text-field
                                :value="displayedDateStart"
                                type="text"

                                dense
                                outlined
                                hide-details
                                readonly

                                label="Начало повторения"
                                prepend-icon="mdi-calendar-outline"

                                @click="$event => showDatePicker($event, 'dateStart')"
                            />
                        </template>

                        <template #right>
                            <v-text-field
                                :value="displayedTimeStart"
                                type="text"

                                dense
                                outlined
                                hide-details
                                readonly

                                label="Время повторения"
                                prepend-icon="mdi-clock-check-outline"

                                @click="$event => showTimePicker($event, 'dateStart')"
                            />
                        </template>
                    </adaptive-item>

                    <adaptive-item>
                        <template #left>
                            <v-text-field
                                v-model="name"
                                type="text"

                                dense
                                outlined
                                hide-details

                                label="Название (опционально)"
                                prepend-icon="mdi-rename"
                            />
                        </template>

                        <template #right>
                            <v-select
                                v-model="color"
                                :items="colors"
                                :color="color"
                                :item-color="color"

                                dense
                                outlined
                                hide-details

                                label="Цвет"
                            >
                                <template #prepend>
                                    <v-icon
                                        v-text="'mdi-palette'"
                                        :color="color"
                                    />
                                </template>

                                <template #item="{ item: { text, value }, on, attrs }">
                                    <v-list-item
                                        v-on="on"
                                        v-bind="attrs"
                                    >
                                        <v-list-item-icon>
                                            <v-icon
                                                v-text="'mdi-rectangle'"
                                                :color="value"
                                            />
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ text }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-select>
                        </template>
                    </adaptive-item>

                    <adaptive-item>
                        <template #left>
                            <v-radio-group
                                v-model="repeatUntil"
                                dense
                                mandatory
                                hide-details
                                class="mt-0"
                            >
                                <template #label>Повторять до</template>

                                <v-radio value="none">
                                    <template #label>
                                        <div
                                            class="d-flex align-center"
                                            style="height: 40px;"
                                        >
                                            Нет конечной даты
                                        </div>
                                    </template>
                                </v-radio>

                                <v-radio value="count">
                                    <template #label>
                                        <div class="w-full d-flex align-center">
                                            <span v-text="'Число повторений'" />

                                            <v-text-field
                                                v-model="repeatCount"
                                                type="number"
                                                min="1"
                                                :rules="validation"

                                                dense
                                                outlined
                                                hide-details

                                                class="ml-2"
                                                style="max-width: 65px;"
                                            />
                                        </div>
                                    </template>
                                </v-radio>

                                <v-radio value="date">
                                    <template #label>
                                        <div class="w-full d-flex align-center">
                                            <span v-text="'Дата окончания'" />

                                            <v-text-field
                                                :value="displayedDateEnd"
                                                type="text"

                                                dense
                                                outlined
                                                readonly
                                                hide-details

                                                class="ml-2"
                                                style="max-width: 110px;"

                                                @click="$event => showDatePicker($event, 'dateEnd')"
                                            />
                                        </div>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </template>

                        <template #right>
                            <repeat-rule
                                v-if="hasRule"
                                :formula="formula"
                                :weekdays="weekdays"
                                :month-day="monthDay"
                                :dialog="dialog"
                                @update:weekdays="weekdays = $event"
                                @update:monthDay="monthDay = $event"
                            />
                        </template>
                    </adaptive-item>
                </v-form>
            </template>

            <template #close>
                <v-btn
                    block
                    color="secondary"
                    @click="close"
                >
                    Закрыть
                </v-btn>
            </template>

            <template #submit>
                <v-btn
                    v-if="!readonly"
                    block
                    color="primary"
                    @click="submit"
                >
                    Применить
                </v-btn>
            </template>
        </adaptive-dialog>

        <date-picker ref="datePicker" />

        <time-picker ref="timePicker" />
    </div>
</template>

<script>
import { formatDate, formatTime } from "@u/formatting";
import eventFormulas from "@u/events/event-formulas";

import AdaptiveDialog from "@c/norton-commander/AdaptiveDialog";
import AdaptiveItem from "@c/event-planner/dialogs/event-create/AdaptiveItem";
import DatePicker from "@c/DatePicker";
import TimePicker from "@c/TimePicker";
import RepeatRule from "@c/event-planner/dialogs/event-create/RepeatRule";

export default {
    name: 'EventCreate',

    components: {
        AdaptiveDialog,
        AdaptiveItem,
        DatePicker,
        TimePicker,
        RepeatRule,
    },

    data: () => ({
        dialog: false,
        readonly: false,

        name: '',
        color: 'blue',

        formula: 'every-month',
        repeatUntil: 'none',

        repeatCount: 1,
        repeatInterval: 1,

        dateStart: new Date(),
        dateEnd: new Date(),

        weekdays: {
            type: 'weekdays',
            value: [],
        },
        monthDay: {
            type: 'date',
            value: [1],
        },

        colors: [
            {
                value: 'blue',
                text: 'Синий',
            },
            {
                value: 'red',
                text: 'Красный',
            },
            {
                value: 'green',
                text: 'Зеленый',
            },
            {
                value: 'pink',
                text: 'Розовый',
            },
            {
                value: 'purple',
                text: 'Фиолетовый',
            },
            {
                value: 'indigo',
                text: 'Индиго',
            },
            {
                value: 'orange',
                text: 'Оранжевый',
            },
        ],

        validation: [v => v >= 1],
    }),

    computed: {
        eventFormulas() {
            return eventFormulas.map(({ alias, label }) => ({
                value: alias,
                text: `${label.charAt(0).toUpperCase()}${label.slice(1)}`,
            }));
        },

        hasInterval() {
            return this.formula !== 'every-working-day';
        },

        hasRule() {
            switch (this.formula) {
                case 'every-week':
                case 'every-month':
                case 'every-year':
                    return true;
                default:
                    return false;
            }
        },

        displayedDateStart() {
            return formatDate(this.dateStart);
        },

        displayedDateEnd() {
            return this.dateEnd.toLocaleDateString(undefined);
        },

        displayedTimeStart() {
            return formatTime(this.dateStart);
        },

        suffix() {
            const currentFormula = eventFormulas
                .find(({ alias }) => alias === this.formula);

            return currentFormula?.suffix;
        },
    },

    watch: {
        dateStart(val) {
            if (val >= this.dateEnd) {
                this.dateEnd = new Date(val);
            }
        },
    },

    methods: {
        open(event, readonly = false) {
            if (event) {
                this.name = event.name;
                this.color = event.color;
                this.formula = event.formula;

                this.repeatUntil = event.repeatUntil;
                this.repeatCount = event.maxCount;
                this.repeatInterval = event.interval;

                this.dateStart = new Date(event.start);
                this.dateEnd = new Date(event.end);

                if (event.formula === 'every-week') {
                    this.weekdays = event.rule;
                }

                if (event.formula === 'every-month') {
                    this.monthDay = event.rule;
                }

                this.readonly = readonly;
            } else {
                const start = new Date();
                start.setHours(0, 0, 0, 0);
                this.dateStart = start;

                const end = new Date();
                end.setHours(23, 59, 59, 59);
                this.dateEnd = end;
            }

            this.dialog = true;
        },

        close() {
            this.dialog = false;
            this.readonly = false;

            setTimeout(() => {
                this.name = '';
                this.color = 'blue';

                this.formula = 'every-month';
                this.repeatUntil = '';

                this.repeatCount = 1;
                this.repeatInterval = 1;

                this.dateStart = new Date();
                this.dateEnd = new Date();

                this.weekdays = {
                    type: 'weekdays',
                    value: [],
                },
                this.monthDay = {
                    type: 'date',
                    value: [1],
                };

                this.$refs.form.resetValidation();
            }, 200);
        },

        submit() {
            if (!this.$refs.form.validate()) {
                return;
            }

            const interval = this.formula === 'every-working-day' ? 1 : Number(this.repeatInterval);

            const event = {
                name: this.name,
                color: this.color,

                formula: this.formula,
                repeatUntil: this.repeatUntil,

                maxCount: Number(this.repeatCount),
                interval,

                start: this.dateStart,
                end: this.dateEnd,

                transactions: [],
            };

            if (event.formula === 'every-week') {
                event.rule = this.weekdays;
            }

            if (event.formula === 'every-month') {
                event.rule = this.monthDay;
            }

            this.$emit('input', event);

            this.close();
        },

        async showDatePicker($event, key) {
            if (this.readonly) {
                return;
            }

            const allowedDates = (dateString) => {
                switch (key) {
                    case 'dateStart':
                        return true;
                    case 'dateEnd':
                        return new Date(dateString) >= this.dateStart;
                }
            };

            const result = await this.$refs.datePicker.open({
                activator: $event.target,
                date: this[key],
                allowedDates,
            });

            if (result) {
                this[key] = this.swapDate(this[key], result);
            }
        },

        async showTimePicker($event, key) {
            if (this.readonly) {
                return;
            }

            const result = await this.$refs.timePicker.open({
                activator: $event.target,
                date: this.dateStart,
            });

            if (result) {
                this[key] = this.swapDate(result, this[key]);
            }
        },

        swapDate(oldDate, newDate) {
            const h = oldDate.getHours();
            const m = oldDate.getMinutes();

            const result = new Date(newDate);
            result.setHours(h, m, 0, 0);

            return result;
        },

        allowedDates(dateString) {
            const date = new Date(dateString);

            return date >= this.dateStart;
        },
    },
};
</script>
