<template>
    <div>
        <span v-text="'Дни повторения'" />

        <v-checkbox
            v-for="weekday in weekdaysOrdered"
            :key="weekday.value"
            v-model="selected"
            v-bind="weekday"
            :dense="!$isMobile()"
            hide-details
            class="ma-0"
            :rules="validation"
        />
    </div>
</template>

<script>
export default {
    name: 'RuleWeek',

    inject: ['$isMobile'],

    props: {
        rule: {
            type: Object,
            required: true,
        },
        dialog: {
            type: Boolean,
            required: true,
        },
    },

    data: () => ({
        selected: [],
        weekdays: [
            'Вс',
            'Пн',
            'Вт',
            'Ср',
            'Чт',
            'Пт',
            'Сб',
        ],
        validation: [v => v.length ? true : false],
    }),

    mounted() {
        const { value } = this.rule;
        this.selected = [...value];
    },

    computed: {
        weekdaysProcessed() {
            return this.weekdays.map((weekday, index) => ({
                value: index,
                label: String(weekday),
            }));
        },

        weekdaysOrdered() {
            return [
                ...this.weekdaysProcessed.slice(1),
                this.weekdaysProcessed[0],
            ];
        },
    },

    watch: {
        selected(value) {
            this.$emit('update', {
                type: 'weekdays',
                value,
            });
        },

        dialog() {
            const { value } = this.rule;
            this.selected = [...value];
        },
    },
};
</script>
