<template>
    <v-card :loading="loading" :disabled="loading || !snpid">
        <v-dialog v-model="showFancy" v-if="info" width="600">
            <v-card>
                <v-carousel v-model="slide" height="600">
                    <v-carousel-item v-for="(i, n) in info.images" :key="n">
                        <img :src="i.b" alt="" class="slide" />
                    </v-carousel-item>
                </v-carousel>
            </v-card>
        </v-dialog>
        <v-toolbar dense flat>
            <v-spacer />
            <v-btn block color="primary" @click="getData">
                <v-icon>mdi-download</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text v-if="info">
            <v-row>
                <v-col>
                    <a :href="info.link" target="_blank">{{ info.name }}</a>
                    <p>Артикул: {{ info.id1c }}</p>
                    <div v-html="info.descr" />
                </v-col>
                <v-col>
                    <v-carousel
                        v-model="slide"
                        v-if="info.images.length > 0"
                        height="310"
                    >
                        <v-carousel-item
                            v-for="(i, n) in info.images"
                            :key="n"
                            @click="showFancy = true"
                        >
                            <img :src="i.m" alt="" class="slide" />
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
            </v-row>
            <v-simple-table>
                <tbody v-html="info.tech" />
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<style lang="scss" scoped>
.slide {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
</style>

<script>
export default {
    name: "CatalogProductCard",
    data() {
        return this.getDefaultData();
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        current: {
            type: Boolean,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
    },
    watch: {
        show: {
            immediate: true,
            handler() {
                for (const [key, value] of Object.entries(
                    this.getDefaultData()
                )) {
                    this[key] = value;
                }
            },
        },
        current: {
            immediate: true,
            handler(dir) {
                if (dir && !this.loaded && this.snpid) {
                    this.getData();
                }
            },
        },
    },
    computed: {
        snpid() {
            const f = this.fields.find((f) => f.perks.includes("snpid"));
            if (!f || !f.value) {
                return "";
            }
            return f.value[0].value;
        },
    },
    methods: {
        getDefaultData: () => ({
            loading: false,
            loaded: false,
            info: null,
            slide: 0,
            showFancy: false,
        }),
        async getData() {
            this.loading = true;
            try {
                const { jwt } = await this.$api("/auth/jwt");
                const query = new URLSearchParams();
                query.append("id", this.snpid);
                const { data, status } = await fetch(
                    `${process.env.VUE_APP_SNP_API}?${query.toString()}`,
                    {
                        headers: {
                            "X-AUTH-TOKEN": jwt,
                        },
                    }
                ).then((r) => r.json());
                if (!status) {
                    throw new Error(this.$t("main.error"));
                }
                this.info = data;
                this.loaded = true;
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
    },
};
</script>
