<template>
    <v-list class="py-0">
        <v-btn
            block
            @click="add"
            :disabled="parentLoading"
            text
            color="primary"
        >
            {{ $t("fields.thes.add") }}
        </v-btn>
        <v-list-item v-for="l in list" :key="l.id" class="px-0">
            <v-list-item-title>
                <v-progress-circular
                    indeterminate
                    v-if="inLoading(l.id)"
                    size="20"
                    color="primary"
                />
                <template v-else>{{ l.name }}</template>
            </v-list-item-title>
            <v-list-item-action v-if="!isSystem">
                <div class="d-flex">
                    <v-btn
                        icon
                        color="primary"
                        @click="edit(l.id, l.name)"
                        :disabled="inLoading(l.id) || parentLoading"
                    >
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        color="error"
                        @click="remove(l.id, l.name)"
                        :disabled="inLoading(l.id) || parentLoading"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </v-list-item-action>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
    name: "FieldThes",
    model: {
        prop: "list",
    },
    data: () => ({
        loading: [],
    }),
    props: {
        id: {
            type: Number,
            required: true,
        },
        list: {
            type: Array,
            required: true,
        },
        isSystem: {
            type: Boolean,
            required: true,
        },
        parentLoading: {
            type: Boolean,
            required: true,
        },
        owner: {
            type: String,
            required: true,
        },
    },
    methods: {
        inLoading(id) {
            return this.loading.includes(id);
        },
        async add() {
            const name = await this.$prompt({
                title: this.$t("fields.thes.add"),
                rules: [(v) => !!v || this.$t("form.required")],
            });
            if (!name) {
                return;
            }
            this.$emit("update:parentLoading", true);
            try {
                const inserted = await this.$api(
                    `/${this.owner}/fields/${this.id}/thesaurus`,
                    {
                        method: "POST",
                        body: {
                            name,
                        },
                    }
                );
                const list = [...this.list];
                list.push(inserted);
                this.$emit("input", list);
            } catch (e) {
                this.$error(e.message);
            }
            this.$emit("update:parentLoading", false);
        },
        async edit(id, prevName) {
            const name = await this.$prompt({
                value: prevName,
                title: this.$t("main.editing"),
                rules: [(v) => !!v || this.$t("form.required")],
            });
            if (!name) {
                return;
            }
            this.loading.push(id);
            try {
                await this.$api(
                    `/${this.owner}/fields/${this.id}/thesaurus/${id}`,
                    {
                        body: {
                            name,
                        },
                        method: "PATCH",
                    }
                );
                const list = this.list.map((l) => ({ ...l }));
                for (let l of list) {
                    if (l.id == id) {
                        l.name = name;
                        break;
                    }
                }
                this.$emit("input", list);
            } catch (e) {
                this.$error(e.message);
            }
            for (let i = 0; i < this.loading.length; i++) {
                if (this.loading[i] == id) {
                    this.loading.splice(i, 1);
                    break;
                }
            }
        },
        async remove(id, name) {
            if (this.list.length == 1) {
                const c = await this.$confirm({
                    text: this.$t("fields.thes.last"),
                });
                if (!c) {
                    return;
                }
                this.$emit("update:parentLoading", true);
                try {
                    await this.$api(`/${this.owner}/fields/${this.id}`, {
                        method: "DELETE",
                    });
                    this.$emit("deleted");
                } catch (e) {
                    this.$error(e.message);
                }
                this.$emit("update:parentLoading", false);
                return;
            }
            const c = await this.$confirm({
                text: this.$t("fields.thes.remove", { name }),
                actions: {
                    false: {
                        text: this.$t('main.close'),
                    },
                    true: {
                        text: this.$t('main.remove'),
                        color: 'error',
                    }
                },
            });
            if (!c) {
                return;
            }
            this.loading.push(id);
            try {
                await this.$api(
                    `/${this.owner}/fields/${this.id}/thesaurus/${id}`,
                    {
                        method: "DELETE",
                    }
                );
                const list = [...this.list];
                for (let i = 0; i < list.length; i++) {
                    if (list[i].id == id) {
                        list.splice(i, 1);
                        break;
                    }
                }
                this.$emit("input", list);
            } catch (e) {
                this.$error(e.message);
            }
            for (let i = 0; i < this.loading.length; i++) {
                if (this.loading[i] == id) {
                    this.loading.splice(i, 1);
                    break;
                }
            }
        },
    },
};
</script>