<template>
    <v-row dense>
        <v-col
            v-for="button in buttons"
            :key="button.id"
            class="d-flex align-center justify-center"
        >
            <v-btn
                :data-test-id="`saved-filters-list-item-${id}-${button.id}-button`"

                depressed
                :class="button.textClass"
                @click="$emit(button.id)"
            >
                <v-icon :color="button.color">
                    {{ button.icon }}
                </v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "FilterButtons",

    props: {
        id: {
            type: Number,
            required: true,
        },
    },

    data: () => ({
        buttons: [
            {
                id: `delete`,
                icon: 'mdi-delete-outline',
                text: 'Удалить',
                color: 'error',
                textClass: 'error--text',
            },
            {
                id: `edit`,
                icon: 'mdi-pencil-outline',
                text: 'Редактировать',
                color: 'grey',
                textClass: 'text--secondary',
            },
            {
                id: `apply`,
                icon: 'mdi-check',
                text: 'Применить',
                color: 'success',
                textClass: 'success--text',
            },
        ],
    }),
};
</script>
