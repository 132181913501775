<template>
    <v-card
        :disabled="disabled"
        class="w-full h-full max-h-full d-flex flex-column bg-transparent"
    >
        <div
            class="flex-grow-1"
            style="min-height: 0;"
        >
            <div class="h-full d-flex">
                <div class="w-full h-full">
                    <slot name="calendar" />
                </div>

                <div
                    class="h-full"
                    :style="{
                        width: '45rem',
                        borderLeft: 'solid #2196F3',
                    }"
                >
                    <v-card class="h-full d-flex flex-column">
                        <div>
                            <v-toolbar elevation="1">
                                <slot name="tabs" />
                            </v-toolbar>
                        </div>

                        <v-divider />

                        <div class="h-full flex-grow-1 overflow-y-scroll">
                            <slot name="event-list" />
                        </div>
                    </v-card>
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
export default {
    name: "DesktopLayout",

    props: {
        busy: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
