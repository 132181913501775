<template>
    <v-container
        v-if="!$isMobile()"
        fluid
    >
        <v-row>
            <v-col>
                <slot name="left" />
            </v-col>

            <v-col>
                <slot name="right" />
            </v-col>
        </v-row>
    </v-container>

    <v-container
        v-else
        fluid
    >
        <v-row dense>
            <v-col>
                <slot name="left" />
            </v-col>
        </v-row>

        <v-row dense>
            <v-col>
                <slot name="right" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "ButtonsSave",

    inject: ['$isMobile'],
};
</script>
