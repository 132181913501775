<template>
    <v-autocomplete
        outlined
        :label="$t('main.search')"
        dense
        hide-no-data
        prepend-inner-icon="mdi-magnify"
        hide-details
        @update:search-input="search"
        :loading="loading"
        :items="items"
        @change="change"
    />
</template>

<script>
export default {
    name: "CategorySearch",
    data: () => ({
        loading: false,
        items: [],
        field: null,
    }),
    methods: {
        change(category) {
            this.items = [];
            this.$router.push({
                params: {
                    category,
                    pg: null,
                },
            });
        },
        search(s) {
            clearTimeout(this.search.t);
            if (!s || !s.length) {
                return;
            }
            this.search.t = setTimeout(async () => {
                this.loading = true;
                try {
                    if (!this.field) {
                        const fields = await this.$api(`/category/fields`);
                        this.field = fields
                            .filter((f) => f.type == "catname")
                            .pop().id;
                    }
                    const query = new URLSearchParams();
                    query.append("filter[]", `${this.field}|∋|${s}`);
                    const r = await this.$apiResponse(
                        `/entity/category?${query.toString()}`
                    );
                    let data = [];
                    if (r.status == 200) {
                        ({ data } = await r.json());
                    }
                    this.items = data.map((d) => ({
                        value: d.id,
                        text: d.name,
                    }));
                } catch (e) {
                    this.$error(e.message);
                }
                this.loading = false;
            }, 500);
        },
    },
};
</script>
