<template>
    <v-menu v-model="menu" offset-y :close-on-content-click="false">
        <template #activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                {{ head.name }}
                <v-icon v-if="flt[head.id]"> mdi-filter </v-icon>
                <template v-if="sort == head.id">
                    <v-icon dense>
                        <template v-if="dir">mdi-sort-ascending</template>
                        <template v-else>mdi-sort-descending</template>
                    </v-icon>
                </template>
            </div>
        </template>
        <v-card>
            <v-card-title>{{ $t("filter.title") }}</v-card-title>
            <v-card-subtitle>{{ $t("filter.subtitle", { field: head.name }) }}</v-card-subtitle>
            <v-divider />
            <v-btn
                v-for="v in sortVariants"
                :key="v.value"
                text
                block
                class="justify-start"
                :small="$vuetify.breakpoint.mobile"
                :input-value="sort == head.id && v.value == dir"
                @click="changeSort(head.id, v.value)"
            >
                <v-icon class="ml-1 mr-3">{{ v.icon }}</v-icon>
                {{ v.text }}
            </v-btn>
            <v-divider />
            <v-form @submit.prevent="addFlt(head.id, operation, head.flt)">
                <v-card-text>
                    <v-select
                        item-value="operation"
                        item-text="name"
                        :items="head.filters"
                        :readonly="head.filters.length == 1"
                        :value="operation"
                        @input="$emit('update:operation', $event)"
                        dense
                    />
                    <owner-filter
                        :head="head"
                        :value="headFlt"
                        @input="$emit('update:headFlt', $event)"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text color="error" @click="removeFlt(head)">
                        {{ $t("main.cancel") }}
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        type="submit"
                        :disabled="head.flt == null"
                    >
                        {{ $t("main.apply") }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-menu>
</template>

<script>
import OwnerFilter from "./Filter";
import { encode } from "@u/flt";
export default {
    name: "OwnerMenu",
    props: {
        head: {
            type: Object,
            required: true,
        },
        flt: {
            type: Object,
            required: true,
        },
        sort: {
            type: Number,
            required: true,
        },
        dir: {
            type: Number,
            required: true,
        },
        operation: {
            type: String,
            required: true,
        },
        headFlt: {
            validator: (f) =>
                ["string", "number"].includes(typeof f) || f == null,
        },
    },
    components: {
        OwnerFilter,
    },
    data: () => ({
        menu: false,
    }),
    computed: {
        sortVariants() {
            return [
                {
                    icon: "mdi-sort-descending",
                    text: this.$t("main.sort.up"),
                    value: 0,
                },
                {
                    icon: "mdi-sort-ascending",
                    text: this.$t("main.sort.down"),
                    value: 1,
                },
            ];
        },
    },
    methods: {
        changeSort(sort, dir) {
            this.$router.push({
                params: {
                    pg: null,
                },
                query: {
                    sort,
                    dir,
                    ...encode(this.flt),
                    t: Date.now(),
                },
            });
        },
        addFlt(id, operation, value) {
            this.$router.push({
                params: {
                    pg: null,
                },
                query: {
                    t: Date.now(),
                    sort: this.sort,
                    dir: this.dir,
                    ...encode({
                        ...this.flt,
                        [id]: { operation, value },
                    }),
                },
            });
            this.menu = false;
        },
        removeFlt(h) {
            h.flt = null;
            const query = {
                t: Date.now(),
                ...encode(
                    Object.keys(this.flt).reduce((acc, key) => {
                        if (key != h.id) {
                            acc[key] = this.flt[key];
                        }
                        return acc;
                    }, {})
                ),
            };
            if (this.sort != this.head.id) {
                query.sort = this.sort;
                query.dir = this.dir;
            }
            this.$router.push({
                params: {
                    pg: null,
                },
                query,
            });
            this.menu = false;
        },
    },
};
</script>