<template>
    <v-row>
        <v-col>
            <v-text-field
                :label="$t('form.lat')"
                type="number"
                :value="value ? value.lat : null"
                :clearable="!cellMode"
                dense
                :rules="rules"
                @input="input('lat', $event)"
                :readonly="cellMode"
            />
        </v-col>
        <v-col>
            <v-text-field
                :label="$t('form.lon')"
                type="number"
                :value="value ? value.lon : null"
                :clearable="!cellMode"
                dense
                :rules="rules"
                @input="input('lon', $event)"
                :readonly="cellMode"
            />
        </v-col>
    </v-row>
</template>

<script>
import FieldBase from "./FieldBase";
export default {
    name: "FieldCoords",
    extends: FieldBase,
    props: {
        value: {
            type: Object,
        },
    },
    computed: {
        cellMode() {
            return !!this.meta.rack;
        },
        rules() {
            const rules = [];
            if (
                (this.value && (this.value.lon || this.value.lat)) ||
                this.field.perks.includes("required")
            ) {
                rules.push(
                    (v) =>
                        String(v ?? "").length > 0 || this.$t("form.required")
                );
            }
            return rules;
        },
    },
    methods: {
        input(key, value) {
            this.$emit("input", {
                ...this.value,
                [key]: value ? Number(value) : value,
            });
        },
        getDefaultValue() {
            if (!this.id && this.cellMode) {
                return {
                    lon: this.meta.coords.lon,
                    lat: this.meta.coords.lat,
                };
            }
            return {
                lon: null,
                lat: null,
            };
        },
        setValue(v) {
            this.$emit("input", {
                lon: v.lon,
                lat: v.lat,
            });
        },
        getValue() {
            for (let k of ["lon", "lat"]) {
                if (this.value[k] == null) {
                    return "";
                }
            }
            return this.value;
        },
    },
};
</script>