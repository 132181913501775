<template>
    <tr :class="{ 'selected-item': isActive }">
        <td
            v-for="(column, index) in row"
            :key="index"
            :class="column.class"
        >
            <v-list-item
                v-if="column.type && buttonText"
                @click="$emit('filter:modify', { type: column.type, value: column.filter })"
            >
                <div
                    :class="column.class"
                    style="width: 100%;"
                >
                    {{ buttonText }}
                </div>
            </v-list-item>

            <span
                v-else
                v-text="column.value"
                class="text-no-wrap"
            />
        </td>
    </tr>
</template>

<script>
import { PathToCashbox, getPathToCostTemplate } from '@u/path';

export default {
    name: "ReportTableRow",

    props: {
        row: {
            type: Array,
            required: true,
        },
        cashboxCategories: {
            type: Array,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        costCategories: {
            type: Array,
            required: true,
        },
        costTemplates: {
            type: Array,
            required: true,
        },
        filterModifier: {
            type: Object,
            required: true,
        },
    },

    computed: {
        meta() {
            return this.row.find((col) => col.type);
        },

        isActive() {
            if (!this.meta) {
                return;
            }

            const sameType = this.filterModifier.type === this.meta.type;
            const sameValue = this.filterModifier.value === this.meta.filter;

            return sameType && sameValue;
        },

        buttonText() {
            if (!this.meta) {
                return;
            }

            if (this.meta.type === 'cashbox') {
                const id = this.meta.filter;

                const cashbox = this.cashboxes.find((c) => String(c.id) === String(id));

                if (!cashbox) {
                    return `#${id}`;
                }

                const path = new PathToCashbox(cashbox, this.cashboxCategories);
                const title = `#${cashbox.id} ${cashbox.title}`;

                const pathChunks = path.array.map(({ title }) => title);
                pathChunks.push(title);
                return pathChunks.join(' / ');
            }

            if (this.meta.type === 'costs.template') {
                const id = this.meta.filter;

                return getPathToCostTemplate(
                    id,
                    this.costTemplates,
                    this.costCategories,
                );
            }

            if (['processingDate', 'transactionId'].includes(this.meta.type)) {
                return `${this.meta.value}`;
            }

            return '';
        },
    }
};
</script>
