<template>
    <div class="pb-1 body-1 text--primary font-weight-medium">
        <span
            class="d-flex"
            :class="{ 'primary--text': condition }"
        >
            <v-icon
                dense
                :color="condition ? 'primary' : ''"
            >
                {{ icon }}
            </v-icon>

            <span class="mx-2">{{ title }}</span>

            <template v-if="condition">
                <span class="font-italic font-weight-regular">
                    <span class="primary--text">
                        (изменено)
                    </span>

                    <span style="display: inline-block;">
                        <slot />
                    </span>
                </span>
            </template>
        </span>
    </div>
</template>

<script>
export default {
    name: "ItemTitle",

    props: {
        title: {
            type: String,
            default: '',
        },

        icon: {
            type: String,
            default: '',
        },

        condition: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
