<template>
    <v-card ref="root" :loading="loading" :disabled="loading">
        <entity-editor ref="editor" :owner="owner" :tabs="tabs">
            <template v-for="t in tabs" #[`tab-${t.id}`]="scope">
                <slot :name="`tab-${t.id}`" v-bind="scope" />
            </template>
        </entity-editor>
        <owner-remove ref="remove" :owner="owner" />
        <owner-head
            ref="head"
            :owner="owner"
            :disabled="Object.keys(appendFlt).map((a) => Number(a))"
        />
        <v-toolbar
            flat
            class="tool"
            dense
            :style="{
                top: loading ? 0 : `${$vuetify.application.top}px`,
            }"
        >
            <v-spacer />
            <v-btn
                icon
                color="error"
                :disabled="Object.keys(flt).length == 0"
                @click="clearFlt"
            >
                <v-icon>mdi-filter-remove</v-icon>
            </v-btn>
            <v-btn icon color="error" :disabled="!sort" @click="clearSort">
                <v-icon>mdi-sort-variant-remove</v-icon>
            </v-btn>
            <v-btn icon @click="openHeadEditor">
                <v-icon>mdi-cog</v-icon>
            </v-btn>
            <v-btn icon color="primary" @click="edit(0)">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-toolbar>
        <template v-if="isMobile">
            <v-card v-for="l in list" :key="l.id" class="mb-4">
                <v-toolbar flat>
                    <v-toolbar-title>#{{ l.id }}</v-toolbar-title>
                    <v-spacer />
                    <v-btn icon color="primary" @click="edit(l.id)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon color="error" @click="remove(l.id)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <owner-menu
                                :head="id"
                                :operation.sync="id.operation"
                                :head-flt.sync="id.flt"
                                :flt="flt"
                                :sort="sort"
                                :dir="dir"
                            />
                        </v-col>
                        <v-col>
                            <owner-row
                                :type="id.type"
                                :value="l.id"
                                :perks="id.perks"
                            />
                        </v-col>
                    </v-row>
                    <v-divider class="mb-2" />
                    <template v-for="h in head">
                        <v-row :key="h.id">
                            <v-col>
                                <owner-menu
                                    :head="h"
                                    :operation.sync="h.operation"
                                    :head-flt.sync="h.flt"
                                    :flt="flt"
                                    :sort="sort"
                                    :dir="dir"
                                />
                            </v-col>
                            <v-col>
                                <owner-row
                                    :type="h.type"
                                    :value="l.values[h.id]"
                                    :perks="h.perks"
                                />
                            </v-col>
                        </v-row>
                        <v-divider :key="`d-${h.id}`" class="mb-2" />
                    </template>
                </v-card-text>
            </v-card>
        </template>
        <v-simple-table v-else>
            <thead>
                <tr>
                    <th>
                        <owner-menu
                            :head="id"
                            :operation.sync="id.operation"
                            :head-flt.sync="id.flt"
                            :flt="flt"
                            :sort="sort"
                            :dir="dir"
                        />
                    </th>
                    <th v-for="h in head" :key="h.id">
                        <owner-menu
                            :head="h"
                            :operation.sync="h.operation"
                            :head-flt.sync="h.flt"
                            :flt="flt"
                            :sort="sort"
                            :dir="dir"
                        />
                    </th>
                    <th style="width: 110px"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="l in list" :key="l.id">
                    <td>{{ l.id }}</td>
                    <td v-for="h in head" :key="h.id">
                        <owner-row
                            :type="h.type"
                            :value="l.values[h.id]"
                            :perks="h.perks"
                        />
                    </td>
                    <td>
                        <v-btn icon color="primary" @click="edit(l.id)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon color="error" @click="remove(l.id)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <v-toolbar class="pagi" v-if="total > 1 || pg > 1" dense>
            <v-spacer />
            <v-pagination :value="pg" :length="total" @input="changePg" />
        </v-toolbar>
    </v-card>
</template>

<style lang="scss" scoped>
.tool {
    position: sticky;
    z-index: 1;
}
.pagi {
    position: sticky;
    bottom: 0;
}
</style>

<script>
import EntityEditor from "@c/EntityEditor";
import OwnerRemove from "./Remove";
import OwnerRow from "./Row";
import OwnerHead from "./Head";
import OwnerMenu from "./Menu";
import { encode } from "@u/flt";
export default {
    name: "OwnerList",
    components: {
        EntityEditor,
        OwnerRemove,
        OwnerRow,
        OwnerHead,
        OwnerMenu,
    },
    props: {
        tabs: {
            type: Array,
            default: () => [],
        },
        owner: {
            type: String,
            required: true,
        },
        pg: {
            type: Number,
            required: true,
        },
        sort: {
            type: Number,
            required: true,
        },
        dir: {
            type: Number,
            required: true,
        },
        flt: {
            type: Object,
            required: true,
        },
        appendFlt: {
            type: Object,
            default: () => ({}),
        },
        meta: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        head: [],
        list: [],
        loading: false,
        total: 1,
        observer: null,
        isMobile: false,
        id: {
            batch: null,
            filters: [
                {
                    "operation": "=",
                    "name": "Равно"
                },
                {
                    "operation": "≠",
                    "name": "Не равно"
                },
                {
                    "operation": ">",
                    "name": "Больше, чем"
                },
                {
                    "operation": "<",
                    "name": "Меньше, чем"
                },
                {
                    "operation": "∩",
                    "name": "В диапазоне"
                }
            ],
            flt: null,
            hidden: 0,
            id: 0,
            name: "#",
            operation: '=',
            perks: [],
            sort: 0,
            thesauruses: [],
            type: "number",
        },
    }),
    watch: {
        $route: {
            immediate: true,
            async handler() {
                await this.getHead();
                await this.getList();
            },
        },
    },
    async mounted() {
        this.observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                let width = 0;
                if (entry.contentBoxSize) {
                    const contentBoxSize = Array.isArray(entry.contentBoxSize)
                        ? entry.contentBoxSize[0]
                        : entry.contentBoxSize;
                    width = contentBoxSize.inlineSize;
                } else {
                    width = entry.contentRect.width;
                }
                this.isMobile = width < 900;
            }
        });
        this.observer.observe(this.$refs.root.$el);
    },
    beforeDestroy() {
        this.observer.disconnect();
    },
    methods: {
        clearFlt() {
            this.head.map((h) => (h.flt = null));
            this.$router.push({
                params: {
                    pg: null,
                },
                query: {
                    t: Date.now(),
                    sort: this.sort,
                    dir: this.dir,
                },
            });
        },
        clearSort() {
            this.$router.push({
                params: {
                    pg: null,
                },
                query: {
                    t: Date.now(),
                    ...encode(this.flt),
                },
            });
        },
        changePg(pg) {
            this.$router.push({
                params: {
                    pg: pg > 1 ? pg : null,
                },
                query: {
                    t: Date.now(),
                    sort: this.sort,
                    dir: this.dir,
                    ...encode(this.flt),
                },
            });
        },
        async getHead() {
            this.loading = true;
            try {
                this.head = (await this.$api(`/${this.owner}/fields/head`))
                    .filter(
                        (h) =>
                            !Object.keys(this.appendFlt)
                                .map((a) => Number(a))
                                .includes(h.id)
                    )
                    .map((h) => {
                        h.operation =
                            this.flt[h.id]?.operation ?? h.filters[0].operation;
                        h.flt = this.flt[h.id]?.value ?? null;
                        return h;
                    });
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
        async getList() {
            this.loading = true;
            try {
                const params = new URLSearchParams();
                params.append("page", this.pg);
                if (this.sort) {
                    params.append(
                        "sort",
                        `${this.sort}|${this.dir ? "desc" : "asc"}`
                    );
                }
                Object.entries({ ...this.flt, ...this.appendFlt }).forEach(
                    ([k, v]) =>
                        params.append(
                            `filter[]`,
                            `${k}|${v.operation}|${v.value}`
                        )
                );
                const r = await this.$apiResponse(
                    `/entity/${this.owner}?${params.toString()}`
                );
                let data = [];
                let hasNext = false;
                if (r.status != 204) {
                    ({
                        data,
                        pagination: { hasNext },
                    } = await r.json());
                }
                this.list = data.map((d) => {
                    d.values = d.values.reduce((acc, v) => {
                        acc[v.field] = v.value;
                        return acc;
                    }, {});
                    return d;
                });
                this.total = this.pg;
                if (hasNext) {
                    this.total = this.pg + 1;
                }
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
        async edit(id) {
            const needUpdate = await this.$refs.editor.open(id, this.meta);
            if (needUpdate) {
                this.getList();
            }
        },
        async remove(id) {
            const needUpdate = await this.$refs.remove.open(id);
            if (needUpdate) {
                this.getList();
            }
        },
        async openHeadEditor() {
            const needUpdate = await this.$refs.head.open();
            if (needUpdate) {
                await this.getHead();
                await this.getList();
            }
        },
    },
};
</script>