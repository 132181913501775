<template>
    <adaptive-dialog
        data-test-id="set-amount-dialog"
        :data-test-state="dialog"

        :dialog="dialog"
        max-width="500"
        :disableSubmit="!formValidation"

        @close="close"
        @submit="submit"
    >
        <template #title>
            Изменение суммы
        </template>

        <template #body>
            <div class="pb-5 body-1 text--primary">
                <div>{{ categoryText }}</div>
                <div class="font-weight-medium">#{{ id }} {{ title }}</div>
            </div>

            <v-divider class="pb-5" />

            <v-text-field
                v-model="balance"
                readonly
                disabled
                type="text"
                label="Текущий баланс"
                append-outer-icon="mdi-currency-rub"
            />

            <v-form
                ref="form"
                v-model="formValidation"
                lazy-validation
                @submit.prevent
            >
                <v-text-field
                    v-model="value"
                    clearable
                    ref="input"
                    type="number"
                    label="Новое значение"
                    append-outer-icon="mdi-currency-rub"
                    :rules="rules"
                    @focus="onFocus"
                />
            </v-form>
        </template>
    </adaptive-dialog>
</template>

<script>
import { formatNumber } from '@u/formatting';
import AdaptiveDialog from "@c/norton-commander/AdaptiveDialog";

export default {
    name: 'SetAmount',

    components: {
        AdaptiveDialog,
    },

    data: () => ({
        dialog: false,
        formValidation: true,

        category: '',
        id: '',
        title: '',
        balance: '',
        value: '',

        rules: [v => !!v || 'Поле не может быть пустым!'],
    }),

    computed: {
        categoryText() {
            if (this.category === 'cashbox') {
                return 'Изменение баланса на счете';
            }

            if (['costs.template', 'costs.element'].includes(this.category)) {
                return 'Изменение суммы расхода';
            }

            return '';
        },

        emit() {
            if (this.category === 'cashbox') {
                return 'cashbox:set-amount';
            }

            return 'cost:set-amount';
        },
    },

    methods: {
        open({ category, id, title, balance }) {
            this.$refs.form.resetValidation();
            setTimeout(() => {
                this.$refs.input.focus();
            }, 100);

            this.category = category;
            this.id = id;
            this.title = title;
            this.balance = formatNumber(Number(balance));
            this.value = balance !== '0' ? balance.toString() : '';

            this.dialog = true;
        },

        close() {
            this.dialog = false;

            setTimeout(() => {
                this.category = '';
                this.id = '';
                this.title = '';
                this.balance = '';
                this.value = '';
            }, 200);
        },

        submit() {
            this.$refs.form.validate();
            if (!this.formValidation) {
                return;
            }

            const payload = {
                id: this.id,
                category: this.category,
                amount: Number(this.value),
            };
            this.$emit(this.emit, payload);

            this.close();
        },

        onFocus($event) {
            this.$refs.form.resetValidation();
            $event.target.select();
        },
    },
};
</script>
