<template>
    <v-expansion-panel>
        <v-expansion-panel-header :data-test-id="`panel-${index}`">
            <template #default="{ open }">
                <div class="d-flex justify-space-between align-center">
                    <span class="subtitle-2">
                        {{ title }}
                        <span>
                            <slot name="header-action-left" :open="open" />
                        </span>
                    </span>

                    <span class="caption mr-2">
                        <slot name="header-action-right">
                            {{ balance }} {{ currency }}
                        </slot>
                    </span>
                </div>
            </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content eager>
            <v-card
                flat
                class="overflow-x-auto"
                style="min-height: 10px;"
            >
                <slot name="subheader" />

                <slot name="body" />
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
    name: "MobileLayoutCard",

    props: {
        busy: {
            type: Boolean,
            default: false,
        },

        title: {
            type: String,
            default: '',
        },

        balance: {
            type: String,
            default: '0',
        },

        currency: {
            type: String,
            default: '₽',
        },

        index: {
            type: Number,
            default: null,
        },
    },
};
</script>
