<template>
    <v-card :loading="loading" :disabled="loading" flat>
        <entity-editor
            owner="cell"
            ref="editor"
            :tabs="[
                { id: 'logs', name: $t('main.logs') },
                { id: 'remains', name: $t('main.remains') },
            ]"
        >
            <template #tab-logs="scope">
                <keep-log v-bind="scope" owner="cell" />
            </template>
            <template #tab-remains="scope">
                <keep-remains v-bind="scope" owner="cell" />
            </template>
        </entity-editor>
        <v-card-text>
            <v-simple-table class="rack">
                <tbody>
                    <tr v-for="(l, i) in list" :key="i">
                        <td v-for="(c, j) in l" :key="j">
                            <v-menu v-if="c" offset-y>
                                <template #activator="{ on, attrs }">
                                    <div v-on="on" v-bind="attrs">
                                        {{ c.name }}
                                    </div>
                                </template>
                                <v-list dense>
                                    <v-list-item @click="edit(c.id)">
                                        <v-list-item-icon>
                                            <v-icon color="primary">
                                                mdi-pencil
                                            </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            {{ $t("main.edit") }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="createInventory(c.id, c.name)">
                                        <v-list-item-icon>
                                            <v-icon color="primary">
                                                mdi-counter
                                            </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Инвентаризировать
                                        </v-list-item-title>
                                    </v-list-item>
                                    <!-- <v-list-item @click="remove(c.id)">
                                        <v-list-item-icon>
                                            <v-icon color="error">
                                                mdi-delete
                                            </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            {{ $t("main.remove") }}
                                        </v-list-item-title>
                                    </v-list-item> -->
                                </v-list>
                            </v-menu>
                            <v-btn
                                v-else
                                icon
                                color="primary"
                                @click="edit(0, { coords: { lon: i, lat: j } })"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<style lang="scss" scoped>
.rack {
    td {
        border: thin solid #bebebe;
        width: 100px;
        height: 75px;
        text-align: center;
    }
}
</style>

<script>
import EntityEditor from "@c/EntityEditor";
import KeepLog from "@c/keep/Log";
import KeepRemains from "@c/keep/Remains";
export default {
    name: "Cells",
    components: {
        EntityEditor,
        KeepLog,
        KeepRemains,
    },
    data: () => ({
        loading: false,
        list: [],
    }),
    props: {
        storage: {
            type: Number,
            required: true,
        },
        room: {
            type: Number,
            required: true,
        },
        rack: {
            type: Number,
            required: true,
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                this.getList();
            },
        },
    },
    methods: {

        async edit(id, meta = {}) {
            const needUpdate = await this.$refs.editor.open(id, {
                storage: this.storage,
                room: this.room,
                rack: this.rack,
                ...meta,
            });
            if (needUpdate) {
                this.getList();
            }
        },

        async createInventory(id, name) {
            const c = await this.$confirm({
                text: `Создать инвентаризацию ячейки ${name}?`,
                actions: {
                    false: {
                        text: this.$t('main.close'),
                    },
                    true: {
                        text: this.$t('main.create'),
                        color: 'error',
                    }
                },
            });
            if (!c) {
                return;
            }
            this.loading = true;
            const body = {};
            try {
                const { order } = await this.$api(`/inventory/create/on/cell/${id}`, {
                    method: "POST",
                    body: body,
                });
                this.$success(`Создана инвентаризация №${order.id}`);
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },

        async remove(id) {
            const c = await this.$confirm({
                text: this.$t("owner.remove", { id: `#${id}` }),
                actions: {
                    false: {
                        text: this.$t('main.close'),
                    },
                    true: {
                        text: this.$t('main.remove'),
                        color: 'error',
                    }
                },
            });
            if (!c) {
                return;
            }
            this.loading = true;
            try {
                await this.$api(`/entity/cell/${id}`, {
                    method: "DELETE",
                });
                this.getList();
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },

        async getList() {
            this.loading = true;
            try {
                this.list = (await this.$api(`/entity/rack/${this.rack}`)).cell;
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
    },
};
</script>