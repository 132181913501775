<template>
    <v-tabs>
        <v-tab
            v-for="{ label, alias, icon } in tabs"
            :key="alias"
            :to="{
                name: 'EventPlanner',
                params: {
                    event: alias,
                    calendar: $route.params.calendar,
                },
            }"
        >
            <v-icon
                dense
                class="mr-2"
                v-text="icon"
            />
            {{ label }}
        </v-tab>
    </v-tabs>
</template>

<script>
export default {
    name: "EventTabs",

    props: {
        tabs: {
            type: Array,
            required: true,
        },
    },
};
</script>
