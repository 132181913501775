<template>
    <v-dialog v-model="show" width="640" persistent>
        <v-card :loading="loading">
            <v-card-title v-if="!id">{{ $t("fields.edit.add") }}</v-card-title>
            <v-card-title v-else-if="isSystem">{{
                $t("fields.edit.show")
            }}</v-card-title>
            <v-card-title v-else>{{ $t("fields.edit.edit") }}</v-card-title>
            <v-form
                @submit.prevent="save"
                ref="form"
                :disabled="loading"
                v-model="ok"
            >
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-text-field
                                dense
                                :label="$t('fields.edit.name')"
                                outlined
                                hide-details="auto"
                                :rules="req"
                                v-model="name"
                                :disabled="isSystem"
                            />
                        </v-col>
                        <v-col>
                            <v-select
                                dense
                                :items="availTypes"
                                outlined
                                :label="$t('fields.edit.type')"
                                :rules="reqSelect"
                                hide-details="auto"
                                v-model="type"
                                return-object
                                item-value="id"
                                :disabled="disableEdit"
                            >
                                <template #selection="{ item }">
                                    <v-icon left> mdi-{{ item.icon }} </v-icon>
                                    {{ item.name }}
                                </template>
                                <template #item="{ item, on, attrs }">
                                    <v-list-item v-on="on" v-bind="attrs">
                                        <v-list-item-icon>
                                            <v-icon>mdi-{{ item.icon }}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ item.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ item.description }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row v-for="p in availPerks" :key="p.id">
                        <v-col>
                            <template v-if="p.id == 'increment'">
                                <div class="d-flex align-center">
                                    <v-checkbox
                                        class="mt-0 pt-0"
                                        hide-details
                                        v-model="models[p.id].checked"
                                        dense
                                        :disabled="disableEdit"
                                    />
                                    <v-text-field
                                        dense
                                        outlined
                                        class="mt-0 pt-0"
                                        hide-details
                                        type="number"
                                        v-model="models[p.id].value"
                                        :disabled="
                                            !models[p.id].checked || disableEdit
                                        "
                                        :label="`${p.name} (${p.start})`"
                                    />
                                </div>
                            </template>
                            <v-checkbox
                                dense
                                v-else-if="p.checkbox"
                                hide-details
                                class="mt-0 pt-0"
                                v-model="models[p.id]"
                                :label="p.name"
                                :disabled="disableEdit"
                            />
                            <v-text-field
                                dense
                                outlined
                                v-else
                                class="mt-0 pt-0"
                                hide-details="auto"
                                type="number"
                                v-model="models[p.id]"
                                :rules="ruleMinMax(p)"
                                :disabled="disableEdit"
                            >
                                <template #label>
                                    {{ p.name }}
                                    <template v-if="p.accuracy">
                                        ({{ p.accuracy }})
                                    </template>
                                    <template v-if="p.min && p.max">
                                        ({{ p.min }} - {{ p.max }})
                                    </template>
                                    <template v-else-if="p.min">
                                        (
                                        <v-icon>
                                            mdi-code-greater-than-or-equal
                                        </v-icon>
                                        {{ p.min }}
                                        )
                                    </template>
                                    <template v-else-if="p.max">
                                        (
                                        <v-icon>
                                            mdi-code-less-than-or-equal
                                        </v-icon>
                                        {{ p.max }}
                                        )
                                    </template>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="type && type.id == 'enum'">
                        <v-col>
                            <v-textarea
                                dense
                                outlined
                                :label="$t('fields.edit.list')"
                                :placeholder="$t('fields.edit.each')"
                                hide-details="auto"
                                :rules="req"
                                v-model="thes"
                                v-if="!disableEdit"
                            />
                            <field-thes
                                v-else
                                v-model="thes"
                                :id="id"
                                :is-system="isSystem"
                                :parent-loading.sync="loading"
                                @deleted="deleted"
                                :owner="owner"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        text
                        color="error"
                        @click="show = false"
                        :disabled="loading"
                    >
                        {{ $t("main.close") }}
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        type="submit"
                        :disabled="!ok || loading"
                        v-if="!isSystem"
                    >
                        {{ $t("main.save") }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import FieldThes from "./Thes";
import { checkCanEdit } from "@u/fields";
export default {
    name: "FieldEdit",
    components: {
        FieldThes,
    },
    data: () => ({
        id: null,
        ok: false,
        show: false,
        loading: false,
        name: null,
        type: null,
        thes: null,
        models: null,
        isSystem: false,
        res: null,
    }),
    props: {
        types: {
            type: Array,
            required: true,
        },
        perks: {
            type: Array,
            required: true,
        },
        owner: {
            type: String,
            required: true,
        },
    },
    watch: {
        show(dir) {
            if (dir) {
                return;
            }
            this.isSystem = false;
            this.thes = null;
            this.$refs.form.reset();
        },
        perks: {
            immediate: true,
            handler() {
                this.getModels();
            },
        },
    },
    computed: {
        availTypes() {
            if (this.id > 0) {
                return this.types;
            }
            return this.types.filter((t) => t.isAvailableForCreation);
        },
        availPerks() {
            if (!this.type) {
                return [];
            }
            const list = [];
            for (let p of this.perks) {
                if (!this.type.perks.includes(p.id)) {
                    continue;
                }
                if (this.id == 0 && !p.pickable) {
                    continue;
                }
                list.push(p);
            }
            return list;
        },
        reqSelect() {
            return [(v) => !!v || this.$t("form.required")];
        },
        req() {
            return [(v) => !!v || this.$t("form.required")];
        },
        disableEdit() {
            return this.id > 0;
        },
    },
    methods: {
        deleted() {
            this.show = false;
            this.res(true);
        },
        ruleMinMax({ min, max }) {
            const r = [];
            if (min) {
                r.push((v) => (v ? v >= min : true) || `<${min}`);
            }
            if (max) {
                r.push((v) => (v ? v <= max : true) || `>${max}`);
            }
            return r;
        },
        getModels() {
            const models = {};
            for (let p of this.perks) {
                if (p.id == "increment") {
                    models[p.id] = {
                        checked: null,
                        value: null,
                    };
                    continue;
                }
                if (p.checkbox) {
                    models[p.id] = null;
                    continue;
                }
                models[p.id] = null;
            }
            this.models = models;
        },
        async open(id = 0) {
            this.id = id;
            this.show = true;
            if (id > 0) {
                this.loading = true;
                try {
                    const info = await this.$api(`/${this.owner}/fields/${id}`);
                    this.name = info.name;
                    for (let t of this.types) {
                        if (t.id == info.type) {
                            this.type = t;
                            break;
                        }
                    }
                    this.isSystem = !checkCanEdit(info.perks);
                    info.perks.forEach((p) => {
                        p = p.split("|");
                        for (let pe of this.perks) {
                            if (pe.id == p[0]) {
                                if (pe.id == "increment") {
                                    this.models[pe.id].checked = true;
                                    this.models[pe.id].value = p[1] ?? null;
                                } else if (pe.checkbox) {
                                    this.models[pe.id] = true;
                                } else {
                                    this.models[pe.id] = p[1] ?? null;
                                }
                                break;
                            }
                        }
                    });
                    if (info.type == "enum") {
                        this.thes = info.thesaurus || info.thesauruses;
                    }
                } catch (e) {
                    this.show = false;
                    this.$error(e.message);
                }
                this.loading = false;
            }
            return new Promise((res) => (this.res = res));
        },
        async save() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.loading = true;
            const body = {
                name: this.name,
            };
            if (this.id > 0) {
                try {
                    await this.$api(`/${this.owner}/fields/${this.id}`, {
                        body,
                        method: "PATCH",
                    });
                    this.show = false;
                } catch (e) {
                    this.$error(e.message);
                }
                this.loading = false;
                this.res(true);
                return;
            }
            body.type = this.type.id;
            if (body.type == "enum") {
                body.thesaurus = this.thes.split("\n");
            }
            const perks = [];
            if (this.type.perks) {
                for (let p of this.type.perks) {
                    for (let pe of this.perks) {
                        if (p == pe.id) {
                            if (pe.id == "increment") {
                                if (this.models[p].checked) {
                                    if (this.models[p].value) {
                                        perks.push(
                                            `${p}|${this.models[p].value}`
                                        );
                                    } else {
                                        perks.push(p);
                                    }
                                }
                            } else if (pe.checkbox) {
                                if (this.models[p]) {
                                    perks.push(p);
                                }
                            } else {
                                if (this.models[p]) {
                                    perks.push(`${p}|${this.models[p]}`);
                                }
                            }
                        }
                    }
                }
            }
            if (perks.length) {
                body.perks = perks;
            }
            try {
                await this.$api(`/${this.owner}/fields`, {
                    method: "POST",
                    body,
                });
                this.show = false;
                this.res(true);
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
    },
};
</script>
