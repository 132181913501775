<template>
    <v-card
        :loading="busy"
        :disabled="busy"
        class="d-flex flex-column"
        flat
    >
        <v-card-title
            class="word-break px-0 pt-0"
            :class="{
                'pb-2': $isMobile(),
            }"
        >
            <v-icon
                v-text="icon"
                class="mr-2"
            />
            <span v-text="title" />
        </v-card-title>

        <field-menu
            v-if="!hideMenu"

            :type="type"
            :title="title"
            :tree="menuTree"
            :selected-items-ids="selectedItemsIds"
            :items-after-search="itemsAfterSearch"
            :categories-after-search="categoriesAfterSearch"
            :search-text="searchText"

            :has-search="hasSearch"
            :hide-category-id="hideCategoryId"
            :hide-item-id="hideItemId"

            v-on="$listeners"
        >
            <template #menu-widget>
                <slot name="menu-widget" />
            </template>

            <template #menu-widget-bottom>
                <slot name="menu-widget-bottom" />
            </template>
        </field-menu>

        <div class="flex-grow-1">
            <slot name="body-widget" />

            <v-list
                v-if="categoriesToDisplay.length"
                class="pa-0"
            >
                <v-subheader v-text="'Категории'" />

                <field-list-item
                    v-for="item in categoriesToDisplay"
                    :key="item.id"
                    :type="type"
                    :item="item"
                    :hideId="hideCategoryId"

                    v-on="$listeners"
                />
            </v-list>

            <v-list
                v-if="itemsToDisplay.length"
                class="pa-0"
            >
                <v-subheader
                    v-if="categoriesToDisplay.length"
                    v-text="title"
                />

                <field-list-item
                    v-for="item in itemsToDisplay"
                    :key="item.id"
                    :type="type"
                    :item="item"
                    :hideId="hideItemId"

                    v-on="$listeners"
                />
            </v-list>
        </div>

        <div v-if="showClearButton">
            <v-btn
                small
                block
                outlined
                @click="$emit('clear')"
            >
                <v-icon
                    v-text="'mdi-close'"
                    left
                />
                Очистить
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import FieldMenu from "@c/norton-commander/dialogs/filter/edit/FieldMenu";
import FieldListItem from "@c/norton-commander/dialogs/filter/edit/FieldListItem";

export default {
    name: "FilterField",

    inject: ['$isMobile'],

    components: {
        FieldMenu,
        FieldListItem,
    },

    props: {
        type: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        icon: {
            type: String,
            required: true,
        },

        menuTree: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },

        categoriesToDisplay: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },

        itemsToDisplay: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },

        selectedItemsIds: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },

        itemsAfterSearch: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },

        categoriesAfterSearch: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },

        searchText: {
            type: String,
            required: false,
            default: '',
        },

        busy: {
            type: Boolean,
            required: false,
            default: false,
        },

        isActive: {
            type: Boolean,
            required: false,
            default: false,
        },

        hasSearch: {
            type: Boolean,
            required: false,
            default: false,
        },

        hideMenu: {
            type: Boolean,
            required: false,
            default: false,
        },

        hideItemId: {
            type: Boolean,
            required: false,
            default: false,
        },

        hideCategoryId: {
            type: Boolean,
            required: false,
            default: false,
        },

        hideClearButton: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    computed: {
        showClearButton() {
            if (this.isActive && this.type !== 'status') {
                return true;
            }

            if (this.hideClearButton) {
                return false;
            }

            if (this.type === 'operationAmount') {
                return false;
            }

            return this.itemsToDisplay.length || this.categoriesToDisplay.length;
        },
    },
};
</script>
