<template>
    <v-card
        :disabled="disabled"
    >
        <v-toolbar>
            <slot name="tabs" />
        </v-toolbar>

        <div class="mb-6">
            <slot name="calendar" />
        </div>

        <slot name="event-list" />
    </v-card>
</template>

<script>
export default {
    name: "MobileLayout",

    props: {
        busy: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
