<template>
    <v-fade-transition>
        <div
            v-if="visibility"

            class="drag-scroll-area"
            :class="{
                'drag-scroll-area--up': up,
                'drag-scroll-area--down': down,
                'drag-scroll-area--light': !$vuetify.theme.isDark,
                'drag-scroll-area--dark': $vuetify.theme.isDark,
            }"

            @dragover="$emit('scroll', up ? -1 : 1)"
            @dragenter="$emit('scroll:start')"
            @dragleave="$emit('scroll:stop')"
        >
            <v-icon v-if="up">mdi-arrow-up-thick</v-icon>
            <v-icon v-else>mdi-arrow-down-thick</v-icon>
        </div>
    </v-fade-transition>
</template>

<script>
export default {
    name: "DragScrollArea",

    props: {
        up: {
            type: Boolean,
            required: false,
            default: false,
        },

        down: {
            type: Boolean,
            required: false,
            default: false,
        },

        visibility: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.drag-scroll-area {
    width: 100%;
    height: 22px;
    padding: 4px 0;

    position: absolute;
    z-index: 9999;

    display: flex;
    justify-content: center;

    transition: all 300ms;

    backdrop-filter: blur(4px);

    &--up {
        top: 0;
    }

    &--down {
        bottom: 0;
    }

    &--light {
        background-color: rgba(0, 0, 0, 0.1);

        &:hover {
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

    &--dark {
        background-color: rgba(255, 255, 255, 0.1);

        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
}
</style>
