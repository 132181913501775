<template>
    <adaptive-card
        :data-test-id="index ? 'vendor-cashboxes' : 'our-cashboxes'"
        :data-test-total="total"
        :data-test-add-to-total="addToTotal"

        :search="search"
        :button-state="buttonState"
        :busy="busy"
        :title="title"
        :total="total"

        :drag-scroll-area-visibility="dragScrollAreaVisibility"
        :stop-scroll="stopScroll"

        :index="index"

        @update:search="search = $event"
        @open="toggleCategories('open')"
        @close="toggleCategories('close')"
        v-on="$listeners"
    >
        <template #body>
            <v-list
                dense
                class="py-0"
            >
                <cashbox-category
                    v-for="node in nodesFiltered"
                    :key="node.id"

                    ref="recursiveLists"

                    :node="node"
                    :selected-item="selectedItem"
                    :items-to-display="leafsToDisplay"
                    :categories-to-display="nodesToDisplay"
                    :search-text="search"

                    @toggle-category="onCategoryToggle"

                    v-on="$listeners"
                />
            </v-list>

            <v-divider v-if="$slots.bottom" />

            <slot name="bottom" />
        </template>
    </adaptive-card>
</template>

<script>
import { SearchTree } from '@u/treeview';

import AdaptiveCard from '@c/norton-commander/layouts/AdaptiveCard';
import CashboxCategory from "@c/norton-commander/cashboxes/CashboxCategory";

export default {
    name: "CashboxCard",

    components: {
        AdaptiveCard,
        CashboxCategory,
    },

    props: {
        title: {
            type: String,
            required: true,
        },
        nodes: {
            type: Array,
            required: true,
        },
        filter: {
            type: Array,
            default() {
                return [];
            },
        },
        selectedItem: {
            type: Object,
            required: true,
        },
        cashboxCategories: {
            type: Array,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        busy: {
            type: Boolean,
            default: false,
        },
        dragScrollAreaVisibility: {
            type: Boolean,
            default: false,
        },
        stopScroll: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: 0,
        },
        addToTotal: {
            type: Number,
            default: 0,
        },
    },

    data: (instance) => ({
        showSearch: false,

        search: '',

        opened: instance.filter.reduce((acc, id) => ({
            ...acc,
            [id]: false,
        }), {}),
    }),

    computed: {
        nodesFiltered() {
            return this.nodes
                .filter((({ id }) => this.filter.includes(id)))
                .filter(({ treeParent }) => !treeParent);
        },

        cashboxesAfterSearch() {
            return this.cashboxes
                .filter(({ id, name, title, treeParent }) => {
                    const search = this.search.toLowerCase();

                    const cashboxName = `#${id} ${name || title}`;
                    const searchByName = cashboxName
                        .toLowerCase()
                        .includes(search);

                    const parent = this.cashboxCategories
                        .find(({ id }) => id == treeParent);
                    const parentName = parent ? (parent.name || parent.title) : '';
                    const searchByParent = parentName
                        .toLowerCase()
                        .includes(search);

                    return searchByName || searchByParent;
                })
                .map(({ id }) => id);
        },

        treeAfterSearch() {
            return new SearchTree({
                items: this.cashboxes,
                categories: this.cashboxCategories,
                selectedItems: this.cashboxesAfterSearch,
            });
        },

        leafsToDisplay() {
            return this.treeAfterSearch.selectedLeafs
                .map(({ id }) => id);
        },

        nodesToDisplay() {
            return this.treeAfterSearch.nodesToDisplay
                .map(({ id }) => id);
        },

        buttonState() {
            return !Object.values(this.opened).some((v) => v);
        },

        total() {
            const total = this.nodesFiltered.reduce((acc, { id, balance }) => {
                return id === 'account_api'
                    ? acc
                    : acc + balance;
            }, 0);

            return total + this.addToTotal;
        },
    },

    watch: {
        search(val) {
            if (val) {
                this.toggleCategories('open');
            }
        },
    },

    methods: {
        toggleCategories(key) {
            if (!this.$refs.recursiveLists) {
                return;
            }

            requestAnimationFrame(() => {
                this.$refs.recursiveLists.forEach((ref) => ref.toggleCategory(key));
            });
        },

        onCategoryToggle({ id, value }) {
            if (Object.keys(this.opened).includes(id)) {
                this.opened[id] = value;
            }
        },
    },
};
</script>
