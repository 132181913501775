<template>
    <v-list-group
        v-if="node.isCategory"
        v-show="node.childrenCountTotal && show"

        v-model="open"
        eager
        sub-group
    >
        <template v-slot:activator>
            <v-list-item
                class="activator pa-0"
                :class="{
                    'activator--light': !$vuetify.theme.isDark,
                    'activator--dark': $vuetify.theme.isDark,
                    'pl-4': isChild,
                }"
                @contextmenu.prevent="toggleAllChildren"
            >
                <v-list-item-action v-if="!hideCategoryId">
                    <v-list-item-action-text
                        v-text="`#${node.id}`"
                        class="font-weight-medium text--primary"
                    />
                </v-list-item-action>

                <v-list-item-content>
                    <v-list-item-title>
                        <text-highlight-wrapper
                            :text="node.name"
                            :queries="searchText"
                        />
                    </v-list-item-title>

                    <v-list-item-subtitle
                        v-if="node.isEveryChildSelected && !isEveryChildSelectedProp"
                        v-text="'Выбрана вся категория'"
                        class="font-weight-light"
                    />
                </v-list-item-content>

                <v-list-item-action>
                    <v-list-item-action-text
                        v-text="`${node.childrenCountSelectedTotal} / ${node.childrenCountTotal}`"
                    />
                </v-list-item-action>
            </v-list-item>
        </template>

        <recursive-list
            v-for="child in node.children"
            :key="child.id"

            ref="recursiveLists"

            :type="type"

            :node="child"

            :selected-items-ids="selectedItemsIds"
            :items-after-search="itemsAfterSearch"
            :categories-after-search="categoriesAfterSearch"
            :search-text="searchText"

            :has-search="hasSearch"
            :hide-category-id="hideCategoryId"
            :hide-item-id="hideItemId"
            :menu="menu"
            :is-every-child-selected-prop="node.isEveryChildSelected"
            :isChild="true"

            :class="{ 'pl-4': isChild }"

            v-on="$listeners"
        />
    </v-list-group>

    <v-list-item
        v-else
        v-show="show"

        #default="{ active }"

        data-test-id="edit-filter-dialog-menu-list-item"
        :data-test-state="node.selected || selectedItemsIds.includes(node.id)"

        :input-value="node.selected || selectedItemsIds.includes(node.id)"
        color="primary"
        @click="toggleItem"
    >
        <v-list-item-action v-if="!hideItemId">
            <v-list-item-action-text
                v-text="`#${node.id}`"
                class="text--primary"
            />
        </v-list-item-action>

        <v-list-item-action v-if="type === 'status'">
            <v-list-item-action-text>
                <v-icon
                    v-text="node.icon"
                    :class="node.textClass"
                />
            </v-list-item-action-text>
        </v-list-item-action>

        <v-list-item-action v-if="type === 'processingDate'">
            <v-list-item-action-text>
                <v-icon
                    v-text="node.icon"
                    :color="active ? 'primary' : ''"
                />
            </v-list-item-action-text>
        </v-list-item-action>

        <v-list-item-content>
            <v-list-item-title
                :class="[
                    node.textClass,
                    `${
                        ['status', 'processingDate'].includes(type)
                            ? ''
                            : 'font-weight-regular'
                    }`,
                ]"
            >
                <text-highlight-wrapper
                    :text="node.name"
                    :queries="searchText"
                />
            </v-list-item-title>

            <v-list-item-subtitle
                v-if="type === 'processingDate'"
                class="font-weight-regular"
            >
                {{ hints[node.id] | capitalize }}
            </v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
import TextHighlightWrapper from "@c/TextHighlightWrapper";

export default {
    name: "RecursiveList",

    components: {
        TextHighlightWrapper,
    },

    props: {
        type: {
            type: String,
            required: true,
        },
        node: {
            type: Object,
            required: true,
        },
        selectedItemsIds: {
            type: Array,
            required: true,
        },
        itemsAfterSearch: {
            type: Array,
            required: true,
        },
        categoriesAfterSearch: {
            type: Array,
            required: true,
        },
        searchText: {
            type: String,
            required: true,
        },
        hasSearch: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideCategoryId: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideItemId: {
            type: Boolean,
            required: false,
            default: false,
        },
        menu: {
            type: Boolean,
            default: false,
        },
        isEveryChildSelectedProp: {
            type: Boolean,
            default: false,
        },
        isChild: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        open: false,
    }),

    mounted() {
        this.openGroupIfAnyChildIsSelected();
    },

    computed: {
        show() {
            if (!this.hasSearch) {
                return true;
            }

            return this.node.isCategory
                ? this.categoriesAfterSearch.includes(this.node.id)
                : this.itemsAfterSearch.includes(this.node.id);
        },

        hints() {
            return {
                '-0d': this.today,
                '-1d': this.yesterday,
                '-7d,-0d': this.lastWeek,
                '-1m,-0d': this.lastMonth,
            };
        },

        today() {
            const options = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            };

            return new Date().toLocaleDateString(undefined, options);
        },

        yesterday() {
            const options = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            };

            return new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString(undefined, options);
        },

        lastWeek() {
            const first = new Date(new Date().setDate(new Date().getDate() - 7)).toLocaleDateString(undefined);
            const last = new Date().toLocaleDateString(undefined);

            return `${first} - ${last}`;
        },

        lastMonth() {
            const first = new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleDateString(undefined);
            const last = new Date().toLocaleDateString(undefined);

            return `${first} - ${last}`;
        },
    },

    watch: {
        menu() {
            if (!this.menu) {
                this.open = false;
            } else {
                this.openGroupIfAnyChildIsSelected();
            }
        },

        open(value) {
            const { id } = this.node;

            this.$emit('toggle-category', { id, value });
        },
    },

    methods: {
        toggleCategory(key) {
            switch (key) {
                case 'open':
                    this.open = true;
                    break;

                case 'close':
                    this.open = false;
                    break;

                case 'active':
                    this.openGroupIfAnyChildIsSelected();
                    break;
            }

            if (this.$refs.recursiveLists) {
                this.$refs.recursiveLists.forEach((ref) => ref.toggleCategory(key));
            }
        },

        openGroupIfAnyChildIsSelected(node = this.node) {
            const typesWithCategories = [
                'cashbox',
                'costTemplate',
            ];

            if (!typesWithCategories.includes(this.type)) {
                return;
            }

            if (node.children.length > 0) {
                node.children.forEach(child => {
                    if (child.selected) {
                        this.open = true;
                    }

                    this.openGroupIfAnyChildIsSelected(child);
                });
            }
        },

        toggleAllChildren(evt, node = this.node, flag = node.isEveryChildSelected) {
            if (node.children.length > 0) {
                node.children.forEach(child => {
                    if (!child.isCategory) {
                        if (flag) {
                            if (child.selected) {
                                this.$emit('toggle-item', this.type, child.id);
                            }
                        } else {
                            if (!child.selected) {
                                this.$emit('toggle-item', this.type, child.id);
                            }
                        }
                    }
                    this.toggleAllChildren(evt, child, flag);
                });
            }
        },

        toggleItem() {
            this.$emit('toggle-item', this.type, this.node.id);
            if (this.type === 'processingDate') {
                this.$emit('close-menu');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.activator {
    transition: all 300ms;

    &--light {
        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }
    }

    &--dark {
        &:hover {
            background-color: rgba(255, 255, 255, 0.05);
        }
    }
}
</style>
