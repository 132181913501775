<template>
    <adaptive-item
        data-test-type="transaction-list-item"
        :data-test-id="`transaction-list-item-${transaction.id}`"
        :data-test-object="JSON.stringify(transaction)"
        :data-test-operations="JSON.stringify(operationTypes)"
        :data-test-links="JSON.stringify(linkTypes)"

        :layout="layout"
        @dragover.prevent
        @dragenter.prevent
        @drop="onDropCashboxToTransaction($event, 'transaction', transaction)"
    >
        <template #info>
            <transaction-info
                :transaction="transaction"

                :cashbox-categories="cashboxCategories"
                :cashboxes="cashboxes"

                :cost-categories="costCategories"
                :cost-templates="costTemplates"
                :cost-elements="costElements"

                :statuses="statuses"
                :users="users"
                v-on="$listeners"
            />
        </template>

        <template #buttons>
            <transaction-buttons
                :transaction="transaction"
                :layout="layout"
                v-on="$listeners"
            />
        </template>
    </adaptive-item>
</template>

<script>
import AdaptiveItem from "@c/norton-commander/transactions/list/AdaptiveItem";
import TransactionInfo from "@c/norton-commander/transactions/list/TransactionInfo";
import TransactionButtons from "@c/norton-commander/transactions/list/TransactionButtons";

export default {
    name: "Transaction",

    components: {
        AdaptiveItem,
        TransactionInfo,
        TransactionButtons,
    },

    props: {
        transaction: {
            type: Object,
            required: true,
        },
        cashboxCategories: {
            type: Array,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        costCategories: {
            type: Array,
            required: true,
        },
        costTemplates: {
            type: Array,
            required: true,
        },
        costElements: {
            type: Array,
            required: true,
        },
        statuses: {
            type: Object,
            required: true,
        },
        users: {
            type: Object,
            required: true,
        },
        layout: {
            type: String,
            required: true,
        }
    },

    computed: {
        operationTypes() {
            return this.transaction.operations.map(({ cashbox }) => {
                const item = this.cashboxes.find((c) => c.id === cashbox);

                return item?.type;
            });
        },

        linkTypes() {
            return this.transaction.links.map(({ type }) => type);
        },
    },

    methods: {
        onDropCashboxToTransaction(evt, type, transaction) {
            const fromCategory = evt.dataTransfer.getData('category');
            if (fromCategory !== 'cashbox') {
                return;
            }
            const fromId = Number(evt.dataTransfer.getData('id'));

            let operationId = null;
            for (const operation of transaction.operations) {
                if (operation.cashbox === null) {
                    operationId = operation.id;
                }
            }

            if (operationId === null) {
                return false;
            }

            const message = 'Установить кассу #' + fromId + ' для транзакции #' + transaction.id + '?';
            if (!confirm(message)) {
                return false;
            }

            this.$emit('transaction:replace-cashbox', {
                id: transaction.id,
                operationId: operationId,
                cashboxId: fromId,
            });
        },
    },
};
</script>
