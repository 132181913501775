<template>
    <v-navigation-drawer
        app
        :value="value"
        @input="$emit('input', $event)"
        temporary
    >
        <v-list>
            <v-list-item>
                <v-list-item-title>2S <v-icon>mdi-account</v-icon> {{ getUserName() }}</v-list-item-title>
            </v-list-item>
        </v-list>
        <v-divider />
        <v-list>
            <v-list-item :to="{ name: 'Home' }" exact>
                <v-list-item-icon>
                    <v-icon>mdi-home</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    {{ $t("drawer.home") }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
        <v-divider />
        <v-progress-linear :active="loading" indeterminate />
        <v-list>
            <v-list-group
                v-for="l in list"
                :key="l.name"
                :prepend-icon="`mdi-${l.icon}`"
            >
                <template #activator>
                    <v-list-item-title>{{ l.name }}</v-list-item-title>
                </template>
                <v-list-item
                    v-for="c in l.childs"
                    :key="c.name"
                    :to="{ name: c.alias }"
                >
                    <v-list-item-title>{{ c.name }}</v-list-item-title>
                </v-list-item>
            </v-list-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: "AppDrawer",
    data: () => ({
        loading: false,
        list: [],
    }),
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    watch: {
        value(dir) {
            if (dir) {
                this.getList();
            }
        },
    },
    methods: {
        async getList() {
            this.loading = true;
            try {
                this.list = await this.$api("/profile/panel");

                // @todo Убрать, когда сервер начнёт присылать
                const settings = this.list.find((l) => l.icon === 'wrench');
                if (settings && !settings.childs.find(({ alias }) => alias === 'AccessControl')) {
                    settings.childs.push({
                        alias: 'AccessControl',
                        name: this.$t('pages.accessControl'),
                    });
                }
                const apps = this.list.find((l) => l.icon === 'apps');
                if (apps && !apps.childs.find(({ alias }) => alias === 'EventPlanner')) {
                    apps.childs.push({
                        alias: 'EventPlanner',
                        name: this.$t('pages.eventPlanner'),
                    });
                }
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },

        getUserName() {
            const username = this.$apiUserName();
            return username;
        },
    },
};
</script>
