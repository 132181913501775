<template>
    <v-list class="pa-0 selected-item">
        <v-list-item @click="$emit('filter:modify', filterModifier)">
            <v-list-item-content>
                <v-list-item-title
                    v-text="text.title"
                    class="body-2"
                />
                <v-list-item-subtitle
                    v-text="text.subtitle"
                    class="caption"
                />
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
    name: "FilterModifier",

    props: {
        filterModifier: {
            type: Object,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        costTemplates: {
            type: Array,
            required: true,
        },
    },

    computed: {
        text() {
            const type = this.filterModifier.type;

            if (type === 'cashbox') {
                const id = this.filterModifier.value;
                const cashbox = this.cashboxes.find((c) => c.id === id);

                const result = { subtitle: 'Касса' };

                if (cashbox) {
                    return {
                        ...result,
                        title: `#${id} ${cashbox.title}`,
                    };
                }

                return {
                    ...result,
                    title: `#${id}`,
                };
            }

            if (type === 'costs.template') {
                const id = this.filterModifier.value;
                const template = this.costTemplates.find(c => c.id === Number(id));

                const result = { subtitle: 'Расход' };

                if (template) {
                    return {
                        ...result,
                        title: `#${id} ${template.name}`,
                    };
                }

                return {
                    ...result,
                    title: `#${id}`,
                };
            }

            if (type === 'processingDate') {
                const dateRange = this.filterModifier.value.split(',');

                if (dateRange.length === 1) {
                    return {
                        subtitle: 'Дата',
                        title: dateRange[0],
                    };
                }

                return {
                    subtitle: 'Диапазон дат',
                    title: `${dateRange[0]} - ${dateRange[1]}`,
                };
            }

            if (type === 'transactionId') {
                return {
                    subtitle: 'Транзакция',
                    title: `#${this.filterModifier.value}`,
                };
            }

            return this.filterModifier.value;
        },
    }
};
</script>
