<template>
    <v-card>
        <v-card-title>{{ $t("login.title") }}</v-card-title>
        <v-card-text>{{ $t("login.text") }}</v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="login" text>
                {{ $t("login.btn") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "LoginMain",
    methods: {
        login() {
            window.addEventListener("message", this.listener);
            const left = window.outerWidth / 2 - 300;
            const top = window.outerHeight / 2 - 300;
            const win = window.open(
                process.env.VUE_APP_PASSPORT,
                "login",
                `left=${left},top=${top},width=600,height=600,menubar=no,toolbar=no`
            );
            let timer = setInterval(() => {
                if (win.closed) {
                    clearInterval(timer);
                    window.removeEventListener("message", this.listener);
                }
            }, 1000);
        },
        listener({ origin, data }) {
            if (
                origin != process.env.VUE_APP_PASSPORT ||
                data.type != "login"
            ) {
                return;
            }
            this.$apiLogin(data.token);
            this.$router.push({
                name: "Home",
            });
        },
    },
};
</script>