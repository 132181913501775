var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tabs',_vm._l((_vm.tabs),function(ref){
var label = ref.label;
var alias = ref.alias;
var icon = ref.icon;
return _c('v-tab',{key:alias,attrs:{"to":{
            name: 'EventPlanner',
            params: {
                event: alias,
                calendar: _vm.$route.params.calendar,
            },
        }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dense":""},domProps:{"textContent":_vm._s(icon)}}),_vm._v(" "+_vm._s(label)+" ")],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }