<template>
    <v-card>
        <storage-bread :storage="storage" :room="room" :rack="rack" />
        <storage-list v-if="!rack" :storage="storage" :room="room" :pg="pg" />
        <storage-cells v-else :storage="storage" :room="room" :rack="rack" />
    </v-card>
</template>

<script>
import StorageBread from "@c/storage/Bread";
import StorageList from "@c/storage/List";
import StorageCells from "@c/storage/Cells";
export default {
    name: "Storages",
    components: {
        StorageBread,
        StorageList,
        StorageCells,
    },
    props: {
        pg: {
            type: Number,
            required: true,
        },
        storage: {
            type: Number,
            required: true,
        },
        room: {
            type: Number,
            required: true,
        },
        rack: {
            type: Number,
            required: true,
        },
    },
};
</script>