<script>
import { SelectionTree } from "@u/treeview";
import { PathToCashbox, getPathToCostTemplate } from '@u/path';

export default {
    name: "FilterInfoRenderless",

    props: {
        filter: {
            type: Object,
            required: true,
        },
        cashboxCategories: {
            type: Array,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        costCategories: {
            type: Array,
            required: true,
        },
        costTemplates: {
            type: Array,
            required: true,
        },
        users: {
            type: Object,
            required: true,
        },
        statuses: {
            type: Object,
            required: true,
        },
        dateHelpers: {
            type: Array,
            required: true,
        },
        groupByList: {
            type: Array,
            required: true,
        },
    },

    render() {
        return this.$scopedSlots.default({
            info: this.infoFiltered,
        });
    },

    computed: {
        info() {
            return [
                {
                    label: 'Статусы',
                    value: this.filterStatuses,
                    alias: 'statuses',
                    icon: 'mdi-list-status',
                },
                {
                    label: 'Категории касс',
                    value: this.cashboxesActiveCategories,
                    alias: 'cashboxCategories',
                    icon: 'mdi-cash-register',
                },
                {
                    label: 'Кассы',
                    value: this.cashboxesItemsToDisplay,
                    alias: 'cashboxes',
                    icon: 'mdi-cash-register',
                },
                {
                    label: 'Дата создания',
                    value: this.getCreationDate,
                    alias: 'creationDate',
                    icon: 'mdi-calendar',
                },
                {
                    label: 'Дата обработки',
                    value: this.processingDate,
                    alias: 'processingDate',
                    icon: 'mdi-calendar-check',
                },
                {
                    label: 'Дата обработки',
                    value: this.getProcessingDateHelpers,
                    alias: 'processingDateHelpers',
                    icon: 'mdi-calendar-check',
                },
                {
                    label: 'Создал',
                    value: this.getCreationUsers,
                    alias: 'creationUsers',
                    icon: 'mdi-account',
                },
                {
                    label: 'Обработал',
                    value: this.getProcessingUsers,
                    alias: 'processingUsers',
                    icon: 'mdi-account-check',
                },
                {
                    label: 'Категории расходов',
                    value: this.costTemplatesActiveCategories,
                    alias: 'costCategories',
                    icon: 'mdi-cash-multiple',
                },
                {
                    label: 'Расходы',
                    value: this.costTemplatesItemsToDisplay,
                    alias: 'costTemplates',
                    icon: 'mdi-cash-multiple',
                },
                {
                    label: 'Транзакции',
                    value: this.siteTransactions,
                    alias: 'siteTransactions',
                    icon: 'mdi-link',
                },
                {
                    label: 'Сплиты',
                    value: this.siteSplits,
                    alias: 'siteSplits',
                    icon: 'mdi-link',
                },
                {
                    label: 'Доставки',
                    value: this.siteDeliveries,
                    alias: 'siteDeliveries',
                    icon: 'mdi-link',
                },
                {
                    label: 'Сумма операции',
                    value: this.operationAmount,
                    alias: 'operationAmount',
                    icon: 'mdi-currency-rub',
                },
                {
                    label: 'Группировка',
                    value: this.groupBy,
                    alias: 'groupBy',
                    icon: 'mdi-format-list-group',
                },
            ];
        },

        infoFiltered() {
            return this.info.filter(({ value }) => value?.length);
        },

        cashboxesTree() {
            return new SelectionTree({
                items: this.cashboxes,
                categories: this.cashboxCategories,
                selectedItems: this.filter.cashboxes || [],
                selectedCategories: this.filter.cashboxesGroup || [],
            });
        },

        cashboxesActiveCategories() {
            return this.cashboxesTree.nodesToDisplay.map(({ name, title, children }) => {
                const result = name || title;

                if (!children) {
                    return result;
                }

                const child = children[0];

                if (!child) {
                    return result;
                }

                if (child.isCategory) {
                    return result;
                }

                const path = new PathToCashbox(child, this.cashboxCategories);

                return path.string;
            });
        },

        cashboxesItemsToDisplay() {
            return this.cashboxesTree.leafsToDisplay.map(({ id }) => {
                const cashbox = this.cashboxes.find((c) => c.id === id);

                if (!cashbox) {
                    return `#${id}`;
                }

                const path = new PathToCashbox(cashbox, this.cashboxCategories);
                const title = `#${cashbox.id} ${cashbox.title}`;

                const pathChunks = path.array.map(({ title }) => title);
                pathChunks.push(title);
                return pathChunks.join(' / ');
            });
        },

        costTemplatesTree() {
            return new SelectionTree({
                items: this.costTemplates,
                categories: this.costCategories,
                selectedItems: this.filter.costTemplates || [],
                selectedCategories: this.filter.costCategories || [],
            });
        },

        costTemplatesActiveCategories() {
            return this.costTemplatesTree.nodesToDisplay.map(({ name, title, children }) => {
                const result = name || title;

                if (!children) {
                    return result;
                }

                const child = children[0];

                if (!child) {
                    return result;
                }

                if (child.isCategory) {
                    return result;
                }

                return getPathToCostTemplate(
                    child.id,
                    this.costTemplates,
                    this.costCategories,
                    false,
                );
            });
        },

        costTemplatesItemsToDisplay() {
            return this.costTemplatesTree.leafsToDisplay.map(({ id }) => getPathToCostTemplate(
                id,
                this.costTemplates,
                this.costCategories,
            ));
        },

        filterStatuses() {
            if (!this.filter.statuses) {
                return '';
            }

            return this.filter.statuses.reduce((acc, id) => {
                const status = this.statuses[id];

                if (!status) {
                    return acc;
                }

                return [
                    ...acc,
                    {
                        text: status.name,
                        textClass: status.textClass,
                    },
                ];
            }, []);
        },

        getCreationDate() {
            if (!this.filter.creationDate) {
                return '';
            }

            if (this.filter.creationDate) {
                return this.filter.creationDate.join(' ~ ');
            }
            return '';
        },

        getProcessingDate() {
            if (!this.filter.processingDate) {
                return '';
            }

            if (this.filter.processingDate) {
                return this.filter.processingDate.join(' ~ ');
            }
            return '';
        },

        getProcessingDateHelpers() {
            if (!this.filter.processingDateHelpers) {
                return '';
            }

            if (this.filter.processingDateHelpers == null) {
                return '';
            }
            for (const helper of this.dateHelpers) {
                if (helper.value.join(';') === this.filter.processingDateHelpers.join(';')) {
                    return helper.name;
                }
            }
            return '';
        },

        getCreationUsers() {
            if (!this.filter.userWhoCreated) {
                return '';
            }

            let users = [];
            for (const userId of this.filter.userWhoCreated) {
                if (this.users[userId]) {
                    users.push(this.users[userId].login);
                } else {
                    users.push('#' + userId);
                }
            }
            return users.join(', ');
        },

        getProcessingUsers() {
            if (!this.filter.userWhoProcessed) {
                return '';
            }

            let users = [];
            for (const userId of this.filter.userWhoProcessed) {
                if (this.users[userId]) {
                    users.push(this.users[userId].login);
                } else {
                    users.push('#' + userId);
                }
            }
            return users.join(', ');
        },

        siteTransactions() {
            if (!this.filter.siteTransactions) {
                return '';
            }

            return this.filter.siteTransactions.join(', ');
        },

        siteSplits() {
            if (!this.filter.siteSplits) {
                return '';
            }

            return this.filter.siteSplits.join(', ');
        },

        siteDeliveries() {
            if (!this.filter.siteDeliveries) {
                return '';
            }

            return this.filter.siteDeliveries.join(', ');
        },

        operationAmount() {
            if (!this.filter.operationAmount) {
                return '';
            }

            if (
                this.filter.operationAmount.from === ''
                && this.filter.operationAmount.to === ''
            ) {
                return '';
            }

            const from = this.filter.operationAmount.from === '' ? '' : `от ${this.filter.operationAmount.from}`;
            const to = this.filter.operationAmount.to === '' ? '' : `до ${this.filter.operationAmount.to}`;
            return [from, to].join(' ');
        },

        groupBy() {
            if (!this.filter.group) {
                return '';
            }

            if (
                this.filter.group === 'cashbox.any'
                && !(
                    (
                        this.filter.processingDate
                        && this.filter.processingDate.length
                    )
                    || this.filter.processingDateHelpers
                )
            ) {
                return '';
            }

            if (this.filter.group !== '') {
                for (const group of this.groupByList) {
                    if (group.value === this.filter.group) {
                        return group.text;
                    }
                }
            }
            return '';
        },
    },
};
</script>
