<template>
    <v-list-item class="px-0">
        <v-list-item-content>
            <v-list-item-title>
                <span v-text="`ID: ${transaction.id} (`" />
                <span
                    v-text="status.text"
                    :class="status.textClass"
                />
                <span v-text="`) ${createdDate}`" />
            </v-list-item-title>

            <v-list-item-subtitle
                v-for="{ id, path, text, textClass } in operations"
                :key="id"
                class="text-wrap"
            >
                {{ path }}
                <span
                    v-text="text"
                    :class="textClass"
                    class="text-no-wrap"
                />
            </v-list-item-subtitle>

            <v-list-item-subtitle>
                Участники: <span>{{ getUserById(transaction.user) }}</span>
                <span v-show="transaction.processedUser"> / {{ getUserById(transaction.processedUser) }}</span>
            </v-list-item-subtitle>

            <v-list-item-subtitle v-show="transaction.links.length">
                Связи:
                <v-list-item-subtitle
                    v-for="(link, index) in transaction.links"
                    :key="index"
                    class="text-wrap"
                >
                    <span v-show="!link.link">{{ getLinkName(link) }} </span>

                    <a
                        v-show="!link.link && !link.showFull"
                        href="javascript:void(0)"
                        @click="$emit('show-full-link', link.id)"
                    >
                        еще
                    </a>

                    <a
                        v-show="link.link"
                        :href="link.link"
                        target="_blank"
                    >
                        {{ link.name }}
                    </a>
                </v-list-item-subtitle>
            </v-list-item-subtitle>

            <v-list-item-subtitle v-show="transaction.comments.length">
                Комментарии:
                <v-list-item-subtitle
                    v-for="comment in transaction.comments"
                    :key="comment.id"
                    class="text-wrap"
                >
                    {{ getUserById(comment.user) }}: {{ comment.comment }}
                </v-list-item-subtitle>
            </v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
import { formatNumber } from '@u/formatting';
import { PathToCashbox, getPathToCostByName } from '@u/path';

export default {
    name: "TransactionInfo",

    props: {
        transaction: {
            type: Object,
            required: true,
        },
        cashboxCategories: {
            type: Array,
            required: true,
        },
        cashboxes: {
            type: Array,
            required: true,
        },
        costCategories: {
            type: Array,
            required: true,
        },
        costTemplates: {
            type: Array,
            required: true,
        },
        costElements: {
            type: Array,
            required: true,
        },
        statuses: {
            type: Object,
            required: true,
        },
        users: {
            type: Object,
            required: true,
        },
        snippetLength: {
            type: Number,
            required: false,
            default: 50,
        },
    },

    computed: {
        status() {
            const status = this.statuses[this.transaction.status];

            if (!status) {
                return {
                    text: '',
                    textClass: '',
                };
            }

            return {
                text: status.name,
                textClass: status.textClass,
            };
        },

        createdDate() {
            return new Date(this.transaction.draft_time).toLocaleString();
        },

        operations() {
            if (!this.transaction.operations) {
                return [];
            }

            return this.transaction.operations
                .map(({ id, cashbox, amount, currency }) => {
                    let path = `#${cashbox}`;
                    const item = this.cashboxes.find((c) => c.id === cashbox);

                    if (item) {
                        const itemPath = new PathToCashbox(item, this.cashboxCategories);
                        const title = `#${item.id} ${item.title}`;

                        const pathChunks = itemPath.array.map(({ title }) => title);
                        pathChunks.push(title);
                        path = pathChunks.join(' / ');
                    }

                    const sign = amount > 0 ? '+' : '';
                    const text = `${sign}${formatNumber(amount)} ${currency}`;
                    const textClass = amount > 0 ? 'success--text' : 'error--text';

                    return {
                        id,
                        path,
                        text,
                        textClass,
                    };
                });
        },
    },

    methods: {
        getUserById(userId) {
            if (typeof this.users[userId] == 'undefined') {
                return '';
            }

            return this.users[userId].login;
        },

        getLinkName({ name, type, showFull }) {
            const result = String(name);

            if (type !== 'costs') {
                if (showFull) {
                    return result;
                }

                if (name.length > this.snippetLength) {
                    return result.substring(0, this.snippetLength) + '...';
                }

                return result;
            }

            return getPathToCostByName(
                name,
                this.costTemplates,
                this.costElements,
                this.costCategories,
                result,
            );
        },
    },
};
</script>
