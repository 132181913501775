<template>
    <v-select
        :items="head.thesaurus || head.thesauruses"
        item-value="id"
        item-text="name"
        :value="value ? Number(value) : null"
        @input="$emit('input', $event)"
        dense
    />
</template>

<script>
import ProductOwnerFilterBase from "./Base";
export default {
    name: "ProductOwnerFilterEnum",
    extends: ProductOwnerFilterBase,
};
</script>