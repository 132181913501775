export function checkCanEdit(perks) {
    const uneditable = ["readonly"];
    return (
        uneditable.filter((value) => perks.includes(value)).length == 0
    );
}

export function checkCanDelete(perks) {
    const undeletable = ["readonly", "undeletable"];
    return (
        undeletable.filter((value) => perks.includes(value)).length == 0
    );
}
