export default [
    {
        text: 'По кассе',
        value: 'cashbox.any',
    },
    /*
    {
        text: 'По кассе откуда',
        value: 'cashbox.outgoing',
    },
    {
        text: 'По кассе куда',
        value: 'cashbox.incoming',
    },
    */
    {
        text: 'По расходу',
        value: 'costs.template',
    },
    {
        text: 'По дням',
        value: 'processing.day',
    },
    {
        text: 'По неделям',
        value: 'processing.week',
    },
    {
        text: 'По месяцам',
        value: 'processing.month',
    },
    {
        text: 'Не группировать',
        value: 'no',
    },
];
