<template>
    <v-app-bar
        app
        :hide-on-scroll="hideOnScroll"
        scroll-threshold="100"
        :elevation="elevation"
    >
        <v-app-bar-nav-icon @click="$emit('input', !value)" />

        <v-toolbar-title>
            <v-list-item
                :to="showPageTitle ? { name: 'Home' } : ''"
                exact
                class="d-inline-flex px-2 mx-n2"
            >
                2S
            </v-list-item>

            <v-slide-x-transition>
                <span v-if="showPageTitle">
                    <span class="mx-4 d-inline-flex">
                        <v-icon
                            v-text="'mdi-chevron-right'"
                            dense
                        />
                    </span>

                    <v-list-item
                        v-text="pageTitle"
                        class="d-inline-flex px-0 font-weight-medium"
                    />
                </span>
            </v-slide-x-transition>
        </v-toolbar-title>

        <v-spacer />

        <div
            class="text-center"
            style="width: 48px;"
        >
            <v-icon
                small
                :color="webSocketConnection ? 'success' : 'error'"
            >
                mdi-circle
            </v-icon>
        </div>

        <v-btn icon @click="dark">
            <v-icon>mdi-theme-light-dark</v-icon>
        </v-btn>

        <locale-changer />

        <template v-if="showProfile">
            <v-btn icon @click="openProfile">
                <v-icon>mdi-account</v-icon>
            </v-btn>

            <v-btn icon @click="logout">
                <v-icon>mdi-logout</v-icon>
            </v-btn>
        </template>
    </v-app-bar>
</template>

<script>
import LocaleChanger from "@c/LocaleChanger";

export default {
    name: "AppBar",

    inject: ['$isMobile'],

    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },

    components: {
        LocaleChanger,
    },

    computed: {
        showProfile() {
            return this.$route.name != "Login";
        },

        webSocketConnection() {
            return this.$store.getters.connected;
        },

        pageTitle() {
            return this.$route.meta.title;
        },

        showPageTitle() {
            return !(this.$isMobile() || this.$route.name === 'Home' || !this.pageTitle);
        },

        hideOnScroll() {
            const pages = [
                'NortonCommander',
                'AccessControl',
                'EventPlanner',
            ];

            return pages.includes(this.$route.name) && this.$isMobile();
        },

        elevation() {
            if (this.$isMobile()) {
                return '';
            }

            const pages = [
                'NortonCommander',
                'AccessControl',
                'EventPlanner',
            ];

            return pages.includes(this.$route.name) ? '1' : '';
        }
    },

    methods: {
        dark() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            localStorage.dark = this.$vuetify.theme.dark ? 1 : 0;
        },

        openProfile() {
            window.addEventListener("message", this.listener);
            const left = window.outerWidth / 2 - 300;
            const top = window.outerHeight / 2 - 300;
            const win = window.open(
                `${process.env.VUE_APP_PASSPORT}/profile`,
                "profile",
                `left=${left},top=${top},width=600,height=600,menubar=no,toolbar=no`
            );
            let timer = setInterval(() => {
                if (win.closed) {
                    clearInterval(timer);
                    window.removeEventListener("message", this.listener);
                }
            }, 1000);
        },

        async logout() {
            if (
                !(await this.$confirm({
                    text: this.$t("app.exit"),
                }))
            ) {
                return;
            }
            try {
                await this.$api("/auth/logout", {
                    method: "DELETE",
                });
                this.$apiLogout();
            } catch (e) {
                this.$error(e.message);
            }
        },

        listener({ origin, data }) {
            if (
                origin != process.env.VUE_APP_PASSPORT ||
                data.type != "logout"
            ) {
                return;
            }
            this.$apiLogout();
        },
    },
};
</script>
