<template>
    <v-card :loading="loading" :disabled="loading || !this.id">
        <v-simple-table fixed-header max-height="400px">
            <thead>
                <tr>
                    <th>#</th>
                    <th>{{ $t("keep.log.user") }}</th>
                    <th>{{ $t("keep.log.date") }}</th>
                    <th>{{ $t("keep.log.operation") }}</th>
                    <th>{{ $t("keep.log.product") }}</th>
                    <th>{{ $t("keep.log.cell") }}</th>
                    <th>{{ $t("keep.log.count") }}</th>
                    <th>
                        <v-btn icon @click="getList" color="primary">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="l in list" :key="l.id">
                    <td>{{ l.id }}</td>
                    <td>{{ l.user }}</td>
                    <td>{{ l.created | apiDate }}</td>
                    <td>{{ l.operation }}</td>
                    <td>{{ l.product }}</td>
                    <td>{{ l.cell }}</td>
                    <td>{{ l.count.from }}</td>
                    <td>
                        <v-checkbox :input-value="l.verified" readonly />
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <v-toolbar class="pagi" v-if="total > 1 || pg > 1" dense flat>
            <v-spacer />
            <v-pagination :value="pg" :length="total" @input="changePg" />
        </v-toolbar>
    </v-card>
</template>

<style lang="scss" scoped>
.pagi {
    position: sticky;
    bottom: 0;
}
</style>

<script>
export default {
    name: "CatalogProductLog",
    data() {
        return this.getDefaultData();
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
        show: {
            type: Boolean,
            required: true,
        },
        current: {
            type: Boolean,
            required: true,
        },
        owner: {
            type: String,
            required: true,
        },
    },
    watch: {
        show: {
            immediate: true,
            handler() {
                for (const [key, value] of Object.entries(
                    this.getDefaultData()
                )) {
                    this[key] = value;
                }
            },
        },
        current: {
            immediate: true,
            handler(dir) {
                if (dir && !this.loaded) {
                    this.getList();
                }
            },
        },
    },
    methods: {
        getDefaultData: () => ({
            loading: false,
            list: [],
            total: 1,
            pg: 1,
            loaded: false,
        }),
        changePg(pg) {
            this.pg = pg;
            this.getList();
        },
        async getList() {
            if (!this.id) {
                return;
            }
            this.loading = true;
            try {
                const query = new URLSearchParams();
                query.append("page", this.pg);
                query.append("filter[]", `${this.owner}|=|${this.id}`);
                const r = await this.$apiResponse(
                    `/keep/log?${query.toString()}`
                );
                let data = [];
                let meta = {};
                let hasNext = false;
                if (r.status == 200) {
                    ({
                        data,
                        meta,
                        pagination: { hasNext },
                    } = await r.json());
                }
                if (hasNext) {
                    this.total = this.pg + 1;
                } else {
                    this.total = this.pg;
                }
                this.list = data.map((d) => {
                    d.user = meta.users[d.user].login;
                    d.operation = meta.operations[d.operation].name;
                    d.product = meta.products[d.keep.product].name;
                    d.cell = meta.cells[d.keep.cell].name;
                    return d;
                });
                this.loaded = true;
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
    },
};
</script>
 
