var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-btn-toggle',{staticClass:"flex-column",attrs:{"mandatory":"","group":"","color":"primary"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.groups),function(group,key,index){return [_vm._l((group),function(ref){
var type = ref.type;
var isActive = ref.isActive;
var title = ref.title;
var icon = ref.icon;
return _c('v-btn',{key:type,staticClass:"ma-0",class:{
                'px-1': _vm.$isMobile(),
                'primary--text': isActive,
            }},[(!_vm.$isMobile())?_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(icon)}}):_vm._e(),_c('span',{staticClass:"flex-grow-1 text-left pr-1 caption",class:{
                    'font-weight-bold': isActive,
                },domProps:{"textContent":_vm._s(title)}}),_c('v-icon',{style:(("opacity: " + (isActive ? 1 : 0) + ";")),attrs:{"right":"","size":"7"},domProps:{"textContent":_vm._s('mdi-circle')}})],1)}),(index !== Object.keys(_vm.groups).length - 1)?_c('v-divider',{key:(key + "-divider"),staticClass:"my-2"}):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }