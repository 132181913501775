<template>
    <v-list-group
        v-if="node.isCategory"
        v-model="open"
        sub-group
        :class="{ 'pl-4': isChild }"
    >
        <template v-slot:activator>
            <v-list-item-title>
                {{ node.name }}
            </v-list-item-title>
        </template>

        <recursive-list
            v-for="child in node.children"
            :key="child.id"

            :node="child"
            :selected-item="selectedItem"
            :isChild="true"
            :menu="menu"

            v-on="$listeners"
        />
    </v-list-group>

    <v-list-item
        v-else
        :input-value="selectedItem.id === node.id"
        @click="$emit('select-item', node.id)"
    >
        <span class="mr-4">#{{ node.id }}</span>
        {{ node.name }}
    </v-list-item>
</template>

<script>
export default {
    name: "RecursiveList",

    props: {
        node: {
            type: Object,
            required: true,
        },

        selectedItem: {
            type: Object,
            required: true,
        },

        isChild: {
            type: Boolean,
            default: false,
        },

        menu: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        open: false,
    }),

    mounted() {
        this.openGroupIfAnyChildIsSelected();
    },

    watch: {
        menu() {
            if (!this.menu) {
                this.open = false;
            } else {
                this.openGroupIfAnyChildIsSelected();
            }
        },
    },

    methods: {
        openGroupIfAnyChildIsSelected(node = this.node) {
            if (node.children.length > 0) {
                node.children.forEach(child => {
                    if (!child.isCategory && this.selectedItem.id === child.id) {
                        this.open = true;
                    }

                    this.openGroupIfAnyChildIsSelected(child);
                });
            }
        },
    },
};
</script>
