<template>
    <v-sheet>
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :loading="busy || disabledItems.length > 0"
            :hide-default-footer="$isMobile()"
            :footer-props="{
                showCurrentPage: true,
            }"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
        >
            <template #top>
                <v-list>
                    <v-list-item>
                        <v-text-field
                            v-model="search"
                            type="string"
                            label="Поиск"
                            dense
                            outlined
                            hide-details
                            prepend-icon="mdi-magnify"
                            :append-icon="search ? 'mdi-close' : ''"

                            @click:append="search = ''"
                        />
                    </v-list-item>
                </v-list>

                <v-divider />
            </template>

            <template
                v-for="key in keys"
                #[`header.${key}`]="{ header: { text, icon } }"
            >
                <span :key="key">
                    <v-icon small>{{ icon }}</v-icon>
                    <span class="ml-2">{{ text }}</span>
                </span>
            </template>

            <template #item.title="{ item }">
                <v-list-item
                    v-if="item.isCategory"
                    :to="{
                        name: 'AccessControl',
                        params: { ...$route.params },
                        query: {
                            ...$route.query,
                            categoryId: String(item.id),
                        },
                    }"
                    exact
                >
                    <v-list-item-icon v-if="!$isMobile()">
                        <v-icon v-text="'mdi-folder-outline'" />
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            <text-highlight-wrapper
                                :text="item.title"
                                :queries="search"
                            />
                        </v-list-item-title>
                        <v-list-item-subtitle v-text="item.childrenCountTotal" />
                    </v-list-item-content>

                    <v-list-item-icon v-if="$isMobile()">
                        <v-icon v-text="'mdi-folder-outline'" />
                    </v-list-item-icon>
                </v-list-item>

                <v-list-item v-else>
                    <v-list-item-content>
                        <text-highlight-wrapper
                            :text="item.title"
                            :queries="search"
                        />
                    </v-list-item-content>
                </v-list-item>
            </template>

            <template
                v-for="key in keys"
                #[`item.${key}`]="{ item }"
            >
                <div
                    v-if="item.isCategory"
                    :key="key"
                >
                    <span
                        v-if="user.id"
                        v-text="`
                            ${user.records.length ? item[key] : item.childrenCountTotal}
                            /
                            ${item.childrenCountTotal}
                        `"
                        class="text--secondary"
                    />
                </div>

                <v-simple-checkbox
                    v-else
                    :key="key"
                    :value="item[key]"
                    :disabled="user.isDummy || disabledItems.includes(item.id)"
                    :ripple="false"
                    color="primary"
                    @input="toggleCheckbox($event, key, item.id)"
                />
            </template>
        </v-data-table>

        <v-divider class="my-2" />

        <v-sheet
            v-if="$isMobile()"
            class="mx-2"
        >
            <v-pagination
                v-model="page"
                :length="pageCount"
            />

            <v-text-field
                :value="itemsPerPage"
                label="Строк на странице"
                type="number"
                min="-1"
                max="15"
                class="mt-2"
                @input="setItemsPerPage"
            />
        </v-sheet>
    </v-sheet>
</template>

<script>
import TextHighlightWrapper from "@c/TextHighlightWrapper";

export default {
    name: "AccessExisting",

    inject: ['$isMobile'],

    components: {
        TextHighlightWrapper,
    },

    props: {
        user: {
            type: Object,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
        keys: {
            type: Array,
            required: true,
        },
        tree: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        busy: false,
        search: '',
        disabledItems: [],
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
    }),

    computed: {
        items() {
            return this.tree.map((item) => {
                const payload = {
                    ...item,
                    id: String(item.id),
                    title: item.title ?? item.name,
                };

                const { records } = this.user;

                if (item.isCategory) {
                    return this.fillCategory(records, payload);
                }

                return this.fillItem(records, payload);
            });
        },
    },

    methods: {
        fillCategory(records, item) {
            this.keys.forEach((key) => {
                item[key] = 0;
            });

            const parseNode = ({ id, isCategory, children }) => {
                if (isCategory) {
                    children.forEach((c) => parseNode(c));

                    return;
                }

                const record = records.find((r) => r.id === id);

                if (!record) {
                    return;
                }

                this.keys.forEach((key) => {
                    item[key] += record.levels[key];
                });
            };

            item.children.forEach((child) => {
                parseNode(child);
            });

            return item;
        },

        fillItem(records, item) {
            item.title = `#${item.id} ${item.title}`;

            this.keys.forEach((key) => {
                item[key] = true;
            });

            if (!records.length) {
                return item;
            }

            const record = records
                .find(({ id }) => String(id) === item.id);

            if (record) {
                const { levels } = record;
                this.keys.forEach((key) => {
                    item[key] = Boolean(levels[key]);
                });
            } else {
                this.keys.forEach((key) => {
                    item[key] = false;
                });
            }

            return item;
        },

        toggleCheckbox($event, key, id) {
            const body = {};

            const item = this.items.find(({ id: itemId }) => itemId === id);

            this.keys.forEach((k) => {
                body[k] = item[k];
            });

            body[key] = $event;

            this.disabledItems.push(id);

            this.$emit('update:existing', {
                id,
                body,
                callback: () => {
                    this.disabledItems = this.disabledItems.filter((i) => i !== id);
                },
            });
        },

        setItemsPerPage($event) {
            if ($event) {
                this.itemsPerPage = parseInt($event, 10);
            }
        },
    },
};
</script>
