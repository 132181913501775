<template>
    <v-sheet
        v-if="false"
        elevation="2"
    >
        <v-container>
            <template v-if="$isMobile()">
                <v-row v-if="event">
                    <v-col>
                        <v-btn
                            block
                            small
                            outlined
                            color="warning"
                            @click="$emit('edit')"
                        >
                            <v-icon
                                v-text="edit.icon"
                                left
                            />
                            <span v-text="edit.text" />
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-btn
                            block
                            small
                            outlined
                            :color="toggle.color"
                            @click="$emit('toggle')"
                        >
                            <v-icon
                                v-text="toggle.icon"
                                left
                            />
                            <span v-text="toggle.text" />
                        </v-btn>
                    </v-col>
                </v-row>
            </template>

            <template v-else>
                <v-row>
                    <v-col>
                        <v-btn
                            v-if="event"
                            block
                            small
                            outlined
                            color="warning"
                            @click="$emit('edit')"
                        >
                            <v-icon
                                v-text="edit.icon"
                                left
                            />
                            <span v-text="edit.text" />
                        </v-btn>
                    </v-col>

                    <v-col>
                        <v-btn
                            block
                            small
                            outlined
                            :color="toggle.color"
                            @click="$emit('toggle')"
                        >
                            <v-icon
                                v-text="toggle.icon"
                                left
                            />
                            <span v-text="toggle.text" />
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </v-container>

        <v-divider v-if="event" />

        <event-info
            v-if="event"
            #default="{ info }"
            :event="event"
        >
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle
                        v-for="{ label, value } in info"
                        :key="label"
                        class="green--text text-wrap"
                    >
                        <span
                            v-if="label"
                            v-text="`${label}: `"
                            class="font-weight-medium"
                        />
                        <span v-text="value" />
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </event-info>
    </v-sheet>
</template>

<script>
import EventInfo from "@c/event-planner/EventInfo";

export default {
    name: 'DialogActivator',

    inject: ['$isMobile'],

    components: {
        EventInfo,
    },

    props: ['event'],

    computed: {
        toggle() {
            return this.event
                ? {
                    icon: 'mdi-repeat-off',
                    text: 'Отменить',
                    color: 'error',
                }
                : {
                    icon: 'mdi-repeat',
                    text: 'Задать повторение',
                    color: 'success',
                };
        },

        edit() {
            return {
                icon: 'mdi-pencil',
                text: 'Редактировать',
            };
        },
    },
};
</script>
