<template>
    <div>
        <full-overlay v-model="loading" />
        <v-row>
            <v-col v-for="l in list" :key="l.name">
                <v-card>
                    <v-card-title>
                        <v-icon left>mdi-{{ l.icon }}</v-icon>
                        {{ l.name }}
                    </v-card-title>
                    <v-list>
                        <v-list-item
                            v-for="c in l.childs"
                            :key="c.name"
                            :to="{ name: c.alias }"
                        >
                            <v-list-item-title>
                                {{ c.name }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import FullOverlay from "@c/FullOverlay";
export default {
    name: "Home",
    components: {
        FullOverlay,
    },
    data: () => ({
        loading: false,
        list: [],
    }),
    mounted() {
        this.getList();
    },
    watch: {
        $route() {
            this.getList();
        },
    },
    methods: {
        async getList() {
            this.loading = true;
            try {
                this.list = await this.$api("/profile/panel");

                // @todo Убрать, когда сервер начнёт присылать
                const settings = this.list.find((l) => l.icon === 'wrench');
                if (settings && !settings.childs.find(({ alias }) => alias === 'AccessControl')) {
                    settings.childs.push({
                        alias: 'AccessControl',
                        name: this.$t('pages.accessControl'),
                    });
                }
                const apps = this.list.find((l) => l.icon === 'apps');
                if (apps && !apps.childs.find(({ alias }) => alias === 'EventPlanner')) {
                    apps.childs.push({
                        alias: 'EventPlanner',
                        name: this.$t('pages.eventPlanner'),
                    });
                }
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
    },
};
</script>
