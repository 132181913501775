<template>
    <adaptive-dialog
        :dialog="dialog"
        :busy="busy"
        max-width="500"
        :btnSubmit="{
            text: 'Подтвердить',
            color: 'error'
        }"

        @close="dialog = false"
        @submit="confirm"
    >
        <template #title>
            Подтвердите закрытие
        </template>

        <template #body>
            <div class="body-1 text--primary">
                <div>Вы уверены, что хотите закрыть диалоговое окно?</div>
                <div class="error--text font-weight-medium">Внесённые изменения не сохранятся!</div>
            </div>
        </template>
    </adaptive-dialog>
</template>

<script>
import AdaptiveDialog from "@c/norton-commander/AdaptiveDialog";

export default {
    name: "CloseConfirmation",

    components: {
        AdaptiveDialog,
    },

    data: () => ({
        busy: false,
        dialog: false,
    }),

    methods: {
        open() {
            this.dialog = true;
        },

        confirm() {
            this.dialog = false;
            this.$emit('close');
        },
    },
};
</script>
