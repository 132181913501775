<template>
    <v-autocomplete
        :label="field.name"
        clearable
        hide-no-data
        dense
        :loading="loading"
        @update:search-input="search"
        :items="items"
        :value="value"
        :rules="rules"
        item-text="name"
        item-value="id"
        @input="$emit('input', $event)"
        return-object
    />
</template>

<script>
import FieldBase from "./FieldBase";
export default {
    name: "FieldCellrange",
    extends: FieldBase,
    data: () => ({
        loading: false,
        searchItems: [],
        targetFields: null,
    }),
    props: {
        value: {
            type: Object,
        },
    },
    computed: {
        rules() {
            const rules = [];
            if (this.field.perks.includes("required")) {
                rules.push((v) => !!v || this.$t("form.required"));
            }
            return rules;
        },
        items() {
            const items = [];
            if (this.value) {
                items.push(this.value);
            }
            return [...items, ...this.searchItems];
        },
    },
    methods: {
        getDefaultValue: () => null,
        getValue() {
            return this.value?.id ?? "";
        },
        search(s) {
            clearTimeout(this.search.t);
            if (!s || !s.length || s == this.value?.name) {
                return;
            }
            this.search.t = setTimeout(async () => {
                this.loading = true;
                try {
                    if (!this.targetFields) {
                        this.targetFields = (
                            await this.$api(`/cellrange/fields`)
                        )
                            .filter(
                                (f) =>
                                    f.type == "storage" ||
                                    f.perks.includes("sort")
                            )
                            .reduce((acc, f) => {
                                acc[
                                    f.type == "storage" ? "storage" : "prefix"
                                ] = f.id;
                                return acc;
                            }, {});
                    }
                    const query = new URLSearchParams();
                    query.append(
                        "filter[]",
                        `${this.targetFields.storage}|=|${this.meta.storage}`
                    );
                    query.append(
                        "filter[]",
                        `${this.targetFields.prefix}|∋|${s}`
                    );
                    const r = await this.$apiResponse(
                        `/entity/cellrange?${query.toString()}`
                    );
                    let data = [];
                    if (r.status == 200) {
                        ({ data } = await r.json());
                    }
                    this.searchItems = data.map((d) => ({
                        id: d.id,
                        name: d.name,
                    }));
                } catch (e) {
                    this.$error(e.message);
                }
                this.loading = false;
            }, 500);
        },
    },
};
</script>